import { omit } from "lodash";

import { Configuration, GroupConfig, GroupConfigHydrated } from "fond/types";

import { apiSlice } from "./apiSlice";
import { draftConfigEntityAdapter, draftSlice } from "./draftSlice";
import { transformGroupConfigResponse } from "./versionsSlice";

export type GetGroupsResponse = {
  Items: GroupConfigHydrated[];
};

/**
 * Groups API Slice
 */
export const groupsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createGroupConfig: build.mutation<GroupConfig, { mapLayerConfigId: string; newGroupConfig: GroupConfig }>({
      query: ({ newGroupConfig }) => ({
        url: "/v2/group-configurations",
        method: "POST",
        body: omit(newGroupConfig, "GlobalPosition"),
      }),
      onQueryStarted: async ({ mapLayerConfigId }, { dispatch, queryFulfilled }) => {
        const { data: newGroupConfig } = await queryFulfilled;
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            if (draft.Data) {
              // Add the new sublayer
              draftConfigEntityAdapter.upsertOne(draft.Data, newGroupConfig);

              // Add the group to the parent layers Children collection
              const updatedMapChildren = [...draft.MapChildren];
              updatedMapChildren.splice(0, 0, newGroupConfig.ID);
              Object.assign(draft, { ...draft, MapChildren: updatedMapChildren });
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    updateGroupConfig: build.mutation<GroupConfigHydrated, { mapLayerConfigId: string; groupConfig: GroupConfigHydrated }>({
      query: ({ groupConfig }) => ({
        url: `/v2/group-configurations/${groupConfig.ID}`,
        method: "PUT",
        body: groupConfig,
      }),
      onQueryStarted: async ({ mapLayerConfigId, groupConfig }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft: Configuration) => {
            if (draft.Data) {
              draftConfigEntityAdapter.upsertOne(draft.Data, transformGroupConfigResponse(groupConfig));
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
    deleteGroupConfig: build.mutation<undefined, { mapLayerConfigId: string; groupConfigId: string }>({
      query: ({ groupConfigId }) => ({
        url: `/v2/group-configurations/${groupConfigId}`,
        method: "DELETE",
      }),
      onQueryStarted: async ({ mapLayerConfigId, groupConfigId }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          draftSlice.util.updateQueryData("getDraft", mapLayerConfigId, (draft) => {
            if (draft.Data) {
              draftConfigEntityAdapter.removeOne(draft.Data, groupConfigId);
              Object.assign(draft, { ...draft, MapChildren: draft.MapChildren.filter((childId) => childId !== groupConfigId) });
            }
          })
        );
        await queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useCreateGroupConfigMutation, useUpdateGroupConfigMutation, useDeleteGroupConfigMutation } = groupsSlice;
