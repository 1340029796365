export const Pages = {
  map: "map",
  bom: "bom",
};

const actions = {
  LOAD: "PAGE/LOAD",
};

export function load(page) {
  return { type: actions.LOAD, page: page };
}

export function reducer(state = null, action) {
  if (action.type === actions.LOAD) {
    return action.page;
  } else {
    return state;
  }
}
