import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { selectAllVersionGroupConfigs, selectAllVersionLayerConfigsInOrder, selectAllVersionStyles } from "fond/api";
import { Map } from "fond/cityPlanner/Map";
import BaseMapButtons from "fond/map/BaseMapButtons";
import { MapContext } from "fond/map/MapProvider";
import { setLayersVisibility } from "fond/project/redux";
import { Store } from "fond/types";
import { isValidBoundingBox } from "fond/utils";
import { useAppDispatch } from "fond/utils/hooks";

interface IProps {
  cityId: string;
}

const CityPlannerFolderMap: React.FC<IProps> = ({ cityId }) => {
  const { map } = useContext(MapContext);
  const dispatch = useAppDispatch();
  const layerConfigs = useSelector((state: Store) => selectAllVersionLayerConfigsInOrder(state, cityId));
  const groupConfigs = useSelector((state: Store) => selectAllVersionGroupConfigs(state, cityId));
  const styles = useSelector((state: Store) => selectAllVersionStyles(state, cityId));
  const { view, project } = useSelector((state: Store) => state.project.projects[cityId] || {});

  /**
   * If the user doesn't have a map `view` stored in local storage, we inject the layer
   * visibility records to allow the project map tile to load properly
   */
  useEffect(() => {
    if (!view?.layers && layerConfigs && groupConfigs) {
      dispatch(setLayersVisibility({ projectId: cityId, layerConfigs: layerConfigs, groupConfigs: groupConfigs }));
    }
  }, [dispatch, view, layerConfigs, groupConfigs, cityId]);

  const onClickZoomToDesignHandler = () => {
    if (project.BoundingBox && isValidBoundingBox(project.BoundingBox)) {
      map?.fitBounds(project.BoundingBox);
    }
  };

  const onClickZoomInHandler = () => {
    map?.zoomIn();
  };

  const onClickZoomOutHandler = () => {
    map?.zoomOut();
  };

  return layerConfigs && styles && view?.layers ? (
    <Map layerView={view.layers} styles={styles} layerConfigs={layerConfigs} showFooter={false}>
      <Box className="right-sidebar-section">
        <div />
        <BaseMapButtons
          editMode="none"
          onClickZoomToDesign={onClickZoomToDesignHandler}
          onClickZoomIn={onClickZoomInHandler}
          onClickZoomOut={onClickZoomOutHandler}
          currentLocationButton={false}
        />
      </Box>
    </Map>
  ) : null;
};

export default CityPlannerFolderMap;
