import React from "react";
import { useSelector } from "react-redux";
import { Close, Delete, FileCopy } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";
import { useDebouncedCallback } from "use-debounce";

import { selectVersionMlcId, useCreateStyleConfigMutation, useDeleteStyleConfigMutation, useUpdateStyleConfigMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { setSelectedId } from "fond/redux/styles";
import { LayerStyle } from "fond/types/ProjectLayerConfig";
import { makeUuid } from "fond/utils";
import { useAppDispatch } from "fond/utils/hooks";

import { Actions, Container, IconButton, OnClickInput } from "./styleHeader.styles";

interface IProps {
  style: LayerStyle;
}

export const StyleHeader: React.FC<IProps> = ({ style }: IProps) => {
  const dispatch = useAppDispatch();
  const mapLayerConfigId = useSelector(selectVersionMlcId);
  const [createStyleConfig] = useCreateStyleConfigMutation();
  const [updateStyleConfig] = useUpdateStyleConfigMutation();
  const [deleteStyleConfig] = useDeleteStyleConfigMutation();

  const handleOnClose = () => {
    dispatch(setSelectedId(""));
  };

  const handleOnRename = useDebouncedCallback((name: string) => {
    const updatedStyle = { ...style, Name: name };
    updateStyleConfig({ mapLayerConfigId: mapLayerConfigId, style: updatedStyle });
  }, 500);

  // Creates a copy of the style
  const handleOnDuplicate = () => {
    mixpanel.track("Style Editor : duplicate style");
    const duplicate: LayerStyle = {
      ...style,
      ID: makeUuid(),
      Name: `${style.Name} (copy)`,
    };
    dispatch(setSelectedId(duplicate.ID));

    createStyleConfig({ mapLayerConfigId: mapLayerConfigId, newStyleConfig: duplicate });
  };

  // Marks the current style for deletion
  const handleOnDelete = () => {
    deleteStyleConfig({ mapLayerConfigId, style });
    dispatch(setSelectedId(""));
  };

  return (
    <Container data-testid="style-header">
      <OnClickInput value={style.Name} onCommit={handleOnRename} />
      <Divider orientation="vertical" />
      <Actions>
        <Tooltip title="Duplicate style">
          <IconButton onClick={handleOnDuplicate} data-testid="duplicate-style">
            <FileCopy />
          </IconButton>
        </Tooltip>
        <Divider orientation="vertical" />
        <Tooltip title="Delete">
          <IconButton onClick={handleOnDelete} data-testid="delete-style">
            <Delete color="error" />
          </IconButton>
        </Tooltip>
      </Actions>
      <Divider orientation="vertical" />
      <IconButton onClick={handleOnClose}>
        <Close />
      </IconButton>
    </Container>
  );
};

export default StyleHeader;
