import React, { useState } from "react";
import { Form, FormSpy } from "react-final-form";
import { Alert, Box, Button, FormLabel } from "@mui/material";
import { FormApi } from "final-form";

import { LoadingButton, Palette } from "ui";

import { useLazyGetLayerClassificationQuery } from "fond/api";
import { SelectField } from "fond/form/fields";
import { NumericField } from "fond/form/fields/NumericField";
import { Attribute, ColorGradient, LayerClassification } from "fond/types";
import { LayerConfig } from "fond/types/ProjectLayerConfig";
import { generateColors } from "fond/utils/colors";
import { required } from "fond/utils/validation";

import ClassifyData from "./ClassifyData";
import { getFondStyleTypeFromGeometry } from "./helper";
import StyleSettingsModal from "./StyleSettingsModal";

import { Adornment } from "../LayerAttributes/layerAttributes.styles";

export interface IClassifyFormData {
  attributeId?: string;
  colorRamp?: ColorGradient;
  range?: "equal_intervals" | "quantiles";
  groupSize?: number;
  selected: LayerClassification[];
}

interface IProps {
  colors: string[];
  data: LayerClassification[] | null;
  initialValues: Partial<IClassifyFormData>;
  isSubmitting?: boolean;
  layerConfig: LayerConfig;
  onChange(values: IClassifyFormData): void;
  setData(data: LayerClassification[]): void;
}

const ClassifyForm: React.FC<IProps> = ({ colors, data, initialValues, isSubmitting = false, layerConfig, setData, onChange }: IProps) => {
  const [getClassification, { isFetching, isLoading }] = useLazyGetLayerClassificationQuery();
  const [error, setError] = useState<string | null>(null);
  const [showSettings, setShowSettings] = useState(false);
  const fondStyleType = getFondStyleTypeFromGeometry(layerConfig.GeometryType);

  /**
   * Requests the layer classification data from the backend &
   * clears previous classifications.
   */
  const handleOnClassifyClick = ({ attributeId, method, groups }: { attributeId: string; method?: string; groups?: number }) => {
    // On classification we remove past classifications & any selected row information.
    reset();

    // Request to classification information via the API.
    getClassification({ layerConfigId: layerConfig.ID, attributeConfigId: attributeId, method: method, groups: groups })
      .unwrap()
      .then((response) => {
        if (response) {
          setData([
            ...response.Classifications.map((item) => ({
              ...item,
              GeometryType: layerConfig.GeometryType,
            })),
            {
              Label: "Other",
              FilterConfiguration: {
                Type: "other",
              },
              GeometryType: layerConfig.GeometryType,
            },
          ]);
        }
      })
      .catch((e) => {
        setError(e.data.message);
      });
  };

  const getAdornment = (type: Attribute["Type"]) => {
    if (type === "INTEGER") return <Adornment>123</Adornment>;
    if (type === "REAL") return <Adornment>1.50</Adornment>;
    return <Adornment>abc</Adornment>;
  };

  const onFormUpdate = (values: IClassifyFormData, _: FormApi<IClassifyFormData>) => {
    onChange(values);
  };

  const reset = () => {
    setError(null);
  };

  return (
    <Form<IClassifyFormData>
      initialValues={initialValues}
      onSubmit={onFormUpdate}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box>
              <Box>
                <SelectField
                  data-testid="attribute-options"
                  name="attributeId"
                  label="Attribute"
                  displayEmpty
                  placeholder="Select"
                  options={
                    layerConfig.Attributes?.map((attribute) => ({
                      startAdornment: getAdornment(attribute.Type),
                      value: attribute.ID,
                      displayValue: attribute.Name,
                    })) || []
                  }
                  size="small"
                  fullWidth
                  disabled={isSubmitting}
                  validate={required}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <SelectField
                  name="colorRamp"
                  label="Color ramp"
                  displayEmpty
                  placeholder="Select"
                  options={[
                    {
                      value: "",
                      displayValue: "Random colors",
                    },
                    ...Object.values(ColorGradient).map((variant) => ({
                      value: variant,
                      displayValue: variant,
                      startAdornment: <Palette colors={generateColors(variant, 5)} sx={{ mr: 1 }} />,
                    })),
                  ]}
                  disabled={isSubmitting}
                  size="small"
                  fullWidth
                />
              </Box>
              <FormSpy subscription={{ values: true }}>
                {({ values }) => {
                  const attribute = layerConfig.Attributes?.find(({ ID }) => ID === values.attributeId);
                  if (!attribute || attribute.Type === "STRING") return null;

                  return (
                    <Box display="flex" sx={{ mt: 2, gap: 1 }}>
                      <SelectField
                        name="range"
                        label="Range"
                        displayEmpty
                        placeholder="Select"
                        options={[
                          {
                            value: "quantiles",
                            displayValue: "Equal count (quantile)",
                          },
                          {
                            value: "equal_intervals",
                            displayValue: "Equal interval",
                          },
                        ]}
                        size="small"
                        fullWidth
                        disabled={isSubmitting}
                      />
                      <NumericField
                        data-testid="group-size-input"
                        name="groupSize"
                        label="No. of groups"
                        fullWidth
                        type="number"
                        validate={required}
                        disabled={isSubmitting}
                      />
                    </Box>
                  );
                }}
              </FormSpy>
            </Box>
            {error && (
              <Alert severity="warning" sx={{ my: 1 }}>
                {error}
              </Alert>
            )}
            <Box sx={{ my: 1, display: "flex", justifyContent: "flex-end" }}>
              <FormSpy subscription={{ values: true, invalid: true }}>
                {({ invalid, values }) => (
                  <LoadingButton
                    data-testid="generate-classification-button"
                    color="primary"
                    type="submit"
                    size="small"
                    disabled={invalid || isSubmitting}
                    loading={isFetching || isLoading}
                    onClick={() => {
                      const attribute = layerConfig.Attributes?.find(({ ID }) => ID === values.attributeId);
                      if (attribute) {
                        const method = attribute.Type === "STRING" ? "distinct_values" : values?.range;
                        const groups = attribute.Type !== "STRING" ? values?.groupSize : undefined;
                        handleOnClassifyClick({ attributeId: values.attributeId, method: method, groups: groups });
                      }
                    }}
                  >
                    Generate classifications
                  </LoadingButton>
                )}
              </FormSpy>
              {showSettings && <StyleSettingsModal type={fondStyleType} onClose={() => setShowSettings(false)} />}
            </Box>
            <FormSpy
              subscription={{ values: true, valid: true, pristine: true }}
              onChange={({ valid, pristine }) => {
                if (valid && !pristine) handleSubmit();
              }}
            />
            <Box mt={4} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <FormLabel>Layers</FormLabel>
              {data && data?.length > 0 && (
                <Button data-testid="style-settings-button" onClick={() => setShowSettings(true)} variant="outlined" size="small">
                  Style settings
                </Button>
              )}
            </Box>
            <Box sx={{ height: 300, mt: 2 }}>
              <ClassifyData colors={colors} data={data} />
            </Box>
          </form>
        );
      }}
    />
  );
};

export default ClassifyForm;
