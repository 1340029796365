import React, { useCallback, useMemo } from "react";
import { useField } from "react-final-form";
import { ColDef, RowDataUpdatedEvent, SelectionChangedEvent } from "@ag-grid-community/core";

import { LayerClassification } from "fond/types";
import { AgGrid } from "fond/widgets";

import CellRenderer from "./CellRenderer";
import { Empty } from "./Empty";
import { useDefaultStyle } from "./helper";

interface IProps {
  colors: string[];
  data: LayerClassification[] | null;
}

const ClassifyData: React.FC<IProps> = ({ colors, data }: IProps) => {
  const {
    input: { onChange },
  } = useField("selected");

  const defaultStyle = useDefaultStyle();

  const getRowColor = useCallback((index: number) => colors[index], [colors]);

  const columns: ColDef[] = useMemo(() => {
    return [
      {
        field: "Style",
        headerName: "Style",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 125,
        cellRenderer: CellRenderer,
        cellRendererParams: { style: defaultStyle, getRowColor: getRowColor },
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        sortable: false,
      },
      {
        field: "Label",
        headerName: "Label",
        flex: 1,
        cellDataType: "text",
        suppressHeaderMenuButton: true,
        suppressMovable: true,
        resizable: false,
        sortable: false,
      },
    ];
  }, [defaultStyle, getRowColor]);

  const onSelectionChange = (event: SelectionChangedEvent) => {
    onChange(event.api.getSelectedRows());
  };

  const onRowDataUpdated = (event: RowDataUpdatedEvent) => {
    event.api.selectAll();
  };

  return (
    <AgGrid
      autoSizeColumns={false}
      columnDefs={columns}
      rowData={data}
      gridOptions={{ rowGroupPanelShow: "never", sideBar: false, domLayout: "normal", noRowsOverlayComponent: Empty }}
      onSelectionChanged={onSelectionChange}
      onRowDataUpdated={onRowDataUpdated}
      size="compact"
      variant="outlined"
    />
  );
};

export default ClassifyData;
