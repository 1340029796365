import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Clear, ColorLens, Functions, Visibility, VisibilityOff } from "@mui/icons-material";
import { Badge, IconButton, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { InteractionMode, Tree, TreeEnvironmentRef, TreeItem, TreeItemIndex, TreeItemRenderContext } from "ui/Tree";

import {
  getUserPreferenceValue,
  selectAllVersionGroupConfigs,
  selectLayerFeatureTotalsForCostToServer,
  selectVersionConfig,
  useGetIconsQuery,
  useGetLayerFeatureTotalsQuery,
  useGetProjectQuery,
  useLazyGetLayerFeatureTotalsQuery,
} from "fond/api";
import { usePermissionCheck } from "fond/hooks/usePermissionCheck";
import { commentGroupConfig } from "fond/layers";
import { TabHeader } from "fond/layout";
import { getCurrentProject, getCurrentProjectView, setLayerVisibility, toggleLayerVisibility } from "fond/project/redux";
import { getTypeCountByResolutionState } from "fond/redux/comments";
import { GroupConfig, MapLayerConfig, Store, UserPreferenceKey, UserPreferenceSubKey } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";
import { generateTreeItems, isVisible } from "fond/utils/configurations";
import { CTS_GROUPCONFIG_ID } from "fond/utils/costToServeTransformers";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions } from "fond/utils/permissions";
import { BlockSpinner } from "fond/widgets";

import ItemTitle from "./ItemTitle";
import { LegendContext } from "./LegendProvider";
import LegendWarning from "./LegendWarning";

import { LegendContainer, LegendInner } from "./Legend.styles";

export type LegendItemType = MapLayerConfig | LayerConfig | SublayerConfig | LayerStyle | GroupConfig;

const isControlKey = (e: React.MouseEvent) => e.ctrlKey || (navigator.platform.toUpperCase().indexOf("MAC") >= 0 && e.metaKey);
const LEGEND_TREE_ID = "legendTreeId";

const useCustomStyles = makeStyles((theme: Theme) => ({
  iconGroup: {
    borderRadius: 4,
    "&.active": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    "&:hover.active": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  isDisabled: {
    color: theme.palette.action.disabled,
  },
  badge: {
    "& .MuiBadge-badge:not(.MuiBadge-invisible)": {
      fontSize: 10,
      fontWeight: "100",
      borderRadius: 3,
      padding: "0 4px",
      height: 12,
      transform: "scale(1) translate(75%, -50%)",
      background: theme.palette.secondary.main,
    },
  },
}));

const Legend: React.FC = () => {
  const dispatch = useAppDispatch();
  const environmentRef = useRef<TreeEnvironmentRef>();
  const navigate = useNavigate();
  const classes = useCustomStyles();
  const versionId = useSelector((state: Store) => state.project.versionId);
  const projectId = useSelector((state: Store) => state.project.projectId);
  const config = useSelector((state: Store) => selectVersionConfig(state, versionId));
  const groupConfigs = useSelector((state: Store) => selectAllVersionGroupConfigs(state, versionId));
  const { data: icons } = useGetIconsQuery(undefined);

  const commentLayerCount = useSelector((state: Store) => getTypeCountByResolutionState(state)(state.comments.filters));
  const layerView = useSelector((state: Store) => getCurrentProjectView(state.project).layers);
  const permissionLevel = useSelector((state: Store) => getCurrentProject(state.project).Permission.Level);
  const { data: project } = useGetProjectQuery(projectId);
  const canEditStyles = usePermissionCheck(Actions.PROJECT_EDIT_STYLES, permissionLevel);
  const { data: totals } = useGetLayerFeatureTotalsQuery({ versionId });
  const [loadFeatureTotals] = useLazyGetLayerFeatureTotalsQuery();
  const getFeatureCount = useSelector((state: Store) => selectLayerFeatureTotalsForCostToServer(state, versionId));
  const hasCustomLayerConfig = useSelector((state: Store) => getCurrentProject(state.project)?.HasCustomLayerConfig);
  const showStyleEditorNewBadge = useSelector((state: Store) =>
    getUserPreferenceValue(state, UserPreferenceKey.UI_TOUR, UserPreferenceSubKey.STYLE_EDITOR)
  );
  const [featureTotals, setFeatureTotals] = useState<{ [key: string]: { count: number | null; length: number | null } }>(totals ?? {});
  const [showTotals, setShowTotals] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<TreeItemIndex[]>([]);
  const [focusedItem, setFocusedItem] = useState<TreeItemIndex>();
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[]>([CTS_GROUPCONFIG_ID, ...pickIDs(groupConfigs), commentGroupConfig.ID]);

  const isEntityVisible = (key: string) => isVisible(config, { id: key, layerView: layerView });
  const handleVisibilityToggle = (key: string) => dispatch(toggleLayerVisibility(projectId, key, config));

  /**
   * Generate the data source for the tree based on the collection of configuration sources
   */
  const treeItems = useMemo(() => generateTreeItems({ config: config, showStyles: false }), [config]);
  const context = useMemo(
    () => ({
      config: config,
      icons: icons,
      isEntityVisible: isEntityVisible,
      onEntityVisibilityToggle: handleVisibilityToggle,
      showTotals: showTotals,
      systemOfMeasurement: project?.SystemOfMeasurement || "imperial",
      totals: featureTotals,
    }),
    [config, icons, isEntityVisible, handleVisibilityToggle, showTotals, featureTotals, project?.SystemOfMeasurement]
  );

  /**
   * When legend loses focus deselect items
   */
  const legendRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = () => {
      if (legendRef.current && window.event?.target instanceof Element && !legendRef.current.contains(window.event?.target)) {
        environmentRef.current?.selectItems([], LEGEND_TREE_ID);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [legendRef]);

  /**
   * On expanding items we determine if we should load feature totals for sublayers
   */
  const handleOnExpand = useCallback(
    (item: TreeItem<LegendItemType | null>, treeId: string) => {
      if (showTotals && item.data?.Type === "LAYER" && item.data.Children.length > 0) {
        if (item.data.SubType === "COST_TO_SERVE") {
          const ctsSublayerTotals = getFeatureCount(item.data);
          // For cost to serve layers we need to calculate feature totals using the filter configuration client side.
          setFeatureTotals((prev) => ({ ...prev, ...ctsSublayerTotals }));
        } else {
          loadFeatureTotals({ versionId: versionId, layerId: item.data.Key }).then((response) => {
            setFeatureTotals((prev) => ({ ...prev, ...response.data }));
          });
        }
      }
      setExpandedItems((prev) => [...prev, item.index]);
    },
    [getFeatureCount, showTotals, loadFeatureTotals, versionId]
  );

  const handleOnCollapse = (item: TreeItem<LegendItemType | null>, treeId: string) =>
    setExpandedItems(expandedItems?.filter((expandedItemIndex) => expandedItemIndex !== item.index));
  const handleOnSelect = useCallback((items: TreeItemIndex[], treeId: string) => setSelectedItems(items), []);
  const handleOnFocus = useCallback((item: TreeItem<LegendItemType | null>, treeId: string) => setFocusedItem(item.index), []);
  const handleOnToggle = () => dispatch(setLayerVisibility(projectId, selectedItems, visibilityState));

  /**
   * Monitor showTotals & if sublayers are expanded we need to request their totals
   */
  useEffect(() => {
    if (showTotals) {
      environmentRef.current?.viewState?.[LEGEND_TREE_ID]?.expandedItems?.forEach((id) => {
        const treeItem = environmentRef.current?.items[id];
        if (treeItem) handleOnExpand(treeItem, LEGEND_TREE_ID);
      });
    }
  }, [showTotals, handleOnExpand]);

  /**
   * Maintain a collection of Feature Totals that can be built on as sublayers are lazy loaded.
   * Note: we manually inject the commentLayerCount which changes as the user filters them
   */
  useEffect(() => {
    setFeatureTotals({ ...totals, ...commentLayerCount });
  }, [config, getFeatureCount, totals, commentLayerCount]);

  /**
   * Returns the TreeItem's title value based on the LegendItemType
   */
  const getItemTitle = ({ data: itemData }: TreeItem<LegendItemType>) => {
    if (itemData.Type === "STYLE") return itemData.Name;
    if (itemData.Type === "MapLayerConfig") return "";
    return itemData.Label;
  };

  /**
   * Custom renderer for the ItemTitle
   */
  const renderItemTitle = (renderProps: { item: TreeItem<LegendItemType | null>; title: string; context: TreeItemRenderContext<never> }) => (
    <ItemTitle {...renderProps} />
  );

  /**
   * Determine if the current selected items are either all on, off or mixed
   */
  const visibilityState: boolean = useMemo(() => {
    const values = selectedItems.map((index) => {
      // If there is no value within layerView we default to true
      const visible = layerView[index];
      return visible !== undefined ? visible : true;
    });
    const hasTrue = values.some((value) => value);
    const hasFalse = values.some((value) => !value);
    return !(hasTrue && !hasFalse) || !hasTrue;
  }, [selectedItems, layerView]);

  /**
   * Monitor showTotals & if sublayers are expanded we need to request their totals
   */
  useEffect(() => {
    if (showTotals) {
      environmentRef.current?.viewState?.[LEGEND_TREE_ID]?.expandedItems?.forEach((id) => {
        const treeItem = environmentRef.current?.items[id];
        if (treeItem) handleOnExpand(treeItem, LEGEND_TREE_ID);
      });
    }
  }, [showTotals, handleOnExpand]);

  /**
   * Maintain a collection of Feature Totals that can be built on as sublayers are lazy loaded.
   * Note: we manually inject the commentLayerCount which changes as the user filters them
   */
  useEffect(() => {
    setFeatureTotals({ ...totals, ...commentLayerCount });
  }, [totals, commentLayerCount]);

  return (
    <LegendContext.Provider value={context}>
      <LegendContainer data-testid="legend" ref={legendRef}>
        <TabHeader
          leftAdornments={
            selectedItems.length > 0 ? (
              <>
                <Tooltip title="Clear selection" PopperProps={{ disablePortal: true }}>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => environmentRef.current?.selectItems([], LEGEND_TREE_ID)}
                    className={classes.iconGroup}
                    data-testid="toggle-totals-button"
                  >
                    <Clear />
                  </IconButton>
                </Tooltip>
                {selectedItems.length} selected
              </>
            ) : (
              <>
                <Tooltip title={`${showTotals ? "Hide" : "Show"} layer totals`} PopperProps={{ disablePortal: true }}>
                  <IconButton
                    aria-label="info"
                    size="small"
                    onClick={() => setShowTotals(!showTotals)}
                    className={classes.iconGroup}
                    data-testid="toggle-totals-button"
                  >
                    <Functions />
                  </IconButton>
                </Tooltip>
                {hasCustomLayerConfig && (
                  <Tooltip title="Edit Styles" PopperProps={{ disablePortal: true }}>
                    <Badge
                      className={classes.badge}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent="new"
                      color="primary"
                      invisible={!showStyleEditorNewBadge}
                      data-testid="style-editor-new-badge"
                    >
                      <IconButton
                        aria-label="info"
                        disabled={!canEditStyles}
                        size="small"
                        onClick={() => navigate(`/styles/${projectId}`)}
                        className={classes.iconGroup}
                        data-testid="edit-styles-button"
                      >
                        <ColorLens />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                )}
              </>
            )
          }
          rightAdornments={
            selectedItems.length > 0 && (
              <Tooltip title={`${visibilityState ? "Show" : "Hide"} layers`} PopperProps={{ disablePortal: true }}>
                <IconButton
                  color="primary"
                  aria-label="visibility"
                  size="small"
                  onClick={handleOnToggle}
                  className={classes.iconGroup}
                  data-testid="toggle-visibility-button"
                >
                  {visibilityState ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Tooltip>
            )
          }
        />
        <LegendInner className={classNames("legend-inner", "customScrollbars")} data-testid="legend-inner">
          <LegendWarning versionId={versionId} />
          {config && layerView ? (
            <Tree<LegendItemType | null>
              id={LEGEND_TREE_ID}
              ref={environmentRef}
              items={treeItems}
              // We use controlled mode to allow treeItems changes to be
              // reflected in the legend (e.g. Dynamically adding cost to serve layers)
              mode="controlled"
              getItemTitle={getItemTitle}
              getItemClass={(item) => `rct-tree-item-button-${item.data?.Type}`}
              renderItemTitle={renderItemTitle}
              onCollapseItem={handleOnCollapse}
              onExpandItem={handleOnExpand}
              onSelectItems={handleOnSelect}
              onFocusItem={handleOnFocus}
              viewState={{
                expandedItems,
                selectedItems,
                focusedItem,
              }}
              /**
               * We use a custom interaction mode to allow the user to toggle selection via clicking.
               * The default behaviour is to select on click (not deselect).
               */
              defaultInteractionMode={{
                mode: "custom",
                extends: InteractionMode.ClickArrowToExpand,
                createInteractiveElementProps: (item, id, actions, renderFlags) => ({
                  onClick: (e) => {
                    actions.focusItem();
                    if (e.shiftKey) {
                      actions.selectUpTo(!e.ctrlKey);
                    } else if (isControlKey(e)) {
                      if (renderFlags.isSelected) {
                        actions.unselectItem();
                      } else {
                        actions.addToSelectedItems();
                      }
                    } else {
                      if (renderFlags.isSelected && environmentRef.current?.viewState[id]?.selectedItems?.length === 1) {
                        actions.unselectItem();
                      } else {
                        actions.selectItem();
                      }

                      if (!item.isFolder || environmentRef.current?.canInvokePrimaryActionOnItemContainer) {
                        actions.primaryAction();
                      }
                    }
                  },
                  onFocus: () => {
                    actions.focusItem();
                  },
                  tabIndex: renderFlags.isFocused ? 0 : -1,
                }),
              }}
            />
          ) : (
            <BlockSpinner containerProps={{ height: "100%" }} />
          )}
        </LegendInner>
      </LegendContainer>
    </LegendContext.Provider>
  );
};

export default Legend;
