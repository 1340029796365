import { alpha, TableCell, TableRow } from "@mui/material";
import { grey } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";

import { LinearProgressWithLabel } from "fond/widgets";

export const ModifiedTableRow = styled(TableRow)(({ theme }) => ({
  // Make the table header more compact by minimising vertical space.
  display: "table",
  tableLayout: "fixed",
  width: "100%",
  borderTop: `1px solid ${grey[300]}`,
  // Add background color for even rows.
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Helper components for export table cell styling.
export const HeaderCell = styled(TableCell)(({ theme, width }) => ({
  // Make the table header more compact by minimising vertical space.
  height: "unset",
  border: "unset",
  backgroundColor: alpha(theme.palette.biarri.secondary.grey, 0.1),
  width: width,
}));

export const BodyCell = styled(TableCell)(({ theme, width }) => ({
  // Make the table rows more compact by minimising cell padding. Disable borders for a cleaner compact look.
  padding: "1px 16px",
  border: "unset",
  width: width,
}));

export interface CompactFormControlProps {
  width?: string | number | undefined;
}

export const CompactFormControl = styled(FormControl)<CompactFormControlProps>(({ theme, width }) => ({
  // Make the Autocomplete field more compact by removing padding.
  width: "100%",
  input: { padding: "0px !important" },
}));

/**
 * Typography with a gradual gradient from start to end color applied.
 */
export interface GradientTypographyProps extends TypographyProps {
  startcolor: string;
  endcolor: string;
}

export const GradientTypography = styled(Typography)<GradientTypographyProps>(({ theme, startcolor, endcolor }) => ({
  background: `linear-gradient(90deg, ${startcolor} 0%, ${endcolor} 50%)`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
}));

/**
 * LinearProgressWithLabel with a gradual gradient from start to end color applied.
 */
export interface GradientLinearProgressWithLabelProps {
  startcolor: string;
  endcolor: string;
}

export const GradientLinearProgressWithLabel = styled(LinearProgressWithLabel)<GradientLinearProgressWithLabelProps>(
  ({ theme, startcolor, endcolor }) => ({
    height: 3,
    colorPrimary: { barColorPrimary: `linear-gradient(90deg, ${startcolor} 3.01%, ${endcolor} 88.89%)` },
  })
);
