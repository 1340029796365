import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DrawCreateEvent, DrawModeChangeEvent, DrawUncombineEvent } from "@mapbox/mapbox-gl-draw";
import staticMode from "@mapbox/mapbox-gl-draw-static-mode";
import { HighlightAlt } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, FormHelperText } from "@mui/material";
import * as Sentry from "@sentry/react";
import chroma from "chroma-js";
import { Feature, FeatureCollection, GeoJsonProperties, MultiPolygon, Polygon } from "geojson";
import { isEmpty } from "lodash";
import { passing_draw_polygon as passingDrawPolygon } from "mapbox-gl-draw-passing-mode";

import { useGetMultiProjectQuery, useLazyGetBoundaryFeatureCountQuery, useUpdateMultiProjectMutation } from "fond/api";
import AutoCarvePopup from "fond/cityPlanner/AreaPanel/AutoCarvePopup";
import DrawHandler from "fond/draw/DrawHandler";
import { intersect } from "fond/draw/helper";
import readOnlySelectMode from "fond/draw/modes/base/readOnlySelect";
import { HistoryContext, useHistoryContext } from "fond/history";
import { useDataset } from "fond/hooks/useDataset";
import { MapContext } from "fond/map/MapProvider";
import { feature as toFeature, featureCollection, multiPolygon } from "fond/turf";
import { MultiProjectArea, MultiProjectAreaImportMethod, Store } from "fond/types";
import { isAnyPolygon, isPolygon } from "fond/types/geojson";
import { makeUuid } from "fond/utils";
import { layerPalette } from "fond/utils/colors";
import { generateUnusedName } from "fond/utils/naming";
import { BlockSpinner, useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { AreaErrorType, setFeatureProperty, validateAreaFeatures } from "../helper";

import areaDrawStyles from "./areaDrawStyles";
import AreaList from "./AreaList";
import { autoCombine } from "./autoCombine";
import { carveToolbarConfig } from "./toolbar";

export type SubareaProperties = {
  id: string;
  name: string;
  minCount: number | null;
  exactCount: number | null;
  importMethod: MultiProjectAreaImportMethod;
  color: string;
};

export type SubareaFeature = Feature<Polygon | MultiPolygon, SubareaProperties>;

const AreaDrawPanel: React.FC = () => {
  const multiProjectId = useSelector((state: Store) => state.project.projectId);
  const { data: multiProject } = useGetMultiProjectQuery(multiProjectId);
  const { goBack, clear } = useStackedNavigationContext();
  const { map, drawControl, setDrawMode, isDrawing } = useContext(MapContext);
  const { set: setHistory, state: historyState, clear: clearHistory } = useHistoryContext<FeatureCollection>(HistoryContext);
  const [updateMultiProject] = useUpdateMultiProjectMutation();
  const [getBoundaryFeatureCount, { isFetching }] = useLazyGetBoundaryFeatureCountQuery();
  const [listKey, setListKey] = useState(makeUuid());
  const [errors, setErrors] = useState<Record<string, AreaErrorType[]>>();
  const [mode, setMode] = useState<string | null>(null);
  const [autoCarving, setAutoCarving] = useState(false);
  const [showCarvePopup, setShowCarvePopup] = useState(true);
  const { isLoading: isDatasetLoading, featuresWithin: datasetFeaturesWithin } = useDataset({
    key: "census-block-groups-2024",
    accountId: multiProject?.Account.ID ?? null,
  });
  const isDisabled = (mode !== "simple_select" && mode !== "direct_select" && mode !== "static") || isFetching;

  /**
   * Get the multiproject Subareas & convert them to multiple polygons ready to load as
   * the initial draw features.
   */
  const initialFeatures = useMemo(() => {
    if (multiProject?.Areas && multiProject.Areas.length > 0) {
      return multiProject?.Areas.map((area) =>
        toFeature(
          area.Boundary,
          { id: area.ID, name: area.Name, importMethod: area.ImportMethod, exactCount: 0, minCount: null, color: area.Style.Color },
          { id: `${area.ID}` }
        )
      );
    }

    return [];
  }, [multiProject?.Areas]);

  // Validate the multiproject areas
  const validate = useCallback(() => {
    const validationStatus = validateAreaFeatures(drawControl.current.getAll().features as SubareaFeature[]);
    setErrors(validationStatus);
  }, [drawControl]);

  /**
   * Callback function that is called when the user created a new feature
   * or updates existing features.
   */
  const updatePremCount = useCallback(
    async ({ features }: { features: Feature[] }) => {
      // Get the boundary information to be requested
      const boundaries = features.filter(isAnyPolygon).map(({ id, geometry: { type, coordinates } }) => ({
        id: id || "",
        geometry: { type, coordinates },
      }));
      try {
        const data = await getBoundaryFeatureCount({ boundaries: boundaries }).unwrap();
        if (data.FeatureCounts) {
          Object.keys(data.FeatureCounts).forEach((id) => {
            const { ExactCount, MinCount } = data.FeatureCounts[id].Addresses;
            setFeatureProperty(drawControl.current, id, "exactCount", ExactCount);
            setFeatureProperty(drawControl.current, id, "minCount", MinCount);
          });

          validate();

          // Refresh the list of subareas
          setListKey(makeUuid());
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [drawControl, getBoundaryFeatureCount, validate]
  );

  /**
   * Sync the drawing features with the current state of history as the user
   * clicks undo/redo.
   */
  useEffect(() => {
    if (isDrawing) {
      if (isEmpty(historyState)) {
        drawControl.current.set(featureCollection(initialFeatures));
      } else {
        drawControl.current.set(historyState);
      }

      updatePremCount({ features: drawControl.current.getAll().features });
    }
  }, [initialFeatures, isDrawing, drawControl, updatePremCount, historyState]);

  useEffect(() => {
    return () => {
      clearHistory();
      setDrawMode("no_feature");
    };
  }, [clearHistory, setDrawMode]);

  const handleModeChange = useCallback(
    (event: DrawModeChangeEvent) => {
      setMode(event.mode);
    },
    [setMode]
  );

  const handleAutoCarveComplete = useCallback(
    (rawFeatures: Feature<Polygon | MultiPolygon, { addressCount: number }>[]) => {
      const features = rawFeatures.map((f, i) => {
        const id = makeUuid();
        return toFeature(
          f.geometry,
          {
            id: id,
            name: `Subarea (${i + 1})`,
            importMethod: "area_select_underground",
            exactCount: f.properties.addressCount,
            minCount: null,
            color: layerPalette[i % layerPalette.length],
          },
          { id }
        );
      });

      drawControl.current.set(featureCollection(features));
      setHistory(drawControl.current.getAll());
      setAutoCarving(false);
    },
    [drawControl, setHistory]
  );

  const handleAutoCarve = useCallback(
    async (event: DrawCreateEvent) => {
      setAutoCarving(true);

      if (!isDatasetLoading && datasetFeaturesWithin) {
        event.features.filter(isPolygon).forEach(async (feature) => {
          const blockGroups = await datasetFeaturesWithin(feature.geometry);
          const subareas = autoCombine(blockGroups.features.filter(isAnyPolygon), 6_000);
          handleAutoCarveComplete(subareas);
        });
      }
    },
    [datasetFeaturesWithin, handleAutoCarveComplete, isDatasetLoading]
  );

  useEffect(() => {
    map?.on("draw.modechange", handleModeChange);
    map?.on("draw.passing-create", handleAutoCarve);

    return () => {
      map?.off("draw.modechange", handleModeChange);
      map?.off("draw.passing-create", handleAutoCarve);
    };
  }, [map, handleModeChange, handleAutoCarve]);

  /**
   * Customised clipping function that clips the feature being drawn or updated so that it does not:
   * 1) Overlap any other subareas
   * 2) Extend beyond the city boundary
   */
  const clipFeature = useCallback((feature: Feature<Polygon | MultiPolygon>, all: Feature[]): Feature<Polygon | MultiPolygon> => {
    // Don't allow feature to overlap other subareas
    let newFeature = intersect(feature, all);

    return newFeature;
  }, []);

  /**
   * When the combine or uncombine event is fired we rename
   * the created features based on the original features & update feature counts.
   */
  const handleOnUncombine = useCallback(
    (event: DrawUncombineEvent) => {
      event.createdFeatures.forEach(({ id }, index) => {
        setFeatureProperty(drawControl.current, String(id), "id", id);
        if (index > 0 && id) {
          const newName = generateUnusedName(
            event.createdFeatures[0].properties?.name,
            drawControl.current.getAll().features.map((feat) => feat.properties?.name || "") || []
          );
          setFeatureProperty(drawControl.current, String(id), "name", newName);
        }
      });

      setHistory(drawControl.current.getAll());
    },
    [drawControl, setHistory]
  );

  const handleBack = () => {
    goBack();
  };

  const onReset = () => {
    drawControl.current.set(featureCollection(initialFeatures));
    clearHistory();
    // Get the prem counts for the new feature
    updatePremCount({ features: initialFeatures });
  };

  /**
   * Handles importing the currently draw features and updating the multiProject
   * with the new area boundaries.
   */
  const handleOnImport = () => {
    if (drawControl.current.getMode() === "draw_polygon") {
      // Exit drawing mode & trash any partially drawn features
      drawControl.current.trash();
    }
    const importFeatures = drawControl.current.getAll().features.filter(isAnyPolygon) as Feature<Polygon | MultiPolygon, SubareaProperties>[];

    const areas: Partial<MultiProjectArea>[] = importFeatures.map((feat) => {
      const newFeature = isPolygon(feat) ? multiPolygon([feat.geometry.coordinates]).geometry : (feat.geometry as MultiPolygon);

      return {
        Name: feat.properties.name,
        Boundary: newFeature,
        ImportMethod: feat.properties.importMethod,
        Style: {
          Color: feat.properties.color,
        },
      };
    });

    updateMultiProject({ ID: multiProjectId, Areas: areas }).then(() => {
      setDrawMode("no_feature");
      clear();
    });
  };

  /**
   * Callback for when the form fields for an area are changed by the user
   */
  const handleOnAreaFieldsChange = (id: string) => {
    validate();
  };

  const getFeatureProperties = useCallback(
    (feature: Feature): GeoJsonProperties => {
      const color = layerPalette?.[drawControl.current.getAll().features.length - 1] || chroma.random().hex();

      return {
        id: String(feature.id),
        name: generateUnusedName("Subarea", drawControl.current.getAll().features.map((feat) => feat.properties?.name || "") || []),
        importMethod: "area_select_underground",
        minCount: null,
        exactCount: 0,
        color: color,
      };
    },
    [drawControl]
  );

  if (isDatasetLoading) {
    return <BlockSpinner containerProps={{ height: "100%" }} />;
  }

  return (
    <>
      <DrawHandler
        initialFeatures={initialFeatures}
        startingMode={initialFeatures.length > 0 ? "simple_select" : "passing_draw_polygon"}
        source="multiProject-source"
        styles={areaDrawStyles}
        autoClip
        // While editing is disabled we are overriding the default modes
        // to only allow ready only select & passing draw polygon (for autocarve).
        // If we re-enable editing there is no need to pass modes here.
        modes={{
          simple_select: readOnlySelectMode,
          passing_draw_polygon: passingDrawPolygon,
          static: staticMode,
        }}
        clipFeature={clipFeature}
        config={carveToolbarConfig}
        getFeatureProperties={getFeatureProperties}
        onUncombine={handleOnUncombine}
      />
      <StackedNavigationHeader onBackButtonClick={handleBack} title="Identify subareas " />
      <Box data-testid="draw-panel" sx={{ py: 2 }}>
        <Alert severity="info" data-testid="layer-alert" sx={{ fontSize: 12 }}>
          <AlertTitle>City area</AlertTitle>
          Start defining your boundaries. Use the auto carve <HighlightAlt style={{ width: 16, height: 16, verticalAlign: "middle" }} /> tool to
          import subareas.
        </Alert>

        <AreaList key={listKey} onReset={onReset} onChange={handleOnAreaFieldsChange} errors={errors} isDisabled={isDisabled} />

        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
          <Box>{errors && <FormHelperText error>Invalid areas found.</FormHelperText>}</Box>
          <Box>
            <Button color="primary" size="small" onClick={handleBack}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={handleOnImport}
              sx={{ ml: 1, px: 2 }}
              data-testid="finish-button"
              disabled={isFetching || errors !== undefined}
            >
              Finish
            </Button>
          </Box>
        </Box>
      </Box>
      {datasetFeaturesWithin && showCarvePopup && <AutoCarvePopup onClose={() => setShowCarvePopup(false)} isLoading={autoCarving} />}
    </>
  );
};

export default AreaDrawPanel;
