import React, { useContext, useEffect } from "react";
import { AnySourceData } from "mapbox-gl";

import { MapContext } from "fond/map/MapProvider";

interface IProps {
  /**
   * A unique layer id.
   */
  id: string;
  /*
    The layer's type
  */
  type: any;
  /**
   * The layers paint styles.
   */
  paint: any;
  /**
   * The layers source
   */
  source: string | AnySourceData;
  /**
   * Vector tile source layer
   */
  sourceLayer: string;
}

const VectorLayer: React.FC<IProps> = ({ id, paint, source, sourceLayer, type }: IProps) => {
  const { map } = useContext(MapContext);

  useEffect(() => {
    add();

    return () => {
      remove();
      map?.off("styledata", onStyleDataChange);
    };
  }, []);

  const onStyleDataChange = () => {
    if (!map?.getLayer(id)) {
      remove();
      add();
    }
  };

  const remove = () => {
    if (map?.getLayer(id)) {
      map?.removeLayer(id);
    }
    // A mapbox source (with id = this.props.id) gets created by addLayer. It should also be removed.
    /* TODO: If a pre-existing source was passed in consider:
       - Not removing it OR
       - Using a parameter to control it's removal
     */
    if (map?.getSource(id)) {
      map?.removeSource(id);
    }
  };

  const add = () => {
    map?.addLayer({
      id: id,
      type: type,
      source: source,
      "source-layer": sourceLayer,
      layout: {},
      paint: paint,
    });
  };

  return null;
};

export default VectorLayer;
