import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";

import { LoadingButton } from "ui";

import { selectFoldersByParentId, useUpdateFolderMutation } from "fond/api";
import { TextField } from "fond/form/fields";
import { TextFieldValue } from "fond/form/fields/TextField";
import { AnyObject, Folder, Store } from "fond/types";
import { compose, required } from "fond/utils/validation";
import { Message as MessageWidget, Modal } from "fond/widgets";

interface IFormData {
  Name: string;
  Description: string;
}

interface IProps {
  /**
   * The Folder being edited
   */
  folder: Folder;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
  /**
   * Optional Callback function for when the form is submitted
   */
  onSubmit?(values: IFormData): void;
}

const EditFolderModal: React.FC<IProps> = ({ folder, onClose, onSubmit }: IProps) => {
  const siblings = useSelector((state: Store) => {
    return selectFoldersByParentId(state, folder.ParentID);
  });
  const [updateFolder, { isLoading: isSaving, isError }] = useUpdateFolderMutation();
  let submit: (event?: Partial<Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">>) => Promise<AnyObject | undefined> | undefined;

  /**
   * Callback function for the Create button within the modal actions
   */
  const handleOnClick = (event: React.MouseEvent<EventTarget>) => {
    // Submits the React-Final-Form (which handles validation & calling onSubmit is validation passes)
    submit();
  };

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async ({ Name, Description }: IFormData) => {
    updateFolder({ ID: folder.ID, Name: Name, Description: Description })
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  /**
   * Validates that the new folders Name is currently not in use
   * @param value New Name value
   */
  const uniqueName = (value: TextFieldValue) => {
    const newName = value?.toString().toLowerCase();
    const existingFolders = siblings.some(
      (existingFolder: Folder) => existingFolder.ID !== folder.ID && existingFolder.Name.toLowerCase() === newName
    );
    if (existingFolders) {
      return "This name is already in use. Please use another.";
    }
    return undefined;
  };

  return (
    <Modal
      open
      header="Edit this folder"
      data-testid="edit-folder-modal"
      content={
        <Box>
          {isError && (
            <Box mb={2}>
              <MessageWidget type="error">There was an issue updating this folder. Please try again.</MessageWidget>
            </Box>
          )}
          <Form<IFormData>
            initialValues={{ Name: folder.Name, Description: folder.Description }}
            onSubmit={onSubmit || handleOnSubmit}
            render={({ handleSubmit }) => {
              submit = handleSubmit;
              return (
                <form onSubmit={handleSubmit}>
                  <Box maxWidth={300}>
                    <TextField name="Name" required label="Name" fullWidth validate={compose(required, uniqueName)} />
                  </Box>
                  <Box mt={2}>
                    <TextField name="Description" label="Description" fullWidth multiline rows={3} />
                  </Box>
                </form>
              );
            }}
          />
        </Box>
      }
      actions={
        <>
          <Button data-testid="edit-folder-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton data-testid="edit-folder-save-button" color="primary" onClick={handleOnClick} loading={isSaving}>
            Update
          </LoadingButton>
        </>
      }
    />
  );
};

export default EditFolderModal;
