import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { Box } from "@mui/material";

import StackedNavigationHeader from "./StackedNavigationHeader";

import { StackablePanel } from "./StackedNavigation.styles";

interface IStackedNavigationContext {
  stack: string[];
  open: (id: string) => void;
  goBack: (count?: number) => void;
  clear: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const StackedNavigationContext = createContext<IStackedNavigationContext>(undefined!);

export const useStackedNavigationContext = (): IStackedNavigationContext => useContext(StackedNavigationContext);

type Screen = {
  id: string;
  name: string;
  component: React.ReactNode;
  useDefaultHeader?: boolean;
};

interface IProps {
  rootComponent: React.ReactNode;
  screens: Screen[];
}

const StackedNavigation: React.FC<IProps> = ({ rootComponent, screens }) => {
  const [stack, setStack] = useState<string[]>([]);

  const open = useCallback(
    (entryId: string) => {
      const screenIds = screens.map((screen) => screen.id);
      if (!screenIds.includes(entryId)) {
        throw new Error("Invalid target screen ID");
      }

      setStack((currentStack) => [...currentStack, entryId]);
    },
    [screens]
  );
  const popStack = (count = 1) => setStack((currentStack) => currentStack.slice(0, currentStack.length - count));
  const resetStack = () => setStack([]);

  const providerValue = useMemo(() => ({ stack: stack, open: open, goBack: popStack, clear: resetStack }), [open, stack]);

  return (
    <StackedNavigationContext.Provider value={providerValue}>
      <Box position="relative" height="100%" overflow="hidden">
        {rootComponent}
        {stack.map((id) => {
          const targetScreenIndex = screens.findIndex((screen) => screen.id === id);
          if (targetScreenIndex === -1) return null;
          const { name, component, useDefaultHeader = true } = screens[targetScreenIndex];

          return (
            <StackablePanel data-testid={`stackable-panel-${id}`} key={id} className="customScrollbars">
              {useDefaultHeader && <StackedNavigationHeader onBackButtonClick={() => popStack(1)} title={name} />}
              {component}
            </StackablePanel>
          );
        })}
      </Box>
    </StackedNavigationContext.Provider>
  );
};

export default StackedNavigation;
