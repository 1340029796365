import React, { useContext, useState } from "react";
import { CheckCircle, Delete, Error, HolidayVillage } from "@mui/icons-material";
import { Box, Divider, IconButton, MenuItem, Popover, SelectChangeEvent, Tooltip, Typography } from "@mui/material";

import { Swab } from "ui";

import { CITY_PLANNER_AREA_MAX } from "fond/constants";
import { MapContext } from "fond/map/MapProvider";
import { formatNumber } from "fond/utils/number";

import { AreaErrorType, setFeatureProperty } from "../helper";

import { SubareaProperties } from "./AreaDrawPanel";
import AreaFormColorPicker from "./AreaFormColorPicker";

import { Container, Select, TextField } from "./AreaForm.styles";

interface AreaFormProps {
  disabled?: boolean;
  errors: AreaErrorType[] | undefined;
  properties: SubareaProperties;
  onChange(id: string): void;
}

const AreaForm: React.FC<AreaFormProps> = ({ disabled = false, errors, properties, onChange }: AreaFormProps) => {
  const { map, drawControl } = useContext(MapContext);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { id, name, minCount, exactCount, importMethod, color: defaultColor } = properties;
  const [color, setColor] = useState(defaultColor);

  const handleOnDelete = () => {
    drawControl.current?.delete(id);
    map?.fire("draw.delete");
    map?.fire("draw.selectionchange");
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (drawControl.current) {
      drawControl.current.setFeatureProperty(`${properties?.id}`, "name", event.target.value);

      // We refresh the features to force a redraw
      drawControl.current.set(drawControl.current.getAll());

      onChange(id);
    }
  };

  const handleOnColorChange = (newColor: string) => {
    setFeatureProperty(drawControl.current, `${id}`, "color", newColor);
    setColor(newColor);
    setAnchorEl(null);
  };

  const handleImportMethodChange = (event: SelectChangeEvent<string>) => {
    setFeatureProperty(drawControl.current, `${id}`, "importMethod", event.target.value);
  };

  return (
    <Container error={!!errors}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          {errors ? <Error color="error" /> : <CheckCircle color="success" />}

          <TextField
            disabled={disabled}
            defaultValue={name}
            onChange={handleOnChange}
            size="small"
            fullWidth
            sx={{ mx: 1 }}
            InputProps={{
              startAdornment: (
                <IconButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                  size="small"
                  sx={{ p: 0 }}
                  disabled={disabled}
                >
                  <Swab color={color} />
                </IconButton>
              ),
            }}
          />
          <IconButton size="small" sx={{ p: 0.5 }} onClick={handleOnDelete} disabled={disabled}>
            <Delete color={disabled ? "disabled" : "primary"} />
          </IconButton>
        </Box>
        {errors?.includes("name_duplication") && (
          <Typography variant="caption" sx={{ ml: 4 }} fontWeight="500" color="error">
            Duplicate name
          </Typography>
        )}
        <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
          <HolidayVillage color="primary" />
          <Typography variant="caption" sx={{ ml: 1 }} fontWeight="500">
            {formatNumber(minCount || exactCount)}
            {minCount && "+"} parcels
            {(minCount || exactCount || 0) > CITY_PLANNER_AREA_MAX && (
              <Typography variant="caption" sx={{ ml: 1 }} fontWeight="500" color="error">
                Maximum is {formatNumber(CITY_PLANNER_AREA_MAX)}
              </Typography>
            )}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />
      <Box display="flex" alignItems="center">
        <Tooltip title="Select the path type for your plan. Street centrelines will be used to approximate Underground path or Aerial span and poles.">
          <Typography variant="caption" sx={{ pr: 1, whiteSpace: "nowrap" }}>
            Path type
          </Typography>
        </Tooltip>
        <Select
          defaultValue={importMethod}
          onChange={handleImportMethodChange}
          margin="dense"
          size="small"
          fullWidth
          disabled={disabled}
          inputProps={{ "data-testid": "path-preference" }}
        >
          <MenuItem value="area_select_underground">Underground</MenuItem>
          <MenuItem value="area_select_aerial">Aerial</MenuItem>
        </Select>
      </Box>

      {anchorEl && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <AreaFormColorPicker onChange={handleOnColorChange} value={color} />
        </Popover>
      )}
    </Container>
  );
};

export default AreaForm;
