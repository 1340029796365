import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBack, AutoAwesomeMotion, ContentCopy, Done, LibraryAdd, PanTool } from "@mui/icons-material";
import { Box, Button, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { groupBy, isEmpty, mapValues, omit } from "lodash";
import pick from "lodash/pick";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import {
  selectVersionsByProjectId,
  useDeleteLayerMutation,
  useGetLayersQuery,
  useGetProjectQuery,
  useGetVersionConfigQuery,
  useGetVersionQuery,
  useLazyGetRootConfigQuery,
  useUpdateRootConfigMutation,
} from "fond/api";
import { basename } from "fond/fileValidation";
import { refreshTileSource } from "fond/map/redux";
import mixpanel from "fond/mixpanel";
import { addImport } from "fond/redux/imports";
import { AppThunkDispatch, Configuration, Project, Store, Version } from "fond/types";
import { LayerConfig } from "fond/types/ProjectLayerConfig";
import { selectLayersFromConfig } from "fond/utils/configurations";
import { ConfirmModal, Modal } from "fond/widgets";

import CardButton from "../CardButton";
import ImportDropzone from "../ImportDropzone";
import ImportLayersTable from "../ImportLayersTable";
import ProjectRenameField from "../ProjectRenameField";
import ProjectVersionSelection from "../ProjectVersionSelection";

import { SetupContainer, StyledImportIcon } from "./ImportModal.styles";

/**
 * The ImportModal component.
 * This modal provides the user with controls to:
 *   - Rename the current project.
 *   - Apply a copy of another project versions layer configuration.
 *   - Perform a geospatial file drop (shapefile, tab, kml or geojson).
 *   - Map copied configurations to imports.
 *   - Upload and import the layers.
 */
interface ImportModalProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
}

// The import is broken into three steps.
enum Steps {
  SETUP = 1,
  FILE_DROP = 2,
  FILE_MAPPING = 3,
}

// The settings can be sourced from the current setting (on reimport), cleared, or copied from an existing project version.
enum Settings {
  CURRENT = "CURRENT",
  BLANK = "BLANK",
  COPY = "COPY",
  COPY_PREVIOUS = "COPY_PREVIOUS",
}

const ImportModal: React.FC<ImportModalProps> = ({ "data-testid": dataTestid = "import-modal", onClose }: ImportModalProps) => {
  const dispatch: AppThunkDispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  /**
   * State.
   */
  // Import configurations. The current step and settings mode.
  const [step, setStep] = useState<Steps>(Steps.SETUP);
  const [settings, setSettings] = useState<null | Settings>(null);
  // The files state is a mapping of file key to files bundle, set from ImportDropZone file drop.
  const [files, setFiles] = useState<{ [fileKey: string]: File[] } | undefined>();
  // The fileConfigurations state is a mapping of file key to layer configuration key.
  const [fileConfigurations, setFileConfigurations] = useState<{ [fileKey: string]: undefined | string } | undefined>();
  const [hasConfigurationErrors, setHasConfigurationErrors] = useState(false);
  // A flag tracking when the root configuration is loading, fetching, updating or otherwise unavailable.
  const [mlcLoading, setMlcLoading] = useState(true);
  // Holds the version that the user has chosen to copy styles from in state, pending confirmation.
  const [targetSettingsVersion, setTargetSettingsVersion] = useState<undefined | Version>();
  // Controls when a replace styles confirmation modal is displayed.
  const [confirmOverwriteSettings, setConfirmOverwriteSettings] = useState<boolean>(false);
  const [confirmResetSettings, setConfirmResetSettings] = useState<boolean>(false);

  /**
   * API hooks
   */
  // Hooks for the project version data.
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const { data: project } = useGetProjectQuery(version?.Project || "", { skip: !version?.Project });
  const { data: layers } = useGetLayersQuery(versionId, { skip: !versionId });
  const versions = useSelector((state: Store) => (version?.Project ? selectVersionsByProjectId(state, version.Project) : []));

  // Hooks for managing MLC data.
  const {
    data: versionConfig,
    isLoading: versionConfigIsLoading,
    isFetching: versionConfigIsFetching,
  } = useGetVersionConfigQuery(versionId, { skip: !versionId });
  const [updateRootConfig, { isLoading: rootConfigIsUpdating }] = useUpdateRootConfigMutation();
  const [getRootConfig] = useLazyGetRootConfigQuery();
  const [deleteLayer] = useDeleteLayerMutation();

  const versionConfigLayers = useMemo(
    () => (versionConfig ? selectLayersFromConfig(versionConfig, ["LAYER"]) : []),
    [versionConfig]
  ) as LayerConfig[];
  /**
   * Effects.
   */
  // Track when the map layer configuration is being loaded. Components are disabled during loading.
  useEffect(() => {
    if (versionConfig == null || rootConfigIsUpdating || versionConfigIsLoading || versionConfigIsFetching) {
      setMlcLoading(true);
    } else {
      setMlcLoading(false);
    }
  }, [versionConfig, rootConfigIsUpdating, versionConfigIsLoading, versionConfigIsFetching]);

  // Jump to the second step when a map layer configuration is first loaded.
  useEffect(() => {
    if (versionConfig != null) {
      if (settings == null) {
        // Set the settings mode. The current settings are preferred if available, otherwise a fresh import is preferred.
        if (versionConfigLayers.length > 0) {
          setSettings(Settings.CURRENT);
        } else {
          setSettings(Settings.BLANK);
        }
      } else {
        if (settings === Settings.COPY) {
          setSettings(Settings.CURRENT);
        }
        setStep(Steps.FILE_DROP);
      }
      setMlcLoading(false);
    }
  }, [versionConfig]);

  // Moves the stepper according to the status of the files drop.
  useEffect(() => {
    if ([Steps.FILE_DROP, Steps.FILE_MAPPING].includes(step)) {
      if (files == null || Object.keys(files).length === 0) {
        setStep(Steps.FILE_DROP);
      } else {
        setStep(Steps.FILE_MAPPING);
      }
    }
  }, [step, files]);

  /**
   * Callbacks.
   */
  // Handles a bulk MLC update. sourceMlcId may be set to null to reset to a blank config.
  const updateVersionConfig = async ({ sourceRootConfigurationId }: { sourceRootConfigurationId: null | string }) => {
    // Prepare the body to use for the version MLC update.
    setMlcLoading(true);

    const emptyPayload: Pick<Configuration, "ID" | "Data" | "MapChildren"> = {
      ID: versionConfig?.ID || "",
      Data: { ids: [], entities: {} },
      MapChildren: [],
    };

    let updatePayload: Pick<Configuration, "ID" | "Data" | "MapChildren"> | null = null;
    if (sourceRootConfigurationId !== null) {
      enqueueSnackbar("Settings copy in progress.");
      const sourceRootConfig = await getRootConfig(sourceRootConfigurationId).unwrap();
      updatePayload = {
        ID: versionConfig?.ID || "",
        ...pick(sourceRootConfig, ["Data", "MapChildren"]),
      };
    } else {
      enqueueSnackbar("Clearing settings.");
    }

    // Delete all existing layers and replace the existing root configuration with a blank one.
    const values: PromiseLike<any>[] = [
      updateRootConfig(
        // If `updatePayload` is null then we pass `versionId` to force the version query data to be invalidated.
        // If `updatePayload` is not null, then this invalidation happens during the second call to `updateRootConfig`
        // below.
        { versionConfig: emptyPayload, versionId: updatePayload === null ? versionId : undefined }
      ),
    ];
    for (let layerId of layers?.ids || []) {
      layerId = layerId.toString();
      if (!layerId.includes("layer-comments-")) {
        values.push(deleteLayer(layerId));
      }
    }

    // Perform the deletions, update the root configuration and refresh the version data.
    return Promise.all(values).then(async () => {
      if (version && versionConfig) {
        // Populate the now-blank root configuration if we're copying settings.
        if (updatePayload !== null) {
          // R: We're making two calls to `updateRootConfig`: once above to clear the existing configuration,
          //    and then another here to populate the configuration with copied data. The motivation for this smell
          //    is that the backend has "order-of-operations" issues: If we try to create an entity (e.g. group)
          //    whose key matches that of an entity being deleted then the update operation can fail, depending
          //    on where the two entities live in the root configuration. This is a workaround to avoid these issues.
          //    Ultimately we should consider refactoring the backend to handle this correctly.
          await updateRootConfig({ versionConfig: updatePayload, trimIds: true, versionId: versionId });
        }
        await dispatch(refreshTileSource("project-layers"));
        enqueueSnackbar("Settings updated.");
      }
    });
  };

  // Groups the files by their basename and sets to the files state, overriding any keys that are already set.
  const setFilesFromDrop = (droppedFiles: File[]) => {
    const newFiles = mapValues(groupBy(droppedFiles, (file: File) => basename(file.name)));
    setFiles({ ...files, ...newFiles });
  };

  // Handles selecting a version to copy styles from.
  const copyStyles = async () => {
    if (targetSettingsVersion) {
      if (versionConfigLayers.length > 0) {
        setConfirmOverwriteSettings(true);
      } else {
        await updateVersionConfig({ sourceRootConfigurationId: targetSettingsVersion.MapLayerConfigID });
      }

      mixpanel.track("Copied settings from existing project", { versionId: versionId, targetVersion: targetSettingsVersion.ID });
    }
  };

  // Maps the fileKey to the layerKey of the configuration to import with.
  const setConfigurations = ({ mappings }: { mappings: { [fileKey: string]: undefined | string } }) => {
    setFileConfigurations(mappings);
  };

  // Renders the ImportController, starting the import process.
  const startImportAndClose = async () => {
    if (files) {
      let successfulFiles: string[] = [];
      const promises = Object.entries(files).map(([fileKey, fileBundle]) => {
        // take the layerKey from the user input if provided.
        let layerKey = fileConfigurations?.[fileKey];
        // The name displayed on the import controller is the layerKey if provided, otherwise the fileKey.
        let displayName = layerKey || fileKey;
        // If the layerKey was not provided then we use the file key (if unique).
        // TODO: Set the layerKey to a UUID and use the API to set the configuration label to the fileKey.
        if (layerKey == null) {
          layerKey = fileKey;
          let i = 1;
          const layerKeys = versionConfigLayers.map((config) => config.Key);
          while (layerKeys.includes(layerKey)) {
            layerKey = `${layerKey}-${i}`;
            i += 1;
          }
        }
        return dispatch(addImport({ versionId: versionId, layerKey: layerKey, displayName: displayName, files: fileBundle })).then(() =>
          successfulFiles.push(fileKey)
        );
      });

      await Promise.all(promises).then(
        () => {
          mixpanel.track("Started import", { versionId });
          onClose();
        },
        (error) => enqueueSnackbar(error.message)
      );

      setFiles(omit(files, successfulFiles));
    }
  };

  // Removes the file bundle from the files state.
  const removeFile = (fileKey: string) => {
    const updatedFiles = omit(files, fileKey);
    setFiles(isEmpty(updatedFiles) ? undefined : updatedFiles);
  };

  return (
    <Modal
      data-testid={dataTestid}
      variant="primary"
      open
      disableBackdropClick
      header="Import"
      headerIcon={<StyledImportIcon />}
      headerSubtitle="COLLABORATION"
      content={
        <Box height="29.5em" display="flex" flexDirection="column">
          {/* The stepper component. */}
          <Stepper activeStep={step - 1} alternativeLabel>
            <Step key="setup">
              <StepLabel>Setup</StepLabel>
            </Step>
            <Step key="upload">
              <StepLabel>Upload files</StepLabel>
            </Step>
            <Step key="finalize">
              <StepLabel>Finalize</StepLabel>
            </Step>
          </Stepper>

          {/* The setup page. */}
          <Box data-testid="controls" height="25em" mt="1em" textAlign="center">
            {step === Steps.SETUP && (
              <SetupContainer>
                <Typography variant="h5">Let's get started</Typography>

                {/* Only show rename modal if the project has no layers. */}
                {!layers?.ids?.some((id) => !(id as string).includes("layer-comments")) && (
                  <ProjectRenameField style={{ width: 424, borderBottom: "1px solid rgba(0, 0, 0, 0.42)" }} project={project} />
                )}

                <Box mt="0.5em">
                  <Typography variant="content">How would you like to import your files?</Typography>
                </Box>

                {/* Only show use current settings option if the project has settings. */}
                {(versionConfigLayers.length || 0) > 0 && (
                  <CardButton
                    sx={{ width: 424, height: "4em" }}
                    icon={<Done color="primary" sx={{ margin: "auto" }} />}
                    title="Use the current settings"
                    subtitle="Import additional layers using the current settings"
                    selected={settings === Settings.CURRENT}
                    loading={mlcLoading}
                    onClick={() => {
                      setSettings(Settings.CURRENT);
                    }}
                  />
                )}
                <CardButton
                  sx={{ width: 424, height: "4em" }}
                  icon={<LibraryAdd color="primary" sx={{ margin: "auto" }} />}
                  title="Start a new import"
                  subtitle="Import layer files from scratch"
                  selected={settings === Settings.BLANK}
                  loading={mlcLoading}
                  onClick={() => {
                    if (versionConfigLayers.length > 0) {
                      setConfirmResetSettings(true);
                    } else {
                      setSettings(Settings.BLANK);
                    }
                  }}
                />
                {versions && versions.length > 1 && !versionConfigLayers.length && (
                  <CardButton
                    sx={{ width: 424, height: "4em" }}
                    icon={<ContentCopy color="primary" sx={{ margin: "auto" }} />}
                    title="Use settings from previous version"
                    subtitle="Copy all settings from the previous version within this project"
                    selected={settings === Settings.COPY_PREVIOUS}
                    loading={mlcLoading}
                    onClick={() => {
                      const previousVersion = versions.filter((ver) => ver.ID !== versionId)[0];
                      setTargetSettingsVersion(previousVersion);

                      setSettings(Settings.COPY_PREVIOUS);
                    }}
                  />
                )}
                <CardButton
                  sx={{ width: 424, height: "4em" }}
                  data-testid="card-button-copy"
                  icon={<AutoAwesomeMotion color="primary" sx={{ margin: "auto" }} />}
                  title="Use settings from an existing project"
                  subtitle="Copy all layer, group, style, sublayer, and attribute settings."
                  selected={settings === Settings.COPY}
                  loading={mlcLoading}
                  onClick={() => {
                    setTargetSettingsVersion(undefined);
                    setSettings(Settings.COPY);
                  }}
                />

                {settings === Settings.COPY && (
                  <ProjectVersionSelection
                    onSelect={setTargetSettingsVersion}
                    loading={mlcLoading}
                    disabled={mlcLoading}
                    reset={targetSettingsVersion === undefined}
                    placeholder="Select Project"
                    // Only supports FOND Collaboration projects.
                    projectFilter={(option: Project) => Boolean(option.HasCustomLayerConfig)}
                    activeProjectVersion={version}
                    targetSettingsVersion={targetSettingsVersion}
                  />
                )}
              </SetupContainer>
            )}

            {/* The file drop and layer mapping table. */}
            {[Steps.FILE_DROP, Steps.FILE_MAPPING].includes(step) && (
              <>
                <ImportDropzone onDrop={setFilesFromDrop} compact={Boolean(files)} />
                {files && (
                  <ImportLayersTable
                    style={{ marginTop: "0.5em" }}
                    height="17em"
                    loading={mlcLoading}
                    files={files}
                    configurations={versionConfigLayers}
                    onRemoveFile={removeFile}
                    onSelectConfigurations={setConfigurations}
                    hasErrors={(hasErrors) => setHasConfigurationErrors(hasErrors)}
                  />
                )}
              </>
            )}
          </Box>
          {/* Confirmation models for overwriting or clearing the current settings. */}
          {confirmOverwriteSettings && (
            <ConfirmModal
              open
              header={
                <>
                  <PanTool color="error" sx={{ marginRight: 2 }} />
                  Hold on
                </>
              }
              confirmText="Replace"
              content={
                <div className="content">
                  <Typography variant="body2">Are you sure you want to copy settings from an existing project?</Typography>
                  <Typography variant="body2">All imported layers will be removed.</Typography>
                </div>
              }
              onConfirm={async () => {
                if (targetSettingsVersion) {
                  await updateVersionConfig({ sourceRootConfigurationId: targetSettingsVersion.MapLayerConfigID });
                }
                setConfirmOverwriteSettings(false);
              }}
              onCancel={() => {
                setTargetSettingsVersion(undefined);
                setConfirmOverwriteSettings(false);
              }}
            />
          )}
          {confirmResetSettings && (
            <ConfirmModal
              open
              header={
                <>
                  <PanTool color="error" sx={{ marginRight: 2 }} />
                  Hold on
                </>
              }
              confirmText="Remove"
              content={
                <div className="content">
                  <Typography variant="body2">Are you sure you want to clear all configuration from this project?</Typography>
                  <Typography variant="body2">All imported layers will be removed.</Typography>
                </div>
              }
              onConfirm={async () => {
                setConfirmResetSettings(false);
                setSettings(Settings.BLANK);
                await updateVersionConfig({ sourceRootConfigurationId: null });
              }}
              onCancel={() => {
                setConfirmResetSettings(false);
              }}
            />
          )}
        </Box>
      }
      actions={
        /* Cancelling closes the modal, Uploading commences the import by rendering the ImportController. */
        <Box display="flex" justifyContent="space-between" width="100%">
          {[Steps.FILE_DROP, Steps.FILE_MAPPING].includes(step) ? (
            <Button
              data-testid="back-button"
              variant="text"
              startIcon={<ArrowBack />}
              onClick={() => {
                setFiles(undefined);
                if (step === Steps.FILE_MAPPING) {
                  setStep(Steps.FILE_DROP);
                } else if (step === Steps.FILE_DROP) {
                  setStep(Steps.SETUP);
                }
              }}
            >
              Back
            </Button>
          ) : (
            <Box />
          )}

          <Box>
            <Button sx={{ marginRight: theme.spacing(1) }} data-testid="cancel-button" color="primary" onClick={onClose}>
              Cancel
            </Button>

            {step === Steps.SETUP && (
              <LoadingButton
                data-testid="continue-button"
                variant="contained"
                color="primary"
                disabled={!settings}
                loading={mlcLoading}
                onClick={() => {
                  if (settings === "COPY" || settings === "COPY_PREVIOUS") {
                    copyStyles();
                  } else {
                    setStep(Steps.FILE_DROP);
                  }
                }}
              >
                Continue
              </LoadingButton>
            )}

            {step === Steps.FILE_MAPPING && (
              <Button
                data-testid="upload-button"
                variant="contained"
                color="primary"
                disabled={!files || hasConfigurationErrors}
                onClick={startImportAndClose}
              >
                Upload
              </Button>
            )}
          </Box>
        </Box>
      }
    />
  );
};

export default ImportModal;
