import React, { useCallback, useEffect, useRef } from "react";
import { CustomCellEditorProps } from "@ag-grid-community/react";

import { TextField } from "./CellEditor.styles";

interface IProps extends CustomCellEditorProps {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

const CellEditor = ({ cellStartedEdit, endAdornment, startAdornment, value, onValueChange, api, column }: IProps) => {
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    // If doing full row edit, this is true if the cell is the one that started the edit
    // (eg it is the cell the use double clicked on, or pressed a key on etc).
    if (cellStartedEdit) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [cellStartedEdit]);

  const checkFocus = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Tab" && api.getFocusedCell()?.column.getColId() === column.getColId()) {
        inputRef.current?.focus();
        inputRef.current?.select();
      }
    },
    [api, column]
  );

  useEffect(() => {
    window.addEventListener("keydown", checkFocus);

    return () => {
      window.removeEventListener("keydown", checkFocus);
    };
  }, [checkFocus]);

  const onChange = ({ target: { value: val } }: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(val === "" ? "" : Number(val));
  };

  return (
    <TextField
      inputRef={inputRef}
      type="number"
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: startAdornment,
        endAdornment: endAdornment,
        sx: { backgroundColor: "white" },
      }}
      size="small"
    />
  );
};

export default React.memo(CellEditor);
