import { ReactElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";

export { ReactComponent as BackgroundAreaLines } from "../svg_icons/background_area_lines.svg";
export { ReactComponent as BackgroundLines } from "../svg_icons/background_lines.svg";
export { ReactComponent as CollaborationElement } from "../svg_icons/collaboration_element.svg";
export { ReactComponent as CollaborationLogo } from "../svg_icons/collaboration_logo.svg";
export { ReactComponent as CreateAccountIcon } from "../svg_icons/create_account.svg";
export { ReactComponent as ClipPolygon } from "../svg_icons/clip_polygon.svg";
export { ReactComponent as DeleteIcon } from "../svg_icons/delete.svg";
export { ReactComponent as ExportIcon } from "../svg_icons/export_icon.svg";
export { ReactComponent as HeaderElement } from "../svg_icons/header_element.svg";
export { ReactComponent as ImportIcon } from "../svg_icons/import_icon.svg";
export { ReactComponent as InvalidLinkIcon } from "../svg_icons/invalid_link.svg";
export { ReactComponent as FONDLogo } from "../svg_icons/logo.svg";
export { ReactComponent as MagnetIcon } from "../svg_icons/magnet.svg";
export { ReactComponent as MergeIcon } from "../svg_icons/merge.svg";
export { ReactComponent as NoContentIcon } from "../svg_icons/no-content.svg";
export { ReactComponent as PageNotFoundIcon } from "../svg_icons/page_not_found.svg";
export { ReactComponent as PlannerElement } from "../svg_icons/planner_element.svg";
export { ReactComponent as PlannerLogo } from "../svg_icons/planner_logo.svg";
export { ReactComponent as PolygonIcon } from "../svg_icons/polygon.svg";
export { ReactComponent as PointerIcon } from "../svg_icons/pointer.svg";
export { ReactComponent as SplitPolygon } from "../svg_icons/split_polygon.svg";
export { ReactComponent as UnionIcon } from "../svg_icons/union.svg";
export { ReactComponent as UnmergeIcon } from "../svg_icons/unmerge.svg";
export { ReactComponent as UploadIcon } from "../svg_icons/upload_icon.svg";
export { ReactComponent as USFlagIcon } from "../svg_icons/us_flag.svg";
export { ReactComponent as Wave } from "../svg_icons/wave.svg";
export { ReactComponent as ZipFolderIcon } from "../svg_icons/zip_folder_icon.svg";
export { ReactComponent as HandShakeIcon } from "svg_icons/handshake_icon.svg";
export { ReactComponent as MoveIcon } from "svg_icons/move.svg";
export { ReactComponent as DrawBoundaryIcon } from "svg_icons/draw_boundary.svg";

/*
 * Generate and return a static url for the SVG ReactElement asset.
 * Intended for use in styling an SVG background-image.
 * Example usage:
 *
 *   import { staticUrl, CollaborationLogo } from "fond/svg_icons";
 *
 *   <Box
 *     style={{
 *       backgroundImage: staticUrl(<CollaborationLogo fill={theme.palette.biarri.primary.red} />),
 *       backgroundColor: {theme.palette.biarri.primary.carbon},
 *       backgroundSize: "cover",
 *     }}
 *   >
 */

// Find any SVG tags that are missing the xmlns attribute. https://regexr.com/7vtu1
const regexSvgTagsNoNamespace = /(<svg)\s+((?![\s\S]*?xmlns=".*")[\s\S]*?>)/gim;
const ns = `xmlns="http://www.w3.org/2000/svg"`;

export const staticUrl = (icon: ReactElement): string => {
  const staticIcon = renderToStaticMarkup(icon);
  const iconWithXmlns = staticIcon.replaceAll(regexSvgTagsNoNamespace, `$1 ${ns} $2`);
  return `url("data:image/svg+xml,${encodeURIComponent(iconWithXmlns)}")`;
};
