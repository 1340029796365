import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { Account, OkResponse, Project, ProjectPreview, ProjectType, Store } from "fond/types";
import { searchCollectionByKey } from "fond/utils/search";

import { selectAccountById, selectCurrentAccount } from "./accountSlice";
import { apiSlice } from "./apiSlice";

export type GetProjectsResponse = {
  Items: Project[];
};

export const projectsAdapter = createEntityAdapter<Project>({
  selectId: (entity: Project): string => entity.ID,
});
const projectsInitialState = projectsAdapter.getInitialState();

/**
 * Projects API Slice
 */
export const projectSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query<Project, string>({
      query: (projectId: string) => `/v2/projects/${projectId}`,
      providesTags: (result) => (result ? [{ type: "Project", id: result.ID }] : []),
      transformResponse: (response: Project): Project => ({
        ...response,
        EntityType: "project",
      }),
    }),
    getProjects: build.query({
      query: () => `/v2/projects`,
      transformResponse: (response: GetProjectsResponse) => {
        return projectsAdapter.setAll(
          projectsInitialState,
          response.Items.map((project) => ({ ...project, EntityType: "project" }))
        );
      },
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: "Project" as const, id: id })), { type: "Project", id: "LIST" }]
          : [{ type: "Project", id: "LIST" }],
    }),
    copyProject: build.mutation<Project, { projectId: string; folderId: string | null }>({
      query: ({ projectId, folderId }) => ({
        url: `/v2/projects/${projectId}/copy`,
        method: "POST",
        body: { FolderID: folderId },
      }),
      invalidatesTags: [{ type: "Project", id: "LIST" }],
    }),
    createProject: build.mutation<Project, { subType: ProjectType; accountId: string; folderId?: string | null }>({
      query: ({ subType, accountId, folderId }) => ({
        url: "/v2/projects",
        method: "POST",
        body: { FolderID: folderId ?? null, AccountID: accountId, SubType: subType },
      }),
      invalidatesTags: [{ type: "Project", id: "LIST" }],
    }),
    createAttachmentThumbnails: build.mutation<Project, { projectId: string; attachmentIds?: string[] }>({
      query: ({ projectId, attachmentIds }) => ({
        url: `/v2/projects/${projectId}/generate-attachment-thumbnails`,
        method: "POST",
        body: { AttachmentIds: attachmentIds },
      }),
      invalidatesTags: [{ type: "Project", id: "LIST" }],
    }),
    updateProject: build.mutation<Project, { ID: string } & Partial<Project>>({
      query: ({ ID, ...updates }) => ({
        url: `/v2/projects/${ID}`,
        method: "PATCH",
        body: updates,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Permission", id: arg.ID }],
      onQueryStarted: async ({ ID, ...updates }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          projectSlice.util.updateQueryData("getProject", ID, (draft) => {
            Object.assign(draft, updates);
          })
        );
        const projectsPatchResult = dispatch(
          projectSlice.util.updateQueryData("getProjects", undefined, (projects) => {
            const projectToUpdate = projects.entities[ID];
            if (projectToUpdate) {
              projectsAdapter.updateOne(projects, { id: ID, changes: updates });
            }
          })
        );
        queryFulfilled.catch(() => {
          patchResult.undo();
          projectsPatchResult.undo();
        });
      },
    }),
    deleteProject: build.mutation<OkResponse, Project | ProjectPreview>({
      query: ({ ID, ...project }) => ({
        url: `/v2/projects/${ID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Project", id: arg.ID }],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useGetProjectQuery,
  useLazyGetProjectQuery,
  useGetProjectsQuery,
  useCopyProjectMutation,
  useCreateProjectMutation,
  useCreateAttachmentThumbnailsMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} = projectSlice;

/**
 * Selectors
 */
const selectProjectsResult = projectSlice.endpoints.getProjects.select(undefined);
const selectProjectsData = createSelector(selectProjectsResult, (projectsResult) => projectsResult.data);

export const { selectAll: selectAllProjects, selectById: selectProjectById } = projectsAdapter.getSelectors(
  (state: Store) => selectProjectsData(state) ?? projectsInitialState
);

/**
 * Returns all projects with the current Account.
 */
export const selectProjectsWithinAccount = createSelector([selectAllProjects, selectCurrentAccount], (projects, currentAccount): Project[] => {
  return projects.filter((project) => !currentAccount || project?.Account.ID === currentAccount.ID);
});

/**
 * Returns all planner projects within the current AccountID.
 */
export const selectPlannerProjectsWithinAccount = createSelector([selectProjectsWithinAccount], (projects): Project[] => {
  return projects.filter((project) => !project.HasCustomLayerConfig);
});

/**
 * Returns all projects with the specified ParentID
 */
export const selectProjectsByParentId = createSelector(
  [selectAllProjects, (_: Store, parentId: string | null | undefined) => parentId],
  (projects, parentId): ProjectPreview[] => {
    return projects.filter((project) => (parentId === undefined && !project.FolderID) || project.FolderID === parentId);
  }
);

export const selectProjectsMatchingSearchKey = createSelector(
  [selectAllProjects, (_: Store, searchKey: string) => searchKey],
  (projects, searchKey): ProjectPreview[] => {
    return searchCollectionByKey(projects, searchKey, "ProjectName");
  }
);

/*
 * Return the full account object relating to the given project.
 *
 * This will only succeed if the user is in the same account as the project, so care
 * should be taken when using this.
 */
export const selectProjectAccount = (state: Store, project: Pick<Project, "Account">): Account | undefined =>
  selectAccountById(state, project.Account.ID);
