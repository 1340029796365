import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";

import { selectFolderById, useGetBoundaryFeatureCountQuery } from "fond/api";
import MapProvider from "fond/map/MapProvider";
import { getCurrentProject, loadMultiProject } from "fond/project/redux";
import { CityPlannerList } from "fond/projects/CityPlanner";
import CityPlannerFolderMap from "fond/projects/CityPlanner/CityPlannerFolderMap";
import CityPlannerInfoGrid from "fond/projects/CityPlanner/CityPlannerInfoGrid";
import { MultiProject, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import GridCard from "fond/views/Report/GridCard";
import SkeletonLoadingGrid from "fond/widgets/SkeletonLoadingGrid";

interface RouteParams {
  folderId: string;
}

/**
 * A main page content displaying city planner projects.
 * Includes a DataGrid supporting column sorting and pagination
 */
const CityPlannerFolderPageContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { folderId } = useParams<keyof RouteParams>();
  const folder = useSelector((state: Store) => (folderId ? selectFolderById(state, folderId) : undefined));
  // TODO - modify "state.project.project" type to be possibly a MultiProject
  const project = useSelector((state: Store) => getCurrentProject(state.project)) as unknown as MultiProject;
  const [loaded, setLoaded] = useState(false);

  const boundaries = project?.Areas?.map(({ ID, Boundary }) => ({ id: ID, geometry: Boundary }));
  const { data: featureCounts, isLoading: isLoadingFeatureCounts } = useGetBoundaryFeatureCountQuery(
    { boundaries: boundaries },
    { skip: !boundaries || boundaries.length === 0 }
  );

  useEffect(() => {
    dispatch(loadMultiProject({ uuid: folder?.MultiProject?.ID })).then(async () => {
      setLoaded(true);
    });
  }, [dispatch, folder?.MultiProject?.ID, folderId]);

  if (!project || isLoadingFeatureCounts || !loaded)
    return (
      <SkeletonLoadingGrid
        items={[
          { height: 300, sizes: { xs: 12, sm: 6 } },
          { height: 300, sizes: { xs: 12, sm: 6 } },
          { height: 300, sizes: { xs: 12, sm: 12 } },
        ]}
      />
    );

  return (
    <>
      <Typography>{project?.Description}</Typography>
      <Grid container spacing={2.5} sx={{ mt: 2 }}>
        {project && <CityPlannerInfoGrid id={folder?.MultiProject?.ID} project={project} featureCounts={featureCounts} />}
        <GridCard breakpoints={{ lg: 6, sm: 12, xs: 12 }} title="Project map">
          <Box sx={{ width: "100%", height: 246, position: "relative", borderRadius: 1, overflow: "hidden" }}>
            <MapProvider mapStyle="monochrome">{folder?.MultiProject?.ID && <CityPlannerFolderMap cityId={folder?.MultiProject?.ID} />}</MapProvider>
          </Box>
        </GridCard>
      </Grid>
      <CityPlannerList />
    </>
  );
};

export default CityPlannerFolderPageContent;
