import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Delete, Edit, Link as LinkIcon } from "@mui/icons-material";
import { Fade, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { deleteComment, updateCommentImportance } from "fond/redux/comments";
import { Comment, CommentImportance, Store } from "fond/types";
import { CopyUrl, NestedMenuItem } from "fond/widgets";

import { renderIcon, renderImportanceIcon } from "./Comment";
import DeleteConfirmation from "./DeleteConfirmation";

interface IProps {
  /**
   * The anchor element the menu should be relatively positioned
   */
  anchorEl?: HTMLButtonElement | null;
  /**
   * The comment that the menu relates to
   */
  comment: Comment;
  /**
   * Flag indicating if the menu should be open
   */
  open: boolean;
  /**
   * Callback function for when the menu closes
   */
  onClose(event: React.MouseEvent<EventTarget>): void;
  /**
   * Callback function to initialise editing of the comment message
   */
  onEditComment(comment: Comment): void;
  /**
   * Callback function to initialise editing of the comment feature
   */
  onEditFeature(comment: Comment): void;
}

const CommentMenu: React.FC<IProps> = ({ anchorEl = null, comment, open, onClose, onEditComment, onEditFeature }: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteModal, showDeleteModal] = useState(false);
  const [shareLinkModal, showShareLinkModal] = useState(false);
  const projectId = useSelector((state: Store) => state.project.projectId);
  const currentUsername = useSelector((state: Store) => state.cognito.user?.username);
  const dispatch: ThunkDispatch<Store, null, AnyAction> = useDispatch();
  const navigate = useNavigate();
  const importanceMenuRows: (CommentImportance | null)[] = ["blocker", "critical", "major", "minor", "trivial", null];

  /**
   * handles the closing of the menu
   */
  const handleMenuOnClose = (event: any) => {
    event.stopPropagation();
    onClose(event);
    showDeleteModal(false);
    showShareLinkModal(false);
  };

  /**
   * Handles opening the delete confirmation modal
   */
  const handleConfirmDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    showDeleteModal(true);
  };

  /**
   * Handles opening the share comment link confirmation modal
   */
  const handleShareLink = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    showShareLinkModal(true);
  };

  /**
   * Handles editing the importance level
   */
  const handleUpdateImportance = (event: React.MouseEvent<HTMLElement>, importance: CommentImportance | null) => {
    event.stopPropagation();
    dispatch(
      updateCommentImportance({
        commentID: comment.ID,
        importance: importance,
      })
    )
      .then(() => {
        onClose(event);
        enqueueSnackbar("The importance has been updated.");
      })
      .catch((e) => {
        console.error("Update Comment Importance Failure", e);
      });
  };

  /*
   * Handles editing the comment message
   */
  const handleOnEditComment = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClose(event);

    onEditComment(comment);
  };

  /**
   * Handles opening the edit confirmation modal
   */
  const handleOnEditFeature = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClose(event);

    onEditFeature(comment);
  };

  /**
   * Handles the deletion of the comment
   */
  const onDelete = () => {
    return dispatch(deleteComment(comment.ID)).then(() => {
      enqueueSnackbar("The comment has been deleted.");
      navigate(`/project/${projectId}`);
    });
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handleMenuOnClose}
        TransitionComponent={Fade}
        data-testid="comment-item-menu"
      >
        {comment.Creator.Email === currentUsername && (
          <MenuItem data-testid="edit-comment-button" onClick={handleOnEditComment}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Edit comment
          </MenuItem>
        )}
        {comment.Type !== "project" && comment.Creator.Email === currentUsername && (
          <MenuItem data-testid="edit-comment-button" onClick={handleOnEditFeature}>
            <ListItemIcon>{renderIcon(comment.Type)}</ListItemIcon>
            Edit feature
          </MenuItem>
        )}
        {comment.Creator.Email === currentUsername && (
          <MenuItem color="danger" onClick={handleConfirmDelete} data-testid="delete-comment-button">
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            Delete
          </MenuItem>
        )}
        <MenuItem onClick={handleShareLink} data-testid="comment-link-button">
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          Link to this comment
        </MenuItem>
        <NestedMenuItem
          data-testid="comment-set-importance"
          label="Set importance"
          parentMenuOpen
          MenuProps={{
            open: true,
            MenuListProps: { dense: true },
          }}
          style={{ paddingLeft: 52 }}
        >
          {importanceMenuRows.map((importanceMenuRow) => (
            <MenuItem
              key={importanceMenuRow}
              data-testid={`set-importance-${importanceMenuRow}`}
              style={{ textTransform: "capitalize" }}
              onClick={(event) => handleUpdateImportance(event, importanceMenuRow)}
            >
              <ListItemIcon>{renderImportanceIcon(importanceMenuRow)}</ListItemIcon>
              {importanceMenuRow || "None"}
            </MenuItem>
          ))}
        </NestedMenuItem>
      </Menu>
      {deleteModal && (
        <DeleteConfirmation
          onClose={handleMenuOnClose}
          onDelete={onDelete}
          title="Delete your comment"
          message="Are you sure you wish to delete this comment & all replies?"
        />
      )}
      {shareLinkModal && (
        <CopyUrl
          url={`${window.location.origin}/project/${projectId}/comment/${comment.ID}`}
          title="Link to this comment"
          message="Copy the link below to share this comment with others who have access to this project"
          onClose={handleMenuOnClose}
        />
      )}
    </>
  );
};

export default CommentMenu;
