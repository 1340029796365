import { RequestInput } from "../types";
import { iterItems } from "../utils";
import XHRWrapper from "../xhr";

import { headersCreator } from "./index";

/**
 * Makes an XHRWrapper, sends the request and returns the wrapper. The request
 * automatically has the headers for the current Cognito user injected, FOND
 * Service will authenticate it.
 *
 * @param {String} method - the HTTP method ("POST", "GET", etc)
 * @param {String} url - the URL to make the request to
 * @param {Object} query - optional - any querystring parameters to append to the URL.
 * @param {Object} data - POST data. Keys are strings, values are strings or `File` objects
 * @param {Object} xhrEvents - mapping of event names to callbacks (see function signature)
 */
export async function makeXHRRequest({
  method,
  url,
  query,
  data,
  xhrEvents = {
    onProgress: (event: any) => null,
    onUploadComplete: () => null,
    onComplete: (event: any, responseText: string) => null,
    onError: () => null,
    onAbort: () => null,
  },
}: RequestInput): Promise<any> {
  const xhr = new XHRWrapper({
    method: method,
    url: url,
    query: query,
    headers: await headersCreator.createXHRHeaders(),
    data: data,
  });

  // Map our event names to the XMLHTTPRequest event names.
  const eventNames = {
    onProgress: "progress",
    onUploadComplete: "uploadComplete",
    onComplete: "load",
    onError: "error",
    onAbort: "abort",
  };

  for (let [paramName, eventName] of iterItems(eventNames)) {
    xhr.on(eventName, xhrEvents[paramName]);
  }

  xhr.send();
  return xhr;
}
