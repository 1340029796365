import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  ColDef,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  IServerSideGetRowsParams,
  MenuItemDef,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from "@ag-grid-community/core";
import { Directions, Highlight, Search } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Divider, TextField, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import { uniqBy } from "lodash";
import { createSelector } from "reselect";
import { useDebounce, useDebouncedCallback } from "use-debounce";

import { selectLayerByVersionAndConfigId, selectLayerByVersionAndLayerKey, useGetLayerFeaturesMutation } from "fond/api";
import { zoomTo } from "fond/map/redux";
import { getCurrentProject, getCurrentProjectData } from "fond/project";
import { highlightFeatures, selectFeature } from "fond/project/redux";
import { DetailedRequest, Project, Store } from "fond/types";
import { LayerConfig, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { convertFeetToMeters, filterFeatureCollection, makeUuid, projectUsesVectorTiles, toSystemOfMeasurement } from "fond/utils";
import { useAppDispatch } from "fond/utils/hooks";
import { AgGrid } from "fond/widgets";

/**
 * Memoized selector for the features to be displayed.
 */
const selectFeatures = createSelector(
  (state: Store) => getCurrentProjectData(state.project)?.layers,
  (state: Store, layerKey: string, filter: any[] | undefined) => layerKey,
  (state: Store, layerKey: string, filter: any[] | undefined) => filter,
  (layers: any, layerKey, filter) => {
    const features = layers[layerKey]?.features || [];
    return {
      all: features || [],
      sublayer: filterFeatureCollection({ features: features }, filter),
    };
  }
);

/**
 * To make sure the tooltip loads in the correct window.document
 * when the component is loaded within a floating window we
 * need to disable the use of a portal.
 */
const tooltipPopperProps = { disablePortal: true };

const customStyles = (theme: Theme) => {
  return createStyles({
    gridRoot: {
      borderLeft: `1px solid ${theme.palette.divider}`,
    },
    searchIcon: {
      background: theme.palette.common.white,
    },
    inputRoot: {
      margin: 0,
    },
    input: {
      padding: theme.spacing(1),
      maxHeight: 16,
      fontSize: 13,
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * The Layer that contains the feature information
   */
  layerConfig: LayerConfig;
  /**
   * The SubLayer of the layer
   */
  sublayerConfig?: SublayerConfig;
  /**
   * Flag indicating that the features table should show the feature ids.
   */
  showIds?: boolean;
}

const FeaturesTable: React.FC<IProps> = ({ classes, layerConfig, sublayerConfig, showIds = false }: IProps) => {
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearch] = useDebounce(searchText, 500);
  const [selectedFeatures, setSelectedFeatures] = useState<mapboxgl.MapboxGeoJSONFeature[]>([]);
  const [selectionHasGeometry, setSelectionHasGeometry] = useState<boolean>(false);
  const project: Project = useSelector((state: Store): Project => getCurrentProject(state.project));
  const versionId = useSelector((state: Store) => state.project.versionId);
  const { ID, SystemOfMeasurement } = useSelector((state: Store): Project => getCurrentProject(state.project));
  const [getLayerFeatures] = useGetLayerFeaturesMutation();
  const isVectorTiles = projectUsesVectorTiles(project);
  const gridApi = useRef<GridApi | null>();
  const queryRef = React.useRef("");
  const layer = useSelector(
    (state: Store) =>
      layerConfig &&
      (selectLayerByVersionAndConfigId(state, { versionId: versionId, configId: layerConfig.ID }) ||
        selectLayerByVersionAndLayerKey(state, { versionId: versionId, layerId: layerConfig.Key }))
  );
  const features: { all: any[]; sublayer: any[] } = useSelector((state: Store) =>
    selectFeatures(state, layer?.LayerKey || layerConfig.Key, sublayerConfig?.FilterConfiguration?.Mapbox)
  );

  /**
   * Callback function used to request row data from the server when
   * using a SSRM.
   */
  const getRows = useCallback(
    (params: IServerSideGetRowsParams): void => {
      if (layer) {
        getLayerFeatures({
          versionId: versionId,
          ...params.request,
          layerIDs: [layer.ID],
          sublayerIDs: sublayerConfig ? [sublayerConfig.ID] : [],
          search: debouncedSearch,
        })
          .unwrap()
          .then((response: DetailedRequest) => {
            params.success({
              rowData: response.rows,
              rowCount: response.lastRow,
            });
          })
          .catch(() => {
            params.fail();
          });
      } else {
        // If no layer information exists it is important that we still return
        // a result to AG-Grid otherwise future getRow calls will never be made.
        params.success({
          rowData: [],
          rowCount: 0,
        });
      }
    },
    [debouncedSearch, getLayerFeatures, layer, sublayerConfig, versionId]
  );

  const gridOptions: GridOptions = {
    // If the project is using vector tiles change to server side rendering
    cacheBlockSize: 50,
    // How many milliseconds to wait before loading a block.  This is useful
    // for debouncing calls to the server when multiple calls are requested in
    // quick succession
    blockLoadDebounceMillis: 500,
    // TODO: Once all projects are migrated we can switch this to always be serverSide
    rowModelType: isVectorTiles ? "serverSide" : "clientSide",
    sideBar: false,
    serverSideDatasource: {
      getRows: getRows,
    },
    autoGroupColumnDef: {
      minWidth: 250,
    },
    getRowId: (params: GetRowIdParams) => params.data.id || makeUuid(),
    getChildCount: (data) => {
      // eslint-disable-next-line no-underscore-dangle
      return data.properties._rowCount;
    },
  };

  /**
   * ValueFormatter function that formats a length value into a locale string
   * with a precision of 2
   */
  const lengthValueFormatter = (params: ValueFormatterParams) =>
    params.value
      ? params.value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })
      : null;

  /**
   * External search criteria text has changed.  Note we debounce this value to avoid multiple
   * calls the API when they are not required.
   */
  useEffect(() => {
    // Set the server side datasource for vector tiles
    if (isVectorTiles) {
      gridApi.current?.updateGridOptions({
        serverSideDatasource: {
          getRows: getRows,
        },
      });
    }
  }, [debouncedSearch, getRows, isVectorTiles, layer, layerConfig, sublayerConfig]);

  /**
   * Callback function that is passed the filter text as it changes
   */
  const handleOnFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    queryRef.current = event.currentTarget.value;
    setSearchText(event.currentTarget.value);
  };

  /**
   * Define the columns to be used within the grid.
   * Get the column defs from the layer properties route.
   */
  const defineColumns = useCallback((): ColDef[] => {
    const definitions: ColDef[] = [];

    if (showIds) {
      definitions.push({
        colId: "featureId",
        field: "properties.featureId",
        headerName: "ID",
        resizable: false,
      });
    }

    if (layer && layer?.Attributes.length > 0) {
      // If a layer Properties Schema exists use that to define which
      // columns should show within the table
      for (const attribute of layer.Attributes) {
        let columnName = attribute.Name;

        const definition: ColDef = {
          colId: columnName,
          field: `properties.${columnName}`,
          headerName: columnName,
          resizable: true,
        };

        let columnType = attribute.Type || "";

        // For server side data we cannot use the Set filtering, instead we default to text filtering
        if (gridOptions.rowModelType === "serverSide") {
          definition.filter = "agTextColumnFilter";
        }

        // Note that many propertySchemas don't have a type, so columnType will be undefined
        // INTEGER64 is old and not used any more.  Just put it in for completeness
        if (["INTEGER", "INTEGER64", "REAL"].includes(columnType)) {
          definition.type = "numericColumn";
          definition.filter = "agNumberColumnFilter";
        }

        if (attribute.SourceSystemOfMeasurement) {
          definition.type = "numericColumn";
          definition.filter = "agNumberColumnFilter";
          // We need to format the value to correct system of measurement units
          definition.valueGetter = (params: ValueGetterParams) =>
            toSystemOfMeasurement(params.data?.properties[params.column.getId()], { from: "metric", to: SystemOfMeasurement });
          // Add precision to the value
          definition.valueFormatter = lengthValueFormatter;
          definition.filterParams = {
            // server-side filtering is always based on meters
            numberParser: (value: string | null) => {
              if (value === null) return null;
              return attribute.SourceSystemOfMeasurement === "imperial" ? convertFeetToMeters(value) : value;
            },
          };
        }
        definitions.push(definition);
      }
    } else if (definitions.length === 0) {
      definitions.push({
        colId: "id",
        field: "properties.id",
        headerName: "ID",
        resizable: false,
      } as ColDef);
    }

    return definitions;
  }, [gridOptions.rowModelType, layer, showIds, SystemOfMeasurement]);

  useEffect(() => {
    // If the system of measurement or layer changes we need to
    // determine the new columns & redraw the grid to update measurement calculations
    gridApi.current?.updateGridOptions({ columnDefs: defineColumns() });
    gridApi.current?.redrawRows();
  }, [defineColumns, SystemOfMeasurement, layer]);

  const columnDefs = useRef<ColDef[]>(defineColumns());

  /**
   * Filters each row of the grid based on the external search text the
   * user has entered.
   *
   * This is a basic search that looks for the search criteria in any column
   */
  const doesExternalFilterPass = (node: IRowNode): boolean => {
    const {
      data: { properties },
    } = node;
    return (
      Object.keys(properties).filter((key) => {
        return `${properties[key]}`.toLocaleLowerCase().includes(queryRef.current.toLocaleLowerCase());
      }).length > 0
    );
  };

  /**
   * Gets the data from a RowNode.  If the RowNode is a group row
   * the leafNode data is returned
   */
  const getNodeData = (nodes: IRowNode[]): mapboxgl.MapboxGeoJSONFeature[] => {
    const data: mapboxgl.MapboxGeoJSONFeature[] = [];
    nodes.forEach((node) => {
      // If the node is a row grouping we return the leaf children instead of the selected row
      if (node.group) {
        if (gridOptions.rowModelType === "clientSide") {
          data.push(...node.allLeafChildren.map((leafNode) => leafNode.data));
        }
      } else {
        data.push(node.data);
      }
    });

    return uniqBy(data, "id");
  };

  /**
   * Provides a custom set of context menu items (right click on row)
   */
  const getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
    return [
      "copy",
      "copyWithHeaders",
      "separator",
      "export",
      "separator",
      {
        // custom item
        name: `Zoom to ${params.node?.group ? "Features" : "Feature"}`,
        action: () => {
          if (params.node) {
            const selectedData: mapboxgl.MapboxGeoJSONFeature[] = getNodeData([params.node]);
            // Zoom to feature or the group of features
            dispatch(zoomTo(selectedData));
          }
        },
        icon: `<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M21.71 11.29l-9-9a.9959.9959 0 00-1.41 0l-9 9c-.39.39-.39 1.02 0 1.41l9 9c.39.39 1.02.39 1.41 0l9-9c.39-.38.39-1.01 0-1.41zM14 14.5V12h-4v3H8v-4c0-.55.45-1 1-1h5V7.5l3.5 3.5-3.5 3.5z"></path></svg>`,
      },
    ];
  };

  /**
   * Callback function called when the AgGrid is ready
   */
  const onGridReady = (event: GridReadyEvent) => {
    gridApi.current = event.api;
  };

  /**
   * Callback function called when row selection changes withing the grid.
   * Enable zoom if at least one feature has geometry.
   */
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData: mapboxgl.MapboxGeoJSONFeature[] = getNodeData(selectedNodes);
    setSelectedFeatures(selectedData);
    setSelectionHasGeometry(selectedData.some((f) => f.geometry != null));
  };

  /**
   * Callback function called when a row is double clicked
   * Feature is zoomed to & selected
   */
  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    const selectedNodes = event.api.getSelectedNodes();

    // We don't handle double clicking on a row group
    if (!event.node.group && layer) {
      // Get all features selected within the table then inject
      // the source property based on the currently selected layer.id
      const selectedData: mapboxgl.MapboxGeoJSONFeature[] = getNodeData(selectedNodes).map((feature) => {
        if (isVectorTiles) {
          return {
            ...feature,
            properties: { ...feature.properties, id: feature.id, layerId: layer.LayerKey },
            source: "project-layers",
            sourceLayer: layer.LayerKey,
          };
        } else {
          return { ...feature, source: layer.LayerKey };
        }
      });
      dispatch(zoomTo(selectedData));
      dispatch(selectFeature(selectedData[0]));
      dispatch(
        highlightFeatures({
          projectId: ID,
          features: selectedData,
        })
      );
    }
  };

  /**
   * When layer selection changes we use this callback to resize all columns
   * as the layer properties also change
   */
  const autoSizeColumns = useDebouncedCallback(() => {
    gridApi.current?.autoSizeAllColumns();
  }, 500);

  /**
   * This is set to temporarily replace the handling of column resizing
   * when rowData gets updated since onRowDataUpdated doesn't seem to
   * trigger autoSizeAllColumns().
   */
  useEffect(() => {
    autoSizeColumns();
  }, [autoSizeColumns, features.all, features.sublayer]);

  /**
   * Callback function for zooming to selected features within the table
   */
  const handleZoomTo = () => {
    dispatch(zoomTo(selectedFeatures));
  };

  /**
   * Callback function for zooming to selected features within the table
   */
  const handleHighlight = () => {
    if (layer) {
      dispatch(
        highlightFeatures({
          projectId: ID,
          // We need to inject the source property based on the currently selected layer.id
          features: selectedFeatures.map((feature) => {
            if (isVectorTiles) {
              return {
                ...feature,
                properties: { ...feature.properties, id: feature.id, layerId: layer.ID },
                source: "project-layers",
                sourceLayer: layer.LayerKey,
              };
            } else {
              return { ...feature, source: layer.LayerKey };
            }
          }),
        })
      );
    }
  };

  return (
    <Box width="100%" height="100%">
      <Box display="flex" height="100%" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" mb={0.5} pt={0.5}>
          <TextField
            variant="outlined"
            data-testid="ag-grid-external-input"
            margin="dense"
            name="search"
            onChange={handleOnFilterChange}
            placeholder="Search layer attributes..."
            className={classes.inputRoot}
            inputProps={{ className: classes.input }}
            InputProps={{
              className: classes.searchIcon,
              startAdornment: <Search />,
            }}
          />
          <Box height="100%" mx={1}>
            <Divider orientation="vertical" />
          </Box>
          <ButtonGroup variant="outlined">
            <Tooltip
              title={selectionHasGeometry ? `Zoom to ${selectedFeatures.length > 1 ? "features" : "feature"}` : "These features do not have geometry"}
              PopperProps={tooltipPopperProps}
            >
              <span>
                <Button
                  variant="text"
                  size="small"
                  disabled={!selectionHasGeometry}
                  onClick={handleZoomTo}
                  startIcon={<Directions color={selectionHasGeometry ? "primary" : "disabled"} />}
                >
                  Zoom
                </Button>
              </span>
            </Tooltip>
            <Tooltip
              title={
                selectionHasGeometry ? `Highlight ${selectedFeatures.length > 1 ? "features" : "feature"}` : "These features do not have geometry"
              }
              PopperProps={tooltipPopperProps}
            >
              <span>
                <Button
                  variant="text"
                  size="small"
                  disabled={!selectionHasGeometry}
                  onClick={handleHighlight}
                  startIcon={<Highlight color={selectionHasGeometry ? "primary" : "disabled"} />}
                >
                  Highlight
                </Button>
              </span>
            </Tooltip>
          </ButtonGroup>
        </Box>

        <Box flexGrow={1} overflow="hidden" mb={1} className={classes.gridRoot} sx={{ height: 500 }}>
          <AgGrid
            rowData={gridOptions.rowModelType === "clientSide" ? features.sublayer || features.all : null}
            columnDefs={columnDefs.current}
            gridOptions={gridOptions}
            getContextMenuItems={getContextMenuItems}
            doesExternalFilterPass={doesExternalFilterPass}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            onRowDoubleClicked={onRowDoubleClicked}
            externalSearchText={debouncedSearch}
            externalFilter
            size="compact"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(customStyles)(FeaturesTable);
