/* eslint-disable import/prefer-default-export */
import { RawDraftContentState } from "draft-js";
import { Feature } from "geojson";

import * as API from "fond/api";
import { setDraggedPopupPosition } from "fond/project/redux";
import {
  ADD_COMMENT_FAILURE,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_REPLY_FAILURE,
  ADD_REPLY_REQUEST,
  ADD_REPLY_SUCCESS,
  CommentsActionType,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_REPLY_FAILURE,
  DELETE_REPLY_REQUEST,
  DELETE_REPLY_SUCCESS,
  GET_COMMENTS_FAILURE,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  RESOLVE_COMMENT_FAILURE,
  RESOLVE_COMMENT_REQUEST,
  RESOLVE_COMMENT_SUCCESS,
  SELECT_COMMENT,
  SET_EDIT_ID,
  SET_FILTERS,
  SET_MAP_FILTER,
  SET_SEARCH_TEXT,
  SET_SORT_ORDER,
  UPDATE_COMMENT_FAILURE,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_REPLY_FAILURE,
  UPDATE_REPLY_REQUEST,
  UPDATE_REPLY_SUCCESS,
} from "fond/redux/comments";
import * as turf from "fond/turf";
import { AppThunk, Comment, CommentFilters, CommentImportance, CommentSortOrder, CommentState, Reply } from "fond/types";
import { getPlainText } from "fond/utils/draftEditor";

const getCommentsRequest = (): CommentsActionType.GetCommentsRequest => ({ type: GET_COMMENTS_REQUEST });
const getCommentsSuccess = (payload: { Comments: Comment[] }): CommentsActionType.GetCommentsSuccess => ({
  type: GET_COMMENTS_SUCCESS,
  payload: payload,
});
const getCommentsFailure = (error: Error): CommentsActionType.GetCommentsFailure => ({ type: GET_COMMENTS_FAILURE, error: error });
const addCommentRequest = (): CommentsActionType.AddCommentRequest => ({ type: ADD_COMMENT_REQUEST });
const addCommentSuccess = (payload: { Comment: Comment }): CommentsActionType.AddCommentSuccess => ({
  type: ADD_COMMENT_SUCCESS,
  payload: payload,
});
const addCommentFailure = (error: Error): CommentsActionType.AddCommentFailure => ({ type: ADD_COMMENT_FAILURE, error: error });

const updateCommentRequest = (): CommentsActionType.UpdateCommentRequest => ({ type: UPDATE_COMMENT_REQUEST });
const updateCommentSuccess = (payload: { Comment: Comment }): CommentsActionType.UpdateCommentSuccess => ({
  type: UPDATE_COMMENT_SUCCESS,
  payload: payload,
});
const updateCommentFailure = (error: Error): CommentsActionType.UpdateCommentFailure => ({ type: UPDATE_COMMENT_FAILURE, error: error });

const resolveCommentRequest = (): CommentsActionType.ResolveCommentRequest => ({ type: RESOLVE_COMMENT_REQUEST });
const resolveCommentSuccess = (payload: { Comment: Comment }): CommentsActionType.ResolveCommentSuccess => ({
  type: RESOLVE_COMMENT_SUCCESS,
  payload: payload,
});
const resolveCommentFailure = (error: Error): CommentsActionType.ResolveCommentFailure => ({ type: RESOLVE_COMMENT_FAILURE, error: error });

const updateReplyRequest = (): CommentsActionType.UpdateReplyRequest => ({ type: UPDATE_REPLY_REQUEST });
const updateReplySuccess = (payload: { Reply: Reply }): CommentsActionType.UpdateReplySuccess => ({
  type: UPDATE_REPLY_SUCCESS,
  payload: payload,
});
const updateReplyFailure = (error: Error): CommentsActionType.UpdateReplyFailure => ({ type: UPDATE_REPLY_FAILURE, error: error });

const deleteCommentRequest = (): CommentsActionType.DeleteCommentRequest => ({ type: DELETE_COMMENT_REQUEST });
const deleteCommentSuccess = (payload: { ID: string }): CommentsActionType.DeleteCommentSuccess => ({
  type: DELETE_COMMENT_SUCCESS,
  payload: payload,
});
const deleteCommentFailure = (error: Error): CommentsActionType.DeleteCommentFailure => ({ type: DELETE_COMMENT_FAILURE, error: error });

const addReplyRequest = (): CommentsActionType.AddReplyRequest => ({ type: ADD_REPLY_REQUEST });
const addReplySuccess = (payload: { Comment: Comment; Reply: Reply }): CommentsActionType.AddReplySuccess => ({
  type: ADD_REPLY_SUCCESS,
  payload: payload,
});
const addReplyFailure = (error: Error): CommentsActionType.AddReplyFailure => ({ type: ADD_REPLY_FAILURE, error: error });

const deleteReplyRequest = (): CommentsActionType.DeleteReplyRequest => ({ type: DELETE_REPLY_REQUEST });
const deleteReplySuccess = (payload: { ID: string; CommentID: string }): CommentsActionType.DeleteReplySuccess => ({
  type: DELETE_REPLY_SUCCESS,
  payload: payload,
});
const deleteReplyFailure = (error: Error): CommentsActionType.DeleteReplyFailure => ({ type: DELETE_REPLY_FAILURE, error: error });

const selectCommentSuccess = (payload: {
  commentID: string;
  highlightedFeatures: mapboxgl.FeatureIdentifier[] | mapboxgl.MapboxGeoJSONFeature[];
  lngLat: mapboxgl.LngLatLike;
  mapSelection: boolean;
  showPopup: boolean;
}): CommentsActionType.SelectComment => ({ type: SELECT_COMMENT, payload: payload });

/**
 * Requests the all comments for a project
 */
export const getComments =
  (id: string): AppThunk<void> =>
  async (dispatch) => {
    dispatch(getCommentsRequest());
    return API.getComments(id)
      .then((result) => {
        dispatch(getCommentsSuccess(result));
      })
      .catch((e) => {
        dispatch(getCommentsFailure(e));
      });
  };

/**
 * Posts a new comment
 */
export const addComment =
  (comment: Pick<Comment, "Version" | "Features" | "Importance" | "RawContent" | "Type">): AppThunk<Promise<Comment>> =>
  async (dispatch) => {
    dispatch(addCommentRequest());

    return API.addComment({
      Content: getPlainText(comment.RawContent),
      RawContent: comment.RawContent,
      Importance: comment.Importance,
      VersionID: comment.Version,
      Features: comment.Features,
      Type: comment.Type,
    })
      .then((result) => {
        dispatch(addCommentSuccess({ Comment: result }));
        return Promise.resolve(result);
      })
      .catch((e: Error) => {
        dispatch(addCommentFailure(e));
        return Promise.reject(e);
      });
  };

export const selectComment =
  ({
    commentID,
    features,
    point,
    showPopup = true,
    isFromFeaturesPopup = false,
  }: {
    commentID: string;
    features: any[];
    point?: mapboxgl.LngLatLike;
    showPopup?: boolean;
    isFromFeaturesPopup?: boolean;
  }): AppThunk<void> =>
  (dispatch) => {
    // If a point was not passed use turf to determine an appropriate lngLat
    // For example the Feature Table can select a feature from the table.
    let lngLat = point;
    if (!lngLat && features.length > 0) {
      const bestPoint = turf.pointOnFeature(features[0]);
      lngLat = {
        lng: bestPoint.geometry.coordinates[0],
        lat: bestPoint.geometry.coordinates[1],
      };
    }

    // Unless the comment is selected from FeaturesPopup, reset the draggable popup states.
    if (!isFromFeaturesPopup) {
      dispatch(setDraggedPopupPosition(null));
    }

    dispatch(
      selectCommentSuccess({
        commentID: commentID,
        highlightedFeatures: features.map((feature) => ({
          ...feature,
          id: commentID,
          source: feature.source || `comments-source`,
        })),
        lngLat: lngLat as mapboxgl.LngLatLike,
        // We need to understand if the selection was from the map
        // or the list of comments
        mapSelection: point !== undefined,
        showPopup: showPopup,
      })
    );
  };

export const addReply =
  ({ rawContent, comment }: { rawContent: RawDraftContentState; comment: Comment }): AppThunk<Promise<Reply>> =>
  async (dispatch) => {
    dispatch(addReplyRequest());

    return API.addReply(comment.ID, {
      Content: getPlainText(rawContent),
      RawContent: rawContent,
    })
      .then((result) => {
        dispatch(addReplySuccess({ Reply: result, Comment: comment }));
        return Promise.resolve(result);
      })
      .catch((e: Error) => {
        dispatch(addReplyFailure(e));
        return Promise.reject(e);
      });
  };

export const deleteComment =
  (commentId: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(deleteCommentRequest());

    return API.deleteComment(commentId)
      .then(() => {
        dispatch(deleteCommentSuccess({ ID: commentId }));
        return Promise.resolve();
      })
      .catch((e: Error) => {
        dispatch(deleteCommentFailure(e));
        return Promise.reject(e);
      });
  };

export const deleteReply =
  (reply: Reply): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(deleteReplyRequest());

    return API.deleteReply(reply.ID)
      .then(() => {
        dispatch(deleteReplySuccess({ ID: reply.ID, CommentID: reply.CommentID }));
        return Promise.resolve();
      })
      .catch((e: Error) => {
        dispatch(deleteReplyFailure(e));
        return Promise.reject(e);
      });
  };

export const updateComment =
  ({
    rawContent,
    importance,
    comment,
    features,
  }: {
    rawContent: RawDraftContentState;
    importance: CommentImportance | null;
    comment: Comment;
    features: Feature[];
  }): AppThunk<Promise<Comment>> =>
  async (dispatch) => {
    dispatch(updateCommentRequest());

    return API.updateComment(comment.ID, {
      Content: getPlainText(rawContent),
      RawContent: rawContent,
      Importance: importance,
      Features: features,
    })
      .then((result) => {
        dispatch(updateCommentSuccess({ Comment: result }));
        return Promise.resolve(result);
      })
      .catch((e: Error) => {
        dispatch(updateCommentFailure(e));
        return Promise.reject(e);
      });
  };

export const updateCommentImportance =
  ({ commentID, importance }: { commentID: string; importance: CommentImportance | null }): AppThunk<Promise<Comment>> =>
  async (dispatch) => {
    dispatch(updateCommentRequest());
    return API.updateComment(commentID, {
      Importance: importance,
    })
      .then((result) => {
        dispatch(updateCommentSuccess({ Comment: result }));
        return Promise.resolve(result);
      })
      .catch((e: Error) => {
        dispatch(updateCommentFailure(e));
        return Promise.reject(e);
      });
  };

export const updateReply =
  ({ rawContent, reply }: { rawContent: RawDraftContentState; reply: Reply }): AppThunk<Promise<Reply>> =>
  async (dispatch) => {
    dispatch(updateReplyRequest());

    return API.updateReply(reply.ID, {
      Content: getPlainText(rawContent),
      RawContent: rawContent,
    })
      .then((result) => {
        dispatch(updateReplySuccess({ Reply: result }));
        return Promise.resolve(result);
      })
      .catch((e: Error) => {
        dispatch(updateReplyFailure(e));
        return Promise.reject(e);
      });
  };

export const resolveComment =
  (commentId: string, commentState: string): AppThunk<Promise<Comment>> =>
  async (dispatch) => {
    dispatch(resolveCommentRequest());

    return API.resolveComment(commentId, commentState as CommentState)
      .then((result) => {
        dispatch(resolveCommentSuccess({ Comment: result }));
        return Promise.resolve(result);
      })
      .catch((e: Error) => {
        dispatch(resolveCommentFailure(e));
        return Promise.reject(e);
      });
  };

export const setEditId = (editId: string | null): CommentsActionType.SetEditId => ({
  type: SET_EDIT_ID,
  editId: editId,
});

export const setMapFilter = (filter: boolean): CommentsActionType.SetMapFilter => ({
  type: SET_MAP_FILTER,
  mapFilter: filter,
});

export const setSortOrder = (order: CommentSortOrder): CommentsActionType.SetSortOrder => ({
  type: SET_SORT_ORDER,
  sortOrder: order,
});

export const setFilters = (filters: CommentFilters): CommentsActionType.SetFilters => ({
  type: SET_FILTERS,
  filters: filters,
});

export const setSearchText = (text: string): CommentsActionType.SetSearchText => ({
  type: SET_SEARCH_TEXT,
  searchText: text,
});
