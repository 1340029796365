import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRowNode } from "@ag-grid-community/core";
import TuneIcon from "@mui/icons-material/Tune";
import { Box } from "@mui/material";
import { useDebounce } from "use-debounce";

import {
  selectFoldersMatchingSearchKey,
  selectProjectsMatchingSearchKey,
  selectReportsMatchingSearchKey,
  useGetFoldersQuery,
  useGetProjectsQuery,
  useGetReportsQuery,
} from "fond/api";
import { SEARCH_KEY } from "fond/constants";
import SearchBar from "fond/projects/SearchBar";
import { Folder, Project, Report, Store } from "fond/types";
import { useQueryParams } from "fond/utils/hooks";
import { AgGrid } from "fond/widgets";

import CityPlannerRowMenu from "../ItemMenu/CityPlannerRowMenu";
import FolderRowMenu from "../ItemMenu/FolderRowMenu";
import ProjectRowMenu from "../ItemMenu/ProjectRowMenu";
import ReportRowMenu from "../ItemMenu/ReportRowMenu";
import useProjectsTable from "../useProjectsTable";

const SearchResultContainer: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined);
  const [menuEntity, setMenuEntity] = useState<Folder | Project | Report | undefined>(undefined);

  const searchQuery = useQueryParams(SEARCH_KEY) ?? "";

  const { isFetching: isFoldersFetching, isLoading: isFoldersLoading } = useGetFoldersQuery(undefined);
  const { isFetching: isProjectsFetching, isLoading: isProjectsLoading } = useGetProjectsQuery(undefined);
  const { isFetching: isReportsFetching, isLoading: isReportsLoading } = useGetReportsQuery(undefined);
  const suggestedProjects = useSelector((store: Store) => selectProjectsMatchingSearchKey(store, searchQuery));
  const suggestedFolders = useSelector((store: Store) => selectFoldersMatchingSearchKey(store, searchQuery));
  const suggestedReports = useSelector((store: Store) => selectReportsMatchingSearchKey(store, searchQuery));

  /**
   * Callback function that handles opening the row menu
   */
  const openRowMenu = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, entity?: Folder | Project) => {
    setMenuAnchor(e.currentTarget);
    setMenuEntity(entity);
  }, []);

  /**
   * Callback function that handles closing the row menu
   */
  const closeRowMenu = () => setMenuAnchor(undefined);

  const { columns: gridColumns } = useProjectsTable({ openRowMenu: openRowMenu });

  const filter = React.useRef(searchText);
  const [debouncedSearch] = useDebounce(searchText, 500);

  useEffect(() => {
    filter.current = searchText;
  }, [searchText]);

  const handleOnFilterChange = (value: string) => {
    setSearchText(value.toUpperCase());
  };

  const doesExternalFilterPass = (node: IRowNode<Folder | Project>): boolean => {
    if (node.data?.EntityType === "project") {
      return node.data.ProjectName.toUpperCase().includes(filter.current) || node.data.Creator.toUpperCase().includes(filter.current);
    } else if (node.data?.EntityType === "folder") {
      return node.data.Name.toUpperCase().includes(filter.current) || node.data.Description?.toUpperCase().includes(filter.current);
    }
    return filter.current === "";
  };

  const isLoading = isProjectsLoading || isFoldersLoading || isReportsLoading;
  const isFetching = isProjectsFetching || isFoldersFetching || isReportsFetching;

  return (
    <>
      <Box my={3}>
        <SearchBar onChange={handleOnFilterChange} placeholder="Filter results" icon={<TuneIcon style={{ marginRight: "0.5em" }} />} />
      </Box>
      <Box data-testid="search-results-all">
        <AgGrid
          autoSizeColumns={false}
          gridOptions={{
            defaultColDef: {
              resizable: true,
              suppressHeaderMenuButton: true,
              suppressMovable: true,
              sortable: true,
            },
            domLayout: "autoHeight",
            animateRows: true,
            suppressGroupRowsSticky: true,
            suppressRowClickSelection: true,
            sideBar: false,
            rowGroupPanelShow: "never",
          }}
          rowData={isLoading ? null : [...suggestedFolders, ...suggestedProjects, ...suggestedReports]}
          columnDefs={gridColumns}
          externalFilter
          externalSearchText={debouncedSearch}
          doesExternalFilterPass={doesExternalFilterPass}
          showLoadingOverlay={isFetching}
          size="large"
        />
      </Box>
      {menuEntity?.EntityType === "project" && <ProjectRowMenu projectPreview={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />}
      {menuEntity?.EntityType === "folder" &&
        (menuEntity.MultiProject ? (
          <CityPlannerRowMenu cityPlannerFolder={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />
        ) : (
          <FolderRowMenu folder={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />
        ))}
      {menuEntity?.EntityType === "report" && <ReportRowMenu report={menuEntity} onMenuClose={closeRowMenu} anchorEl={menuAnchor} />}
    </>
  );
};
export default SearchResultContainer;
