import React, { useMemo } from "react";
import { Delete } from "@mui/icons-material";
import { Alert, IconButton, Tooltip, Typography } from "@mui/material";

import { Folder, Project, Report } from "fond/types";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { ConfirmModal } from "fond/widgets";

interface IProps {
  /**
   * The current datagrid row item selection.
   */
  selectedItems: Array<Folder | Project | Report>;
  /**
   * Callback function for when the delete button is clicked.
   */
  onDelete?(): void;
}

const ProjectListToolbarActions: React.FC<IProps> = ({ selectedItems, onDelete }: IProps) => {
  const [confirmModal, showConfirmModal] = React.useState<boolean>(false);

  const cannotDeleteProjects: Project[] = useMemo(
    () =>
      selectedItems.filter((item) => item.EntityType === "project" && !permissionCheck(item.Permission.Level, Actions.PROJECT_DELETE)) as Project[],
    [selectedItems]
  );
  const cannotDeleteReports: Report[] = useMemo(
    () => selectedItems.filter((item) => item.EntityType === "report" && !permissionCheck(item.Permission?.Level, Actions.REPORT_DELETE)) as Report[],
    [selectedItems]
  );
  let cannotDeleteEntity = "projects";
  if (cannotDeleteProjects.length > 0 && cannotDeleteReports.length > 0) {
    cannotDeleteEntity = "projects and reports";
  } else if (cannotDeleteReports.length > 0) {
    cannotDeleteEntity = "reports";
  }

  const containsProjects: boolean = selectedItems.some((item) => item.EntityType === "project");
  const containsReports: boolean = selectedItems.some((item) => item.EntityType === "report");
  let entityToDelete = "projects";
  if (containsProjects && containsReports) {
    entityToDelete = "projects and reports";
  } else if (containsReports) {
    entityToDelete = "reports";
  }

  return (
    <>
      {/* 
        // TODO - Future additional actions
        {onMove && (<></>)} 
      */}
      {onDelete && (
        <>
          <Tooltip title="Delete">
            <span>
              <IconButton aria-label="delete" onClick={() => showConfirmModal(true)} color="primary" data-testid="delete-button">
                <Delete />
              </IconButton>
            </span>
          </Tooltip>
          <ConfirmModal
            data-testid="confirm-delete-modal"
            header={`Delete ${entityToDelete}`}
            confirmText="Delete"
            open={confirmModal}
            onConfirm={onDelete}
            onCancel={() => showConfirmModal(false)}
            disabled={cannotDeleteProjects.length + cannotDeleteReports.length === selectedItems.length}
            content={
              <div className="content">
                <Typography variant="body2">Are you sure you want to delete the selected {entityToDelete}?</Typography>
                {(cannotDeleteProjects.length > 0 || cannotDeleteReports.length > 0) && (
                  <Alert severity="warning" sx={{ mt: 1 }}>
                    The following {cannotDeleteEntity} will not be deleted as you do not have the appropriate permissions:
                    <ul>
                      {cannotDeleteProjects.map((item) => (
                        <li key={item.ID}>{item.ProjectName}</li>
                      ))}
                      {cannotDeleteReports.map((item) => (
                        <li key={item.ID}>{item.Name}</li>
                      ))}
                    </ul>
                  </Alert>
                )}
              </div>
            }
          />
        </>
      )}
    </>
  );
};

export default ProjectListToolbarActions;
