import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { multiProjectConfiguration } from "fond/cityPlanner/configuration";
import { SomeRequired, Store } from "fond/types";
import { BaseMultiProject, CarveMultiProjectParams, CreateMultiProjectParams, MultiProject, UpdateMultiProjectParams } from "fond/types/multiProject";

import { apiSlice } from "./apiSlice";
import { versionConfigEntityAdapter, versionsSlice } from "./versionsSlice";

type ListMultiProjectsResponse = {
  MultiProjects: BaseMultiProject[];
};

export const baseMultiProjectsAdapter = createEntityAdapter<BaseMultiProject>({
  selectId: (entity: BaseMultiProject): string => entity.ID,
});
const baseMultiProjectInitialState = baseMultiProjectsAdapter.getInitialState();

/**
 * MultiProjects API Slice
 */
export const multiProjectsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMultiProject: build.query<MultiProject, string>({
      query: (multiProjectId: string) => `/v2/multi-projects/${multiProjectId}`,
      providesTags: (result) => (result ? [{ type: "MultiProject", id: result.ID }] : []),
    }),
    getMultiProjects: build.query({
      query: () => `/v2/multi-projects`,
      transformResponse: (response: ListMultiProjectsResponse) =>
        baseMultiProjectsAdapter.setAll(baseMultiProjectInitialState, response.MultiProjects),
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: "MultiProject" as const, id: id })), { type: "MultiProject", id: "LIST" }]
          : [{ type: "MultiProject", id: "LIST" }],
    }),
    createMultiProject: build.mutation<MultiProject, CreateMultiProjectParams>({
      query: (params) => ({
        url: "/v2/multi-projects",
        method: "POST",
        body: params,
      }),
      invalidatesTags: [
        { type: "MultiProject", id: "LIST" },
        { type: "Folder", id: "LIST" },
      ],
    }),
    updateMultiProject: build.mutation<MultiProject, UpdateMultiProjectParams>({
      query: ({ ID, ...multiProjectData }) => ({
        url: `/v2/multi-projects/${ID}`,
        method: "PATCH",
        body: multiProjectData,
      }),
      onQueryStarted: async (multiProject, { dispatch, queryFulfilled }) => {
        const { data: newMultiProject } = await queryFulfilled;
        // When a multiproject is updated we need to rebuild the root-configuration of
        // the project so that we generate the new layers based off the boundary & subareas
        dispatch(
          versionsSlice.util.updateQueryData("getVersionConfig", multiProject.ID, (draft) => {
            if (draft.Data) {
              const upsert = multiProjectConfiguration(newMultiProject);
              versionConfigEntityAdapter.upsertMany(draft.Data, upsert);
            }
          })
        );
      },
      invalidatesTags: (result, error, arg) => [
        { type: "MultiProject", id: arg.ID },
        ...(result ? [{ type: "Folder" as const, id: result.Folder.ID }] : []),
      ],
    }),
    carveMultiProject: build.mutation<MultiProject, CarveMultiProjectParams>({
      query: ({ ID, Actions }) => ({
        url: `/v2/multi-projects/${ID}/carve`,
        method: "POST",
        body: { Actions: Actions ?? [] },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "MultiProject", id: arg.ID }, ...(result ? [{ type: "Project" as const, id: "LIST" }] : [])],
    }),
    deleteMultiProject: build.mutation<void, SomeRequired<MultiProject, "ID">>({
      query: ({ ID }) => ({
        url: `/v2/multi-projects/${ID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "MultiProject", id: arg.ID },
        // Deleting a multi-project deletes any subprojects as well as its parent folder.
        { type: "Project", id: "LIST" },
        { type: "Folder", id: "LIST" },
      ],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useGetMultiProjectQuery,
  useGetMultiProjectsQuery,
  useCreateMultiProjectMutation,
  useUpdateMultiProjectMutation,
  useCarveMultiProjectMutation,
  useDeleteMultiProjectMutation,
} = multiProjectsSlice;

/**
 * Selectors
 */
const selectMultiProjectsResult = multiProjectsSlice.endpoints.getMultiProjects.select(undefined);
const selectMultiProjectsData = createSelector(selectMultiProjectsResult, (result) => result.data);

export const { selectAll: selectAllBaseMultiProjects, selectById: selectMultiProjectById } = baseMultiProjectsAdapter.getSelectors(
  (state: Store) => selectMultiProjectsData(state) ?? baseMultiProjectInitialState
);
