import { CITY_PLANNER_AREA_MAX } from "fond/constants";
import defaultStyles from "fond/draw/defaultStyles";
import { MapboxStyleLayer } from "fond/types";

/**
 * The Mapboxdraw theme extended to include styles specific to drawing Multiproject areas
 */
const styles: MapboxStyleLayer[] = [
  ...defaultStyles,
  {
    id: "gl-draw-polygon-fill-icon",
    type: "symbol",
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["any", [">", "user_minCount", CITY_PLANNER_AREA_MAX], [">", "user_exactCount", CITY_PLANNER_AREA_MAX]],
    ],
    layout: {
      "text-field": "\ue81f",
      "text-allow-overlap": true,
      "text-ignore-placement": true,
      "text-font": ["fontello Regular"],
    },
    paint: {
      "text-translate": [0, -10],
      "text-color": "#E1373B",
      "text-halo-width": 1.5,
      "text-halo-color": "#ffffff",
    },
  },
  {
    id: "gl-draw-polygon-fill-label",
    type: "symbol",
    filter: ["==", "$type", "Polygon"],
    layout: {
      "text-allow-overlap": true,
      "text-ignore-placement": true,
      "text-field": ["get", "user_name"],
      "text-size": 12,
    },
    paint: {
      "text-halo-width": 1.5,
      "text-halo-color": "#ffffff",
    },
  },
];

export default styles;
