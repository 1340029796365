import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Feature, MultiPolygon, Polygon } from "geojson";

import { CITY_PLANNER_AREA_MAX } from "fond/constants";
import { bbox, difference, feature as toFeature, featureCollection, polygon as toPolygon } from "fond/turf";
import { MultiProjectArea } from "fond/types";
import { isAnyPolygon, isMultiPolygon, isPolygon } from "fond/types/geojson";
import { toBBox } from "fond/utils";

import { SubareaFeature } from "./AreaPanel/AreaDrawPanel";

export type AreaErrorType = "prem_count_exceeded" | "name_duplication";

/**
 * Prevents a polygon from extending beyond another polygon
 */
export const clipOutside = (
  polygon: Feature<Polygon | MultiPolygon>,
  boundaryPolygon: Feature<Polygon | MultiPolygon>
): Feature<Polygon | MultiPolygon> => {
  let diffPoly: Feature<Polygon | MultiPolygon> | null = difference(polygon, boundaryPolygon);

  diffPoly = diffPoly ? difference(polygon, diffPoly) : polygon;

  return { ...diffPoly, id: polygon.id } as Feature<Polygon | MultiPolygon>;
};

/**
 * Converts a MultiPolygon into multiple Polygon features
 */
export const uncombine = (feature: Feature<MultiPolygon | Polygon>): Feature<Polygon>[] => {
  if (isMultiPolygon(feature)) {
    const polygons = feature.geometry.coordinates.map((coord) => toPolygon(coord, { ...feature.properties }));
    return polygons;
  } else if (isPolygon(feature)) {
    return [feature];
  }
  return [];
};

/**
 * Updates a property value on a feature currently being drawn
 */
export const setFeatureProperty = (drawControl: MapboxDraw, id: string, property: string, value: any): void => {
  if (drawControl) {
    drawControl.setFeatureProperty(id, property, value);

    // We refresh the features to force a redraw
    drawControl.set(drawControl.getAll());
  }
};

/**
 * Validates the subarea features currently drawn on the map:
 *
 * - Number of prem counts cannot exceed <CITY_PLANNER_AREA_MAX>
 * - Names of areas must be unique
 *
 */
export const validateAreaFeatures = (features: SubareaFeature[]): Record<string, AreaErrorType[]> | undefined => {
  const validation: Record<string, AreaErrorType[]> = {};
  features.filter(isAnyPolygon).forEach((feat) => {
    const areaErrors: AreaErrorType[] = [];
    const names = features.filter(({ id }) => id !== feat.id).map(({ properties }) => properties?.name);

    if (feat.properties) {
      const { id, exactCount, minCount, name } = feat.properties;
      const count = minCount || exactCount || 0;
      if (count > CITY_PLANNER_AREA_MAX) {
        areaErrors.push("prem_count_exceeded");
      }

      if (names.includes(name)) {
        areaErrors.push("name_duplication");
      }

      if (areaErrors.length > 0) {
        validation[id] = areaErrors;
      }
    }
  });

  if (Object.keys(validation).length === 0) return undefined;
  return validation;
};

/**
 * Generates a bounding box based on the projects subarea boundaries.
 * @returns A turf.bbox converted to the format used by mapboxgl.Map
 */
export const getAreaBoundingBox = (areas: MultiProjectArea[]): [[number, number], [number, number]] | null => {
  // Create a feature collection for turf to use
  const collection = featureCollection(areas.map(({ Boundary }) => toFeature(Boundary)));
  const turfBBox = bbox(collection);

  // Convert to mapboxgl bbox
  return toBBox(turfBBox);
};
