import React from "react";
import { AgChartsReact } from "ag-charts-react";

import { CostBreakdown } from "fond/types";
import { formatCurrency } from "fond/utils/currency";

interface IProps {
  totalCost: number | null;
  data: CostBreakdown[];
}

const CostBreakdownChart: React.FC<IProps> = ({ totalCost, data }) => {
  const summarizedData = data.reduce(
    (currentList, next) => {
      const copyOfCurrentList = [...currentList];

      const updateItem = (tag: string, cost: number) => {
        const targetIndex = currentList.findIndex((item) => item.tag === tag);
        if (targetIndex === -1) {
          copyOfCurrentList.push({ tag: tag, cost: cost });
        } else {
          copyOfCurrentList[targetIndex].cost += cost;
        }
      };

      if (next.Tags.length === 0) {
        updateItem("Untagged", next.TotalCost);
      } else {
        next.Tags.forEach((tag) => {
          updateItem(tag, next.TotalCost);
        });
      }

      return copyOfCurrentList;
    },
    [] as Array<{ tag: string; cost: number }>
  );

  return (
    <AgChartsReact
      options={{
        height: 320,
        data: summarizedData,
        series: [
          {
            type: "donut",
            calloutLabelKey: "tag",
            calloutLabel: { enabled: false },
            angleKey: "cost",
            innerRadiusRatio: 0.6,
            innerLabels: [
              {
                text: formatCurrency(totalCost, { notation: "compact" }),
                fontSize: 20,
                fontWeight: 700,
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              },
              {
                text: "Total Cost",
                fontSize: 16,
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                margin: 8,
                color: "rgba(0, 0, 0, 0.45)",
              },
            ],
            tooltip: {
              renderer: (params) => {
                return {
                  title: "Total cost",
                  content: `${params.datum.tag}: ${formatCurrency(params.datum.cost)}`,
                };
              },
            },
          },
        ],
      }}
    />
  );
};

export default CostBreakdownChart;
