import React, { useContext, useEffect, useMemo, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import { TreeItem, TreeItemRenderContext } from "ui/Tree";

import { convertAndFormatLegendCount } from "fond/layers/functions";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";

import { InferredIcon } from "./InferredIcon";
import { LegendItemType } from "./Legend";
import { LegendContext } from "./LegendProvider";

import { BlockSpinner, Label, Row, Total, VisibilityIcon } from "./Legend.styles";

interface IProps {
  title: string;
  item: TreeItem<LegendItemType | null>;
  context: TreeItemRenderContext<never>;
}

const ItemTitle: React.FC<IProps> = ({ context, item, title }: IProps) => {
  const { config, isEntityVisible, onEntityVisibilityToggle, showTotals, systemOfMeasurement, totals } = useContext(LegendContext);
  const { data } = item;
  const [total, setTotal] = useState<string | number>("-");

  /**
   * Update the rows total if either the system of measurement changes or the rows
   * totals are updated.
   */
  useEffect(() => {
    if (data && totals) {
      const { Type, ID } = data;
      if (Type === "LAYER") {
        setTotal(convertAndFormatLegendCount(totals[data.Key], systemOfMeasurement));
      } else if (Type === "SUBLAYER") {
        setTotal(convertAndFormatLegendCount(totals[data.ID], systemOfMeasurement));
      } else if (Type === "CommentConfig") {
        setTotal(convertAndFormatLegendCount(totals[ID], systemOfMeasurement));
      }
    }
  }, [data, totals, systemOfMeasurement]);

  /**
   * Current visibility status of the item based on inheritance from parents.
   */
  const isVisible = useMemo(() => (data ? isEntityVisible(data?.ID) : false), [data, isEntityVisible]);

  if (!data?.Type) return null;

  const isLoadingTotal = (): boolean => {
    if (data && totals) {
      const { Type, ID } = data;
      return (Type === "LAYER" && !totals[data.Key]) || (Type === "SUBLAYER" && !totals[ID]);
    }
    return true;
  };

  const renderTotal = () => {
    if (showTotals && ["LAYER", "SUBLAYER", "CommentConfig"].includes(data.Type)) {
      if (isLoadingTotal()) {
        return <BlockSpinner />;
      } else {
        return <Total data-testid="row-total">{`(${total})`}</Total>;
      }
    }
    return null;
  };

  /**
   * Toggles an individual layers visibility setting & prevents row selection propogation
   */
  const handleOnVisibilityClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onEntityVisibilityToggle?.(data.ID);
  };

  return (
    <Row isVisible={isVisible} className={`rct-tree-item-title-${data.Type}`}>
      {data.Type !== "GROUP" && data.Type !== "MapLayerConfig" && (
        <InferredIcon entity={data as LayerConfig | SublayerConfig | LayerStyle} config={config} />
      )}
      <Label entityType={data.Type} isVisible={isVisible}>
        {title}
      </Label>
      {renderTotal()}
      {onEntityVisibilityToggle !== undefined && (
        <VisibilityIcon isVisible={isVisible} onClick={handleOnVisibilityClick} component="a">
          {isVisible ? <Visibility data-testid="visible-icon" /> : <VisibilityOff data-testid="invisible-icon" />}
        </VisibilityIcon>
      )}
    </Row>
  );
};

export default ItemTitle;
