import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Box } from "@mui/material";

import { selectAllAccounts, selectCurrentAccount, selectUserId } from "fond/api";
import { ACCOUNT_CONTEXT } from "fond/constants";
import { useAccountSort } from "fond/hooks/useAccountSort";
import { AccountSelectionButton } from "fond/topBar/accountMenuItems.styles";
import { Account } from "fond/types";
import { getItem, setItem } from "fond/utils/localStorage";
import { Avatar } from "fond/widgets";

const ROW_HEIGHT = 50;
const MAX_ROW = 10;
const MAX_WIDTH = 300;

interface IAccountRowProps extends ListChildComponentProps<Account[]> {
  onClick: () => void;
  selectedAccountId: string;
  userId: string | undefined;
}

const accountRow = ({ data: accounts, index, style, onClick, selectedAccountId, userId }: IAccountRowProps) => {
  const account = accounts[index];

  const onClickRow = () => {
    const existingAccountContext = getItem(ACCOUNT_CONTEXT);
    if (userId) {
      setItem(ACCOUNT_CONTEXT, existingAccountContext ? { ...existingAccountContext, [userId]: account.ID } : { [userId]: account.ID });
    }
    onClick();
  };

  return (
    <AccountSelectionButton
      style={style}
      key={account.ID}
      onClick={onClickRow}
      selected={account.ID === selectedAccountId}
      data-testid="account-items"
    >
      <Avatar name={account.Name} variant="rounded" sx={{ width: 32, height: 32 }} data-testid="account-avatar" />
      {account.Name}
    </AccountSelectionButton>
  );
};

interface IProps {
  onAccountSwitch: () => void;
}

const AccountMenuItems: React.FC<IProps> = ({ onAccountSwitch }) => {
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const selectedAccount = useSelector(selectCurrentAccount);

  const accounts = useSelector(selectAllAccounts);
  const { sortedAccounts } = useAccountSort(accounts);

  const listHeight = (sortedAccounts.length >= MAX_ROW ? MAX_ROW : sortedAccounts.length) * ROW_HEIGHT;

  return (
    <Box width={MAX_WIDTH} data-testid="account-list">
      {accounts?.length > 0 && selectedAccount && (
        <>
          <FixedSizeList<Account[]>
            itemData={sortedAccounts}
            itemSize={ROW_HEIGHT}
            height={listHeight}
            width={MAX_WIDTH}
            overscanCount={20}
            itemCount={sortedAccounts.length}
            className="customScrollbars"
          >
            {(fixedProps) =>
              accountRow({
                ...fixedProps,
                onClick: onAccountSwitch,
                selectedAccountId: selectedAccount.ID,
                userId: userId,
              })
            }
          </FixedSizeList>
          {/* <Divider /> */}
        </>
      )}
      {/* <AddAccountButton data-testid="add-account" onClick={() => navigate("/createaccount")}>
        <AddIcon color="action" />
        Add account
      </AddAccountButton> */}
    </Box>
  );
};

export default AccountMenuItems;
