/**
 * Converts stripe amount to human readable format
 * @param stripeAmount amount in the smallest units, as we only accept USD at this stage, it will be amount in cents.
 * @returns {string} formatted currency string. e.g $50.05
 */
export function getCurrencyFromStripe(stripeAmount: number): string {
  return (stripeAmount / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
}

/**
 * Format a given value as a currency string
 *
 * @param value - The string or number to format
 * @param options - formatting options
 * @param options.currency - currency code to use, defaults to USD
 * @param options.forceDecimals - whether to force 2 decimal places for whole numbers
 * @param options.notation - formatting to be displayed for the number (e.g. standard, and compact)
 * @returns
 */
export function formatCurrency(
  value: number | string | null | undefined,
  options?: {
    currency?: string;
    forceDecimals?: boolean;
    notation?: Intl.NumberFormatOptions["notation"];
    fallback?: string;
  }
): string {
  if (value === null || value === undefined || Number.isNaN(value)) return options?.fallback !== undefined ? options.fallback : "-";
  const { currency = "USD", forceDecimals = false, notation = "standard" } = options || {};
  let val = value;
  if (typeof val === "string") {
    val = Number(value);
    if (Number.isNaN(val)) {
      return String(value);
    }
  }
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    notation: notation,
    minimumFractionDigits: Number.isInteger(val) && !forceDecimals ? 0 : 2, // force 2 decimal places for floats only
  }).format(val);
}
