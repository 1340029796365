import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import { makeFondVectorTileURL, makeVersionVectorTileURL, selectDraftData, selectVersionConfig, useGetVersionQuery } from "fond/api";
import { useHighlightedFeatures } from "fond/hooks/useHighlightedFeatures";
import { commentLayerConfigs, LayerIds } from "fond/layers";
import SourceAndLayers from "fond/map/SourceAndLayers";
import VectorLayer from "fond/map/VectorLayer";
import { getCurrentProject, getCurrentProjectData } from "fond/project";
import { getAll } from "fond/redux/comments";
import { EditMode, Store } from "fond/types";
import { LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { projectUsesVectorTiles } from "fond/utils";
import { isVisible } from "fond/utils/configurations";

import * as turf from "../turf";

interface IProps {
  editMode: EditMode;
  layerConfigs?: Array<LayerConfig | SublayerConfig>;
  styles?: LayerStyle[];
  layerView: {
    [key: string]: boolean;
  };
}

const MapContent: React.FC<IProps> = ({ editMode, layerConfigs = [], layerView, styles = [] }: IProps) => {
  const versionId = useSelector((state: Store) => state.project.versionId);
  const usesVectorTiles = useSelector((state: Store) => projectUsesVectorTiles(getCurrentProject(state.project)));
  const polygonState = useSelector((state: Store) => state.project.polygon);
  const data = useSelector((state: Store): any => getCurrentProjectData(state.project));
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const { editId, filters } = useSelector((state: Store) => state.comments);
  const comments = useSelector((state: Store) => getAll(state));
  const [commentFeatures, setCommentFeatures] = useState<any>();
  const isPolygonDrawActive = polygonState != null && polygonState.isOpen && polygonState.polygonState !== "modeSelect";
  const configData = useSelector((state: Store) => (editMode === "styles" ? selectDraftData(state) : selectVersionConfig(state, versionId)));
  const checkVisibility = (id: string, view: { [layerId: string]: boolean }) => isVisible(configData, { id: id, layerView: view });

  useHighlightedFeatures({ editMode, styles, layerConfigs });

  /**
   * If comments change we need to determine the new features to load
   * within the SourceAndLayer
   */
  useEffect(() => {
    // Only show comment features that are not currently being edited & have the same
    // Resolution State that is currently being viewed

    setCommentFeatures(
      comments
        .filter(
          (comment) =>
            comment.ID !== editId &&
            (filters.State.length === 0 || filters.State.indexOf(comment.State) > -1) &&
            (filters.Importance.length === 0 || filters.Importance.indexOf(comment.Importance) > -1) &&
            (filters.Version.length === 0 || filters.Version.indexOf(comment.Version) > -1)
        )
        .map((comment) => comment.Features)
        .flat()
    );
  }, [comments, editId, filters]);

  const getSourceAndLayer = () => {
    if (!layerConfigs) return null;

    return [...layerConfigs].reverse().map((item) => {
      if (item.Key === LayerIds.inputDataArea && isPolygonDrawActive) {
        return null;
      }
      if (item.Key === "comments-source") {
        return null;
      }
      let geojson;
      if (isPolygonDrawActive && polygonState.selectedLayers[item.Key] != null) {
        // If we're in polygon select mode, use the current polygon select
        // data for streets / addresses / parcels instead of the current
        // project data.
        geojson = polygonState.selectedData[item.Key];
      } else if (item.Key === LayerIds.inputDataArea) {
        // Input data area doesn't come in with the other layers; it comes from the project response instead.
        if (version?.UploadedArea) {
          geojson = turf.featureCollection([version.UploadedArea]);
        } else {
          geojson = turf.featureCollection([]);
        }
      } else {
        geojson = data.layers[item.Key];
      }

      if (!geojson) return null;

      return (
        <SourceAndLayers
          key={item.ID}
          sourceId={`${item.Key}`}
          source={{
            type: "geojson",
            data: geojson,
            promoteId: "id",
          }}
          layer={item}
          layers={layerConfigs}
          styles={styles || []}
          layerVisibilities={layerView}
          isVisible={checkVisibility}
        />
      );
    });
  };

  if (!layerConfigs || !layerView || !styles) {
    return null;
  }

  const tileURL = makeVersionVectorTileURL(versionId);

  return (
    <Box data-testid="map-content">
      {data && (
        <>
          {usesVectorTiles ? (
            <SourceAndLayers
              key="project-layers"
              sourceId="project-layers"
              source={{
                type: "vector",
                tiles: [tileURL],
                promoteId: "id",
                // The backend only stores tiles up to a maximum zoom level of 18, so avoid making
                // requests beyond that zoom level.
                maxzoom: 18,
              }}
              layers={layerConfigs}
              styles={styles}
              layerVisibilities={layerView}
              isVisible={checkVisibility}
            />
          ) : (
            <>{getSourceAndLayer()}</>
          )}
        </>
      )}

      {commentFeatures && editMode !== "styles" && (
        <SourceAndLayers
          sourceId="comments-source"
          source={{
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: commentFeatures,
            },
            promoteId: "commentID",
          }}
          styles={styles}
          layers={Object.values(commentLayerConfigs.entities) as LayerConfig[]}
          layerVisibilities={layerView}
          isVisible={checkVisibility}
        />
      )}

      {editMode === "polygonSelect" && (
        <>
          {/* https://biarri-wiki.atlassian.net/wiki/spaces/FIBRE/pages/1670184965/Unavailability+polygons */}
          <VectorLayer
            key="unavailableArea"
            id="notavail"
            type="fill"
            source={{
              type: "vector",
              url: "mapbox://biarrinetworks.31hgz5of",
            }}
            sourceLayer="unavailability_v1-0j74dh"
            paint={{
              "fill-color": "black",
              "fill-opacity": 0.65,
            }}
          />
          {/*
            Vector tiles can contain multiple layers.
            The vector tile layer is extracted here using the 'sourceLayer' param.
            We currently only use one layer per tile and that layer is called ‘default’.
          */}
          <VectorLayer
            key="parcelTiles"
            id="parcel-tiles"
            type="fill"
            source={{
              type: "vector",
              tiles: [makeFondVectorTileURL("parcels")],
            }}
            sourceLayer="default"
            paint={{
              "fill-opacity": 0.08,
              "fill-color": "purple",
              "fill-outline-color": "gray",
            }}
          />
          <VectorLayer
            key="addressTiles"
            id="address-tiles"
            type="circle"
            source={{
              type: "vector",
              tiles: [makeFondVectorTileURL("addresses")],
            }}
            sourceLayer="default"
            paint={{
              "circle-opacity": 0.5,
              "circle-radius": 1.5,
              "circle-color": "#000000",
            }}
          />
          <VectorLayer
            key="streetTiles"
            id="street-tiles"
            type="line"
            source={{
              type: "vector",
              tiles: [makeFondVectorTileURL("streets")],
            }}
            sourceLayer="default"
            paint={{
              "line-opacity": 0.5,
              "line-color": "#ff9933",
              "line-width": 0.6,
            }}
          />
        </>
      )}
    </Box>
  );
};

export default MapContent;
