import update from "immutability-helper";

import { ADD_IMPORT, COMPLETE_IMPORT, importAction, REMOVE_IMPORT, START_IMPORT, UPDATE_IMPORT } from "fond/redux/imports";
import { ImportsState, ImportStatus } from "fond/types";
import { reduceReducers } from "fond/utils";

// eslint-disable-next-line import/prefer-default-export
export const importsReducer = reduceReducers((state: ImportsState = {}, action: importAction): ImportsState => {
  switch (action.type) {
    case ADD_IMPORT: {
      const layerImportState = {
        layerId: action.layerId,
        displayName: action.displayName,
        files: action.files,
        started: action.started,
        completed: null,
        error: null,
        status: action.status,
        progress: action.progress,
      };
      if (state[action.versionId] != null) {
        return update(state, {
          [action.versionId]: {
            $merge: {
              [action.layerKey]: layerImportState,
            },
          },
        });
      } else {
        return update(state, {
          $merge: {
            [action.versionId]: {
              [action.layerKey]: layerImportState,
            },
          },
        });
      }
    }
    case START_IMPORT:
      return update(state, {
        [action.versionId]: { [action.layerKey]: { $merge: { started: action.started, status: ImportStatus.PENDING_UPLOAD, progress: 5 } } },
      });
    case UPDATE_IMPORT:
      return update(state, {
        [action.versionId]: {
          [action.layerKey]: {
            $merge: { layerId: action.layerId, status: action.status, progress: action.progress, error: action.error, completed: action.completed },
          },
        },
      });
    case COMPLETE_IMPORT:
      return update(state, {
        [action.versionId]: { [action.layerKey]: { $merge: { completed: action.completed, status: ImportStatus.COMPLETE, progress: 100 } } },
      });
    case REMOVE_IMPORT: {
      if (action.layerKey === undefined) {
        return update(state, { $unset: [action.versionId] });
      } else if (action.layerKey != null) {
        return update(state, { [action.versionId]: { $unset: [action.layerKey] } });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
});
