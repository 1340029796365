import React, { useContext, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import { Undo } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, Divider, Paper, Typography } from "@mui/material";
import { featureCollection } from "@turf/helpers";
import { Feature } from "geojson";

import { LoadingButton } from "ui";

import { useGetLayerFeaturesMutation } from "fond/api";
import DrawHandler from "fond/draw/DrawHandler";
import { MapContext } from "fond/map/MapProvider";
import { pointDrawingConfig } from "fond/map/Toolbar";
import { EditMode, setEditMode } from "fond/project/redux";
import { addImport } from "fond/redux/imports";
import { DesignLayerIds, ImportStatus } from "fond/types";
import { isAnyPoint, isValuePoint } from "fond/types/geojson";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { LinearProgressWithLabel, useStackedNavigationContext } from "fond/widgets";
import StackedNavigationHeader from "fond/widgets/StackedNavigation/StackedNavigationHeader";

import { useGetDesignLayer } from "./useGetDesignLayers";

const ConnectionsDrawPanel: React.FC = () => {
  const dispatch = useAppDispatch();
  const versionId = useAppSelector((state) => state.project.versionId);
  const { goBack, clear } = useStackedNavigationContext();
  const { map, drawControl, setDrawMode } = useContext(MapContext);
  const [isSaving, setIsSaving] = useState(false);
  const [getLayerFeatures] = useGetLayerFeaturesMutation();
  const connectionsLayer = useGetDesignLayer(versionId, DesignLayerIds.INPUT_LAYER_CONNECTION_POINTS);
  const [initialFeatures, setInitialFeatures] = useState<Feature[] | null>(null);
  const prevImportStatus = usePrevious(connectionsLayer.status);

  useEffect(() => {
    const getBoundaryFeatures = async () => {
      if (connectionsLayer.layer?.ID) {
        // TODO - would be better to have a real route & not use the AgGrid features route here
        getLayerFeatures({
          versionId: versionId,
          startRow: undefined,
          endRow: undefined,
          filterModel: {},
          rowGroupCols: [],
          valueCols: [],
          pivotCols: [],
          pivotMode: false,
          layerIDs: [connectionsLayer.layer.ID],
          sublayerIDs: [],
          search: "",
          sortModel: [],
          groupKeys: [],
        })
          .unwrap()
          .then((response) => {
            setInitialFeatures(response.rows as Feature[]);
          });
      }
    };
    getBoundaryFeatures();
  }, [connectionsLayer, getLayerFeatures, versionId]);

  useEffect(() => {
    // When the import status completes navigate back to root
    if (prevImportStatus === ImportStatus.BUILDING_TILES && connectionsLayer.status === ImportStatus.COMPLETE) {
      clear();
    }
  }, [clear, prevImportStatus, connectionsLayer.status]);

  useEffect(() => {
    // We enter edit mode so that the map turns off non-drawing interactions
    dispatch(setEditMode(EditMode.edit));
  }, [map, drawControl, dispatch]);

  const handleBack = () => {
    goBack();
  };

  const onReset = () => {
    drawControl.current.deleteAll();
    drawControl.current.set(featureCollection(initialFeatures || []));
  };

  /**
   * Handles importing the currently draw features and updating the multiProject
   * with the new Boundary.
   */
  const handleOnImport = () => {
    setIsSaving(true);
    setDrawMode("static");
    dispatch(setEditMode(EditMode.none));

    const features = drawControl.current
      .getAll()
      .features.filter(isAnyPoint)
      // MapboxDraw returns an invalid point with null geometry so we need to filter that out
      .filter(isValuePoint);

    const file = new File([JSON.stringify(featureCollection(features))], "geojson.json", {
      type: "text/plain",
    });

    dispatch(
      addImport({
        versionId: versionId,
        layerKey: DesignLayerIds.INPUT_LAYER_CONNECTION_POINTS,
        displayName: "Connection Points",
        files: [file],
      })
    );
  };

  return (
    <>
      <DrawHandler
        startingMode="draw_point"
        initialFeatures={initialFeatures}
        source="project-layers"
        sourceLayer={DesignLayerIds.INPUT_LAYER_CONNECTION_POINTS}
        config={pointDrawingConfig}
      />

      <StackedNavigationHeader onBackButtonClick={handleBack} title="Draw on map" />
      <Box data-testid="draw-panel" padding={1}>
        <Alert severity="info" data-testid="layer-alert">
          <AlertTitle sx={{ mb: 0 }}>Draw connection points</AlertTitle>
        </Alert>
        {isSaving ? (
          <Paper variant="outlined" sx={{ py: 2, px: 2.75, mt: 2 }}>
            <LinearProgressWithLabel color="primary" variant="indeterminate" title="Importing connection points" subtext="Processing..." />
          </Paper>
        ) : (
          <>
            <Divider sx={{ mt: 3 }} />
            <Button fullWidth sx={{ my: 0.5, justifyContent: "left" }} startIcon={<Undo sx={{ height: 20 }} />} onClick={onReset}>
              <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
                Reset
              </Typography>
            </Button>
            <Divider />
            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
              <Button color="primary" size="small" onClick={handleBack}>
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                size="small"
                onClick={handleOnImport}
                sx={{ ml: 1, px: 2 }}
                data-testid="finish-button"
                loading={isSaving}
              >
                Finish
              </LoadingButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ConnectionsDrawPanel;
