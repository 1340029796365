import { ConfigurationUpsert, GroupConfig } from "fond/types";
import { FilterConfiguration, LayerConfig, LayerStyle, SublayerConfig } from "fond/types/ProjectLayerConfig";
import { pickIDs } from "fond/utils";

interface FamilialLayerConfig {
  config: LayerConfig;
  descendants: (LayerStyle | SublayerConfig)[];
}

const fccMaxDownloadBuckets: { id: string; label: string; color: string; filter: FilterConfiguration }[] = [
  {
    id: "fcc-max-download-0-25",
    label: "0 - 25",
    color: "#d53e4f",
    filter: {
      Mapbox: ["all", [">=", ["get", "max_download"], 0], ["<", ["get", "max_download"], 25]],
      Type: "group",
    },
  },
  {
    id: "fcc-max-download-25-100",
    label: "25 - 100",
    color: "#f96a00",
    filter: {
      Mapbox: ["all", [">=", ["get", "max_download"], 25], ["<", ["get", "max_download"], 100]],
      Type: "group",
    },
  },
  {
    id: "fcc-max-download-100-500",
    label: "100 - 500",
    color: "#f5c802",
    filter: {
      Mapbox: ["all", [">=", ["get", "max_download"], 100], ["<", ["get", "max_download"], 500]],
      Type: "group",
    },
  },
  {
    id: "fcc-max-download-500-1000",
    label: "500 - 1000",
    color: "#c9e816",
    filter: {
      Mapbox: ["all", [">=", ["get", "max_download"], 500], ["<", ["get", "max_download"], 1000]],
      Type: "group",
    },
  },
  {
    id: "fcc-max-download-1000+",
    label: "1000+",
    color: "#4dd53e",
    filter: {
      Mapbox: [">=", ["get", "max_download"], 1000],
      Type: "expression",
    },
  },
];
const fccGroupId = "multiProjectFccGroup";
const fccFiberFootprintLayerId = "fcc-fiber-footprint";
const fccMaxDownloadLayerId = "fcc-max-download";

const technologies = [
  { technology: "Fiber", color: "#80b1d3" },
  { technology: "Cable", color: "#bebada" },
  { technology: "Power", color: "#b3de69" },
  { technology: "Satellite", color: "#fdb462" },
  { technology: "Wireless", color: "#fb8072" },
  { technology: "DSL", color: "#8dd3c7" },
  { technology: "Copper", color: "#ffffb3" },
];

/**
 * Create the FCC overlay configuration.
 */
export const fccOverlayConfiguration = (): ConfigurationUpsert => {
  const { config: fiberFootprintLayerConfig, descendants: fiberFootprintDescendants } = createFiberFootprintLayer();
  const { config: maxDownloadLayerConfig, descendants: maxDownloadDescendants } = createMaxDownloadLayer();
  const { config: attLayerConfig, descendants: attDescendants } = createProviderLayer("AT&T Coverage", "att_service_type");
  const { config: tmobLayerConfig, descendants: tmobDescendants } = createProviderLayer("T-Mobile Coverage", "tmob_service_type");
  const { config: verizonLayerConfig, descendants: verizonDescendants } = createProviderLayer("Verizon Coverage", "verizon_service_type");

  // Assemble everything together into one group.
  const layerConfigs = [fiberFootprintLayerConfig, maxDownloadLayerConfig, attLayerConfig, tmobLayerConfig, verizonLayerConfig];
  const descendants = [...fiberFootprintDescendants, ...maxDownloadDescendants, ...attDescendants, ...tmobDescendants, ...verizonDescendants];
  const fccGroup = groupConfigTemplate(layerConfigs, "Overlays");
  return [...descendants, ...layerConfigs, fccGroup];
};

/**
 * Create the provider (e.g. AT&T, T-Mobile) layer having service type sublayers.
 */
const createProviderLayer = (label: string, providerField: string): FamilialLayerConfig => {
  const layerConfigId = `fcc-${providerField}-service-types`;

  const descendants: (LayerStyle | SublayerConfig)[] = technologies.flatMap(({ technology, color }) => {
    const id = `${layerConfigId}-${technology}`;
    const filter = {
      Mapbox: ["in", technology, ["get", providerField]],
      Type: "expression" as const,
    };
    const styles = maxDownloadStyles(id, color, filter, 0.8);
    const subLayer = sublayerTemplate(id, layerConfigId, technology, filter, [...styles]);
    return [...styles, subLayer];
  });

  const config = layerConfigTemplate(
    layerConfigId,
    label,
    [],
    descendants.filter(({ Type }) => Type === "SUBLAYER").map(({ ID }) => ID)
  );

  return { config, descendants };
};

/**
 * Create the max download speed layer.
 */
const createMaxDownloadLayer = (): FamilialLayerConfig => {
  // Use the buckets to make sublayers for the max download speed layer
  const descendants = fccMaxDownloadBuckets.flatMap((bucket) => {
    const styles = maxDownloadStyles(bucket.id, bucket.color, bucket.filter, 0.3);
    const sublayerConfig = sublayerTemplate(bucket.id, fccMaxDownloadLayerId, bucket.label, bucket.filter, styles);
    return [...styles, sublayerConfig];
  });

  const config = layerConfigTemplate(
    fccMaxDownloadLayerId,
    "Max Download Speed",
    [],
    fccMaxDownloadBuckets.map((bucket) => bucket.id)
  );

  return { config, descendants };
};

const maxDownloadStyles = (layerId: string, color: string, filter?: FilterConfiguration | null, opacity?: number): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: `${layerId}-polygon-fill`,
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      filter: filter?.Mapbox ?? undefined,
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, opacity ?? 0.5],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: opacity ?? 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
];

/**
 * Create the Fiber Footprint layer.
 */
const createFiberFootprintLayer = (): FamilialLayerConfig => {
  const descendants = fiberFootprintStyles(fccFiberFootprintLayerId, "#4dd53e");
  const config = layerConfigTemplate(fccFiberFootprintLayerId, "Fiber Footprint", [...descendants]);

  return { config, descendants };
};

const fiberFootprintStyles = (layerId: string, color: string): LayerStyle[] => [
  {
    ID: `${layerId}-polygon-fill`,
    Name: "fcc-fiber-served-polygon-fill",
    GlobalPosition: 1,
    ConfigurationID: layerId,
    ConfigurationType: "LAYER",
    Position: 0,
    MapboxStyle: {
      type: "fill",
      filter: ["==", ["get", "fiber_served"], "True"],
      paint: {
        "fill-opacity": ["case", ["boolean", ["feature-state", "isEditing"], false], 0, 0.3],
        "fill-color": ["case", ["boolean", ["feature-state", "isSelected"], false], "#FFFF00", color],
      },
    },
    RawStyles: {
      Type: "fill",
      FillOpacity: 0.5,
      FillColor: color,
    },
    Type: "STYLE",
  },
];

/**
 * FCC-specific config templates.
 */

const groupConfigTemplate = (children: LayerConfig[], label: string): GroupConfig => ({
  ID: fccGroupId,
  Type: "GROUP",
  Label: label,
  Key: null,
  Children: pickIDs(children),
  IsVisible: false,
  GlobalPosition: 0,
  Position: 0,
  ParentID: null,
  RootID: null,
});

const layerConfigTemplate = (id: string, label: string, styles: LayerStyle[], children?: string[]): LayerConfig => ({
  ID: id,
  Label: label,
  IsVisible: true,
  Styles: pickIDs(styles),
  Position: 0,
  Type: "LAYER",
  GeometryType: "Polygon",
  GlobalPosition: 0,
  ParentID: fccGroupId,
  Key: "census-tracts",
  MaxZoomLevel: null,
  MinZoomLevel: null,
  Children: children ?? [],
});

const sublayerTemplate = (id: string, layerId: string, label: string, filter: FilterConfiguration | null, styles: LayerStyle[]): SublayerConfig => ({
  Type: "SUBLAYER",
  ParentID: layerId,
  FilterConfiguration: filter,
  Styles: pickIDs(styles),
  ID: id,
  Label: label,
  Key: "census-tracts",
  IsVisible: true,
  Position: 0,
  GeometryType: "Polygon",
});
