/* eslint-disable import/prefer-default-export */
import { CSSProperties } from "react";
import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";

export interface StyleOverrideProp {
  editorRoot?: CSSProperties;
  editorContainer?: CSSProperties;
  editorContent?: CSSProperties;
}

/**
 * Provides a dynamic way to update the Editors CSS properties
 */
export const getStyles = (styles?: StyleOverrideProp) => {
  if (styles) {
    return {
      "& .DraftEditor-root": styles.editorRoot,
      "& .DraftEditor-editorContainer": styles.editorContainer,
      "& .public-DraftEditor-content": styles.editorContent,
    };
  } else {
    return {};
  }
};

export const getEditorState = (value?: RawDraftContentState): EditorState => {
  if (value) {
    return EditorState.createWithContent(convertFromRaw(value));
  } else {
    return EditorState.createEmpty();
  }
};

export const getPlainText = (value: RawDraftContentState): string =>
  EditorState.createWithContent(convertFromRaw(value)).getCurrentContent().getPlainText();
