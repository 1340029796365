import { blue, common, deepPurple } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

/**
 * Biarri Branding documentation {@link https://docs.google.com/presentation/d/1YrJSbViL-ZEf3MB51raDjRvcEx2J3-k2TSRJ6fQpvsc}
 * Note: As we transition to using a fully implemented Material UI Theme structure vars.scss contains duplicate variables (vars will eventually be removed)
 */
const biarri = {
  primary: {
    carbon: "#839DAD",
    darkGrey: "#525252",
    grey: "#9D9D9D",
    lightGrey: "#E0E0E0",
    red: "#E1373B",
    midnight: "#01161F",
  },
  secondary: {
    activeBlue: "#2196f3",
    blue: "#5BB1E2",
    darkBlue: "#1682B0",
    darkCarbon: "#5C7A8C",
    green: "#A0C850",
    grey: "#656565",
    lightGrey: "#707070",
    lightBlue: "#F4F8FA",
    lightCarbon: "#C6DAE3",
    orange: "#FFC800",
    skyBlue: "#E9F4FE",
    stripeBlue: "#0570de",
    yellow: "#FFF000",
    whiteSmoke: "#F5F5F5",
    activeGrey: "rgba(0, 0, 0, 0.56)",
    inactiveGrey: "rgba(0, 0, 0, 0.38)",
    darkGrey: "rgba(0, 0, 0, 0.87)",
    disabled: "rgba(0, 0, 0, 0.04)",
  },
};

/*
 * Typography configuration.
 * Reference: https://mui.com/material-ui/customization/typography/
 */
declare module "@mui/material/styles" {
  interface TypographyVariants {
    h7: React.CSSProperties;
    overline: React.CSSProperties;
    content: React.CSSProperties;
    heading: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    overline?: React.CSSProperties;
    content?: React.CSSProperties;
    heading?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    overline: true;
    content: true;
    heading: true;
  }
}

const typography = {
  button: {
    textTransform: "none" as const, // https://github.com/mui/material-ui/issues/16307
  },
  h1: {
    fontSize: "1.75rem",
    fontWeight: 700,
  },

  h2: {
    fontSize: "1.5rem",
    fontWeight: 500,
  },

  h3: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },

  h4: {
    fontSize: "1.5rem",
    fontWeight: 500,
    textTransform: "capitalize" as const, // https://github.com/mui/material-ui/issues/16307
  },

  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },

  h6: {
    fontSize: "1rem",
    fontWeight: 500,
  },

  h7: {
    fontSize: "0.75rem",
    fontWeight: 400,
  },

  body1: {
    fontSize: 14,
  },

  body3: {
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.87)",
  },

  heading: {
    color: biarri.primary.red,
    fontSize: "0.85rem",
    fontWeight: 500,
  },

  content: {
    color: biarri.primary.darkGrey,
    fontFamily: "Roboto",
    fontSize: 16,
  },

  overline: {
    color: biarri.primary.darkGrey,
    fontFamily: "Roboto",
    fontSize: 14,
    letterSpacing: 1.5,
    textTransform: "uppercase" as const, // https://github.com/mui/material-ui/issues/16307
    fontWeight: 500,
  },
};

/**
 * Biarri Material-UI Theme definition
 */
const theme = createTheme({
  palette: {
    action: {
      active: "rgba(0, 0, 0, 0.4)",
    },
    primary: {
      light: blue[400],
      main: blue[500],
      dark: blue[600],
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#E1373B",
    },
    tertiary: {
      main: deepPurple.A200,
    },
    background: {
      active: blue[50],
      default: "#F9FAFB",
      paper: "#FFFFFF",
      dialog: "#F9F9F9",
      sidebar: {
        primary: biarri.primary.midnight,
        hover: biarri.secondary.darkCarbon,
      },
      topbar: {
        hover: "rgba(255, 255, 255, 0.04)",
      },
      gridRow: {
        active: "#EBF6FF",
      },
    },
    error: {
      main: biarri.primary.red,
    },
    destructive: {
      main: biarri.primary.red,
      contrastText: "#FFFFFF",
    },
    biarri: biarri,
    success: {
      main: "#4CAF50",
    },
    warning: {
      main: "#EF6C00",
    },
  },
  custom: {
    shadows: {
      default: "0px 1px 4px rgba(0, 0, 0, 0.3)",
      inset: "inset 0px 1px 2px rgba(0, 0, 0, 0.3)",
    },
    borderColor: {
      divider: "rgba(0, 0, 0, 0.12)",
      gridRow: {
        current: "rgba(32, 150, 243, 0.3)",
      },
    },
  },
  typography: typography,
  /**
   * Global style for all Mui Components
   */
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 13,
          fontWeight: 500,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.25rem",
          fontWeight: 500,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: biarri.primary.midnight,
          fontWeight: 500,
          fontSize: "0.875rem",
          minHeight: "0.875rem",
        },
        asterisk: {
          color: biarri.secondary.darkBlue,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          "&:hover": {
            color: blue[600],
          },
        },
      },
      defaultProps: {
        underline: "none",
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 14,
          "&.Mui-selected, &.Mui-selected:hover": {
            borderRadius: 4,
            backgroundColor: "rgba(0,0,0,0.08)",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 14,
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: biarri.secondary.darkBlue,
          },
          "&.Mui-disabled svg": {
            color: "rgba(0, 0, 0, 0.25)",
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        },
        inputAdornedStart: {
          paddingLeft: 16,
        },
        adornedStart: {
          paddingLeft: 8,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: biarri.secondary.lightBlue,
          "&:hover, &.Mui-focused": {
            backgroundColor: "#E3EDF2",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          },
        },
        inputSizeSmall: {
          paddingTop: "15px",
          paddingBottom: "15px",
        },
        underline: {
          "&:before": {
            borderColor: `${biarri.secondary.lightCarbon} !important`,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          "&:before": {
            border: "none !important",
          },
          "&:focus": {
            backgroundColor: "none",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 36,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        inset: {
          paddingLeft: 52,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: "rgba(0,0,0,0.08)",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          height: 52,
          backgroundColor: "#fbfbfb",
        },
        footer: {
          backgroundColor: "#fbfbfb",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.datagrid-rowgroup": {
            backgroundColor: "#F7F7F7",
            "&> td": {
              fontWeight: 500,
              padding: "8px 16px",
            },
          },
          "&.Mui-selected": {
            backgroundColor: blue[50],
            "&$hover:hover": {
              backgroundColor: blue[50],
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${biarri.primary.lightGrey}`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "rgba(1, 22, 31, 0.90)",
        },
        arrow: {
          color: "rgba(1, 22, 31, 0.90)",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        ".mapboxgl-popup": {
          zIndex: 2,
        },
        ".mapboxgl-popup-content": {
          boxShadow: "0px 1px 4px rgb(0 0 0 / 30%)",
        },
        ".mapboxgl-popup-dragged .mapboxgl-popup-tip": {
          display: "none",
        },
        // MuiDataGrid is still in the Lab and therefore needs to be overrride using
        ".MuiDataGrid-root": {
          border: "none !important",
        },
        ".MuiDataGrid-columnsContainer": {
          backgroundColor: "#fbfbfb",
        },
        ".MuiDataGrid-colCell[role=columnheader]": {
          minHeight: "56px",
        },
        ".MuiDataGrid-columnSeparator": {
          display: "none !important",
        },
        ".customScrollbars::-webkit-scrollbar, .public-DraftEditor-content::-webkit-scrollbar, .flexlayout__tab::-webkit-scrollbar": {
          width: 17,
        },
        ".customScrollbars::-webkit-scrollbar-track, .public-DraftEditor-content::-webkit-scrollbar-track, .flexlayout__tab::-webkit-scrollbar-track":
          {
            background: "#ffffff",
            borderRadius: 4,
          },
        ".customScrollbars.greyBackground::-webkit-scrollbar-track": {
          background: "#f9fafb",
        },
        ".customScrollbars::-webkit-scrollbar-thumb, .public-DraftEditor-content::-webkit-scrollbar-thumb, .flexlayout__tab::-webkit-scrollbar-thumb":
          {
            backgroundColor: "#aeaeae",
            borderRadius: 20,
            border: "5px solid #ffffff",
          },
        ".customScrollbars.greyBackground::-webkit-scrollbar-thumb": {
          border: "5px solid #f9fafb",
        },
        ".darkScrollbars::-webkit-scrollbar, .public-DraftEditor-content::-webkit-scrollbar, .flexlayout__tab::-webkit-scrollbar": {
          width: 14,
        },
        ".darkScrollbars::-webkit-scrollbar-track, .public-DraftEditor-content::-webkit-scrollbar-track, .flexlayout__tab::-webkit-scrollbar-track": {
          background: "#F9F9F9",
          borderRadius: 4,
        },
        ".darkScrollbars::-webkit-scrollbar-thumb, .public-DraftEditor-content::-webkit-scrollbar-thumb, .flexlayout__tab::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          borderRadius: 20,
          border: "4px solid #F9F9F9",
        },
        ".ag-body-viewport::-webkit-scrollbar, .ag-body-vertical-scroll-viewport::-webkit-scrollbar": {
          width: 17,
        },
        ".ag-body-viewport::-webkit-scrollbar-track, .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track": {
          background: "#ffffff",
        },
        ".ag-body-viewport::-webkit-scrollbar-thumb, .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb": {
          backgroundColor: "#aeaeae",
          borderRadius: 20,
          border: "5px solid #ffffff",
        },
        ".ag-body-horizontal-scroll-viewport::-webkit-scrollbar": {
          width: 17,
        },
        ".ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track": {
          background: "#ffffff",
        },
        ".ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb": {
          backgroundColor: "#aeaeae",
          borderRadius: 20,
          border: "5px solid #ffffff",
        },
        ".ReactCollapse--collapse": {
          transition: "height 250ms",
        },
        ".public-DraftEditor-content[contenteditable=true]": {
          fontSize: 12,
          padding: "8px 12px",
          cursor: "text",
          border: "1px solid #c4c4c4",
          borderRadius: 4,
          "&:focus": {
            borderColor: blue[500],
          },
        },
        ".public-DraftEditorPlaceholder-inner": {
          padding: "8px 13px",
          border: "1px solid transparent",
        },
        ".public-DraftEditorPlaceholder-root": {
          fontSize: 12,
        },
        ".flexlayout__tab_button_leading svg": {
          width: 16,
          height: 16,
        },
        ".flexlayout__drag_rect": {
          backgroundColor: biarri.primary.red,
          color: common.white,
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)",
          border: "none",
          fontWeight: 500,
          opacity: 1,
          padding: "8px",
        },
        ".flexlayout__edge_rect": {
          backgroundColor: biarri.primary.red,
        },
        // With the upgrade to mapbox-gl 2.8.2 the <canvas> was being
        // set with fractional a height value (e.g. height=1200.25px)
        // due to the flexlayout-react widget which results in a blurry canvas,
        // therefore to avoid this we correct the 0.25 height
        ".flexlayout__tab #mapContainer": {
          bottom: 0.25,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        anchorOriginTopCenter: {
          top: "12px !important",
        },
      },
    },
    /**
     * Default props for all Mui Components
     */
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingBottom: 4,
        },
      },
    },
    MuiSlider: {
      defaultProps: {
        track: false,
        size: "small",
      },
      styleOverrides: {
        thumb: {
          backgroundColor: blue[500],
          border: `3px solid ${common.white}`,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          "&:hover": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          },
          "&.Mui-focusVisible": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          },
        },
        track: {
          opacity: 1,
          background: `linear-gradient(270deg, ${blue[500]} 1.83%, #E0E0E0 100%)`,
        },
        rail: {
          opacity: 0.3,
          background: blue[500],
        },
        markLabel: {
          fontSize: 12,
        },
        sizeSmall: {
          "& .MuiSlider-thumb": {
            height: 14,
            width: 14,
          },
          "& .MuiSlider-rail": {
            height: 3,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 50,
          height: 30,
          padding: 10,
          "& .MuiSwitch-thumb": {
            height: 20,
            width: 20,
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(20px)",
            ".MuiSwitch-thumb": {
              background: blue[500],
            },
            "+ .MuiSwitch-track": {
              opacity: 1,
              background: `linear-gradient(270deg, ${blue[500]} 15%, ${biarri.primary.lightGrey} 100%)`,
            },
            ":hover": {
              backgroundColor: "transparent",
            },
          },
          "& .MuiSwitch-switchBase.Mui-disabled": {
            ".MuiSwitch-thumb": {
              borderColor: "#F5F5F5",
            },
            "+ .MuiSwitch-track": {
              opacity: 0.4,
            },
          },
          "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
            ".MuiSwitch-thumb": {
              background: blue[200],
            },
          },
        },
        switchBase: {
          height: 30,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        thumb: {
          background: biarri.primary.lightGrey,
          border: `3px solid ${common.white}`,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        },
        track: {
          opacity: 1,
          height: 12,
          background: `linear-gradient(90deg, #C0C0C0 1.83%, ${biarri.primary.lightGrey} 100%)`,
        },
      },
    },
  },
});

export default theme;
