import { ProjectsView } from "fond/types";
import { reduceReducers } from "fond/utils";

interface UpdateFilter {
  type: "UpdateFilter";
  filters: ProjectsView;
}

interface UpdateSharedAccount {
  type: "UpdateSharedAccount";
  account?: string;
}

interface ClearSharedAccount {
  type: "ClearSharedAccount";
}

type Action = UpdateFilter | UpdateSharedAccount | ClearSharedAccount;

export function updateFilter(filter: ProjectsView): Action {
  return { type: "UpdateFilter", filters: filter };
}

export function updateSharedAccount(account?: string): Action {
  return { type: "UpdateSharedAccount", account: account };
}

export const clearSharedAccount: Action = {
  type: "ClearSharedAccount",
};

const initialState: Pick<UpdateFilter, "filters"> & Pick<UpdateSharedAccount, "account"> = {
  filters: "workspace",
  account: undefined,
};

export const reducer = reduceReducers(function reducer(state = initialState, action: Action) {
  switch (action.type) {
    case "UpdateFilter":
      return {
        ...state,
        filters: action.filters,
      };
    case "UpdateSharedAccount":
      return {
        ...state,
        filters: "shared",
        account: action.account,
      };
    case "ClearSharedAccount":
      return {
        ...state,
        account: undefined,
      };
    default:
      return state;
  }
});
