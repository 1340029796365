/* eslint-disable no-param-reassign */
import { TreeItemIndex } from "react-complex-tree";
import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ProjectAction } from "fond/project/redux";
import { StylesState } from "fond/types";

export type DropContainerType = "MapLayerConfig" | "GROUP" | "LAYER" | "SUBLAYER";

export type DropResult = {
  // data.ID of the item being dragged.
  id: string | TreeItemIndex;
  source: {
    // the current index of the drag item within the source.
    index: number;
    type: DropContainerType;
  };
  destination: {
    // data.ID of the destination entity the drag item is moving to.
    id: string | TreeItemIndex;
    // the new index within the destination the drag item is being moved to.
    index?: number;
    type: DropContainerType;
  };
};

const initialState = {
  settings: {
    filter: "",
    selectedId: "",
  },
  configId: "",
} as StylesState;

export const stylesSlice = createSlice({
  name: "styles",
  initialState: initialState,
  reducers: {
    setSelectedId: (state, action: PayloadAction<string>) => {
      state.settings.selectedId = action.payload;
    },
    setFilter: (state, action: PayloadAction<string>) => {
      state.settings.filter = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action: AnyAction) => action.type === ProjectAction.CLOSE_PROJECT,
      (state) => {
        // Reset all to initial state
        return initialState;
      }
    );
  },
});

/**
 * Actions
 */
export const { setFilter, setSelectedId, reset } = stylesSlice.actions;
