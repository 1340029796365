/* eslint-disable no-param-reassign */
// Because with the `immer.produce` function, assigning to the param is the
// fundamental pattern we use for mutation, and disabling this rule per line
// would be very noisy.

import produce from "immer";

import { getRowInitialState } from "fond/architecture/bom/template";
import { convertFeetToMeters, convertMetersToFeet, enumerate, feetToMetersDisplay, iterItems, makeUuid, metersToFeetDisplay } from "fond/utils";

import { generateMSTRows, hasRemovedRows } from "./mst";

/**
 * Create the initial state for the BOM tab.
 *
 * The state for which category is selected, and which (if any) row is being edited
 * is stored in architecture/context.js. See the docstring there for more information.
 *
 * @param {object} arch - An architecture object which contains a BOM property.
 * @param {array} categories - An array of categories which contain rule templates.
 */
export function getInitialState({ arch, categories, systemOfMeasurement }) {
  return {
    categories: categories,

    systemOfMeasurement: systemOfMeasurement,

    bom: withUUIDs(arch.BOM),

    // Is this a new (vs. existing) rule? This affects how cancelling behaves.
    isNewRule: false,

    // When we start editing a rule, we keep a backup of it here, so if the
    // user hits cancel we can use this to restore it.
    backupRule: null,

    // Are we confirming the save of an auto MST rule that would remove some
    // rows
    confirmingMSTUpdate: null,

    // Have we hit the Delete button on a row that's in a group of MST rows
    // (which would delete the whole group)
    confirmingMSTDelete: null,

    // Double checking that the user wants to clear all rows
    confirmingDeleteAllRows: null,
  };
}

/**
 * Make sure every row in the bom has a unique ID.  We have to have an ID just
 * for the purposes of the client, even though it's not part of the schema.
 * Since we allow deleting of rules, which makes things mess up if we use the
 * index of the rule in the array as the React key.  There's no need to persist
 * these IDs.
 */
function withUUIDs(bom) {
  if (bom == null) {
    return bom;
  }

  return produce(bom, (draftBom) => {
    for (let group of draftBom.Categories) {
      for (let row of group.Rows) {
        if (row.ID == null) {
          row.ID = makeUuid();
        }
      }
    }
  });
}

/**
 * An alternative to the awkward giant switch statements we sometimes use for
 * reducers.
 *
 * Each method (except `reduce`) corresponds to an action. For BOM actions,
 * each action has the prefix "bom/", though this is just to sanity check that
 * we don't get passed the wrong kinds of actions. So eg. the
 * `selectCategoryIndex` function is called when an action of type
 * "bom/selectCategoryIndex" is dispatched.
 *
 * This also makes calling reducers from other reducers more natural. Eg. in
 * the `saveRule` function we can simply call `return this.contractRow(state)`
 * to call the reducer for the `contractRow` action.
 *
 * We don't export this class, we just export a single `reduce` function which
 * is tied to a singleton instance of the class, so this implementation is
 * hidden from outside modules. The exported reducer behaves no differently
 * from our reducers in other modules.
 */
class Reducers {
  reduce(state, action) {
    if (action == null) {
      return state;
    }

    if (typeof action.type !== "string" || !action.type.startsWith("bom/")) {
      throw new Error(`BOM action reducer expects an action with type starting with "bom/"; got ${action}`);
    }
    const reducer = this[action.type.substr("bom/".length)].bind(this);
    if (reducer == null) {
      throw new Error(`No reducer found for action type ${action.type}`);
    }
    return reducer(state, action);
  }

  addTemplateToBom(state, { template, rowID, selectedCategoryID }) {
    const category = state.categories.find((c) => c.ID === selectedCategoryID);
    const row = {
      ...getRowInitialState(template),

      // See comment for withUUIDs above.
      // We pass it in as an argument so that we can pass the ID back up to the
      // architecture context so we can expand the row immediately.
      ID: rowID,
    };

    return produce(state, (draftState) => {
      let { bom } = draftState;

      if (bom == null) {
        bom = draftState.bom = { Categories: [] };
      }

      let group = bom.Categories.find((g) => g.ID === category.ID);
      if (group == null) {
        group = {
          ID: category.ID,
          Name: category.Name,
          Rows: [],
        };
        bom.Categories.push(group);
      }

      if (state.systemOfMeasurement === "imperial") {
        convertRowToFeet(row, template);
      }

      group.Rows.push(row);

      draftState.isNewRule = true;
    });
  }

  saveRule(state, { ruleIndex, selectedCategoryID }) {
    const rule = getSelectedCategoryRows(state, selectedCategoryID)[ruleIndex];
    if (rule.TemplateID === "mst/auto") {
      if (hasRemovedRows(rule)) {
        return produce(state, (draftState) => {
          draftState.confirmingMSTUpdate = {
            rule: rule,
            ruleIndex: ruleIndex,
            categoryID: selectedCategoryID,
          };
        });
      } else {
        return saveMSTs(state, ruleIndex, rule, selectedCategoryID);
      }
    } else {
      return produce(state, (draftState) => {
        draftState.isNewRule = false;
      });
    }
  }

  confirmMSTUpdate(state) {
    let newState = saveMSTs(state, state.confirmingMSTUpdate.ruleIndex, state.confirmingMSTUpdate.rule, state.confirmingMSTUpdate.categoryID);
    return produce(newState, (draftState) => {
      draftState.confirmingMSTUpdate = null;
      draftState.isNewRule = false;
    });
  }

  cancelMSTUpdate(state) {
    return produce(state, (draftState) => {
      draftState.confirmingMSTUpdate = null;
    });
  }

  confirmMSTDelete(state) {
    const newState = this.deleteRule(state, {
      rule: state.confirmingMSTDelete.rule,
      selectedCategoryID: state.confirmingMSTDelete.categoryID,
      isConfirmed: true,
    });
    return produce(newState, (draftState) => {
      draftState.confirmingMSTDelete = null;
    });
  }

  cancelMSTDelete(state) {
    return produce(state, (draftState) => {
      draftState.confirmingMSTDelete = null;
    });
  }

  deleteAllRows(state) {
    return produce(state, (draftState) => {
      draftState.confirmingDeleteAllRows = true;
    });
  }

  confirmDeleteAllRows(state) {
    return produce(state, (draftState) => {
      draftState.confirmingDeleteAllRows = null;
      draftState.bom = null;
    });
  }

  cancelDeleteAllRows(state) {
    return produce(state, (draftState) => {
      draftState.confirmingDeleteAllRows = null;
    });
  }

  contractRow(state) {
    return produce(state, (draftState) => {
      draftState.isNewRule = false;
    });
  }

  deleteRule(state, { rule, selectedCategoryID, isConfirmed }) {
    return produce(state, (draftState) => {
      if (
        rule.TemplateID === "mst/auto" &&
        // A rule.Rows may be null if Rows have never been generated (ie.
        // we're cancelling a new MST group).
        (rule.Rows || []).length > 1 &&
        !isConfirmed
      ) {
        // If we would be deleting more than one row, and we haven't confirmed
        // yet, display a confirmation instead.
        draftState.confirmingMSTDelete = {
          rule: rule,
          categoryID: selectedCategoryID,
          numRows: rule.Rows.length,
        };
      } else {
        modifySelectedCategoryRows(draftState, selectedCategoryID, (rows) => {
          // TODO-devex-4985-2 check this
          rows.splice(
            rows.findIndex((row) => row.ID === rule.ID),
            1
          );
        });

        // If we deleted the last template, normalise the BOM back to `null`.
        if (!draftState.bom.Categories.some((c) => c.Rows.length > 0)) {
          draftState.bom = null;
        }
      }
    });
  }

  updateRule(state, { ruleIndex, newRule, selectedCategoryID }) {
    return produce(state, (draftState) => {
      modifySelectedCategoryRows(draftState, selectedCategoryID, (rows) => {
        rows[ruleIndex] = newRule;
      });
    });
  }

  updateMSTRow(state, { ruleIndex, mstRowIndex, newRule, selectedCategoryID }) {
    return produce(state, (draftState) => {
      modifySelectedCategoryRows(draftState, selectedCategoryID, (rows) => {
        rows[ruleIndex].Rows[mstRowIndex] = newRule;
      });
    });
  }

  cancelRule(state, { rule, selectedCategoryID }) {
    if (state.isNewRule) {
      let newState = this.deleteRule(state, { rule: rule, selectedCategoryID: selectedCategoryID });
      return produce(newState, (draftState) => {
        draftState.isNewRule = false;
      });
    } else {
      // When we edit a rule, we update the rule in-place (it's easier that
      // way, since the rule may be in the middle of a bunch of other rules).
      // But it means that when we cancel editing a rule, we have to explicitly
      // revert back to how it was before we started editing.
      return produce(state, (draftState) => {
        modifySelectedCategoryRows(draftState, selectedCategoryID, (rows) => {
          const ruleIndex = rows.findIndex((row) => row.ID === rule.ID);
          rows[ruleIndex].Parameters = draftState.backupRule.Parameters;
        });
      });
    }
  }

  editRule(state, { rule }) {
    return produce(state, (draftState) => {
      draftState.backupRule = rule;
      draftState.isNewRule = false;
    });
  }

  moveRuleIndex(state, { categoryID, newRows }) {
    return produce(state, (draftState) => {
      modifySelectedCategoryRows(draftState, categoryID, () => {
        draftState.bom.Categories.find((category) => category.ID === categoryID).Rows = newRows;
      });
    });
  }
}

const reducers = new Reducers();

const reducer = reducers.reduce.bind(reducers);

export { reducer };

function saveMSTs(state, ruleIndex, rule, selectedCategoryID) {
  const newState = reducers.updateRule(state, {
    ruleIndex: ruleIndex,
    newRule: produce(rule, (draftValue) => {
      draftValue.Rows = generateMSTRows(draftValue);
    }),
    selectedCategoryID: selectedCategoryID,
  });
  return reducers.contractRow(newState);
}

export function addTemplateToBom(template, rowID, selectedCategoryID) {
  return { type: "bom/addTemplateToBom", template: template, rowID: rowID, selectedCategoryID: selectedCategoryID };
}

export function saveRule(ruleIndex, selectedCategoryID) {
  return { type: "bom/saveRule", ruleIndex: ruleIndex, selectedCategoryID: selectedCategoryID };
}

export function confirmMSTUpdate(selectedCategoryID) {
  return { type: "bom/confirmMSTUpdate", selectedCategoryID: selectedCategoryID };
}

export function cancelMSTUpdate() {
  return { type: "bom/cancelMSTUpdate" };
}

export function confirmMSTDelete(selectedCategoryID) {
  return { type: "bom/confirmMSTDelete", selectedCategoryID: selectedCategoryID };
}

export function cancelMSTDelete() {
  return { type: "bom/cancelMSTDelete" };
}

export function deleteAllRows() {
  return { type: "bom/deleteAllRows" };
}

export function confirmDeleteAllRows(selectedCategoryID) {
  return { type: "bom/confirmDeleteAllRows" };
}

export function cancelDeleteAllRows() {
  return { type: "bom/cancelDeleteAllRows" };
}

export function deleteRule(rule, selectedCategoryID) {
  return { type: "bom/deleteRule", rule: rule, selectedCategoryID: selectedCategoryID };
}

export function updateRule(ruleIndex, newRule, selectedCategoryID) {
  return { type: "bom/updateRule", ruleIndex: ruleIndex, newRule: newRule, selectedCategoryID: selectedCategoryID };
}

export function updateMSTRow(ruleIndex, mstRowIndex, newRule, selectedCategoryID) {
  return {
    type: "bom/updateMSTRow",
    ruleIndex: ruleIndex,
    mstRowIndex: mstRowIndex,
    newRule: newRule,
    selectedCategoryID: selectedCategoryID,
  };
}

export function cancelRule(rule, selectedCategoryID) {
  return { type: "bom/cancelRule", rule: rule, selectedCategoryID: selectedCategoryID };
}

export function editRule(rule) {
  return { type: "bom/editRule", rule: rule };
}

/**
 * Given a unique rowID (generated by the UI) return the index of the category the contains the row,
 * and the index of the row within that category.
 * Returns -1 for each index if the row isn't found.
 *
 * @param {object} state
 * @param {string} rowID - The ID of the row. These are generated by the UI and unique across the whole bom.
 * @returns {[number, number]} - The category index and the row index in a 2 element array.
 */
export function findRowIndices(state, rowID) {
  if (state.bom == null) {
    return null;
  }

  for (const [categoryIndex, category] of enumerate(state.bom.Categories)) {
    const rowIndex = category.Rows.findIndex((row) => row.ID === rowID);
    if (rowIndex !== -1) {
      return [categoryIndex, rowIndex];
    }
  }

  return [-1, -1];
}

export function moveRuleIndex(state, source, destination) {
  if (source.index !== destination.index) {
    const category = state.bom.Categories.find((cat) => cat.ID === source.droppableId);
    const newRows = [...category.Rows];
    const draggedItem = newRows[source.index];

    newRows.splice(source.index, 1);
    newRows.splice(destination.index, 0, draggedItem);
    return { type: "bom/moveRuleIndex", categoryID: category.ID, newRows: newRows };
  }
}

export function getSelectedCategory(state, selectedCategoryID) {
  return state.categories.find((category) => category.ID === selectedCategoryID);
}

/**
 * This returns all the available templates in the selected category,
 * not just the ones used in the current BOM.
 */
export function getCategoryTemplates(state, categoryID) {
  const category = state.categories.find((c) => c.ID === categoryID);
  return category != null ? category.Templates : [];
}

export function getSelectedCategoryRows(state, selectedCategoryID) {
  if (state.bom == null) {
    return [];
  }
  let category = state.bom.Categories.find((c) => c.ID === selectedCategoryID);
  return category != null ? category.Rows : [];
}

/**
 * Call `func` on the selected category's rows, throwing an error if there is
  no selected category.
  */
export function modifySelectedCategoryRows(state, selectedCategoryID, func) {
  let bomCategory = state.bom.Categories.find((category) => category.ID === selectedCategoryID);
  if (bomCategory == null) {
    throw new Error("Tried to modify selected category rows with no selected category");
  }
  func(bomCategory.Rows);
}

/**
 * Make a lookup mapping template IDs to templates (regardless of which
 * category they are in).  Assumes that template IDs are unique even across
 * categories.
 */
function makeTemplateLookup(categories) {
  const lookup = {};
  for (let category of categories) {
    for (let template of category.Templates) {
      lookup[template.ID] = template;
    }
  }
  return lookup;
}

export function sanitizeBom(bom, categories, systemOfMeasurement) {
  const lookup = makeTemplateLookup(categories);

  return produce(bom, (draftBom) => {
    for (let category of draftBom.Categories) {
      for (let row of category.Rows) {
        const template = lookup[row.TemplateID];

        row.Cost = parseFloat(row.Cost);
        if (systemOfMeasurement === "imperial" && template.IsLength) {
          // Need to use MetersToFeet here. When we convert from feet to meters,
          // the quantity goes down, but the unit cost goes up.
          row.Cost = convertMetersToFeet(row.Cost);
        }
        if (isNaN(row.Cost)) {
          row.Cost = 0;
        }

        for (let [paramId, paramValue] of iterItems(row.Parameters)) {
          const param = template.Parameters.find((p) => p.ID === paramId);
          if (param.Type === "Numeric") {
            row.Parameters[paramId] = parseFloat(paramValue);
            if (isNaN(row.Parameters[paramId])) {
              if (param.Default != null) {
                row.Parameters[paramId] = param.Default;
              } else {
                row.Parameters[paramId] = 0;
              }
            }
            if (systemOfMeasurement === "imperial" && param.IsLength) {
              row.Parameters[paramId] = convertFeetToMeters(row.Parameters[paramId]);
            }
          } else if (param.Type === "Range") {
            row.Parameters[paramId].min = parseFloat(paramValue.min);
            row.Parameters[paramId].max = parseFloat(paramValue.max);
            if (systemOfMeasurement === "imperial" && param.IsLength) {
              row.Parameters[paramId].min = convertFeetToMeters(row.Parameters[paramId].min);
              row.Parameters[paramId].max = convertFeetToMeters(row.Parameters[paramId].max);
            }
          } else if (param.Type === "IntegerRange") {
            row.Parameters[paramId].min = parseInt(paramValue.min);
            row.Parameters[paramId].max = parseInt(paramValue.max);
          } else if (param.Type === "NumericChips" && systemOfMeasurement === "imperial" && param.IsLength) {
            row.Parameters[paramId] = paramValue.map((value) => convertFeetToMeters(value));
          }
        }
        if (row.TemplateID === "mst/auto") {
          for (let mstRow of row.Rows || []) {
            mstRow.Cost = parseFloat(mstRow.Cost);
            if (systemOfMeasurement === "imperial") {
              mstRow.tailLengths = mstRow.tailLengths.map((length) => convertFeetToMeters(length));
            }
            if (isNaN(mstRow.Cost)) {
              mstRow.Cost = 0;
            }
          }
        }
      }
    }
  });
}

// Function is used to convert metric values to feet if the user has selected
// Imperial with the fromArchitecture function
export function convertBomValuesToFeet(bom, categories) {
  const lookup = makeTemplateLookup(categories);

  return produce(bom, (draftBom) => {
    for (let category of draftBom.Categories) {
      for (let row of category.Rows) {
        const template = lookup[row.TemplateID];
        convertRowToFeet(row, template);
      }
    }
  });
}

export function convertRowToFeet(row, template) {
  if (template.IsLength) {
    // Need to use FeetToMeters here. When we convert from meters to feet,
    // the quantity goes up, but the unit cost goes down.
    row.Cost = feetToMetersDisplay(row.Cost);
  }

  for (let [paramId, paramValue] of iterItems(row.Parameters)) {
    const param = template.Parameters.find((p) => p.ID === paramId);
    if (param.IsLength) {
      if (param.Type === "Numeric") {
        row.Parameters[paramId] = metersToFeetDisplay(paramValue);
      } else if (param.Type === "Range") {
        row.Parameters[paramId].min = metersToFeetDisplay(paramValue.min);
        row.Parameters[paramId].max = metersToFeetDisplay(paramValue.max);
      } else if (param.Type === "NumericChips") {
        row.Parameters[paramId] = paramValue.map((value) => metersToFeetDisplay(value));
        if (row.TemplateID === "mst/auto") {
          for (let mstRow of row.Rows || []) {
            mstRow.tailLengths = mstRow.tailLengths.map((length) => metersToFeetDisplay(length));
          }
        }
      }
    }
  }
}

/**
 * This is similar to _.set(validationResults, path, error) but it creates
 * dictionaries instead of arrays for intermediate objects if they don't exist.
 * We want this because we want the results to be sparse. Eg. if there is an
 * error in the third item, we just want {2: <error>}, not [undefined,
 * undefined, <error>].
 */
function addError(validationResults, path, error) {
  // Suppose `path` is [1, 1, 'Parameters'], then `v` will iterate through:
  // - validationResults
  // - validationResults[1]
  // - validationResults[1][1]
  // - validationResults[1][1]['Parameters']
  let v = validationResults;
  for (let [i, p] of enumerate(path)) {
    if (i === path.length - 1) {
      break;
    }
    if (v[p] == null) {
      v[p] = {};
    }
    v = v[p];
  }
  v[path[path.length - 1]] = error;
}

export function validate(bom, categories) {
  const lookup = makeTemplateLookup(categories);

  let validationResults = {};

  // If `num` is not a float then add an error to the validation results at the `path`.
  function checkFloat(path, num) {
    if (isNaN(Number(num))) {
      addError(validationResults, path, {
        state: "error",
        message: "Must be a number",
      });
    }
  }

  // If `num` is not an integer then add an error to the validation results at the `path`.
  function checkInt(path, num) {
    if (!Number.isInteger(Number(num))) {
      addError(validationResults, path, {
        state: "error",
        message: "Must be an integer",
      });
    }
  }

  function checkRange(path, paramValue) {
    // Both min and max are optional. So it only makes sense to check for
    // min > max if both are provided. If either are absent then we're
    // good.
    if (paramValue.min != null && paramValue.max != null && parseFloat(paramValue.min) > parseFloat(paramValue.max)) {
      addError(validationResults, path, {
        state: "error",
        message: "Minimum cannot be larger than maximum",
      });
    }
  }

  function checkID(path, id) {
    // When the architecture panel first loads the RowID is null,
    // however if the user creates a new row or deletes the ID it is an empty string.
    if (id == null || id === "") {
      addError(validationResults, path, {
        state: "error",
        message: "Please enter an ID",
      });
    }
  }

  for (let category of bom.Categories) {
    for (let row of category.Rows) {
      if (row.TemplateID === "mst/auto" && row.Rows !== undefined) {
        for (const [mstRowIndex, mstRow] of enumerate(row.Rows)) {
          const mstRowPath = [category.ID, row.ID, "Rows", mstRowIndex];
          checkID([...mstRowPath, "RowID"], mstRow.RowID);
          checkFloat([...mstRowPath, "Cost"], mstRow.Cost);
        }
      } else {
        const rowPath = [category.ID, row.ID];
        checkID([...rowPath, "RowID"], row.RowID);
        checkFloat([...rowPath, "Cost"], row.Cost);
      }

      const template = lookup[row.TemplateID];
      for (let [paramId, paramValue] of iterItems(row.Parameters)) {
        const param = template.Parameters.find((p) => p.ID === paramId);
        const path = [category.ID, row.ID, "Parameters", paramId];

        if (param.Type === "Numeric") {
          checkFloat(path, paramValue);
        } else if (param.Type === "Range") {
          checkFloat(path, paramValue.min);
          checkFloat(path, paramValue.max);
          checkRange(path, paramValue);
        } else if (param.Type === "IntegerRange") {
          checkInt(path, paramValue.min);
          checkInt(path, paramValue.max);
          checkRange(path, paramValue);
        } else if (
          param.Type === "MultiSelect" &&
          // We only want to apply this validation to specific MultiSelect params.
          (paramId === "colocatedWith" || paramId === "placement" || paramId === "cableSizes")
        ) {
          if (paramValue.length === 0) {
            addError(validationResults, path, {
              state: "error",
              message: "At least one value must be selected",
            });
          }
        } else if (param.Type === "TextInput") {
          if (!paramValue) {
            addError(validationResults, path, {
              state: "error",
              message: "Value must not be empty",
            });
          }
        }

        if (row.TemplateID === "miscellaneous/buried-fiber" && paramId === "length") {
          if (parseFloat(paramValue) === 0) {
            addError(validationResults, path, {
              state: "error",
              message: "Value cannot be 0",
            });
          }
        }
      }
    }
  }

  return validationResults;
}
