import React, { createContext, createRef, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "react-use";
import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, WithStyles, withStyles } from "@mui/styles";
import mapboxgl, { Popup } from "mapbox-gl";

import { MapContext } from "fond/map/MapProvider";
import { setDraggedPopupPosition } from "fond/project/redux";
import { Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";

export const DragContext = createContext<{
  onDragStart: (event: React.DragEvent<HTMLElement>) => void;
  onDrag: (event: React.DragEvent<HTMLElement>) => void;
  onDragEnd: (event: React.DragEvent<HTMLElement>) => void;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
}>(undefined!);

const customStyles = (theme: Theme) => {
  return createStyles({
    hidden: {
      display: "none",
    },
    popup: {
      "&:hover": {
        cursor: "move",
      },
    },
  });
};

interface IProps extends WithStyles<typeof customStyles> {
  /**
   * Classname that will be applied to the popup
   */
  className?: string;
  /**
   * The popup content
   */
  children: React.ReactNode | React.ReactNodeArray;
  /**
   * Flag indicating if the popup should be hidden (but not removed)
   */
  hidden?: boolean;
  /**
   * The Lng Lat position on the map the popup relates to
   */
  lngLat: mapboxgl.LngLatLike;
  /**
   * The title content
   */
  title?: string;
  /**
   * Callback function fired when the popup calls onClose
   */
  onClose(event: any): void;
  /**
   * Additional ReactContent
   */
  rightContent?: React.ReactNode | React.ReactNodeArray;
  /**
   * Show close icon
   */
  showCloseIcon?: boolean;
}

const MapPopup: React.FC<IProps> = ({
  children,
  classes,
  className,
  lngLat,
  onClose,
  hidden = false,
  rightContent = null,
  showCloseIcon = true,
  title = "",
}: IProps) => {
  const contentRef = createRef<HTMLDivElement>();
  const { map } = useContext(MapContext);
  const dispatch = useAppDispatch();

  // Offset between the popup anchor position and the start mouse click of the drag
  const [offset, setOffset] = useState({ lng: 0, lat: 0 });
  const anchorPos = useRef(lngLat);

  const draggedPopupPosition = useSelector((state: Store) => state.project.draggedPopupPosition);
  const isDragged = useRef(false);

  // Preload the transparent drag ghost image to make sure it is loaded before called.
  let dragImg = new Image(0, 0);
  dragImg.src = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

  const [popup] = useState(() => {
    const newPopup = new Popup({
      className: className,
      closeButton: false,
      maxWidth: "none",
      // Popup does not handle it's own close.  We control this ourselves
      // to allow for the popup content to change depending on what the user
      // has clicked on.
      closeOnClick: false,
    }).setLngLat(lngLat);

    if (map) {
      newPopup.addTo(map);
    }

    return newPopup;
  });
  const prevClose = usePrevious(onClose);

  useEffect(() => {
    popup.on("close", onClose);

    return () => {
      // Cleanup popup
      popup.remove();
    };
  }, []);

  useEffect(() => {
    if (hidden) {
      popup.addClassName(classes.hidden);
    } else {
      popup.removeClassName(classes.hidden);
    }
  }, [hidden]);

  // Determine whether to remove the arrow of the popup based on:
  // 1. Whether the current popup is dragged.
  // 2. Whether there is an anchor position saved in the store.
  // Whenever the child context re-rendereds, validate the condition.
  useEffect(() => {
    if (isDragged.current || draggedPopupPosition) {
      popup.addClassName("mapboxgl-popup-dragged");
    } else {
      popup.removeClassName("mapboxgl-popup-dragged");
    }
  }, [isDragged.current, draggedPopupPosition, children]);

  // Rebind the "on" event if the onClose changes
  useEffect(() => {
    popup.off("close", prevClose);
    popup.on("close", onClose);
  }, [onClose]);

  useEffect(() => {
    popup.setDOMContent(contentRef.current as HTMLDivElement);
  }, [contentRef.current]);

  // If the lngLat changes reposition the popup
  useEffect(() => {
    anchorPos.current = lngLat;
    popup.setLngLat(anchorPos.current);
    isDragged.current = false;
  }, [lngLat]);

  /**
   * Callback for if the close popup button is clicked
   *
   * We need to remove the bound close event that would be fired on popup.remove()
   * as we are manually calling it to provide the event type
   */
  const handleManualClose = () => {
    popup.off("close", onClose);
    onClose({ type: "manual-close" });
    popup.remove();
    isDragged.current = false;
    dispatch(setDraggedPopupPosition(null));
  };

  const getMouseEventLngLat = (event: React.MouseEvent<HTMLElement>) => {
    if (event.clientX && event.clientY && map) {
      const bounds = map.getBounds();

      // Get the width and height of the canvas
      const canvasWidth = Number(map.getCanvas().style.width.replace("px", ""));
      const canvasHeight = Number(map.getCanvas().style.height.replace("px", ""));

      if (canvasWidth && canvasHeight) {
        const lng = bounds.getEast() + ((canvasWidth - event.clientX) / canvasWidth) * (bounds.getWest() - bounds.getEast());
        const lat = bounds.getNorth() + (event.clientY / canvasHeight) * (bounds.getSouth() - bounds.getNorth());
        return { lng, lat };
      }
    }
    return null;
  };

  const onDragStart = (event: React.DragEvent<HTMLElement>) => {
    // Set transparent drag ghost image.
    event.dataTransfer.setDragImage(dragImg, 0, 0);

    const mousePos = getMouseEventLngLat(event);

    if (mousePos) {
      // Convert anchorPos from LngLatLike to LngLat to separately retrieve lng & lat.
      const anchorPoslngLat = mapboxgl.LngLat.convert(anchorPos.current);
      setOffset({
        lng: mousePos.lng - anchorPoslngLat.lng,
        lat: mousePos.lat - anchorPoslngLat.lat,
      });
    }
    isDragged.current = true;
  };

  const onDrag = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();

    const mousePos = getMouseEventLngLat(event);
    if (mousePos) {
      anchorPos.current = { lng: mousePos.lng - offset.lng, lat: mousePos.lat - offset.lat };
      popup.setLngLat(anchorPos.current);
    }
  };

  const onDragEnd = (event: React.DragEvent<HTMLElement>) => {
    dispatch(setDraggedPopupPosition(anchorPos.current));
  };

  return (
    // Note that mapbox requires the contentRef element to be wrapped in an additional element
    <Box>
      <Box ref={contentRef} data-testid="mapbox-popup">
        <DragContext.Provider value={{ onDragStart: onDragStart, onDrag: onDrag, onDragEnd: onDragEnd }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            draggable
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDrag={onDrag}
            className={classes.popup}
          >
            {title && (
              <Typography variant="h6" data-testid="mapbox-popup-title">
                {title}
              </Typography>
            )}
            <Box>
              {rightContent}
              {showCloseIcon && (
                <IconButton size="small" onClick={handleManualClose} data-testid="close-button" style={{ marginLeft: 8 }}>
                  <Close />
                </IconButton>
              )}
            </Box>
          </Box>
          {children}
        </DragContext.Provider>
      </Box>
    </Box>
  );
};

export default withStyles(customStyles)(MapPopup);
