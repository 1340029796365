/* eslint-disable import/prefer-default-export */
import { RawDraftEntity } from "draft-js";

import { Comment, Reply } from "fond/types";

type EntityMap = { [key: string]: RawDraftEntity };

/**
 * Iterates over a collection of comments and returns a unique
 * set of tags found
 */
export const getAllTags = (items: Array<Comment | Reply>): string[] => {
  const tags = new Set<string>();

  // Extracts the tag name from the RawContent entityMap
  const getTags = (entityMap: EntityMap) => {
    const values: string[] = [];
    Object.values(entityMap)
      .filter((entity) => entity.type === "#mention")
      .map((entity: any) => values.push(entity.data.mention.name));
    return values;
  };

  items.forEach((item) => {
    getTags(item.RawContent.entityMap).forEach((tag) => tags.add(tag));

    (item as Comment).Replies?.forEach((reply: Reply) => {
      getTags(reply.RawContent.entityMap).forEach((tag) => tags.add(tag));
    });
  });

  // Convert set to array and sort
  return Array.from(tags).sort((a, b) => a.localeCompare(b));
};
