import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Check as CheckIcon, Close as CloseIcon, PriorityHigh as ErrorIcon } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import classNames from "classnames";
import { clone, sortBy, sumBy } from "lodash";

import { apiSlice, useGetImportStatusQuery, useGetProjectQuery, useGetVersionQuery, versionsSlice } from "fond/api";
import { refreshTileSource, viewDesign } from "fond/map/redux";
import { getCurrentProject, loadProject } from "fond/project/redux";
import { completeImport, removeImport, updateImport } from "fond/redux/imports";
import { area, bboxPolygon } from "fond/turf";
import { ImportStatus, LayerImportState, LayerImportStatus, Project, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { CircularProgressWithLabel } from "fond/widgets";

import LayerImportController from "../LayerImportController";

import { GradientTypography } from "../controller.styles";
import { Indicator, ResizableFlexBox } from "./VersionImportController.styles";

/**
 * The VersionImportController component.
 * This component displays the overall status of a versions imports and renders the controllers for each layer importing into this version.
 * This component olls the version status and updates the status and progress of each layer during the asyncronous import.
 *
 */
interface VersionImportControllerProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * The version under import.
   */
  versionId: string;
  /**
   * The layer files, a mapping of layer name to file bundle.
   */
  imports: { [layerKey: string]: LayerImportState };
}

const POLLING_INTERVAL = process.env.NODE_ENV === "test" ? 500 : 1500;

const VersionImportController: React.FC<VersionImportControllerProps> = ({
  "data-testid": dataTestid = "version-import-controller",
  versionId,
  imports,
}: VersionImportControllerProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  // Constants
  /**
   * Maps the status to an approximate measure of overall progress.
   */
  const statusProgress = {
    [ImportStatus.STARTING]: 0,
    [ImportStatus.PENDING_UPLOAD]: 10,
    [ImportStatus.CONVERTING]: 40,
    [ImportStatus.IMPORTING]: 50,
    [ImportStatus.BUILDING_TILES]: 75,
    [ImportStatus.COMPLETE]: 100,
    [ImportStatus.ERROR]: 0,
  };

  // States.
  /**
   * Controls whether the version import status polling should be active.
   */
  const [polling, setPolling] = useState(false);
  const [isPriorUpload, setIsPriorUpload] = useState<{ [layerId: string]: null | boolean }>({});

  // Data.
  const { data: version } = useGetVersionQuery(versionId);
  const { data: project, refetch: refetchProject } = useGetProjectQuery(version?.Project as string, { skip: !version?.Project });

  let { data: versionImportStatus } = useGetImportStatusQuery(
    { versionId },
    {
      skip: !polling,
      pollingInterval: POLLING_INTERVAL,
      refetchOnMountOrArgChange: true,
    }
  );

  // Layer loading.
  // Note: Layer loading is only performed if the project is active.
  const currentProjectId = useSelector((state: Store): undefined | string => getCurrentProject(state.project)?.ID);
  const projectActive = currentProjectId != null && project?.ID === currentProjectId;

  /*
   * Determine when the import state polling should be active
   */
  useEffect(() => {
    let isPolling = false;
    for (const [, layerImport] of Object.entries(imports)) {
      if (layerImport.started != null && layerImport.completed == null) {
        isPolling = true;
        break;
      }
    }
    setPolling(isPolling);
  }, [imports]);

  /**
   * Process the version import status polling results.
   * Load any layers that have completed import.
   */
  useEffect(() => {
    // Process the status and update redux.
    let layerCompletedImport = false;
    let isPriorUploadUpdate = clone(isPriorUpload);
    (versionImportStatus?.Layers || []).forEach((layerImportStatus: LayerImportStatus) => {
      const { ID: layerId, ImportStatus: importStatus, LayerKey: layerKey } = layerImportStatus;
      const { Message: errorMessage } = importStatus;
      let status: ImportStatus = importStatus.Status;

      // Only consider layers in the file drop that are not already complete
      if (imports[layerKey] != null && imports[layerKey].completed == null) {
        // Handle the possibility that the current status is from a prior upload.
        if (isPriorUploadUpdate[layerId] == null || isPriorUploadUpdate[layerId] === true) {
          if ([ImportStatus.COMPLETE, ImportStatus.ERROR].includes(status)) {
            isPriorUploadUpdate[layerId] = true;
            status = ImportStatus.PENDING_UPLOAD;
          } else {
            isPriorUploadUpdate[layerId] = false;
          }
        }

        if (status === ImportStatus.COMPLETE) {
          dispatch(completeImport({ versionId: versionId, layerKey: layerKey, completed: new Date() }));
          layerCompletedImport = true;
        } else {
          dispatch(
            updateImport({
              versionId: versionId,
              layerKey: layerKey,
              layerId: layerId,
              status: status,
              progress: statusProgress[status],
              error: errorMessage,
              completed: status === ImportStatus.ERROR ? new Date() : null,
            })
          );
        }
      }
    });
    setIsPriorUpload(isPriorUploadUpdate);

    // Refresh all the data sources if the project is currently active and a new layer has completed import.
    if (projectActive && layerCompletedImport) {
      // Load all the data sources.
      if (version != null) {
        dispatch(
          apiSlice.util.invalidateTags([
            { type: "Version", id: version.ID },
            { type: "VersionRootConfiguration", id: version.ID },
            { type: "Layers", id: version.ID },
            { type: "FeatureTotals", id: version.ID },
          ])
        );
      }

      // Refetch the project and update redux.
      refetchProject();
      dispatch(loadProject({ uuid: project.ID, forceRefetch: true })).then(async (updatedProject: Project) => {
        // Zoom to the design if there has been a significant change in bounding box size.
        const boundingBoxArea = (p: Project) => area(bboxPolygon(p.BoundingBox.flat()));
        const deltaRatio = boundingBoxArea(project) / boundingBoxArea(updatedProject);
        if (deltaRatio < 0.5 || deltaRatio > 2) {
          // `version?.BoundingBox` is stale here; need to get the latest version from RTK's cache.
          const result = dispatch(versionsSlice.endpoints.getVersion.initiate(versionId));
          const updatedVersion = (await result)?.data;
          result.unsubscribe();
          dispatch(viewDesign(updatedVersion?.BoundingBox));
        }

        // Refresh the tile cache
        dispatch(refreshTileSource("project-layers"));
      });
    }
  }, [versionImportStatus]);

  /**
   * Navigate to the project. This currently requires a refresh to load state correctly.
   */
  const openProject = () => {
    navigate(`/project/${version?.Project}`);
    navigate(0);
  };

  // The project link is not available if the ttarget is already open or their are uploads in progress (due to the need for a page refresh).
  const canOpenProject = useMemo(() => {
    const isOpen = currentProjectId === project?.ID;
    const isUploading = (versionImportStatus?.Layers || []).some((layerImport) =>
      [ImportStatus.PENDING_UPLOAD, ImportStatus.PENDING_UPLOAD].includes(layerImport.ImportStatus.Status)
    );
    return !(isOpen || isUploading);
  }, [currentProjectId, project, versionImportStatus]);

  /**
   * Calculate the total progress
   */
  const totalProgress = sumBy(Object.values(imports), "progress") / Object.keys(imports).length;
  const incomplete = Object.values(imports).some(({ status }) => status === ImportStatus.ERROR);
  const complete = totalProgress === 100;

  /**
   * Set the message and indicator.
   */
  let message;
  let indicator;
  if (incomplete) {
    message = (
      <Typography data-testid="header-status" color="orange" variant="subtitle2">
        Incomplete
      </Typography>
    );
    indicator = (
      <Indicator bgColor="linear-gradient(180deg, #FF841F 0%, #ED6C02 100%)">
        <ErrorIcon />
      </Indicator>
    );
  } else if (complete) {
    message = (
      <Typography data-testid="header-status" color="green" variant="subtitle2">
        Done!
      </Typography>
    );
    indicator = (
      <Indicator bgColor="linear-gradient(180deg, #24CF7F 0%, #4CAF50 100%)">
        <CheckIcon />
      </Indicator>
    );
  } else {
    message = (
      <GradientTypography
        data-testid="header-status"
        startcolor={theme.palette.biarri.secondary.blue}
        endcolor={theme.palette.biarri.secondary.darkBlue}
        variant="subtitle2"
      >
        Importing...
      </GradientTypography>
    );
    indicator = <CircularProgressWithLabel thickness={4} size={30} sx={{ margin: "auto" }} value={totalProgress} />;
  }

  return (
    <>
      {Object.keys(imports).length > 0 && (
        <ResizableFlexBox data-testid={dataTestid} width={380} initialHeight={76} minHeight={76} tooltip="Drag to see progress">
          {/* The status and version import details. Clicking on this element will load the target version. */}
          <Box display="flex" padding={1.5} alignItems="center" width="100%" paddingTop={0}>
            {indicator}
            <Tooltip title={canOpenProject ? "Open Project" : ""} placement="right-start">
              <Box
                flex="1"
                paddingLeft={1.5}
                sx={{ cursor: canOpenProject ? "pointer" : "unset" }}
                onClick={() => (canOpenProject ? openProject() : null)}
              >
                <Typography variant="h6" style={{ fontSize: 14, paddingRight: 1.5 }}>
                  {project?.ProjectName || "Starting..."}
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="caption" style={{ fontSize: 12 }}>
                    {version?.Name}
                  </Typography>
                  {message}
                </Box>
              </Box>
            </Tooltip>
            {/* If all layer imports are complete the version import controller may be dismissed. */}
            {(complete || incomplete) && (
              <Tooltip title="Dismiss">
                <IconButton sx={{ padding: 0, height: 15, width: 15, alignSelf: "flex-start" }} onClick={() => dispatch(removeImport({ versionId }))}>
                  <CloseIcon sx={{ fontSize: "12px" }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {/* A collapsable list of layer imports. */}
          <Box pl={3.75} pr={1.75} className={classNames("darkScrollbars")} sx={{ overflowY: "auto" }}>
            {sortBy(Object.entries(imports), (o) => o[0]).map(([layerKey, layerImport]: [string, LayerImportState]) => (
              <LayerImportController
                key={layerKey}
                versionId={versionId}
                layerKey={layerKey}
                importState={layerImport}
                style={{ "&:not(:last-child)": { borderBottom: "0.7px solid rgba(0, 0, 0, 0.1)" } }}
              />
            ))}
          </Box>
        </ResizableFlexBox>
      )}
    </>
  );
};

export default VersionImportController;
