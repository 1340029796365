import React from "react";
import { Cancel as CancelIcon, Replay as RerunIcon } from "@mui/icons-material";
import { Box, IconButton, LinearProgress, Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSnackbar } from "notistack";

import { useDeleteExportMutation, useRegenerateExportMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { Export, ExportsByStatus, ExportStatus, getExportFormat } from "fond/types";

import { BodyCell, GradientLinearProgressWithLabel, GradientTypography, HeaderCell, ModifiedTableRow } from "../ExportTable.styles";

interface InProgressExportTableProps {
  exportsByStatus: ExportsByStatus;
}

/**
 * Maps the status to a user friendly label.
 */
const statusLabel = {
  [ExportStatus.Pending]: "Pending...",
  [ExportStatus.InProgress]: "Processing...",
  [ExportStatus.Complete]: "Complete!",
  [ExportStatus.Error]: "Failed!",
};

const InProgressExportTable: React.FC<InProgressExportTableProps> = ({ exportsByStatus }: InProgressExportTableProps) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteExport] = useDeleteExportMutation();
  const [regenerateExport] = useRegenerateExportMutation();

  const onCancel = (exportItem: Export) => {
    deleteExport(exportItem)
      .unwrap()
      .then(() => {
        mixpanel.track("Cancelled export", { exportId: exportItem.ID });
        enqueueSnackbar("Export cancelled.", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Export failed to cancel. Please try again...", { variant: "error" });
      });
  };

  const onRerun = (exportId: string) => {
    regenerateExport(exportId)
      .unwrap()
      .then(() => {
        mixpanel.track("Reran export", { exportId: exportId });
        enqueueSnackbar("Export reran.", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Export failed to rerun. Please try again...", { variant: "error" });
      });
  };

  return (
    <Box data-testid="export-table-inprogress">
      {Boolean(exportsByStatus.InProgress.length) && (
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead sx={{ display: "block", overflow: "auto" }}>
              <ModifiedTableRow>
                <HeaderCell width="70%">Status</HeaderCell>
                <HeaderCell>Format</HeaderCell>
                <HeaderCell />
              </ModifiedTableRow>
            </TableHead>
            <TableBody className="customScrollbars" sx={{ display: "block", overflow: "auto", maxHeight: 144 }}>
              {exportsByStatus.InProgress.map((item: Export) => (
                <ModifiedTableRow key={item.ID}>
                  <BodyCell width="70%">
                    <Typography variant="h7">{item.Name}</Typography>
                    <Box>
                      <GradientLinearProgressWithLabel
                        color="primary"
                        value={(item.Status.Progress || 0) * 100}
                        startcolor={theme.palette.biarri.secondary.blue}
                        endcolor={theme.palette.biarri.secondary.darkBlue}
                      />
                      <GradientTypography
                        variant="h7"
                        startcolor={theme.palette.biarri.secondary.blue}
                        endcolor={theme.palette.biarri.secondary.darkBlue}
                      >
                        {statusLabel[item.Status.Value]}
                      </GradientTypography>
                    </Box>
                  </BodyCell>
                  <BodyCell>{getExportFormat(item.Format)}</BodyCell>
                  <BodyCell>
                    <IconButton onClick={(event: React.MouseEvent) => onCancel(item)}>
                      <CancelIcon color="action" />
                    </IconButton>
                  </BodyCell>
                </ModifiedTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {Boolean(exportsByStatus.Error.length) && (
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead sx={{ display: "block", overflow: "auto" }}>
              <ModifiedTableRow>
                <HeaderCell>Failed Exports</HeaderCell>
              </ModifiedTableRow>
            </TableHead>
            <TableBody className="customScrollbars" sx={{ display: "block", overflow: "auto", maxHeight: 144 }}>
              {exportsByStatus.Error.map((item: Export) => (
                <ModifiedTableRow key={item.ID}>
                  <BodyCell width="70%">
                    <Typography variant="h7">{item.Name}</Typography>
                    <Box pr={8}>
                      <LinearProgress color="warning" value={0} />
                      <Typography variant="h7" color="orange">
                        {statusLabel[item.Status.Value]}
                      </Typography>
                    </Box>
                  </BodyCell>
                  <BodyCell>
                    <IconButton onClick={(event: React.MouseEvent) => onRerun(item.ID)}>
                      <RerunIcon color="primary" />
                    </IconButton>
                  </BodyCell>
                  <BodyCell>
                    <IconButton onClick={(event: React.MouseEvent) => onCancel(item)}>
                      <CancelIcon color="action" />
                    </IconButton>
                  </BodyCell>
                </ModifiedTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default InProgressExportTable;
