import React from "react";
import { Link } from "react-router-dom";
import { Business, HolidayVillage, PowerInput, SquareFoot } from "@mui/icons-material";
import { Button, Grid, Paper, Typography } from "@mui/material";

import { DrawBoundaryIcon } from "fond/svg_icons";
import { area } from "fond/turf";
import { BoundaryCount, BoundaryFeatureCountResponse, MultiProject, MultiProjectArea } from "fond/types";
import { convertMetersToFeet, convertSquareMetersToSquareKilometers, convertSquareMetersToSquareMiles } from "fond/utils";
import { formatNumber } from "fond/utils/number";

import CityPlannerInfoCard from "./CityPlannerInfoCard";

interface IProps {
  id?: string;
  project: MultiProject;
  featureCounts?: BoundaryFeatureCountResponse;
}

const CityPlannerInfoGrid: React.FC<IProps> = ({ id, project, featureCounts }) => {
  const noSubarea = project.Areas.length === 0;

  let totalPrems = 0;
  let totalStreets = 0;
  if (featureCounts) {
    Object.values(featureCounts?.FeatureCounts).forEach((count: BoundaryCount) => {
      totalPrems += count.Addresses.ExactCount ?? count.Addresses.MinCount ?? 0;
      totalStreets += count.Streets.ExactLengthMetres ?? count.Streets.MinLengthMetres ?? 0;
    });
    // Convert the total street length to the proper unit.
    totalStreets = project.SystemOfMeasurement === "imperial" ? convertMetersToFeet(totalStreets) : totalStreets;
  }

  let totalArea = 0;
  project.Areas.forEach((subarea: MultiProjectArea) => {
    totalArea += area(subarea.Boundary);
  });
  // Convert the total area to the proper unit.
  totalArea =
    project.SystemOfMeasurement === "imperial" ? convertSquareMetersToSquareMiles(totalArea) : convertSquareMetersToSquareKilometers(totalArea);

  const cardData = [
    { title: "Subareas", icon: <Business color="secondary" />, value: formatNumber(project.Areas.length) },
    { title: "Total premises", icon: <HolidayVillage color="secondary" />, value: formatNumber(totalPrems) },
    {
      title: "Total path",
      icon: <PowerInput color="secondary" />,
      value: formatNumber(totalStreets, 0),
      unit: project.SystemOfMeasurement === "imperial" ? "feet" : "meters",
    },
    {
      title: "Total area",
      icon: <SquareFoot color="secondary" />,
      value: formatNumber(totalArea),
      unit: project.SystemOfMeasurement === "imperial" ? "sq mi" : "km²",
    },
  ];

  if (noSubarea) {
    return (
      <Grid item lg={6}>
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Paper
            variant="elevation"
            elevation={1}
            square={false}
            sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
          >
            <DrawBoundaryIcon />
            <Typography variant="h3" sx={{ mt: 3 }}>
              No subareas
            </Typography>
            <Typography component="p" sx={{ mb: 2 }}>
              This city planner project has no subareas created.
            </Typography>
            <Button variant="contained" component={Link} to={`/city/${id ?? ""}`}>
              Start drawing subareas
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item lg={6}>
      <Grid container spacing={2.5} sx={{ height: "100%" }}>
        {cardData.map((data) => (
          <CityPlannerInfoCard key={data.title} {...data} />
        ))}
      </Grid>
    </Grid>
  );
};

export default CityPlannerInfoGrid;
