/**
We effectively have the following types for base rules:

  type Rule = {
    condition: (val: any, state: any) => any,
    state: 'error' | 'warning',
    message: string
  };
  type Rules = Array<Rule>;

The idea is to have one `Rules` object for each field that needs validation.
Making `Rules` objects always be arrays rather than allowing top-level Rule
objects means we can always combine rules using array concatenation.

We assign `Rules` objects to fields in the `fieldRules` object which mirrors
the structure of a UI arch:

  const fieldRules = {
    Tier1: {
      Hubs: {
        Sizes: <some Rules object>,
        ...
      }
    ...
    },
  };

Each field is validated by iterating through its rules and checking the value
of their `condition` functions. For the *first* Rule that returns true, the
state and message of that Rule are taken to be the current state of that field.
If none return true, the field is deemed to be valid.

Note that this means that conditions that are more stringent must be placed
before conditions are less stringent, or they will never be activated. For
example, the following is a mistake:

  rules = [
    {condition: (val) => val > 1000, ...},
    {condition: (val) => val > 2000, ...},
  ];

because the `val > 2000` could never be activated, since the `val > 1000`
condition would always win.

Note that the `condition` function takes two arguments: `val` (the value
itself) and `state`, which is the entire widget architecture. Most fields will
only need to use `val`, but some fields rely on the values of other fields
which they can retrieve from `state`.

For example we could say that `Tier2.ConnectorizedDropTerminals.MaxTailLength`
must exist, but only if `Tier2.ConnectorizedDropTerminals.Enabled` is true, by
setting up `fieldRules` like this:

fieldRules = {
  Tier2: {
    ConnectorizedDropTerminals: {
      MaxTailLength: {
        condition: (val, state) => state.ConnectorizedDropTerminals.Enabled && !val,
        // ...
      }
    }
  }
}
*/

/**
 * `rules` is a mapping of names to `Rules` objects.
 */
export const rules = {};

rules.number = [
  {
    condition: (val) => val === "",
    state: "error",
    message: "This field is required",
  },
  {
    condition: (val) => Number.isNaN(Number(val)),
    state: "error",
    message: "Must be a number",
  },
];

rules.notNegative = [
  {
    condition: (val) => val < 0,
    state: "error",
    message: "Value must not be less than zero",
  },
];

rules.nonNegativeNumber = [...rules.number, ...rules.notNegative];

rules.integer = [
  {
    condition: (val) => val === "" || !Number.isInteger(Number(val)),
    state: "error",
    message: "Must be an integer",
  },
];

rules.nonNegativeInteger = [...rules.integer, ...rules.notNegative];

rules.positiveInteger = [
  {
    condition: (val) => {
      const number = Number(val);
      return !(Number.isInteger(number) && number > 0);
    },
    state: "error",
    message: "Must be a positive integer",
  },
];

/**
 * Usage example:
 *
 *    rules.spare(state => state.Tier1.Hubs.Sizes, 'port count')
 *
 * This will ensure that the biggest Tier 1 hub size specified will be able to
 * serve at least 1 demand. This is true if:
 *
 * - For absolute spare, the absolute spare must be less than the max Tier 1 hub size
 * - For percent spare, it must be true that:
 *     (max T1 hub size) * (100 - percent spare) / 100 >= 1
 *
 * @param {Function} limitFunc
 * @param {String} limitType a textual description of the limit (eg. "port count")
 *   that will be included in some output messages.
 */
rules.spare = (limitFunc, limitType) => {
  return [
    {
      condition: (val) => val.AbsoluteValue === "" || val.PercentValue === "",
      state: "error",
      message: "This field is required",
    },
    ...rules.nonNegativeInteger.map((rule) => ({
      ...rule,
      condition: (val) => val.AbsoluteValue != null && rule.condition(val.AbsoluteValue),
    })),
    ...rules.nonNegativeNumber.map((rule) => ({
      ...rule,
      condition: (val) => val.PercentValue != null && rule.condition(val.PercentValue),
    })),
    {
      condition: (val) => val.PercentValue >= 100,
      state: "error",
      message: "Must have less than 100% spare",
    },
    {
      condition: (val, state) => {
        return val.AbsoluteValue != null && val.AbsoluteValue > Math.max(...limitFunc(state)) - 1;
      },
      state: "error",
      message: `Absolute spare must be less than your biggest ${limitType}`,
    },
    {
      condition: (val, state) => {
        return val.PercentValue != null && (Math.max(...limitFunc(state)) * (100 - val.PercentValue)) / 100 < 1;
      },
      state: "error",
      message: "This amount of spare would prevent anything being served",
    },
  ];
};

/**
 * Why does this exist as well as the above `rules.spare`?
 *
 * Sometimes for spare, we have three fields: absolute, percent, and
 * combination method, and sometimes we just have one field which produces
 * either {AbsoluteValue: number} or {PercentValue: number}.
 *
 * These are represented in the same way in the UI arch: in both cases we have
 *
 *    Spare: {
 *      // In the multi-field case we have both, in the single-field case just one.
 *      AbsoluteValue: number,
 *      PercentValue: number,
 *      ...
 *    }
 *
 * but:
 * - in the multi-field case we have separate rules in order to assign
 *   errors to Spare.AbsoluteValue and/or Spare.PercentValue.
 * - in the single field case we have only one rule which assigns errors
 *   directly to Spare.
 */
rules.percentSpare = [
  ...rules.nonNegativeNumber,
  {
    condition: (val) => val >= 100,
    state: "error",
    message: "Must have less than 100% spare",
  },
];

/**
 * Shortcut for making a Rules that depends on the project's
 * `SystemOfMeasurement`.  We specify maxFeet and maxMeters independently rather
 * than calculating one from the other so we can keep both numbers neat. (Ie.
 * we can say 2000 feet / 600 meters rather than 2000 feet / 609.6 meters.)
 * `messageFunc` is called with a string argument that either looks like "2000
 * feet" or "600 meters" and should return the full desired message as a
 * string.
 */
rules.maxLength = ({ maxFeet, maxMeters, state, messageFunc }) => {
  return [
    {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      condition: (val, arch, state, systemOfMeasurement) => systemOfMeasurement === "imperial" && val > maxFeet,
      state: state,
      message: messageFunc(`${maxFeet} feet`),
    },
    {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      condition: (val, arch, state, systemOfMeasurement) => systemOfMeasurement === "metric" && val > maxMeters,
      state: state,
      message: messageFunc(`${maxMeters} meters`),
    },
  ];
};

rules.nonEmptyList = (message) => {
  return [
    {
      condition: (val) => val.length === 0,
      state: "error",
      message: message,
    },
  ];
};
