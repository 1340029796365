import React, { PropsWithChildren } from "react";
import { createPortal } from "react-dom";
import Draggable, { ControlPosition } from "react-draggable";
import { Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

import { Container, Content, Header } from "./Popup.styles";

interface PropupProps extends PropsWithChildren {
  offset?: ControlPosition;
  width?: number;
  title: string;
  /**
   * Optional callback that is called when the user clicks on the close icon.
   * Note that if no onClose is called the close icon will not be added to the header.
   */
  onClose?(): void;
}

const Popup: React.FC<PropupProps> = ({ children, offset = { x: 8, y: 8 }, onClose, title, width = 225 }: PropupProps) => {
  return createPortal(
    <Draggable handle=".draggable" bounds="parent">
      <Container
        sx={{
          width: width,
          zIndex: 1000,
          position: "absolute",
          top: offset.y,
          left: offset.x,
        }}
      >
        <Header className="draggable">
          <Typography variant="h6" sx={{ fontSize: 12 }}>
            {title}
          </Typography>
          {onClose && (
            <IconButton onClick={onClose} size="small">
              <Close />
            </IconButton>
          )}
        </Header>
        <Content>{children}</Content>
      </Container>
    </Draggable>,
    document.getElementById("mapContainer") as HTMLElement
  );
};

export default Popup;
