import { Box, TableCell, TableRow } from "@mui/material";
import { grey } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";

export const ModifiedTableRow = styled(TableRow)(({ theme }) => ({
  // Make the table header more compact by minimising vertical space.
  height: 38,
  display: "table",
  tableLayout: "fixed",
  width: "100%",

  "&:nth-of-type(even)": {
    backgroundColor: grey[100],
  },
}));

// Helper components for ImportLayersTable cell styling.
export const HeaderCell = styled(TableCell)(({ theme, width }) => ({
  // Make the table header more compact by minimising vertical space.
  height: "unset",
  backgroundColor: "rgba(117, 117, 117, 0.15)",
  width: width,
}));

export const BodyCell = styled(TableCell)(({ theme, width }) => ({
  // Make the table rows more compact by minimising cell padding. Disable borders for a cleaner compact look.
  padding: "1px 16px",
  border: "unset",
  width: width,
}));

export interface CompactFormControlProps {
  width?: string | number | undefined;
}

export const CompactFormControl = styled(FormControl)<CompactFormControlProps>(({ theme, width }) => ({
  // Make the Autocomplete field more compact by removing padding.
  width: width,
  input: { padding: "0px !important" },
}));

export const CheckCircleIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "15px",
  width: "15px",
  alignItems: "center",
  justifyContent: "center",
  background: `linear-gradient(180deg, #48CCFF 0%, ${theme.palette.primary.main} 100%)`,
  marginRight: theme.spacing(1),
  borderRadius: "50%",
}));
