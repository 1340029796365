import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Error, InsertChart } from "@mui/icons-material";
import { Box, Button, LinearProgress, Typography } from "@mui/material";

import { useGenerateReportMutation, useLazyGetReportQuery } from "fond/api";
import { PageContent } from "fond/widgets";

import { Page } from "../constants";
import Navigation from "../Navigation";
import { reportWindowTitle } from "../util";

enum ProgressDescriptionMapping {
  "Analyzing costs and revenue",
  "Generating report",
}

const PollReportStatus: React.FC = () => {
  const { reportId } = useParams<"reportId">();
  const navigate = useNavigate();
  const [pollInterval, setPollInterval] = useState(1500);
  const [generateReport, { isError: isErrorOnGenerate }] = useGenerateReportMutation();
  const [getReport, { data: report, error: isReportError }] = useLazyGetReportQuery({ pollingInterval: pollInterval });

  useEffect(() => {
    if (reportId) getReport(reportId);
  }, [reportId, getReport]);

  // If the report status indicates an error stop polling
  useEffect(() => {
    if (report?.Status?.State === "ERROR" || isErrorOnGenerate) {
      setPollInterval(0);
    }
  }, [report?.Status?.State, isErrorOnGenerate]);

  if (reportId && isReportError) {
    return <Navigate to="/" />;
  }

  if (report?.Status?.State === "COMPLETE") {
    return <Navigate to={`/reports/${reportId}/`} replace />;
  }

  const getErrorMessage = (): React.ReactNode => {
    if (reportId && isReportError) {
      return (
        <>
          <Error sx={{ fontSize: 48, color: "error.main", mb: 5 }} />
          <Typography fontWeight={500} color="common.black" mb={1}>
            Report failed to load
          </Typography>
          <Typography color="text.secondary">Loading the report encountered an error.</Typography>
          <Button variant="outlined" onClick={() => navigate(-1)} sx={{ mt: 2, mb: 1 }}>
            Go back
          </Button>
        </>
      );
    } else if (report && report.Type === "financial_analytics") {
      return (
        <>
          <Error sx={{ fontSize: 48, color: "error.main", mb: 5 }} />
          <Typography fontWeight={500} color="common.black" mb={1}>
            Report generation failed
          </Typography>
          <Typography color="text.secondary">
            Report generation encountered an error. Please review settings and configuration, or attempt the process again.
          </Typography>
          <Button variant="outlined" component={Link} to={`/reports/${reportId}/settings`} sx={{ mt: 2, mb: 1 }}>
            Go back to settings
          </Button>
          <Box>
            <Typography component="span" color="text.secondary">
              or
            </Typography>
            <Button variant="text" onClick={() => generateReport(report.ID)} sx={{ ml: 1 }}>
              Try again
            </Button>
          </Box>
        </>
      );
    } else if (report && report.Type === "financial_analytics_imported") {
      return (
        <>
          <Error sx={{ fontSize: 48, color: "error.main", mb: 5 }} />
          <Typography fontWeight={500} color="common.black" mb={1}>
            Report generation failed
          </Typography>
          <Typography color="text.secondary">Report generation encountered an error.</Typography>
          <Box mt={2}>
            <Button variant="outlined" onClick={() => generateReport(report.ID)} sx={{ ml: 1 }}>
              Try again
            </Button>
          </Box>
        </>
      );
    }

    return null;
  };

  return (
    <PageContent
      windowTitle={reportWindowTitle("Updating...", report?.Name)}
      title=""
      mainContent={
        <Box display="flex" flex={1} alignItems="center" justifyContent="center">
          <Box display="flex" flexDirection="column" alignItems="center">
            {report?.Status?.State === "ERROR" || (report && isErrorOnGenerate) ? (
              <>{getErrorMessage()}</>
            ) : (
              <>
                <InsertChart sx={{ fontSize: 80, opacity: 0.12, mb: 6 }} />
                <Box width={555} mb={3}>
                  <LinearProgress />
                </Box>
                <Typography fontWeight={500}>{report?.Status ? ProgressDescriptionMapping[report.Status.CurrentStepIndex] : "Pending"}</Typography>
                <Typography sx={{ my: 1.5, color: "text.secondary" }}>This might take a few minutes.</Typography>
              </>
            )}
          </Box>
        </Box>
      }
      navigation={<Navigation page={Page.OVERVIEW} isCreatingReport />}
      testId="report-generate-page"
    />
  );
};

export default PollReportStatus;
