/**
 * Redux expects all its store data to be serialised, which is a problem if we
 * want to defer a redux-thunk action. For example, we wish to support the
 * following sequence of events:
 *
 * 1. User tries an action.
 * 2. The system determines that the user should be prompted to confirm
 *    the action before proceeding.
 * 3. The system displays a popup asking the user to confirm.
 * 4. The user confirms.
 * 5. The system finally executes the action from step 1.
 *
 * In order to achieve this we have to store the action in the store, which
 * is fine for a regular action, but a redux-thunk action is a function and is
 * not serialisable. So we now have `actionref` to pack and unpack actions for
 * us.
 *
 * For example:
 *
 *    function myAction(arg) {
 *      return function(dispatch) {
 *        dispatch(action1(arg));
 *        dispatch(action2(arg));
 *      }
 *    }
 *
 *    // If we take `myAction()` and put it in the store, we're being naughty
 *    // because it's not serialisable.
 *    dispatch({
 *      type: 'CONFIRM_OPERATION',
 *      action: myAction('myarg')
 *    }
 *
 *    // Instead:
 *    import { actionLookup, actionRef } from 'fond/actionref';
 *
 *    // Register the action so it can be unpacked
 *    actionLookup.myAction = function({arg}) {
 *      return function(dispatch) {
 *        dispatch(action1(arg));
 *        dispatch(action2(arg));
 *      }
 *    };
 *
 *    dispatch({
 *      type: 'CONFIRM_OPERATION',
 *      // Use `actionRef` to make an representation of an action that can be unpacked
 *      action: actionRef('myAction', {arg: 'myarg'})
 *    });
 *
 * And then we just need to add the `actionRefMiddleware` before the thunk middleware
 * when we create the store in order to process the actions.
 */

export const actionLookup = {};

export const actions = {
  ACTION_REF: "ACTION_REF",
};

export function actionRefMiddleware(store) {
  return (next) => {
    return (action) => {
      if (action.type === actions.ACTION_REF) {
        return next(actionLookup[action.key](action.params));
      } else {
        return next(action);
      }
    };
  };
}

export function actionRef(key, params) {
  return {
    type: actions.ACTION_REF,
    key: key,
    params: params,
  };
}
