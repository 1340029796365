import tj from "@mapbox/togeojson";
import _ from "lodash";
import * as shapefile from "shapefile";

export function extname(filename) {
  const ix = filename.lastIndexOf(".");
  if (ix === -1) {
    return;
  }
  return filename.slice(ix + 1);
}

export function basename(filename) {
  const ix = filename.lastIndexOf(".");
  if (ix === -1) {
    return filename;
  }
  return filename.slice(0, ix);
}

export default function validateFile(file, expectedGeometryType, maxFeatures, validator) {
  /**
   * `file` is a `File` object (https://developer.mozilla.org/en-US/docs/Web/API/File)
   */
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      const validationResult = validateFileText(reader.result, expectedGeometryType, maxFeatures, validator);
      if (validationResult.isValid) {
        resolve();
      } else {
        reject(validationResult.message);
      }
    };
    reader.readAsText(file);
  });
}

export function validateFileText(text, expectedGeometryType, maxFeatures, validator) {
  let json;
  try {
    json = JSON.parse(text);
  } catch (e) {
    return {
      isValid: false,
      message: "Must be a GeoJSON file",
    };
  }

  if (json.features == null || json.features.length == null || json.features.length === 0) {
    return {
      isValid: false,
      message: `File contains no features`,
    };
  }

  let geomFile = _.find(json.metadata.source, (f) => isGeometryFile(f));

  // when uploading a file, multigeometries are also allowed
  const uploadGeometryTypes = {
    Point: ["Point", "MultiPoint"],
    LineString: ["LineString", "MultiLineString"],
    Polygon: ["Polygon", "MultiPolygon"],
  };

  const expectedGeometryTypes = uploadGeometryTypes[expectedGeometryType];

  const invalidFeature = json.features.find((f) => f.geometry == null || !expectedGeometryTypes.includes(f.geometry.type));

  if (invalidFeature) {
    const invalidGeomMessage = `All features must be ${expectedGeometryTypes[0]}s or ${expectedGeometryTypes[1]}s`;

    if (invalidFeature.geometry == null || invalidFeature.geometry.type == null) {
      return {
        isValid: false,
        message: `${geomFile} contains features with no geometry. ${invalidGeomMessage}`,
      };
    }

    const invalidGeomType = invalidFeature.geometry.type;
    return {
      isValid: false,
      message: `${geomFile} contains ${invalidGeomType} geometry. ${invalidGeomMessage}`,
    };
  }

  if (json.features.length > maxFeatures) {
    return {
      isValid: false,
      message: `${geomFile} contains ${json.features.length} features. The maximum allowed is ${maxFeatures}`,
    };
  }

  const validatorResult = validator && validator(json);
  if (validatorResult) {
    return {
      isValid: false,
      message: validatorResult,
    };
  }

  return { isValid: true };
}

/**
 * Checks whether the input files can be bundled into a 'valid' package.

 * The expected input is an array of file objects.
 * File objects are defined here: https://developer.mozilla.org/en-US/docs/Web/API/File

 * To be considered a valid file package:
 *   All file basenames must be identical
 *   & All of the FILE_LOAD_CONFIG required extensions must be present in the input files
 *   & All other input file extensions must be in the FILE_LOAD_CONFIG optional extensions list

 * The output is an object with properties:
 *   isPackageValid: (bool) does the file package pass validation?
 *   expectedExtensions: (ArrayOf(str)) A collection of the extensions needed for validation to pass
 *   error: (Error) The error, if error detected. Intended for user feedback
 *   validFiles: (ArrayOf(file)) A subset of the input files which are considered valid
 */
export function validateFilePackage(files) {
  // get the config that contains the input extension
  const config = getFileConfig(files);

  // not valid if all package file basenames do not match (a shapefile convention)
  const inputFilenames = new Set(files.map((file) => basename(file.name)));
  if (inputFilenames.size > 1) {
    return {
      isPackageValid: false,
      expectedExtensions: config.requiredExtensions,
      error: new Error(`Expected all filenames to be the same (ignoring the file type extension) but received ${[...inputFilenames].join(", ")}`),
      validFiles: [],
    };
  }

  // not valid if none of the files match any fileconfig extension
  if (config == null) {
    const extensions = files.map((file) => extname(file.name).toLowerCase());
    return {
      isPackageValid: false,
      expectedExtensions: null,
      error: new Error(`Supported formats are GeoJSON, KML and Shapefile but received ${extensions.join(", ")}`),
      validFiles: [],
    };
  }

  // group the files according to file config
  const requiredFiles = [];
  const optionalFiles = [];
  const invalidFiles = [];
  for (const file of files) {
    const ext = extname(file.name).toLowerCase();
    if (config.requiredExtensions.includes(ext)) {
      requiredFiles.push(file);
    } else if (config.optionalExtensions.includes(ext)) {
      optionalFiles.push(file);
    } else {
      invalidFiles.push(file);
    }
  }
  const validFiles = [...requiredFiles, ...optionalFiles];

  // not valid if unknown files are present
  if (invalidFiles.length > 0) {
    const extensions = invalidFiles.map((file) => extname(file.name).toLowerCase());
    return {
      isPackageValid: false,
      expectedExtensions: config.requiredExtensions,
      error: new Error(`Expected file types are ${config.requiredExtensions.join(", ")} but received ${extensions.join(", ")}`),
      validFiles: validFiles,
    };
  }

  // valid package if all required files are present
  if (requiredFiles.length === config.requiredExtensions.length) {
    return {
      isPackageValid: true,
      expectedExtensions: config.requiredExtensions,
      error: null,
      validFiles: validFiles,
    };
  }

  // otherwise, not a valid package but no need to raise an error for an incomplete drop
  // the UI will provide feedback via the picemeal upload panel
  return {
    isPackageValid: false,
    expectedExtensions: config.requiredExtensions,
    error: null,
    validFiles: validFiles,
  };
}

function getFileConfig(files) {
  return FILE_LOAD_CONFIGS.find((config) => {
    const requiredExtensions = config.requiredExtensions.map((e) => e.toLowerCase());
    const optionalExtensions = config.optionalExtensions.map((e) => e.toLowerCase());

    return files.some((file) => {
      const ext = extname(file.name).toLowerCase();
      return requiredExtensions.includes(ext) || optionalExtensions.includes(ext);
    });
  });
}

/**
 *
 * @returns {Accept} object listing all accepted file extensions to be used within Dropzone.
 */
export function getAllAcceptedExtensions() {
  const requiredExtensions = _.flatten(FILE_LOAD_CONFIGS.map((config) => config.requiredExtensions.map((ext) => `.${ext.toLocaleLowerCase()}`)));
  const optionalExtensions = _.flatten(FILE_LOAD_CONFIGS.map((config) => config.optionalExtensions.map((ext) => `.${ext.toLocaleLowerCase()}`)));

  return { "application/unknown": _.union(requiredExtensions, optionalExtensions) };
}

const FILE_LOAD_CONFIGS = [
  {
    name: "Shapefile",
    requiredExtensions: ["shp", "dbf", "prj"],
    optionalExtensions: [
      "shx", // a positional index of the feature geometry to allow seeking forwards and backwards quickly
      "sbn",
      "sbx", // a spatial index of the features
      "fbn",
      "fbx", // a spatial index of the features that are read-only
      "ain",
      "aih", // an attribute index of the active fields in a table
      "ixs", // a geocoding index for read-write datasets
      "mxs", // a geocoding index for read-write datasets (ODB format)
      "atx", // an attribute index for the .dbf file in the form of shapefile.columnname.atx (ArcGIS 8+)
      // TODO: after MAG-1478 change the xml extension to the more specific and correct shp.xml extension
      "xml", // geospatial metadata in XML format, such as ISO 19115 or other XML schema
      "cpg", // used to specify the code page (only for .dbf) for identifying the character encoding to be used
      "qix", // an alternative quadtree spatial index used by MapServer and GDAL/OGR software
      "qpj", // QGIS project file. Not a shp extension, but likely to be included in the shp package by users
    ],
    toGeoJson: loadShpAsGeoJson,
  },
  {
    name: "GeoJSON",
    requiredExtensions: ["json"],
    optionalExtensions: [],
    toGeoJson: loadJson,
  },
  {
    name: "GeoJSON",
    requiredExtensions: ["geojson"],
    optionalExtensions: [],
    toGeoJson: loadJson,
  },
  {
    name: "KML",
    requiredExtensions: ["kml"],
    optionalExtensions: [],
    toGeoJson: loadKmlAsGeojson,
  },
];

function loadKmlAsGeojson(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      try {
        const kml = new DOMParser().parseFromString(reader.result, "text/xml");
        /*
          togeojson returns a html dom when the kml cannot be converted to geojson.
          We need to check this to see if the conversion was successful or not.
        */
        if (kml.getElementsByTagName("kml").length > 0) {
          resolve(tj.kml(kml));
        } else {
          reject(new Error("Error parsing KML"));
        }
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsText(file);
  });
}

async function loadShpAsGeoJson(filesObj) {
  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.readAsArrayBuffer(file);
    });
  };

  let dbfBuffer = readFileAsArrayBuffer(filesObj.dbf);
  let shpBuffer = readFileAsArrayBuffer(filesObj.shp);

  const [shapeFile, dbfFile] = await Promise.all([shpBuffer, dbfBuffer]);
  return shapefile.read(shapeFile, dbfFile);
}

export function loadJson(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      try {
        const json = JSON.parse(reader.result);
        resolve(json);
      } catch (e) {
        reject(e);
      }
    };
    reader.readAsText(file);
  });
}

/**
 * This function returns true if the given filename is the geometry file based on the extension.
 *
 * This is useful because some file formats are made of multiple files, for example Shapefile.
 *
 * Supported geometry file extensions:
 *  - .geojson
 *  - .json
 *  - .kml
 *  - .shp
 *  - .tab
 */
function isGeometryFile(filename) {
  return ["geojson", "json", "kml", "shp", "tab"].includes(extname(filename).toLowerCase());
}

export async function loadFilesAsGeoJsonFile(files) {
  files.sort((fileA, fileB) => {
    const nameA = extname(fileA.name);
    const nameB = extname(fileB.name);
    return nameA.localeCompare(nameB, "en-US", { sensitivity: "base" });
  });

  const config = getFileConfig(files);
  if (!config) {
    throw new Error("Supported formats are GeoJSON, KML and Shapefile");
  }

  let filesObj = files[0];
  // in the case of multi file, pack the files into an object mapping file extension to file
  if (files.length > 1) {
    filesObj = {};
    let ext;
    files.forEach((file, i) => {
      ext = extname(file.name).toLowerCase();
      filesObj[ext] = files[i];
    });
  }
  try {
    const json = await config.toGeoJson(filesObj);

    json.metadata = {
      ...json.metadata,
      source: files.map((f) => f.name),
    };

    return new File([JSON.stringify(json)], "data.geojson");
  } catch (e) {
    throw new Error(`Error loading ${config.name}.`);
  }
}
