import { Check as CheckIcon, ErrorOutline as ErrorOutlineIcon, InfoOutlined as InfoOutlinedIcon } from "@mui/icons-material";
import { Chip as ChipBase, FormHelperText, styled, TableCell, TableRow as TableRowBase } from "@mui/material";

type ChipProps = {
  textColor?: string;
  borderColor?: string;
};

export const Chip = styled(ChipBase, {
  shouldForwardProp: (prop) => prop !== "textColor" && prop !== "borderColor",
})<ChipProps>(
  ({ textColor, borderColor }) =>
    textColor &&
    borderColor && {
      color: textColor,
      borderColor: borderColor,
    }
);

export const Check = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));

export const TableRow = styled(TableRowBase)<{ submitted?: boolean }>(({ submitted }) => ({
  opacity: submitted ? 0.5 : 1,
}));

export const InfoOutlined = styled(InfoOutlinedIcon)`
  width: 16px;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

export const ErrorOutline = styled(ErrorOutlineIcon)`
  width: 16px;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

export const PermissionUserTableCell = styled(TableCell)`
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const InfoHelperText = styled(FormHelperText)(({ theme }) => ({
  color: theme.palette.primary.main,
  whiteSpace: "normal",
}));
