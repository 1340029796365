import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import { CreateExportParams, Export, Store } from "fond/types";

import { apiSlice } from "./apiSlice";

export type GetExportsResponse = {
  Items: Export[];
};

export const exportsAdapter = createEntityAdapter<Export>({
  selectId: (entity: Export): string => entity.ID,
  // Keep the "all IDs" array sorted based on CreatedAt (desc)
  sortComparer: (a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime(),
});
const exportsInitialState = exportsAdapter.getInitialState();

/**
 * Exports API Slice
 */

export const exportsSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getExport: build.query({
      query: (exportId: string) => `/v2/exports/${exportId}`,
      providesTags: (result) => (result ? [{ type: "Export", id: result.ID }] : []),
    }),
    getExports: build.query({
      query: (projectId: string) => `/v2/exports?project_id=${projectId}`,
      transformResponse: (response: GetExportsResponse) => exportsAdapter.setAll(exportsInitialState, response.Items),
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: "Export" as const, id: id })), { type: "Export", id: "LIST" }]
          : [{ type: "Export", id: "LIST" }],
    }),
    createExport: build.mutation<Export, CreateExportParams>({
      query: (exportItem) => ({
        url: "/v2/exports",
        method: "POST",
        body: exportItem,
      }),
      invalidatesTags: [{ type: "Export", id: "LIST" }],
    }),
    regenerateExport: build.mutation<undefined, string>({
      query: (exportId) => ({
        url: `/v2/exports/${exportId}/regenerate`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "Export", id: "LIST" }],
    }),
    deleteExport: build.mutation<undefined, Export>({
      query: ({ ID, ...exportItem }) => ({
        url: `/v2/exports/${ID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Export", id: arg.ID }],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const { useGetExportQuery, useGetExportsQuery, useCreateExportMutation, useRegenerateExportMutation, useDeleteExportMutation } = exportsSlice;

/**
 * Selectors
 */
export const selectByExportId = createSelector(
  [(state) => state, (state: Store, args: { projectId: string; exportId: string }) => args],
  (state: Store, { projectId, exportId }) => {
    const data = createSelector(exportsSlice.endpoints.getExports.select(projectId), (exportsResult) => exportsResult.data)(state);
    return data?.entities[exportId];
  }
);

export const selectExportsByProjectId = createSelector(
  [(state) => state, (state: Store, projectId: string) => projectId],
  (state: Store, projectId) => {
    const data = createSelector(exportsSlice.endpoints.getExports.select(projectId), (exportsResult) => exportsResult.data)(state);
    return data?.ids.map((id) => data.entities[id] as Export);
  }
);
