import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Lock } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";

import Architecture from "fond/architecture/Architecture";
import { ArchitecturePanel, AreaDrawPanel, AreaPanel, CarvePanel } from "fond/cityPlanner";
import { closeArchitectureModal, Modals, openArchitectureModal } from "fond/project/redux";
import { ArchitectureBase, MultiProject, Store } from "fond/types";
import { useAppDispatch } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { AccordionStepper, AccordionStepperPane, NonIdealState, StackedNavigation } from "fond/widgets";

type IProps = {
  /**
   * The project currently being viewed / edited
   */
  multiProject: MultiProject;
  /**
   * The current architecture.
   */
  architecture: ArchitectureBase | null;
  /**
   * Flag indicating if the project is read only
   */
  readOnly: boolean;
};

const cityPlannerStackedNavigationPanels: Array<{ id: string; name: string; component: React.ReactNode; useDefaultHeader?: boolean }> = [
  {
    id: "areaDraw",
    name: "Subareas",
    component: <AreaDrawPanel />,
    useDefaultHeader: false,
  },
];

const AutoDesignPanel: React.FC<IProps> = ({ architecture, multiProject, readOnly }: IProps) => {
  const dispatch = useAppDispatch();
  const modal = useSelector((state: Store) => state.project.modal);

  const handleOnChangeArchitectureClick = useCallback(() => dispatch(openArchitectureModal()), [dispatch]);
  const handleOnArchitectureModalClose = () => dispatch(closeArchitectureModal());

  const accordionPanes: AccordionStepperPane[] = useMemo(
    () => [
      {
        header: "Architecture",
        id: "architecture",
        content: (
          <div style={{ textAlign: "left" }}>
            <ArchitecturePanel multiProject={multiProject} readOnly={readOnly} onChangeClick={handleOnChangeArchitectureClick} />
          </div>
        ),
        isComplete: multiProject.Architecture !== null,
      },
      {
        header: "Work area",
        id: "subareas",
        content: <AreaPanel multiProject={multiProject} />,
        isComplete: multiProject.Areas.length > 0,
      },
      {
        header: "Assess",
        id: "assess",
        rightAdornment: <Chip label="optional" size="small" />,
        content: null,
        isComplete: false,
        optional: true,
      },
      {
        header: "Projects",
        id: "projects",
        content: <CarvePanel multiProject={multiProject} />,
        isComplete: multiProject.Areas.some((area) => area.Project?.ID),
      },
    ],
    [handleOnChangeArchitectureClick, multiProject, readOnly]
  );

  if (!multiProject) return null;

  return (
    <Box data-testid="city-planner-design-panel" sx={{ height: "100%" }}>
      {!permissionCheck(multiProject.Permission?.Level, Actions.PROJECT_EDIT) ? ( // if user has access and no license, more granular "locks" are applied per component
        <NonIdealState size="small" icon={<Lock />} title="View only access" description="You only have view access to this project" />
      ) : (
        <StackedNavigation
          rootComponent={<AccordionStepper id={multiProject.ID} panes={accordionPanes} autoMove={["architecture"]} />}
          screens={cityPlannerStackedNavigationPanels}
        />
      )}
      {modal === Modals.architecture && (
        <Architecture onClose={() => handleOnArchitectureModalClose()} project={multiProject} architecture={multiProject.Architecture} />
      )}
    </Box>
  );
};

export default AutoDesignPanel;
