import { createContext } from "react";

import { BaseIcon, Configuration, SystemOfMeasurement } from "fond/types";

export const LegendContext = createContext<{
  /**
   * The collection of groups, layers and sublayers being rendered within the legend.
   */
  config: Configuration;
  /**
   * All the image icons that could be used within the layers styles.
   */
  icons?: BaseIcon[];
  /**
   * Callable indicating whether the entity having the given ID is visible.
   */
  isEntityVisible: (key: string) => boolean;
  /**
   * Called when toggling the visibility of an entity.
   */
  onEntityVisibilityToggle?: (key: string) => void;
  /**
   * The feature totals for each entity.
   */
  totals: { [key: string]: { count: number | null; length: number | null } };
  /**
   * Flag indicating if the feature totals should be shown.
   */
  showTotals: boolean;
  /**
   * Current system of measurement being used within the project
   */
  systemOfMeasurement: SystemOfMeasurement;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
}>(undefined!);
