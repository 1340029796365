import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box, SxProps, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import JSZip from "jszip";

import { uploadFileToS3, useCreateLayerMutation, useImportLayerMutation } from "fond/api";
import mixpanel from "fond/mixpanel";
import { addImport, removeImport, startImport, updateImport } from "fond/redux/imports";
import { AppThunkDispatch, ImportStatus, LayerImportState } from "fond/types";

import { GradientLinearProgressWithLabel } from "./LayerImportController.styles";

// This is the maximum size that a zipped file can be as dictated by the Service API.
const LAYER_ZIPFILE_BYTES_LIMIT = 31457280;

/**
 * The LayerImportController component.
 * This component drives the import pipeline and reports on layer import status.
 *
 * The import pipeline is:
 *   - Register the layer with the Service API and retrieve the UUID and presigned post authorization.
 *   - Upload a zipped layer bundle to the S3 bucket using the presign authorization. The zip and key path is named by the UUID.
 *   - Report on the progress of the S3 upload till completion.
 *   - Instruct the Service API to begin the import from S3 to the database.
 *   - Handle any failures and provide the user with resolution instructions.
 */
interface LayerImportControllerProps {
  /**
   * Set the component test identifier.
   */
  "data-testid"?: string;
  /**
   * The version under import.
   */
  versionId: string;
  /**
   * The layer 'key'
   */
  layerKey: string;
  /**
   * The import state
   */
  importState: LayerImportState;
  /**
   * Additional styles.
   */
  style?: SxProps;
}

const LayerImportController: React.FC<LayerImportControllerProps> = ({
  "data-testid": dataTestid = "layer-import-controller",
  versionId,
  layerKey,
  importState,
  style,
}: LayerImportControllerProps) => {
  const theme = useTheme();
  const dispatch: AppThunkDispatch = useDispatch();

  // Constants.
  /**
   * Maps the status to a user friendly label.
   */
  const statusLabel = {
    [ImportStatus.STARTING]: "Starting...",
    [ImportStatus.PENDING_UPLOAD]: "Uploading...",
    [ImportStatus.CONVERTING]: "Converting...",
    [ImportStatus.IMPORTING]: "Importing...",
    [ImportStatus.BUILDING_TILES]: "Building Tiles...",
    [ImportStatus.COMPLETE]: "Complete!",
    [ImportStatus.ERROR]: "Failed!",
  };

  // Data.
  const [createLayer] = useCreateLayerMutation();
  const [importLayer] = useImportLayerMutation();

  /**
   * Block a page refresh if the import is uploading
   */
  useEffect(() => {
    if ([ImportStatus.STARTING, ImportStatus.PENDING_UPLOAD].includes(importState.status)) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [importState]);

  /**
   * Run the upload and import.
   *
   * Register the layers with the Service API.
   * Generates a zip blob.
   * Names the zip per the registered UUID.
   * Uploads to the received bucket Url using the presigned authorisation contained in the received Fields.
   * Calls the import and wait for a response.
   * Logs the responses of success and failure requests for debugging (to be removed).
   */
  useEffect(() => {
    let mounted = true;
    // Do not perform the upload and import if the layer has already started.
    if (importState.started != null) {
      return;
    }

    // The import only step is run when the layer is registered and there are no files in state.
    const runImport = (layerId: string) =>
      importLayer({ id: layerId })
        .unwrap()
        .catch(() => {
          dispatch(
            updateImport({
              versionId: versionId,
              layerKey: layerKey,
              layerId: layerId,
              completed: new Date(),
              status: ImportStatus.ERROR,
              progress: importState.progress,
              error: "Import Failed!",
            })
          );
        });

    // The full register, upload and import is run when the layer has not been registered yet.
    const runRegisterUploadAndImport = () =>
      createLayer({ Key: layerKey, VersionID: versionId })
        .unwrap()
        .then(
          (layer) => {
            const { ID: layerId, Url: presignedUrl, Fields: presignedFields } = layer;

            // Zip the layer file bundle.
            const zip = new JSZip();
            for (const layerFile of importState.files) {
              zip.file(layerFile.name, layerFile);
            }

            // Generate the compressed zip.
            zip.generateAsync({ type: "blob", compression: "DEFLATE" }).then((content) => {
              // Name the zip after the layer ID, as required by the presigned POST configuration.
              const zipfile = new File([content], `${layerId}.zip`, { type: "application/zip" });
              if (zipfile.size > LAYER_ZIPFILE_BYTES_LIMIT) {
                // Raise an error is the filesize is above the FOND Service limit.
                dispatch(
                  updateImport({
                    versionId: versionId,
                    layerKey: layerKey,
                    layerId: layerId,
                    completed: new Date(),
                    status: ImportStatus.ERROR,
                    progress: importState.progress,
                    error: "The layer is too large. The max size is 30MB (zipped)",
                  })
                );
              } else {
                // Perform the upload.
                uploadFileToS3({
                  presignedPost: { Url: presignedUrl, Fields: presignedFields },
                  file: zipfile,
                  onProgress: (percentComplete: number) =>
                    dispatch(
                      updateImport({
                        versionId: versionId,
                        layerKey: layerKey,
                        layerId: layerId,
                        status: ImportStatus.PENDING_UPLOAD,
                        progress: 10 + percentComplete * 0.3,
                      })
                    ),
                  onComplete: () => mounted && runImport(layerId),
                  onError: (error: Error) => {
                    mixpanel.track("Failed layer files upload", { layerId: layerId, error: error.message });
                    dispatch(
                      updateImport({
                        versionId: versionId,
                        layerKey: layerKey,
                        layerId: layerId,
                        completed: new Date(),
                        status: ImportStatus.ERROR,
                        progress: importState.progress,
                        error: "Upload Failed!",
                      })
                    );
                  },
                });
              }
            });
          },
          () =>
            dispatch(
              updateImport({
                versionId: versionId,
                layerKey: layerKey,
                layerId: undefined,
                completed: new Date(),
                status: ImportStatus.ERROR,
                progress: importState.progress,
                error: "Creation Failed!",
              })
            )
        );

    dispatch(startImport({ versionId: versionId, layerKey: layerKey, started: new Date() }));
    if (importState.layerId == null || importState.files.length > 0) {
      runRegisterUploadAndImport();
    } else {
      runImport(importState.layerId);
    }

    return () => {
      mounted = false;
    };
  }, []);

  /**
   * Set the message and progress bar.
   */
  let message;
  let progressBar;
  if (importState.status === ImportStatus.ERROR) {
    message = (
      <Typography data-testid="layer-status" variant="h7" color="orange">
        {importState.error || statusLabel[importState.status]}
      </Typography>
    );
    progressBar = (
      <GradientLinearProgressWithLabel
        color="warning"
        value={100}
        // Reset the import to inital state.
        onRetry={() =>
          dispatch(
            addImport({
              versionId: versionId,
              layerKey: layerKey,
              layerId: importState.layerId,
              displayName: importState.displayName,
              files: importState.files,
            })
          )
        }
        startcolor="#FFA621"
        endcolor="#ED6C02"
        onDismiss={() => dispatch(removeImport({ versionId, layerKey }))}
      />
    );
  } else if (importState.status === ImportStatus.COMPLETE) {
    message = (
      <Typography data-testid="layer-status" variant="h7" color={theme.palette.primary.main}>
        {statusLabel[importState.status]}
      </Typography>
    );
    progressBar = (
      <GradientLinearProgressWithLabel
        color="primary"
        value={100}
        startcolor="#48CDFF"
        endcolor={theme.palette.primary.main}
        onDismiss={() => dispatch(removeImport({ versionId, layerKey }))}
      />
    );
  } else {
    message = (
      <Typography data-testid="layer-status" variant="h7" color={theme.palette.primary.main}>
        {statusLabel[importState.status]}
      </Typography>
    );
    progressBar = (
      <GradientLinearProgressWithLabel color="primary" value={importState.progress} startcolor="#48CDFF" endcolor={theme.palette.primary.main} />
    );
  }

  return (
    <Box data-testid={dataTestid} sx={{ padding: "3px 0px", ...style }}>
      <Typography variant="h7">{importState.displayName}</Typography>
      <Box>{progressBar}</Box>
      {message}
    </Box>
  );
};

export default LayerImportController;
