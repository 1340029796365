import exifr from "exifr";

import * as API from "fond/api";
import { basename, extname } from "fond/fileValidation";
import {
  AttachmentActionTypes,
  DELETE_ATTACHMENT_FAILURE,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENTS_REQUEST,
  DOWNLOAD_ATTACHMENTS_FAILURE,
  DOWNLOAD_ATTACHMENTS_REQUEST,
  DOWNLOAD_ATTACHMENTS_SUCCESS,
  GET_ATTACHMENTS_FAILURE,
  GET_ATTACHMENTS_REQUEST,
  GET_ATTACHMENTS_SUCCESS,
  UPDATE_ATTACHMENT_FAILURE,
  UPDATE_ATTACHMENT_SUCCESS,
  UPDATE_ATTACHMENTS_REQUEST,
  UPLOAD_ATTACHMENT_ABORT,
  UPLOAD_ATTACHMENT_FAILURE,
  UPLOAD_ATTACHMENT_PROGRESS_UPDATE,
  UPLOAD_ATTACHMENT_REQUEST,
  UPLOAD_ATTACHMENT_SUCCESS,
  UPLOAD_ATTACHMENTS_REQUEST,
} from "fond/redux/attachments";
import { AppThunk, Attachment, AttachmentEntityType, BulkFileProps } from "fond/types";

/**
 * GET Attachments
 */

const getAttachmentsRequest = (): AttachmentActionTypes.GetAttachmentsRequest => ({ type: GET_ATTACHMENTS_REQUEST });
const getAttachmentsSuccess = (payload: { Items: Attachment[] }, entityType: AttachmentEntityType): AttachmentActionTypes.GetAttachmentsSuccess => ({
  type: GET_ATTACHMENTS_SUCCESS,
  payload: payload,
  entityType: entityType,
});
const getAttachmentsFailure = (error: Error): AttachmentActionTypes.GetAttachmentsFailure => ({ type: GET_ATTACHMENTS_FAILURE, error: error });

export const getAttachments =
  (projectId: string, layerFeatureId?: string | null): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(getAttachmentsRequest());
    await new Promise<void>((resolve, reject) => {
      API.getAttachments(projectId, layerFeatureId).then(
        (payload) => {
          dispatch(getAttachmentsSuccess(payload, layerFeatureId ? "Feature" : "Project"));
          resolve();
        },
        (error: Error) => {
          dispatch(getAttachmentsFailure(error));
          reject(error);
        }
      );
    });
  };

/**
 * UPDATE Attachments
 */

const updateAttachmentsRequest = (): AttachmentActionTypes.UpdateAttachmentsRequest => ({ type: UPDATE_ATTACHMENTS_REQUEST });
const updateAttachmentSuccess = (attachment: Attachment): AttachmentActionTypes.UpdateAttachmentSuccess => ({
  type: UPDATE_ATTACHMENT_SUCCESS,
  attachment: attachment,
});
const updateAttachmentFailure = (error: Error): AttachmentActionTypes.UpdateAttachmentFailure => ({
  type: UPDATE_ATTACHMENT_FAILURE,
  error: error,
});

export const updateAttachments =
  (attachments: Attachment[]): AppThunk<Promise<void[]>> =>
  async (dispatch: any) => {
    dispatch(updateAttachmentsRequest());

    const updatePromises = attachments.map((attachment: Attachment) => {
      const { Location, Description, ...attachmentWithoutLocation } = attachment;
      return new Promise<void>((resolve, reject) => {
        API.updateAttachment(attachmentWithoutLocation).then(
          () => {
            dispatch(updateAttachmentSuccess(attachment));
            resolve();
          },
          (error: Error) => {
            dispatch(updateAttachmentFailure(error));
            reject(error);
          }
        );
      });
    });

    return Promise.all(updatePromises);
  };

/**
 * DELETE Attachments
 */

const deleteAttachmentsRequest = (): AttachmentActionTypes.DeleteAttachmentsRequest => ({ type: DELETE_ATTACHMENTS_REQUEST });
const deleteAttachmentSuccess = (attachment: Attachment): AttachmentActionTypes.DeleteAttachmentSuccess => ({
  type: DELETE_ATTACHMENT_SUCCESS,
  attachment: attachment,
});
const deleteAttachmentFailure = (error: Error): AttachmentActionTypes.DeleteAttachmentFailure => ({
  type: DELETE_ATTACHMENT_FAILURE,
  error: error,
});

export const deleteAttachments =
  (attachments: Attachment[]): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    dispatch(deleteAttachmentsRequest());

    const deletePromises = attachments.map((attachment: Attachment) => {
      return new Promise<void>((resolve, reject) => {
        API.deleteAttachment(attachment.ID).then(
          () => {
            dispatch(deleteAttachmentSuccess(attachment));
            resolve();
          },
          (error: Error) => {
            dispatch(deleteAttachmentFailure(error));
            reject(error);
          }
        );
      });
    });

    await Promise.all(deletePromises);
  };

/**
 * UPLOAD Attachments
 */

const uploadAttachmentsRequest = (files: File[]): AttachmentActionTypes.UploadAttachmentsRequest => ({
  type: UPLOAD_ATTACHMENTS_REQUEST,
  files: files,
});

const uploadAttachmentRequest = (file: File, XHRRequest: XMLHttpRequest): AttachmentActionTypes.UploadAttachmentRequest => ({
  type: UPLOAD_ATTACHMENT_REQUEST,
  file: file,
  XHRRequest: XHRRequest,
});
const uploadAttachmentSuccess = (): AttachmentActionTypes.UploadAttachmentSuccess => ({
  type: UPLOAD_ATTACHMENT_SUCCESS,
});
const uploadAttachmentFailure = (file: File, error: Error): AttachmentActionTypes.UploadAttachmentFailure => ({
  type: UPLOAD_ATTACHMENT_FAILURE,
  file: file,
  error: error,
});
const uploadAttachmentProgressUpdate = (file: File, percentComplete: number): AttachmentActionTypes.UploadAttachmentProgressUpdate => ({
  type: UPLOAD_ATTACHMENT_PROGRESS_UPDATE,
  percentComplete: percentComplete,
  file: file,
});
export const uploadAttachmentAbort =
  (file: File): AppThunk =>
  async (dispatch: any, getState: any) => {
    const { uploadStatus } = getState().attachments;
    if (uploadStatus[file.name].progress !== 100) {
      uploadStatus[file.name]?.request?.abort();
      dispatch({ type: UPLOAD_ATTACHMENT_ABORT, file: file });
    }
  };

/*
 * Upload the files into a directory of s3.
 * The attachment file structure is flat and files must be distinguishable by ID.
 * Any uploads with matching key will be overwritten.
 */
export const uploadAttachments =
  (projectId: string, files: File[], layerFeatureId?: string | null, filesWithFeatureDetails?: BulkFileProps[]): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    dispatch(uploadAttachmentsRequest(files));
    const entityType = layerFeatureId ? "Feature" : "Project";
    const successfullyUploadedAttachmentIds: string[] = [];

    const uploadPromises = files.map(async (file: File) => {
      // Check if the attachment with matching name is already loaded.
      // If it is not then consider this a new file upload rather than an overwrite.

      let attachment = getState().attachments.items[entityType]?.find((item: Attachment) => `${item.Name}.${item.Extension}` === file.name);

      if (attachment == null) {
        const fileExtension = extname(file.name).toLowerCase();
        let exifData;
        if (["png", "jpg", "jpeg"].includes(fileExtension)) {
          exifData = await exifr.gps(file);
        }

        const featureDetails = filesWithFeatureDetails?.find((item: BulkFileProps) => item.file.name === file.name)?.featureDetails;

        try {
          // Create an attachment record. This will provide us with a resource ID to request a presigned post.
          attachment = await API.addAttachment({
            ProjectID: projectId,
            Name: basename(file.name),
            Extension: fileExtension,
            MimeType: file.type || "application/octet-stream", // assume the closest thing to a default type.
            Size: file.size,
            Uploaded: false,
            LayerFeatureID: layerFeatureId,
            Location: {
              latitude: exifData?.latitude,
              longitude: exifData?.longitude,
            },
            FeatureDetails: featureDetails,
          });
        } catch (error) {
          const err = new Error(`Could not create attachment record for ${file.name}`);
          dispatch(uploadAttachmentFailure(file, err));
          throw err;
        }
      }

      return new Promise<void>((resolve, reject) => {
        if (!attachment) {
          const err = new Error("Attachment not found");
          dispatch(uploadAttachmentFailure(file, err));
          reject(err);
          return;
        }

        // Request a presigned post to authorize the upload of this attachment.
        API.getAttachmentsPresignedPost(attachment.ID).then(
          (presignedPost) => {
            // Check if the upload has been aborted before opening a new upload.
            if (getState().attachments.uploadStatus[file.name]?.isAborted) {
              const error = new Error("Upload Aborted");
              dispatch(uploadAttachmentFailure(file, error));
              reject(error);
              return;
            }

            // The file is renamed for storage using the unique attachment ID.
            const renamedFile = new File([file], `${attachment.ID}.${attachment.Extension}`, { type: file.type });
            // Remove Location from attachment, as update attachment request does not need location info.
            // Location info has been already sent during create attachment request.
            const { Location, Description, ...attachmentWithoutLocation } = attachment;
            // Begin the file upload.
            const XHRRequest = API.uploadFileToS3({
              presignedPost: presignedPost,
              file: renamedFile,
              onProgress: (percentComplete: number) => dispatch(uploadAttachmentProgressUpdate(file, percentComplete)),
              onComplete: () =>
                // Mark the attachment database resource as being uploaded.
                API.updateAttachment({ ...attachmentWithoutLocation, Uploaded: true }).then(
                  () => {
                    dispatch(uploadAttachmentSuccess());
                    const fileExtension = extname(file.name).toLowerCase();
                    if (["png", "jpg", "jpeg"].includes(fileExtension)) {
                      successfullyUploadedAttachmentIds.push(attachment.ID);
                    }
                    resolve();
                  },
                  (error) => {
                    dispatch(uploadAttachmentFailure(file, error));
                    reject(error);
                  }
                ),
              onError: (error: Error) => {
                dispatch(uploadAttachmentFailure(file, new Error("Upload Failed")));
                reject(error);
              },
            });
            dispatch(uploadAttachmentRequest(file, XHRRequest));
          },
          (error) => {
            dispatch(uploadAttachmentFailure(file, error));
            reject(error);
          }
        );
      });
    });

    await Promise.all(uploadPromises);

    // If there are successfully uploaded attachments, generate thumbnails
    if (successfullyUploadedAttachmentIds.length > 0) {
      await dispatch(
        API.projectSlice.endpoints.createAttachmentThumbnails.initiate({
          projectId: projectId,
          attachmentIds: successfullyUploadedAttachmentIds,
        })
      );
    }
  };

/**
 * DOWNLOAD Attachments
 */

const downloadAttachmentsRequest = (): AttachmentActionTypes.DownloadAttachmentsRequest => ({ type: DOWNLOAD_ATTACHMENTS_REQUEST });
const downloadAttachmentsSuccess = (): AttachmentActionTypes.DownloadAttachmentsSuccess => ({
  type: DOWNLOAD_ATTACHMENTS_SUCCESS,
});
const downloadAttachmentsFailure = (error: Error): AttachmentActionTypes.DownloadAttachmentsFailure => ({
  type: DOWNLOAD_ATTACHMENTS_FAILURE,
  error: error,
});

export const downloadAttachments =
  (attachments: Attachment[]): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    dispatch(downloadAttachmentsRequest());

    // Initialise all downloads simultaneously by requesting each in a seperate off-screen iframe.
    // 15 seconds is given for the downloads the initalise before the iframes are cleaned up.
    return new Promise<void>((resolve, reject) => {
      // Open iframes and initalise downloads
      let iframes: any[] = [];
      try {
        for (const attachment of attachments) {
          const iframe = document.createElement("iframe");
          iframe.src = attachment.Urls?.Download || "";
          iframe.style.display = "none";
          document.body.appendChild(iframe);
          iframes.push(iframe);
        }

        // Clean up after 15 seconds.
        setTimeout(() => {
          for (const iframe of iframes) {
            iframe.remove();
          }
        }, 15000);

        // Include a 4s delay to give any initialisation errors time to occur.
        setTimeout(() => {
          dispatch(downloadAttachmentsSuccess());
          resolve();
        }, 4000);
      } catch (error: any) {
        dispatch(downloadAttachmentsFailure(error));
        reject(error);
      }
    });
  };
