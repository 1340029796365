import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import pick from "lodash/pick";

import { Folder, ProjectPreview, Report, Store } from "fond/types";
import { getPath } from "fond/utils/folder";
import { Actions, mappings } from "fond/utils/permissions";
import { searchCollectionByKey } from "fond/utils/search";

import { apiSlice } from "./apiSlice";

export type GetFoldersResponse = {
  Items: Folder[];
};

export const foldersAdapter = createEntityAdapter<Folder>({
  selectId: (entity: Folder): string => entity.ID,
});
const foldersInitialState = foldersAdapter.getInitialState();

/**
 * Folders API Slice
 */
export const folderSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getFolder: build.query({
      query: (folderId: string) => `/v2/folders/${folderId}`,
      providesTags: (result) => (result ? [{ type: "Folder", id: result.ID }] : []),
    }),
    getFolders: build.query({
      query: (params: { expanded: boolean } = { expanded: true }) => `/v2/folders?expanded=${params.expanded.toString()}`,
      transformResponse: (response: GetFoldersResponse) => {
        return foldersAdapter.setAll(
          foldersInitialState,
          response.Items.map((folder) => ({ ...folder, EntityType: "folder" }))
        );
      },
      providesTags: (result) =>
        result
          ? [...result.ids.map((id) => ({ type: "Folder" as const, id: id })), { type: "Folder", id: "LIST" }]
          : [{ type: "Folder", id: "LIST" }],
    }),
    createFolder: build.mutation<Folder, { AccountID: string } & Pick<Folder, "Name" | "Description" | "ParentID">>({
      query: (folder) => ({
        url: "/v2/folders",
        method: "POST",
        body: { AccountID: folder.AccountID, ...pick(folder, ["Name", "Description", "ParentID"]) },
      }),
      invalidatesTags: [{ type: "Folder", id: "LIST" }],
    }),
    updateFolder: build.mutation<Folder, Pick<Folder, "ID" | "Name" | "Description">>({
      query: ({ ID, ...initialFolder }) => ({
        url: `/v2/folders/${ID}`,
        method: "PUT",
        body: pick(initialFolder, ["Name", "Description"]),
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Folder", id: arg.ID }],
    }),
    moveFolder: build.mutation<Folder, Pick<Folder, "ID" | "ParentID">>({
      query: ({ ID, ParentID }) => ({
        url: `/v2/folders/${ID}`,
        method: "PUT",
        body: { ParentID },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Folder", id: arg.ID },
        { type: "Permission", id: "LIST" },
      ],
    }),
    deleteFolder: build.mutation<undefined, Folder>({
      query: ({ ID, ...initialFolder }) => ({
        url: `/v2/folders/${ID}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Folder", id: "LIST" },
        { type: "Folder", id: arg.ID },
      ],
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useGetFolderQuery,
  useGetFoldersQuery,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useUpdateFolderMutation,
  useMoveFolderMutation,
} = folderSlice;

/**
 * Selectors
 */
const selectFoldersResult = folderSlice.endpoints.getFolders.select(undefined);
const selectFoldersData = createSelector(selectFoldersResult, (foldersResult) => foldersResult.data);

export const { selectAll: selectAllFolders, selectById: selectFolderById } = foldersAdapter.getSelectors(
  (state: Store) => selectFoldersData(state) ?? foldersInitialState
);

/**
 * Returns all folders with the specified ParentID
 */
export const selectFoldersByParentId = createSelector(
  [selectAllFolders, (state: Store, parentId: string | null | undefined) => parentId],
  (folders, parentId): Folder[] => {
    return folders.filter((folder) => folder.ParentID === (parentId || null));
  }
);

/**
 * Returns all ancestor folders of an item
 */
export const getFolderAncestry = createSelector(
  [selectFoldersData, (state: Store, folderId: string | undefined) => folderId],
  (folders, folderId) => {
    const parents: Folder[] = [];

    const getFolder = (id: string) => {
      const parent: Folder | undefined = folders?.entities[id];

      if (parent) {
        parents.push(parent);
        if (parent.ParentID) {
          getFolder(parent.ParentID);
        }
      }
    };

    if (folderId) getFolder(folderId);

    return parents;
  }
);

/**
 * Return folders within the specified account with the required permissions to perform Action.
 *
 * Generates the Path property matching the same permission
 *
 * MultiProject folders are filtered out as they are never a legal target of any action.
 */
const filterFolders = (folders: Folder[], action: Actions, accountId: string): Folder[] => {
  return folders
    .filter(
      (folder) =>
        // filter out any folders that the current user is not allowed to write to
        mappings.get(action)?.includes(folder.Permission.Level) &&
        // filter out any folders that aren't in the same account as the resource being moved
        folder.Account.ID === accountId &&
        // filter out any multi-project folders
        folder.MultiProject === null
    )
    .map((folder) => ({ ...folder, Path: getPath(folders, folder.ID) }));
};

/**
 * Return folders within the project's account with the required permissions.
 */
export const selectFoldersForAddingProject = createSelector(
  [selectAllFolders, (_: Store, project: ProjectPreview) => project.Account.ID],
  (folders: Folder[], accountId: string): Folder[] => filterFolders(folders, Actions.PROJECT_ADD, accountId)
);

/**
 * Return folders within the report's account with the required permissions.
 */
export const selectFoldersForReportMove = createSelector(
  [selectAllFolders, (_: Store, report: Report) => report.Account.ID],
  (folders: Folder[], accountId: string): Folder[] => filterFolders(folders, Actions.REPORT_MOVE, accountId)
);

/**
 * Return folders within the folder's account with the required permissions.
 */
export const selectFoldersForFolderMove = createSelector(
  [selectAllFolders, (_: Store, folder: Folder) => folder.Account.ID],
  (folders: Folder[], accountId: string): Folder[] => filterFolders(folders, Actions.FOLDER_ADD, accountId)
);

export const selectFoldersMatchingSearchKey = createSelector(
  [selectAllFolders, (_: Store, searchKey: string) => searchKey],
  (folders: Folder[], searchKey: string): Folder[] => searchCollectionByKey(folders, searchKey, "Name")
);
