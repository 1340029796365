import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import _ from "lodash";
import mapboxgl from "mapbox-gl";

import { getCurrentUser, getToken } from "fond/cognito/aws_cognito_utils";
import { ACCOUNT_CONTEXT } from "fond/constants";
import { getImpersonatedUser } from "fond/impersonate";
import { layerIdToSolverParameter } from "fond/layers";
import {
  Attachment,
  Comment,
  CommentState,
  FeaturePropertiesProps,
  geoSpatialConvertProps,
  LayerId,
  Reply,
  RequestInput,
  Store,
  uploadFileToS3Props,
  UploadVersionLayersProps,
} from "fond/types";
import { iterItems, makeQueryString } from "fond/utils";

import { selectCurrentAccount } from "./accountSlice";
import { fondServiceURL } from "./apiSlice";
import { makeXHRRequest } from "./xhr";

export * from "./accountSlice";
export * from "./allocationSlice";
export * from "./apiSlice";
export * from "./architecturesSlice";
export * from "./areaSelectSlice";
export * from "./attributesSlice";
export * from "./attachmentsSlice";
export * from "./bomSlice";
export * from "./customWorkflowSlice";
export * from "./draftSlice";
export * from "./exportsSlice";
export * from "./folderSlice";
export * from "./groupsSlice";
export * from "./iconSlice";
export * from "./invitationSlice";
export * from "./layersSlice";
export * from "./layoutSlice";
export * from "./multiProjectsSlice";
export * from "./permissionSlice";
export * from "./projectSlice";
export * from "./reportSlice";
export * from "./stripeSlice";
export * from "./styleSlice";
export * from "./userSlice";
export * from "./versionsSlice";

const JSON_HEADER = new Headers({ "Content-Type": "application/json" });

// see fond_service/exceptions.py for list of error codes
export const fondServiceErrorCodes = {
  tooManyFeatures: "TooManyFeatures",
  invalidGeometryExtent: "InvalidGeometryExtent",
};

/**
 * Generic API GET function
 */
export async function get(url: string): Promise<Request> {
  const headers = await headersCreator.createHeaders();
  return new Request(`${fondServiceURL}${url}`, { headers: headers });
}

type RequestOptions = {
  isFormData?: boolean;
};

/**
 * Generic API POST function
 */
export async function post(url: string, data: any, options?: RequestOptions): Promise<Request> {
  const headers = await headersCreator.createHeaders(options?.isFormData === true ? undefined : JSON_HEADER);
  const init = {
    headers: headers,
    method: "POST",
    body: JSON.stringify(data),
  };

  return new Request(`${fondServiceURL}${url}`, init);
}

/**
 * Generic API PUT function
 */
export async function put(url: string, data: any, options?: RequestOptions): Promise<Request> {
  const headers = await headersCreator.createHeaders(options?.isFormData === true ? undefined : JSON_HEADER);
  const init = {
    headers: headers,
    method: "PUT",
    body: JSON.stringify(data),
  };

  return new Request(`${fondServiceURL}${url}`, init);
}

/**
 * Generic API PUT function
 */
export async function patch(url: string, data: any, options?: RequestOptions): Promise<Request> {
  const headers = await headersCreator.createHeaders(options?.isFormData === true ? undefined : JSON_HEADER);
  const init = {
    headers: headers,
    method: "PATCH",
    body: JSON.stringify(data),
  };
  return new Request(`${fondServiceURL}${url}`, init);
}

/**
 * Generic API DELETE function
 */
export async function del(url: string): Promise<Request> {
  const headers = await headersCreator.createHeaders();
  const init = {
    headers: headers,
    method: "DELETE",
  };

  return new Request(`${fondServiceURL}${url}`, init);
}

/* Data API URLs */

/**
 * The URL for updating the data (GeoJSON) for one of a project's layers.
 */
export function getFileUploadURL(versionId: string): string {
  return `${fondServiceURL}/v2/versions/${versionId}/layers`;
}

/**
 * Creates a Headers object with an authorisation header containing the cognito JWT access token.
 * @returns {Headers}
 */
async function createRequestHeadersWithAuth(
  initialHeaders?: Headers,
  api?: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">
): Promise<Headers> {
  // if initialHeaders is provided, create a new headers object from it so we don't modify the original
  let headers = initialHeaders ? new Headers(Object.fromEntries(initialHeaders.entries())) : new Headers();
  const currentUser = getCurrentUser();
  if (currentUser !== null) {
    headers.append("Authorization", `Bearer ${await getToken(currentUser)}`);
  }

  const impersonate = getImpersonatedUser();
  if (impersonate != null) {
    headers.append("impersonate", impersonate);
  }

  // tries to read selected account from local storage, otherwise uses original account from user details
  const accountId = api && selectCurrentAccount(api.getState() as Store)?.ID;
  if (api && accountId) {
    headers.append(ACCOUNT_CONTEXT, `${accountId}`);
  }

  return headers;
}

/**
 * Returns the same headers as above but as a dictionary suitable for passing
 * into `XHRWrapper`.
 */
export async function fondXHRHeaders(): Promise<any> {
  const impersonate = getImpersonatedUser();
  return {
    Authorization: `Bearer ${await getToken(getCurrentUser())}`,
    ...(impersonate != null ? { impersonate: impersonate } : null),
  };
}

/**
 * Always access the header creator method indirectly via this object to
 * make it easy for tests to replace it. Ie, use `headersCreator.createHeaders()`
 * rather than `createRequestHeadersWithAuth()`.
 */
export const headersCreator = {
  createHeaders: createRequestHeadersWithAuth,
  createXHRHeaders: fondXHRHeaders,
};

export async function request({ method, path, query, data }: RequestInput): Promise<Request> {
  let initialHeaders;
  if (["PUT", "POST", "PATCH"].includes(method) && data != null) {
    initialHeaders = JSON_HEADER;
  }
  const queryString = query != null ? `?${makeQueryString(query)}` : "";
  const url = `${fondServiceURL}${path}${queryString}`;
  return new Request(url, {
    headers: await headersCreator.createHeaders(initialHeaders),
    method: method,
    body: data != null ? JSON.stringify(data) : null,
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function requestJson(method: string, path: string, data?: any, { query = null } = {}): Promise<any> {
  return new Promise(async (resolve, reject) => {
    fetch(await request({ method, path, query, data })).then(
      (response) => {
        const responseStatus = response.status;
        /* eslint yoda: ["error", "never", { "exceptRange": true }] */
        response.json().then(
          (json) => {
            if (200 <= responseStatus && responseStatus < 300) {
              resolve(json);
            } else {
              reject(json);
            }
          },
          () => {
            reject(new Error(JSON.stringify({ error: "not_json", response: response })));
          }
        );
      },
      (err) => {
        reject(new Error(JSON.stringify({ error: err })));
      }
    );
  });
}

export function updateFeatureProperties({ featureId, properties }: FeaturePropertiesProps): Promise<any> {
  return requestJson("PATCH", `/v2/features/${featureId}`, { Properties: properties });
}

export function revertMovedHubs(versionId: string): Promise<any> {
  return requestJson("POST", `/v2/versions/${versionId}/revert-hub-edits`);
}

export function regenerateCost(versionId: string): Promise<any> {
  return requestJson("POST", `/v2/versions/${versionId}/regenerate-cost`);
}

export async function getVersionBOM(versionId: string): Promise<any> {
  const response = await fetch(
    await request({
      method: "GET",
      path: `/v2/versions/${versionId}/bom`,
    })
  );
  if (response.status === 404) {
    return null;
  }
  if (response.status !== 200) {
    console.error("Export bom failed", response);
    throw new Error("Export bom failed");
  }
  return response;
}

/**
 * Uploads one or more input layers to a project. Creates, sends and returns an
 * `XHRWrapper`.
 *
 * @param {mapping of layer ids to lists of `File`s} layers
 *   Eg. {inSpan: [File1, File2, File3], inPole: [File4, File5, File6]}
 * @param {String (UploadSources)} source
 * @param {GeoJSON Polygon} (optional) uploadedArea the polygon selected with polygon select,
 *   if polygon select was used
 * @param {Object} (optional) callbacks to add to the XMLHTTPRequest.
 *   If provided, use the following keys to specify callbacks:
 *     onProgress: (ProgressEvent) => any
 *       - called at regular intervals during the upload (XMLHTTPRequest 'progress' event)
 *     onComplete: (ProgressEvent, responseText:string) => any
 *       - called when the upload is complete (XMLHTTPRequest 'load' event)
 *     onError: (ProgressEvent) => any
 *       - called if the upload fails (XMLHTTPRequest 'error' event)
 *     onAbort: (ProgressEvent) => any
 *       - called if the upload is aborted (eg. by calling the `abort` method
 *       on the `XMLHTTPRequest`. (XMLHTTPRequest 'abort' event)
 *
 *   ProgressEvent is documented here:
 *   https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent
 * @param {Object} xhrEvents - passed through to makeXHRRequest
 * @returns {XHRWrapper}
 */
export async function uploadVersionLayers({
  versionId,
  layers,
  source,
  uploadedArea = null,
  convertToDualSided = false,
  xhrEvents,
  addressTypeField,
}: UploadVersionLayersProps): Promise<any> {
  const solverParameterFiles = _.mapKeys(layers, (value, key) => layerIdToSolverParameter(key as LayerId));
  const data = {
    ...layerFilesToPostParams(solverParameterFiles),
    convertToDualSided: JSON.stringify(convertToDualSided),
  };
  if (uploadedArea != null) {
    data.UploadedArea = JSON.stringify(uploadedArea);
  }

  const queryParams = { source } as any;
  if (addressTypeField) queryParams.address_type_field = addressTypeField;
  return makeXHRRequest({
    method: "PATCH",
    url: getFileUploadURL(versionId),
    query: queryParams,
    data: data,
    xhrEvents: xhrEvents,
  });
}

/**
  Converts a mapping of layer IDs to lists of files to a form suitable for
  sending via a multipart/form-data request (ie. where we don't have support
  for arbitrarily-structured JSON requests; we only have have key/value pairs
  where the keys are strings and the values are strings or files).

  Example:

    Input:
      {
        'layer1': [File1, File2],
        'layer2': [File3, File4]
      }

    Output:

      {
        'file_0': File1,
        'file_1': File2,
        'file_2': File3,
        'file_3': File3,
        'solverParameters': JSON.stringify({
          'layer1': ['file_0', 'file_1'],
          'layer2': ['file_2', 'file_3'],
        })
      }
 */

export function layerFilesToPostParams(layers: { [key: string]: File[] }): any {
  const data: any = {};
  const solverParameters: any = {};
  let i = 0;

  for (let [layerId, layerFiles] of iterItems(layers)) {
    if (layerFiles != null) {
      // eslint-disable-next-line no-multi-assign
      let layerFileIds = (solverParameters[layerId] = []);
      for (let file of layerFiles) {
        data[`file_${i}`] = file;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layerFileIds.push(i);
        i += 1;
      }
    }
  }
  data.solverParameters = JSON.stringify(solverParameters);

  return data;
}

export function geospatialConvert({ selectedFiles, xhrEvents }: geoSpatialConvertProps): Promise<any> {
  const { solverParameters, ...data } = layerFilesToPostParams(selectedFiles);
  return makeXHRRequest({
    method: "POST",
    url: `${fondServiceURL}/v2/geospatial/convert`,
    data: {
      ...data,
      format: "geojson",
    },
    xhrEvents: xhrEvents,
  });
}

/**
  Url to retrieve a vector tile from FOND service, for viewing parcels, addresses or streets.
  The default z, x, y values are designed for use with mapbox vector tile sources.
  When used as a vector tile source, mapbox will calculate z, x, y from the map extent.
 */
export function makeFondVectorTileURL(view: string, z = "{z}", x = "{x}", y = "{y}"): string {
  return `${fondServiceURL}/v2/area-select/tiles/${view}/${z}/${x}/${y}`;
}

/**
  Url to retrieve a vector tile from all the project's version layers.
  The fond service route will return a single tile containing multiple
  layers, identified by their layer_key.
 */
export const makeVersionVectorTileURL = (versionId: string): string => {
  return `${fondServiceURL}/v2/versions/${versionId}/tile/{z}/{x}/{y}`;
};

/**
 * Upload file to S3 using pre-signed POST data.
 * See getAttachmentsPresignedPost for an example of retrieving a presigned-post.
 * @param {Url: string, Fields: {...}} presignedPost.
 * @param {File} file. The file to be uploaded to the key authenticated by the presigned post.
 * @param onProgress(progress: number): void. A callback that processes the file upload percentage.
 * @param onComplete(): void. A callback that runs on successful upload completion.
 * @param onError(): void. A callback that runs on upload error.
 * @returns {XMLHttpRequest}
 */
export const uploadFileToS3 = ({
  presignedPost,
  file,
  onProgress = (progress) => {},
  onComplete = () => {},
  onError = (error) => {},
}: uploadFileToS3Props): any => {
  const formData = new FormData();
  // Add the presigned post fields.
  for (let [key, val] of Object.entries(presignedPost.Fields)) {
    formData.append(key, val as string | Blob);
  }

  // Add the file object.
  formData.append("file", file);
  formData.append("content-type", file.type || "application/octet-stream"); // assume the closest thing to a default type.
  const xhr = new XMLHttpRequest();

  xhr.onerror = (event: ProgressEvent<EventTarget>) => onError(new Error(xhr.response));

  // 204 No content is issued on success.
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 204) {
        onComplete();
      } else {
        onError(new Error(xhr.response));
      }
    }
  };

  // Track the upload progress
  xhr.upload.onprogress = (e) => {
    if (e.lengthComputable) {
      const progressPercent = Math.min(100, Math.round((e.loaded / file.size) * 100));
      onProgress(progressPercent);
    }
  };

  xhr.open("POST", presignedPost.Url);
  xhr.send(formData);
  return xhr;
};

/**
 * Loads an array of Comment for a particular project
 * @param {string} projectId
 * @returns {Promise>}
 */
export const getComments = async (projectId: string): Promise<{ Comments: Comment[] }> => {
  return fetch(await get(`/v2/comments?project_id=${projectId}`)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error(`There was a problem requesting this projects comments: ${response.statusText}`));
    }
    return Promise.resolve(response.json());
  });
};

/**
 * Creates a new Comment
 * @param data the content of the Comment
 * @returns {Promise} A promise that contains the newly created Comment
 */
export const addComment = async (data: any): Promise<Comment> => {
  return fetch(await post(`/v2/comments`, data)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText));
    }
    return Promise.resolve(response.json());
  });
};

/**
 * Deletes a Comment
 * @param {string} commentId The ID of the comment to be deleted
 * @returns {Promise} A promise that contains the recently deleted Comment
 */
export const deleteComment = async (commentId: string): Promise<void> => {
  return fetch(await del(`/v2/comments/${commentId}`)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText));
    }
    return Promise.resolve();
  });
};

/**
 * Updates an existing Comment
 * @param {string} commentId The ID of the comment to be updated
 * @param data the content of the Comment
 * @returns {Promise} A promise that contains the newly updated Comment
 */
export const updateComment = async (commentId: string, data: any): Promise<Comment> => {
  return fetch(await put(`/v2/comments/${commentId}`, data)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText));
    }
    return Promise.resolve(response.json());
  });
};

/**
 * Resolves the Comment
 * @param {string} commentId The ID of the comment to be resolved
 * @returns {Promise} A promise that contains the newly resolved Comment
 */
export const resolveComment = async (commentId: string, commentState: CommentState): Promise<Comment> => {
  return fetch(await patch(`/v2/comments/${commentId}`, { State: commentState })).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText));
    }
    return Promise.resolve(response.json());
  });
};

/**
 * Creates a new Reply
 * @param {string} commentId The ID of the Comment the Reply is related to
 * @param data The content of the Reply
 * @returns {Promise} A promise that contains the newly created Reply
 */

export const addReply = async (commentId: string, data: any): Promise<Reply> => {
  return fetch(await post(`/v2/comments/${commentId}/reply`, data)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText));
    }
    return Promise.resolve(response.json());
  });
};

/**
 * Deletes a Reply
 * @param {string} replyId The ID of the Reply to be deleted
 * @returns {Promise} A promise that contains the recently deleted Reply
 */
export const deleteReply = async (replyId: string): Promise<void> => {
  return fetch(await del(`/v2/comment-replies/${replyId}`)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText));
    }
    return Promise.resolve();
  });
};

/**
 * Updates an existing Reply
 * @param {string} replyId The ID of the Reply to be updated
 * @param data the content of the Reply
 * @returns {Promise} A promise that contains the newly updated Reply
 */
export const updateReply = async (replyId: string, data: any): Promise<Reply> => {
  return fetch(await put(`/v2/comment-replies/${replyId}`, data)).then((response) => {
    if (response.status !== 200) {
      return Promise.reject(new Error(response.statusText));
    }
    return Promise.resolve(response.json());
  });
};

/**
 * Load an array of Attachments for a project
 * @param {string} projectId
 * @returns {Promise}
 */
export async function getAttachments(projectId: string, layerFeatureId?: string | null): Promise<{ Items: Attachment[] }> {
  const baseUrl = `/v2/attachments?project_id=${projectId}`;
  const url = layerFeatureId ? `${baseUrl}&layer_feature_id=${layerFeatureId}` : baseUrl;
  return fetch(await get(url)).then(async (response) => {
    if (response.status !== 200) {
      const { message } = await response.json();
      return Promise.reject(new Error(message || `There was a problem requesting this projects attachments: ${response.statusText}`));
    }
    return Promise.resolve(response.json());
  });
}

/**
 * Create a new Attachhment
 * @param {AttachmentPostBody} data
 * @returns {Promise} A promise that contains the newly created Attachment
 */
export const addAttachment = async (data: any): Promise<Request> => {
  return fetch(await post(`/v2/attachments`, data)).then(async (response) => {
    if (response.status !== 200) {
      const { message } = await response.json();
      return Promise.reject(new Error(message || response.statusText));
    }
    return Promise.resolve(response.json());
  });
};

/**
 * Update an existing Attachment
 * @param {Attachment} data
 * @returns {Promise} A promise that contains the updated Attachment
 */
export const updateAttachment = async (data: any): Promise<Request> => {
  return fetch(await patch(`/v2/attachments/${data.ID}`, _.pick(data, ["Name", "MimeType", "Size", "Uploaded"]))).then(async (response) => {
    if (response.status !== 200) {
      const { message } = await response.json();
      return Promise.reject(new Error(message || response.statusText));
    }
    return Promise.resolve(response.json());
  });
};

/**
 * Delete an Attachment. The requesting user must have WRITE access to the attachments project.
 * @param {string} attachmentId The ID of the attachment to be deleted.
 * @returns {Promise} A promise that resolve on successful response.
 */
export const deleteAttachment = async (attachmentId: string): Promise<any> => {
  return fetch(await del(`/v2/attachments/${attachmentId}`)).then(async (response) => {
    if (response.status !== 200) {
      const { message } = await response.json();
      return Promise.reject(new Error(message || response.statusText));
    }
    return Promise.resolve();
  });
};

/**
 * Get the presigned post data for uploading an attachment.
 * @param {string} attachmentId the ID of the project that the attachment is to be uploaded to.
 * @returns {Promise}
 */
export async function getAttachmentsPresignedPost(attachmentId: string): Promise<Request> {
  return fetch(await get(`/v2/attachments/${attachmentId}/presigned-upload`)).then(async (response) => {
    if (response.status !== 200) {
      const { message } = await response.json();
      return Promise.reject(new Error(message || response.statusText));
    }
    return Promise.resolve(response.json());
  });
}

/**
 * Load a feature.
 * @param {string} featureId the ID of the feature to be loaded.
 * @returns {Promise}
 */
export async function getFeature(featureId: string): Promise<mapboxgl.MapboxGeoJSONFeature> {
  return fetch(await get(`/v2/features/${featureId}`)).then(async (response) => {
    if (response.status !== 200) {
      const { message } = await response.json();
      return Promise.reject(new Error(message || response.statusText));
    }
    return Promise.resolve(response.json());
  });
}
