import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AddBox, Close, Delete, Layers, Segment, Visibility, VisibilityOff } from "@mui/icons-material";
import { Divider, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDebouncedCallback } from "use-debounce";

import {
  hydrateLayer,
  selectDraftConfigEntities,
  selectVersionMlcId,
  useCreateStyleConfigMutation,
  useCreateSublayerConfigMutation,
  useDeleteLayerConfigMutation,
  useDeleteSublayerConfigMutation,
  useUpdateLayerConfigMutation,
  useUpdateSublayerConfigMutation,
} from "fond/api";
import { setSelectedId } from "fond/redux/styles";
import { Classify, NewStyle } from "fond/styleEditor";
import { OnClickInput } from "fond/styleEditor/StyleHeader/styleHeader.styles";
import { LayerConfig, LayerConfigHydrated, LayerStyle, SublayerConfig, SublayerConfigHydrated } from "fond/types/ProjectLayerConfig";
import { makeUuid } from "fond/utils";
import { useAppDispatch } from "fond/utils/hooks";
import { ConfirmModal } from "fond/widgets";

import { fromFondStyleType } from "../helper";
import { IFormData } from "../NewStyle/NewStyle";

import { Actions, Container, IconButton } from "./layerHeader.styles";

interface IProps {
  layerConfig: LayerConfig | SublayerConfig;
}

export const LayerHeader: React.FC<IProps> = ({ layerConfig }: IProps) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const mapLayerConfigId = useSelector(selectVersionMlcId);
  const [openAdd, setOpenAdd] = useState(false);
  const [openClassify, setOpenClassify] = useState(false);
  const [confirmDeleteLayer, setConfirmDeleteLayer] = useState(false);
  const { Type, ID } = layerConfig;
  const [updateLayerConfig] = useUpdateLayerConfigMutation();
  const [deleteLayerConfig] = useDeleteLayerConfigMutation();
  const [createSublayerConfig] = useCreateSublayerConfigMutation();
  const [updateSublayerConfig] = useUpdateSublayerConfigMutation();
  const [deleteSublayerConfig] = useDeleteSublayerConfigMutation();
  const [createStyleConfig] = useCreateStyleConfigMutation();
  const draft = useSelector(selectDraftConfigEntities);

  const handleOnClose = () => {
    dispatch(setSelectedId(""));
  };

  const handleOnAddClick = () => {
    setOpenAdd(true);
  };

  const handleOnClassifyClick = () => {
    setOpenClassify(true);
  };

  const onChange = useDebouncedCallback((values: LayerConfig | SublayerConfig) => {
    if (values.Type === "LAYER") {
      const newLayer = hydrateLayer(draft, values) as LayerConfigHydrated;
      updateLayerConfig({ layerConfig: newLayer, mapLayerConfigId: mapLayerConfigId }).catch(() => {
        enqueueSnackbar("Failed to save draft changes", { variant: "error" });
      });
    } else if (values.Type === "SUBLAYER") {
      const newSublayer = hydrateLayer(draft, values) as SublayerConfigHydrated;
      updateSublayerConfig({ sublayer: newSublayer, mapLayerConfigId: mapLayerConfigId }).catch(() => {
        enqueueSnackbar("Failed to save draft changes", { variant: "error" });
      });
    }
  }, 500);

  const handleOnRename = (name: string) => {
    onChange({ ...layerConfig, Label: name });
  };

  const handleOnVisibility = () => {
    onChange({ ...layerConfig, IsVisible: !layerConfig.IsVisible });
  };

  const handleOnAddSublayer = async () => {
    if (layerConfig.Type !== "LAYER") return;
    const id = makeUuid();
    const newSublayerConfig: SublayerConfig = {
      ID: id,
      Type: "SUBLAYER",
      Label: "Sublayer",
      IsVisible: true,
      ParentID: ID,
      Styles: [],
      FilterConfiguration: null,
      Position: layerConfig.Children.length,
      Key: layerConfig.Key,
      GeometryType: layerConfig.GeometryType,
    };

    try {
      const sublayer = await createSublayerConfig({ newSublayerConfig, mapLayerConfigId }).unwrap();
      dispatch(setSelectedId(sublayer.ID));
    } catch {
      enqueueSnackbar("Failed to save draft changes", { variant: "error" });
    }
  };

  const handleOnDeleteSublayer = async () => {
    if (layerConfig.Type !== "SUBLAYER") return;

    // Disallow deletion of sublayer if not empty
    if (layerConfig.Styles.length > 0) {
      enqueueSnackbar("Sublayer is not empty. Please delete all styles & try again.", {
        variant: "error",
      });
      return;
    }

    try {
      await deleteSublayerConfig({ sublayer: layerConfig, mapLayerConfigId: mapLayerConfigId }).unwrap();
      dispatch(setSelectedId(""));
    } catch (error) {
      enqueueSnackbar("Failed to save draft changes", { variant: "error" });
    }
  };

  const handleOnDeleteLayer = async () => {
    if (layerConfig.Type !== "LAYER") return;

    try {
      await deleteLayerConfig({ layerConfig: layerConfig, mapLayerConfigId: mapLayerConfigId }).unwrap();
      dispatch(setSelectedId(""));
    } catch (error) {
      enqueueSnackbar("Failed to save draft changes", { variant: "error" });
    }
  };

  /**
   * Creates a new style under the layerConfig the user has selected
   */
  const handleOnSubmit = ({ name, type }: IFormData) => {
    const id = makeUuid();
    const mapboxType = fromFondStyleType(type);

    let newStyle: LayerStyle = {
      Type: "STYLE",
      ID: id,
      ConfigurationID: ID,
      ConfigurationType: Type === "SUBLAYER" ? "SUBLAYER" : "LAYER",
      Name: name,
      MapboxStyle: { type: mapboxType },
      GlobalPosition: -1,
      Position: layerConfig.Styles.length,
      RawStyles: { Type: type },
    };
    createStyleConfig({ newStyleConfig: newStyle, mapLayerConfigId: mapLayerConfigId })
      .unwrap()
      .then((response) => {
        dispatch(setSelectedId(response.ID));
      })
      .catch(() => {
        enqueueSnackbar("Failed to save draft changes", { variant: "error" });
      });

    setOpenAdd(false);
  };

  /**
   * Update the selected layer with the new Sublayers based on the classification
   */

  return (
    <>
      <Container>
        <OnClickInput value={layerConfig.Label} onCommit={handleOnRename} />
        <Actions display="flex">
          <Actions display="flex">
            <Divider orientation="vertical" />
            <Tooltip title="Set default visibility">
              <IconButton data-testid="style-layer-visibility">
                {layerConfig.IsVisible ? <Visibility onClick={handleOnVisibility} /> : <VisibilityOff onClick={handleOnVisibility} />}
              </IconButton>
            </Tooltip>
            {Type === "LAYER" && (
              <>
                <Tooltip title="Classify">
                  <IconButton onClick={handleOnClassifyClick} data-testid="classify-layer">
                    <Segment />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add a new sublayer">
                  <IconButton onClick={handleOnAddSublayer} data-testid="add-new-sublayer">
                    <Layers />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title="Add a new style">
              <IconButton data-testid="add-style">
                <AddBox onClick={handleOnAddClick} />
              </IconButton>
            </Tooltip>
            {Type === "SUBLAYER" && (
              <>
                <Divider orientation="vertical" />
                <Tooltip title="Delete sublayer">
                  <IconButton onClick={handleOnDeleteSublayer}>
                    <Delete color="error" />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Actions>
          {Type === "LAYER" && (
            <>
              <Divider orientation="vertical" />
              <Tooltip title="Delete layer">
                <IconButton onClick={() => setConfirmDeleteLayer(true)}>
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Divider orientation="vertical" />
          <IconButton onClick={handleOnClose}>
            <Close />
          </IconButton>
        </Actions>
      </Container>
      {openAdd && <NewStyle onClose={() => setOpenAdd(false)} onSubmit={handleOnSubmit} />}
      {openClassify && Type === "LAYER" && (
        <Classify layerConfig={layerConfig} onClose={() => setOpenClassify(false)} mapLayerConfigId={mapLayerConfigId} />
      )}
      {confirmDeleteLayer && (
        <ConfirmModal
          open
          header={`Delete "${layerConfig.Label}" layer`}
          confirmText="Delete"
          content={<Typography variant="body2">Are you sure you want to delete the layer and all its content?</Typography>}
          onConfirm={() => {
            handleOnDeleteLayer();
          }}
          onCancel={() => {
            setConfirmDeleteLayer(false);
          }}
        />
      )}
    </>
  );
};

export default LayerHeader;
