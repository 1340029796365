/* eslint-disable import/prefer-default-export */
import dayjs from "dayjs";

export const dateDisplayFormat = "DD MMM YYYY"; // formatted string looks like 05 Apr 2999

export const relativeDate = (value: string): string => {
  const date = dayjs(value, "YYYY-MM-DD h:mm:ss");

  // To allow for slight differences in time between server and local
  // for future events to confirm they are at least 1 minute out.
  if (date.isAfter(dayjs()) && date.diff(dayjs(), "minute") > 1) {
    return "Coming up";
  } else if (date.isSame(dayjs(), "day")) {
    return "Today";
  } else if (date.isSame(dayjs().subtract(1, "day"), "day")) {
    return "Yesterday";
  } else if (date.isSame(dayjs(), "week")) {
    return "Earlier this week";
  } else if (date.isSame(dayjs().subtract(1, "week"), "week")) {
    return "Last week";
  } else if (date.isSame(dayjs(), "month")) {
    return "Earlier this month";
  } else if (date.isSame(dayjs().subtract(1, "month"), "month")) {
    return "Last month";
  } else if (date.isSame(dayjs(), "year")) {
    return "Earlier this year";
  } else if (date.isSame(dayjs().subtract(1, "year"), "year")) {
    return "Last year";
  } else {
    return "Older";
  }
};

export const formatDateString = (value: string | null): string => {
  return dayjs(value).format(dateDisplayFormat); // formatted string looks like 05 Apr 2023
};

export const formatDateStringWithTimezone = (value: string): string => {
  const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();
  return dayjs.utc(value).tz(timeZone).format(dateDisplayFormat);
};

/**
 * Return string formatted date as hh:mm A (e.g. 7:42 am) if the date is today's date.
 * Otherwise format date as DD MM YYYY (18 Jan 2023).
 *
 * @param value - timezoned datetime as object or string or number (time since epoch)
 * @returns The string object representing a time or a date
 */
export const formatToTimeOrDate = (value: string | number): string => {
  const date = dayjs.utc(value);
  return date.local().isSame(dayjs(), "day") ? date.local().format("h:mm A") : date.local().format("DD MMM YYYY");
};

/**
 * Format single digit number by adding a leading 0.
 */
export const formatMonth = (value: number): string => {
  return String(value).padStart(2, "0");
};

export type DurationUnit = "year" | "month" | "week" | "day" | "hour" | "minute" | "second";
export type UnitDisplayStyle = "long" | "short" | "narrow";

/**
 *
 * @param duration - The duration to format
 * @param unit - the unit to use
 * @param display - the display style for the unit, defaults to "long"
 * @returns the formatted duration
 * @example
 *   formatDuration(1234, "minute") // "1,234 minutes"
 *   formatDuration(1234, "minute", "short") // "1,234 min"
 *   formatDuration(1234, "minute", "narrow") // "1,234m"
 *
 */
export function formatDuration(duration: number | undefined | null, unit: DurationUnit, display: UnitDisplayStyle = "long"): string {
  if (duration === null || duration === undefined || Number.isNaN(duration)) return "-";
  return Intl.NumberFormat("en", { style: "unit", unit: unit, unitDisplay: display }).format(duration);
}

/**
 * Format a given number of months as years and months
 * @param months - The number of months to format
 * @returns The formatted string
 * @example
 *  formatMonthsAsYears(12) // "1 year"
 *  formatMonthsAsYears(25) // "2 years 1 month"
 */
export function formatMonthsAsYears(months: number | undefined | null): string {
  if (months == null || Number.isNaN(months) || months === 0) return "-";

  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  if (years === 0) {
    return `${formatDuration(remainingMonths, "month")}`;
  }
  if (remainingMonths === 0) {
    return `${formatDuration(years, "year")}`;
  }
  return `${formatDuration(years, "year")} ${formatDuration(remainingMonths, "month")}`;
}
