/* eslint-disable import/prefer-default-export */
import React from "react";
import { History } from "@mui/icons-material";
import { Box, Theme, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { VersionsList } from "fond/project/versions";

import { ITabProps } from "./factory";

const useCustomStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  groupRow: {
    height: 32,
    position: "sticky",
    top: 0,
    paddingLeft: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconGroup: {
    borderRadius: 4,
    "&.active": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    "&:hover.active": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  text: {
    color: theme.palette.secondary.main,
    fontSize: "0.85rem",
  },
  label: {
    flex: "1 0 auto",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: 50,
    whiteSpace: "nowrap",
  },
  field: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 500,
  },
  timestamp: {
    color: theme.palette.action.active,
  },
}));

/**
 * Main widget component that determines the content rendered within the tab
 */
export const Widget: React.FC<ITabProps> = () => {
  const classes = useCustomStyles();

  return (
    <Box className={classes.root}>
      <Box pb={1} flexGrow={1}>
        <VersionsList />
      </Box>
    </Box>
  );
};

/**
 * Icon component used by the iconFactory to generate the tabs left icon adornment.
 */
export const Icon: React.FC<ITabProps> = ({ node }: ITabProps) => (
  <Tooltip title="Versions" arrow placement="right">
    <History />
  </Tooltip>
);
