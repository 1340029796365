import { logErrorToBrowser, setIn } from "fond/utils";

let SUCCESS_TIMEOUT_MS = 1000;

export function getSuccessTimeoutMs(ms) {
  return SUCCESS_TIMEOUT_MS;
}

export function setSuccessTimeoutMs(ms) {
  SUCCESS_TIMEOUT_MS = ms;
}

export const AsyncOperationState = {
  executing: "executing",
  success: "success",
  failure: "failure",
  dismissed: "dismissed",
  notFound: "404",
};

export function makeAsyncActions(name) {
  return {
    INITIATE: `${name}/INITIATE`,
    SUCCESS: `${name}/SUCCESS`,
    FAILURE: `${name}/FAILURE`,
    SUCCESS_TIMEOUT_EXPIRED: `${name}/SUCCESS_TIMEOUT_EXPIRED`,
    DISMISS_ERROR: `${name}/DISMISS_ERROR`,
  };
}

export function makeAsyncActionCreator(asyncActions, getInitiateArgs, execute, success, failure) {
  function actionCreator(...actionArgs) {
    return async (dispatch, getState) => {
      dispatch({ type: asyncActions.INITIATE, ...getInitiateArgs(getState, ...actionArgs) });
      return execute(getState, ...actionArgs).then(
        (data) => {
          dispatch({ type: asyncActions.SUCCESS, data: data });
          if (success != null) {
            success(dispatch, getState, data);
          }
          setTimeout(() => dispatch({ type: asyncActions.SUCCESS_TIMEOUT_EXPIRED }), SUCCESS_TIMEOUT_MS);
        },
        (errorResponse) => {
          logErrorToBrowser(errorResponse);
          const error = errorResponse ? errorResponse.error : null;
          dispatch({ type: asyncActions.FAILURE, error: error });
          if (failure != null) {
            failure(dispatch, getState, error);
          }
        }
      );
    };
  }

  actionCreator.dismissError = () => ({ type: asyncActions.DISMISS_ERROR });

  return actionCreator;
}

export function makeAsyncReducer(asyncActions, key) {
  /**
   * `key` can be a string or an array of strings. If it's an array, we take it to be a path
   * and use `utils.setIn` to update the state.
   *
   * Eg:
   *    makeAsyncReducer(..., 'save')  -- updates state.save
   *    makeAsyncReducer(..., ['operations', 'save']) -- updates state.operation.save
   */
  const lookup = {
    [asyncActions.INITIATE]: AsyncOperationState.executing,
    [asyncActions.SUCCESS]: AsyncOperationState.success,
    [asyncActions.FAILURE]: AsyncOperationState.failure,
    [asyncActions.SUCCESS_TIMEOUT_EXPIRED]: AsyncOperationState.dismissed,
    [asyncActions.DISMISS_ERROR]: null,
  };

  return function reducer(state = null, action) {
    if (action != null && action.type in lookup) {
      return setIn(state, key, lookup[action.type]);
    } else {
      return state;
    }
  };
}
