import { useMemo } from "react";
import { useSelector } from "react-redux";
import { ColDef, GetRowIdParams, GridOptions, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import CheckIcon from "@mui/icons-material/Check";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAccount, useGetAccountUsersQuery } from "fond/api";
import { UserAccountAllocationResponse } from "fond/types";
import { capitalizeFirstLetter } from "fond/utils/strings";
import IconCellRenderer from "fond/widgets/AgGrid/cellRenderer/Icon";

import UserRowAction from "./UserRowAction";

interface IUseUserList {
  userAccountAllocations: UserAccountAllocationResponse[] | undefined;
  columns: ColDef[];
  gridOptions: GridOptions;
}

export const useUserList = (): IUseUserList => {
  const selectedAccount = useSelector(selectCurrentAccount);
  const { data } = useGetAccountUsersQuery(selectedAccount?.ID ?? skipToken);

  const gridOptions: GridOptions = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: true,
    paginationPageSize: 20,
    suppressMovableColumns: true,
    suppressRowClickSelection: true,
    domLayout: "autoHeight",
    getRowId: (params: GetRowIdParams) => params.data.ID,
  };

  const columns = useMemo(() => {
    const cols: ColDef[] = [
      // TODO(FND-250) add when info is available
      // {
      //   field: "FirstName",
      //   headerName: "First Name",
      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
      //   valueFormatter: () => "John",
      //   flex: 1,
      // },
      // {
      //   field: "LastName",
      //   headerName: "Last Name",
      //   valueFormatter: () => "Doe",
      //   flex: 1,
      // },
      {
        field: "User.Email",
        headerName: "Email",
        flex: 2,
        sort: "asc",
        menuTabs: ["filterMenuTab"],
        filter: "agTextColumnFilter",
      },
      {
        field: "License",
        headerName: "License",
        cellRenderer: IconCellRenderer,
        cellRendererParams: {
          icon: CheckIcon,
          iconProps: {
            color: "primary",
          },
        },
        suppressNavigable: true,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          valueFormatter: (params: ValueFormatterParams) => (params.value ? "Licensed" : "No License"),
        },
        menuTabs: ["filterMenuTab"],
        width: 100,
        cellClass: "vertically-align",
      },
      {
        field: "Role",
        headerName: "Role",
        width: 100,
        valueFormatter: (cell) => cell.value && capitalizeFirstLetter(cell.value),
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true,
          valueGetter: (params: ValueGetterParams) => params.data.Role[0].toUpperCase() + params.data.Role.slice(1),
        },
        menuTabs: ["filterMenuTab"],
      },
      // TODO(FND-253) add column when info is available
      // {
      //   field: "LastActive",
      //   headerName: "Last Active",
      //   width: 125,
      // },
      {
        colId: "actions",
        cellRenderer: (row: ICellRendererParams) => <UserRowAction userAccountAllocation={row.data as UserAccountAllocationResponse} />,
        type: "rightAligned",
        width: 100,
        sortable: false,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
      },
    ];
    return cols;
  }, []);

  return { userAccountAllocations: data, columns: columns, gridOptions: gridOptions };
};
