import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

import { HelpIcon, NumericInput } from "fond/widgets";

import { getSelectOptions } from "./template";

export { BomTagAutocomplete as BOMTagAutocomplete } from "./widgets/BomTagAutocomplete";

/**
 * Widgets for BOM rule parameters.
 *
 * Each widget should have a `value` prop (taking the value for the particular
 * param as it will appear in the rule object), and an `onChange` prop, which
 * should be called with an argument of the same type as in the `value` prop when
 * the value changes.
 */

const useMultiSelectStyles = makeStyles((theme) => ({
  chip: {
    margin: "0.25em",
  },
  noSplittersMessage: {
    fontStyle: "italic",
  },
}));

export function MultiSelect({ architecture, widgetSettings, value, onChange }) {
  const classes = useMultiSelectStyles();
  const values = getSelectOptions(widgetSettings, architecture);

  return (
    <div data-testid="select">
      {values.map((buttonValue, i) => {
        let id;
        let label;
        // `values` might be a list of numbers, in which case the ID and label of
        // each value is itself (ie. if `values` is [4, 8], then the first option
        // has an ID of 4 and a label of "4", and the second option has an ID of
        // 8 and a label of "8". Otherwise, items in the `values` list will
        // explicitly have their IDs and labels defined.
        if (typeof buttonValue === "number") {
          id = label = buttonValue;
        } else {
          id = buttonValue.ID;
          label = buttonValue.Label;
        }

        return (
          <Chip
            key={i}
            onClick={() => onChange(toggleValue(value, id))}
            className={classes.chip}
            data-testid="toggle-button"
            color={value.indexOf(id) !== -1 ? "primary" : "default"}
            label={label}
          />
        );
      })}

      {widgetSettings.Options.Type === "SplitRatio" && values.length === 0 && (
        <div className={classes.noSplittersMessage}>(Your architecture is not currently using splitters of this type)</div>
      )}
    </div>
  );
}

MultiSelect.propTypes = {
  architecture: PropTypes.object.isRequired,
  widgetSettings: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

function toggleValue(values, value) {
  if (values.indexOf(value) !== -1) {
    return values.filter((v) => v !== value);
  } else {
    return [...values, value];
  }
}

/**
 * `Text` and `Numeric` both have their props defined as in `getTextInputProps`.
 */
export function Text(props) {
  return <TextField {...getTextInputProps(props)} />;
}

export function Numeric(props) {
  const { endAdornment, ...rest } = props;
  return <NumericInput InputProps={{ endAdornment: endAdornment }} {...getTextInputProps(rest)} />;
}

Numeric.propTypes = {
  endAdornment: PropTypes.node,
};

Numeric.defaultProps = {
  endAdornment: null,
};

function getTextInputProps({ widgetSettings, value, onChange, ...other }) {
  const { "data-t-input": dataTInput, ...rest } = other;

  // filter out some non-DOM properties.
  const filterProps = ["architecture", "systemOfMeasurement", "error"];
  const domProps = Object.fromEntries(Object.entries(rest).filter(([key]) => !filterProps.includes(key)));

  return {
    value: value != null ? value : "",
    variant: "outlined",
    margin: "dense",
    onChange: (event) => onChange(event.target.value),
    inputProps: { "data-testid": dataTInput },
    ...domProps,
  };
}

const useRangeStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  left: {
    flex: "0 0 6em",
  },
  middle: {
    flex: 1,
    paddingLeft: theme.spacing(1),
  },
  right: {
    flex: "0 0 6em",
    paddingLeft: theme.spacing(3),
  },
}));

/**
  `value` is of the form {
    min: number,
    max: number,
    includeMin: boolean,
    includeMax: boolean
  }
 */
export function Range({ architecture, widgetSettings, value, onChange, validationResults, NumericInputProps, endAdornment }) {
  const classes = useRangeStyles();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.left}>
          <Typography variant="body2">Minimum</Typography>
        </div>
        <div className={classes.middle}>
          <NumericInput
            variant="outlined"
            margin="dense"
            value={value.min}
            onChange={(event) => onChange({ ...value, min: event.target.value })}
            InputProps={{
              endAdornment: endAdornment,
            }}
            {...NumericInputProps}
          />
        </div>
        <div className={classes.right}>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={value.includeMin} onChange={(event) => onChange({ ...value, includeMin: event.target.checked })} />
            }
            label={<Typography variant="body2">Inclusive</Typography>}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.left}>
          <Typography variant="body2">Maximum</Typography>
        </div>
        <div className={classes.middle}>
          <NumericInput
            variant="outlined"
            margin="dense"
            value={value.max}
            onChange={(event) => onChange({ ...value, max: event.target.value })}
            InputProps={{
              endAdornment: endAdornment,
            }}
            {...NumericInputProps}
          />
        </div>
        <div className={classes.right}>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={value.includeMax} onChange={(event) => onChange({ ...value, includeMax: event.target.checked })} />
            }
            label={<Typography variant="body2">Inclusive</Typography>}
          />
        </div>
      </div>
    </div>
  );
}

Range.propTypes = {
  architecture: PropTypes.object.isRequired,
  widgetSettings: PropTypes.object.isRequired,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    includeMin: PropTypes.bool,
    includeMax: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  validationResults: PropTypes.object.isRequired,
  NumericInputProps: PropTypes.object,
  endAdornment: PropTypes.node,
};

Range.defaultProps = {
  endAdornment: null,
  NumericInputProps: {},
};

const useIntegerRangeStyles = makeStyles((theme) => ({
  range: {
    display: "flex",
    flexDirection: "row",
  },
  word: {
    paddingTop: 12,
    paddingRight: theme.spacing(1),
  },
  numberInput: {
    paddingRight: theme.spacing(1),
    width: 100,
  },
  helpWidgetIcon: {
    paddingTop: 20,
    overflow: "visible",
  },
  helpPaper: {
    width: "35%",
  },
}));

export function IntegerRange({ architecture, widgetSettings, value, onChange, validationResults, NumericInputProps }) {
  const classes = useIntegerRangeStyles();
  if (!value.includeMax) {
    // eslint-disable-next-line no-param-reassign
    value = { ...value, max: Number(value.max) - 1, includeMax: true };
  }
  if (!value.includeMin) {
    // eslint-disable-next-line no-param-reassign
    value = { ...value, min: Number(value.min) + 1, includeMin: true };
  }

  return (
    <div>
      <div className={classes.range}>
        <Typography variant="h6" className={classes.word}>
          between
        </Typography>
        <NumericInput
          className={classes.numberInput}
          variant="outlined"
          margin="dense"
          value={value.min}
          placeholder="min"
          onChange={(event) => onChange({ ...value, min: event.target.value })}
          {...NumericInputProps}
        />
        <Typography variant="h6" className={classes.word}>
          and
        </Typography>
        <NumericInput
          className={classes.numberInput}
          variant="outlined"
          margin="dense"
          value={value.max}
          placeholder="max"
          onChange={(event) => onChange({ ...value, max: event.target.value })}
          {...NumericInputProps}
        />
        <HelpIcon
          classes={{ icon: classes.helpWidgetIcon, paper: classes.helpPaper }}
          helpText={
            "Range includes the minimum and maximum e.g. 'between 1 and 2 cables' will " +
            "sum the length of all path that is colocated with 1 cable or 2 cables"
          }
        />
      </div>
      <Typography variant="h6" data-testid="subheading">
        {widgetSettings.Subheading}
      </Typography>
    </div>
  );
}

IntegerRange.propTypes = {
  architecture: PropTypes.object.isRequired,
  widgetSettings: PropTypes.object.isRequired,
  value: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    includeMin: PropTypes.bool,
    includeMax: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  validationResults: PropTypes.object.isRequired,
  NumericInputProps: PropTypes.object,
  endAdornment: PropTypes.node,
};

IntegerRange.defaultProps = {
  NumericInputProps: {},
  endAdornment: null,
};
