import React, { useMemo } from "react";
import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import { Box, Button } from "@mui/material";
import * as Sentry from "@sentry/react";

import { LoadingButton } from "ui";

import { useCreateMultiProjectMutation } from "fond/api/multiProjectsSlice";
import { TextField } from "fond/form/fields";
import { TextFieldValue } from "fond/form/fields/TextField";
import { Folder, HTTPErrorResponse } from "fond/types";
import { setValue } from "fond/utils/formMutators";
import { generateUnusedName } from "fond/utils/naming";
import { compose, required } from "fond/utils/validation";
import { Message, Modal } from "fond/widgets";

interface IFormData {
  Name: string;
  Description: string | null;
}

interface IProps {
  /**
   * The account ID of the folder / workspace to create the new project id
   */
  accountId: string;
  /**
   * The parent folder
   */
  parentFolder?: Folder;
  /**
   * Subfolders of the parent folder
   */
  siblings: Folder[];
  /**
   * Callback function to handle the onClose of the modal
   */
  onClose(): void;
}

const NewCityPlanner: React.FC<IProps> = ({ parentFolder, siblings, accountId, onClose }: IProps) => {
  const navigate = useNavigate();

  // Creating a City Planner project creates a folder having the same name, so suggest a default name that doesn't
  // overlap with any existing one.
  const defaultName = useMemo(
    () =>
      generateUnusedName(
        "Untitled City Planner project",
        siblings.map((f) => f.Name)
      ),
    [siblings]
  );

  const [createCityPlannerProject, { isLoading: isSaving, isError, error }] = useCreateMultiProjectMutation();

  /**
   * On submit function called when the form is submitted and valid
   */
  const handleOnSubmit = async ({ Name, Description }: IFormData) => {
    createCityPlannerProject({
      FolderID: parentFolder?.ID ?? null,
      AccountID: accountId,
      Name: Name,
      Description: Description,
    })
      .unwrap()
      .then((multiProject) => {
        onClose();

        // Navigate to the MultiProject
        navigate(`/city/${multiProject.ID}`);
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  };

  /**
   * Validates that the Name is currently not in use
   * @param value New Name value
   */
  const uniqueName = (value: TextFieldValue) => {
    const newName = value?.toString().toLowerCase().trim();
    const existingFolder = siblings.some((folder: Folder) => folder.Name.toLowerCase().trim() === newName);
    if (existingFolder || newName === "home") {
      return "This name is already in use. Please use another.";
    }
    return undefined;
  };

  let errorMessage = "There was an issue creating the City Planner project. Please try again.";
  if (error && "data" in error && !("error" in error)) {
    const errorData = error.data as HTTPErrorResponse;
    if (errorData.code === "UniquenessViolation") {
      errorMessage = "The project could not be created as this name is already in use. Please use another.";
    }
  }

  return (
    <Modal
      open
      header="Create a new City Planner project"
      data-testid="new-city-planner-modal"
      content={
        <Box>
          {isError && (
            <Box mb={2}>
              <Message type="error">{errorMessage}</Message>
            </Box>
          )}
          <Form<IFormData>
            initialValues={{
              Name: defaultName,
              Description: null,
            }}
            onSubmit={handleOnSubmit}
            render={({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} id="new-city-planner-form">
                  <Box maxWidth={300}>
                    <TextField name="Name" required label="Name" fullWidth validate={compose(required, uniqueName)} />
                  </Box>
                  <Box mt={2}>
                    <TextField name="Description" label="Description" fullWidth multiline rows={3} />
                  </Box>
                </form>
              );
            }}
            mutators={{ setValue }}
          />
        </Box>
      }
      actions={
        <>
          <Button data-testid="new-city-planner-project-cancel-button" color="primary" onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            data-testid="new-city-planner-project-save-button"
            color="primary"
            loading={isSaving}
            type="submit"
            form="new-city-planner-form"
          >
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default NewCityPlanner;
