/* eslint-disable import/prefer-default-export */
import update from "immutability-helper";
import _ from "lodash";

import { ProjectAction } from "fond/project/redux";
import {
  ADD_COMMENT_SUCCESS,
  ADD_REPLY_SUCCESS,
  CommentsActionType,
  DELETE_COMMENT_SUCCESS,
  DELETE_REPLY_SUCCESS,
  GET_COMMENTS_FAILURE,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  RESOLVE_COMMENT_SUCCESS,
  SET_EDIT_ID,
  SET_FILTERS,
  SET_MAP_FILTER,
  SET_SEARCH_TEXT,
  SET_SORT_ORDER,
  UPDATE_COMMENT_SUCCESS,
  UPDATE_REPLY_SUCCESS,
} from "fond/redux/comments";
import { CommentsState } from "fond/types";
import { reduceReducers } from "fond/utils";
import localStorage from "fond/utils/localStorage";

import { getAllTags } from "./utils";

const initialState: CommentsState = {
  isFetching: false,
  items: {},
  loadStatus: null,
  editId: null,
  sortOrder: localStorage.getItem("state.comments.sortOrder", "created"),
  filters: {
    State: ["open"],
    Importance: [],
    Version: [],
  },
  mapFilter: false,
  searchText: "",
  tags: [],
};

export const commentReducer = reduceReducers((state = initialState, action: CommentsActionType.ReducerActions): CommentsState => {
  switch (action.type) {
    case GET_COMMENTS_REQUEST:
      return update(state, {
        loadStatus: { $set: "executing" },
        isFetching: { $set: true },
      });
    case GET_COMMENTS_SUCCESS: {
      return update(state, {
        loadStatus: { $set: "success" },
        isFetching: { $set: false },
        items: {
          $set: _.keyBy(action.payload.Comments, "ID"),
        },
        tags: { $set: getAllTags(action.payload.Comments) },
      });
    }
    case GET_COMMENTS_FAILURE:
      return update(state, {
        loadStatus: { $set: "failure" },
        isFetching: { $set: false },
      });
    case ADD_COMMENT_SUCCESS:
      return update(state, {
        items: { $merge: { [action.payload.Comment.ID]: action.payload.Comment } },
        tags: { $set: getAllTags([...Object.values(state.items), action.payload.Comment]) },
      });
    case UPDATE_COMMENT_SUCCESS:
    case RESOLVE_COMMENT_SUCCESS:
      return update(state, {
        items: {
          [action.payload.Comment.ID]: {
            $set: action.payload.Comment,
          },
        },
        tags: { $set: getAllTags([...Object.values(state.items), action.payload.Comment]) },
      });
    case UPDATE_REPLY_SUCCESS: {
      const index = state.items[action.payload.Reply.CommentID].Replies.findIndex((reply) => reply.ID === action.payload.Reply.ID);
      return update(state, {
        items: {
          [action.payload.Reply.CommentID]: {
            Replies: {
              [index]: {
                $set: action.payload.Reply,
              },
            },
          },
        },
        tags: { $set: getAllTags([...Object.values(state.items), action.payload.Reply]) },
      });
    }
    case DELETE_COMMENT_SUCCESS:
      return update(state, {
        items: { $unset: [action.payload.ID] },
      });
    case DELETE_REPLY_SUCCESS: {
      const index = state.items[action.payload.CommentID].Replies.findIndex((reply) => reply.ID === action.payload.ID);
      return update(state, {
        items: {
          [action.payload.CommentID]: {
            Replies: { $splice: [[index, 1]] },
          },
        },
      });
    }
    case ADD_REPLY_SUCCESS:
      return update(state, {
        items: {
          [action.payload.Comment.ID]: {
            Replies: { $push: [action.payload.Reply] },
          },
        },
        tags: { $set: getAllTags([...Object.values(state.items), action.payload.Reply]) },
      });
    case ProjectAction.CLOSE_PROJECT: {
      return update(state, { $set: initialState });
    }
    case ProjectAction.SELECT_COMMENT: {
      return update(state, { editId: { $set: null } });
    }
    case ProjectAction.UNSELECT_COMMENT: {
      return update(state, { editId: { $set: null } });
    }
    case SET_EDIT_ID:
      return update(state, {
        editId: { $set: action.editId },
      });
    case SET_MAP_FILTER:
      return update(state, {
        mapFilter: { $set: action.mapFilter },
      });
    case SET_SORT_ORDER:
      return update(state, {
        sortOrder: { $set: action.sortOrder },
      });
    case SET_FILTERS:
      return update(state, {
        filters: { $set: action.filters },
      });
    case SET_SEARCH_TEXT:
      return update(state, {
        searchText: { $set: action.searchText },
      });
    default:
      return state;
  }
});
