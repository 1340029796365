import update from "immutability-helper";

import { FeaturesActionTypes, GET_FEATURE_FAILURE, GET_FEATURE_REQUEST, GET_FEATURE_SUCCESS } from "fond/redux/features";
import { FeaturesState } from "fond/types";
import { reduceReducers } from "fond/utils";

const initialState: FeaturesState = {
  isFetching: false,
  items: {},
  loadStatus: null,
};

/* eslint-disable import/prefer-default-export */
export const featuresReducer = reduceReducers((state = initialState, action: FeaturesActionTypes.ReducerActions): FeaturesState => {
  switch (action.type) {
    case GET_FEATURE_REQUEST:
      return update(state, {
        loadStatus: { $set: "executing" },
        isFetching: { $set: true },
      });
    case GET_FEATURE_SUCCESS: {
      const featureId = action.payload.id as string;
      return update(state, {
        loadStatus: { $set: "success" },
        isFetching: { $set: false },
        items: { $merge: { [featureId]: action.payload } },
      });
    }
    case GET_FEATURE_FAILURE:
      return update(state, {
        loadStatus: { $set: null },
        isFetching: { $set: false },
      });
    default:
      return state;
  }
});
