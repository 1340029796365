import * as Sentry from "@sentry/react";
import { sortBy } from "lodash";

import { GetAccountModulesResponse } from "fond/api";
import {
  AccountBase,
  AccountModules,
  ExtendedPermission,
  Permission,
  PermissionLevel,
  Subscription,
  SubscriptionStatus,
  UserAccountAllocationResponse,
  UserShareSuggestion,
} from "fond/types";

export enum Actions {
  ATTACHMENTS_BULK_IMPORT,
  ARCHITECTURE_EDIT, // create/apply/copy/delete
  ARCHITECTURE_ENABLE_FLEXNAP,
  CITY_PLANNER_ADD,
  CITY_PLANNER_CARVE,
  CITY_PLANNER_DELETE,
  CITY_PLANNER_EDIT,
  CITY_PLANNER_RENAME,
  FEATURE_DELETE_ATTACHMENT,
  FEATURE_EDIT_ATTACHMENT,
  FEATURE_UPLOAD_ATTACHMENT,
  FEATURE_VIEW_ATTACHMENT,
  FOLDER_ADD,
  FOLDER_VIEW,
  FOLDER_EDIT,
  FOLDER_MOVE,
  FOLDER_DELETE,
  FOLDER_SHARE_VIEW,
  FOLDER_SHARE_EDIT,
  PLANNER_PROJECT_ADD,
  PLANNER_PROJECT_EDIT,
  PROJECT_ADD,
  PROJECT_VIEW,
  PROJECT_EDIT,
  PROJECT_RENAME,
  PROJECT_COPY,
  PROJECT_CREATE_REPORT,
  PROJECT_DELETE,
  PROJECT_DOWNLOAD,
  PROJECT_DELETE_ATTACHMENT,
  PROJECT_EDIT_ATTACHMENT,
  PROJECT_UPLOAD_ATTACHMENT,
  PROJECT_VIEW_ATTACHMENT,
  PROJECT_MOVE,
  PROJECT_SHARE_VIEW,
  PROJECT_SHARE_EDIT,
  PROJECT_EDIT_STYLES,
  PROJECT_IMPORT,
  PROJECT_EXPORT_VIEW,
  PROJECT_EXPORT_EDIT,
  PROJECT_EXPORT_SPLICE_TABLES,
  PERMISSION_ADD,
  PERMISSION_EDIT_USER,
  PERMISSION_EDIT_GROUP,
  PERMISSION_REMOVE_USER,
  PERMISSION_REMOVE_GROUP,
  PERMISSION_DENY,
  REPORT_COPY,
  REPORT_DELETE,
  REPORT_EDIT,
  REPORT_GENERATE,
  REPORT_IMPORT,
  REPORT_MOVE,
  REPORT_RENAME,
  REPORT_SHARE_EDIT,
  REPORT_SHARE_VIEW,
  VERSION_ADD,
  VERSION_EDIT,
}

const licenseSubscriptionActionsMapping: {
  [key in Actions]: {
    license: boolean;
    subscription?: boolean; // temporary optional
  };
} = {
  [Actions.ATTACHMENTS_BULK_IMPORT]: { license: true, subscription: false },
  [Actions.ARCHITECTURE_EDIT]: { license: true, subscription: true },
  [Actions.ARCHITECTURE_ENABLE_FLEXNAP]: { license: true, subscription: true },
  [Actions.FEATURE_DELETE_ATTACHMENT]: { license: true, subscription: false },
  [Actions.FEATURE_EDIT_ATTACHMENT]: { license: true, subscription: false },
  [Actions.FEATURE_UPLOAD_ATTACHMENT]: { license: true, subscription: false },
  [Actions.FEATURE_VIEW_ATTACHMENT]: { license: false, subscription: false },
  [Actions.FOLDER_ADD]: { license: true, subscription: false },
  [Actions.FOLDER_VIEW]: { license: false, subscription: false },
  [Actions.FOLDER_EDIT]: { license: true, subscription: false },
  [Actions.FOLDER_MOVE]: { license: true, subscription: false },
  [Actions.FOLDER_DELETE]: { license: true, subscription: false },
  [Actions.FOLDER_SHARE_VIEW]: { license: false, subscription: false },
  [Actions.FOLDER_SHARE_EDIT]: { license: false, subscription: false },
  [Actions.PLANNER_PROJECT_ADD]: { license: true, subscription: true },
  [Actions.PLANNER_PROJECT_EDIT]: { license: true, subscription: true },
  [Actions.PROJECT_ADD]: { license: true, subscription: false },
  [Actions.PROJECT_VIEW]: { license: false, subscription: false },
  [Actions.PROJECT_EDIT]: { license: true, subscription: false },
  [Actions.PROJECT_RENAME]: { license: true, subscription: false },
  [Actions.PROJECT_COPY]: { license: true, subscription: true },
  [Actions.PROJECT_CREATE_REPORT]: { license: true, subscription: true },
  [Actions.PROJECT_DELETE]: { license: true, subscription: false },
  [Actions.PROJECT_DOWNLOAD]: { license: false, subscription: false }, // can possibly delete??
  [Actions.PROJECT_DELETE_ATTACHMENT]: { license: true, subscription: false },
  [Actions.PROJECT_EDIT_ATTACHMENT]: { license: true, subscription: false },
  [Actions.PROJECT_UPLOAD_ATTACHMENT]: { license: true, subscription: false },
  [Actions.PROJECT_VIEW_ATTACHMENT]: { license: false, subscription: false },
  [Actions.PROJECT_MOVE]: { license: true, subscription: false },
  [Actions.PROJECT_SHARE_VIEW]: { license: false, subscription: false },
  [Actions.PROJECT_SHARE_EDIT]: { license: false, subscription: false },
  [Actions.PROJECT_EDIT_STYLES]: { license: true, subscription: false },
  [Actions.PROJECT_IMPORT]: { license: true, subscription: false },
  [Actions.PROJECT_EXPORT_VIEW]: { license: false, subscription: false },
  [Actions.PROJECT_EXPORT_EDIT]: { license: false, subscription: false },
  [Actions.PROJECT_EXPORT_SPLICE_TABLES]: { license: false, subscription: false },
  [Actions.REPORT_COPY]: { license: true, subscription: true },
  [Actions.REPORT_DELETE]: { license: true, subscription: false },
  [Actions.REPORT_EDIT]: { license: true, subscription: false },
  [Actions.REPORT_GENERATE]: { license: true, subscription: false },
  [Actions.REPORT_IMPORT]: { license: true, subscription: false },
  [Actions.REPORT_MOVE]: { license: true, subscription: false },
  [Actions.REPORT_RENAME]: { license: true, subscription: false },
  [Actions.REPORT_SHARE_EDIT]: { license: false, subscription: false },
  [Actions.REPORT_SHARE_VIEW]: { license: false, subscription: false },
  [Actions.VERSION_ADD]: { license: true, subscription: false },
  [Actions.VERSION_EDIT]: { license: true, subscription: false },
  [Actions.CITY_PLANNER_ADD]: { license: true, subscription: true },
  [Actions.CITY_PLANNER_RENAME]: { license: true, subscription: true },
  [Actions.CITY_PLANNER_DELETE]: { license: true, subscription: false },
  [Actions.CITY_PLANNER_EDIT]: { license: true, subscription: true },
  [Actions.CITY_PLANNER_CARVE]: { license: true, subscription: true },
  // require permission level check instead of license/subscription check
  [Actions.PERMISSION_ADD]: { license: false, subscription: false },
  [Actions.PERMISSION_EDIT_USER]: { license: false, subscription: false },
  [Actions.PERMISSION_EDIT_GROUP]: { license: false, subscription: false },
  [Actions.PERMISSION_REMOVE_USER]: { license: false, subscription: false },
  [Actions.PERMISSION_REMOVE_GROUP]: { license: false, subscription: false },
  [Actions.PERMISSION_DENY]: { license: false, subscription: false },
};

export const permissionLevel: { [key: string]: number } = {
  deny: 0,
  view: 1,
  read: 2,
  write: 3,
  manage: 4,
};

export const accountModulesActionsMappings = new Map<Actions, AccountModules>();
accountModulesActionsMappings.set(Actions.ARCHITECTURE_ENABLE_FLEXNAP, AccountModules.FlexNap);
accountModulesActionsMappings.set(Actions.PROJECT_EXPORT_SPLICE_TABLES, AccountModules.SpliceTables);
accountModulesActionsMappings.set(Actions.REPORT_IMPORT, AccountModules.ImportReport);
accountModulesActionsMappings.set(Actions.ATTACHMENTS_BULK_IMPORT, AccountModules.AttachmentsBulkImport);

export const mappings = new Map<Actions, PermissionLevel[]>();
mappings.set(Actions.ARCHITECTURE_EDIT, ["manage", "write"]);
mappings.set(Actions.ARCHITECTURE_ENABLE_FLEXNAP, ["manage", "write"]);
mappings.set(Actions.FEATURE_DELETE_ATTACHMENT, ["manage", "write"]);
mappings.set(Actions.FEATURE_EDIT_ATTACHMENT, ["manage", "write"]);
mappings.set(Actions.FEATURE_UPLOAD_ATTACHMENT, ["manage", "write"]);
mappings.set(Actions.FEATURE_VIEW_ATTACHMENT, ["manage", "write", "read"]);
mappings.set(Actions.FOLDER_ADD, ["manage", "write"]);
mappings.set(Actions.FOLDER_EDIT, ["manage", "write"]);
mappings.set(Actions.FOLDER_SHARE_VIEW, ["manage", "write", "read"]);
mappings.set(Actions.FOLDER_SHARE_EDIT, ["manage", "write", "read"]);
mappings.set(Actions.FOLDER_DELETE, ["manage"]);
mappings.set(Actions.FOLDER_VIEW, ["manage", "write", "read", "view"]);
mappings.set(Actions.FOLDER_MOVE, ["manage"]);
mappings.set(Actions.PLANNER_PROJECT_ADD, ["manage", "write"]);
mappings.set(Actions.PLANNER_PROJECT_EDIT, ["manage", "write"]);
mappings.set(Actions.PROJECT_ADD, ["manage", "write"]);
mappings.set(Actions.PROJECT_VIEW, ["manage", "write", "read", "view"]);
mappings.set(Actions.PROJECT_EDIT, ["manage", "write"]);
mappings.set(Actions.PROJECT_RENAME, ["manage", "write"]);
mappings.set(Actions.PROJECT_COPY, ["manage", "write"]);
mappings.set(Actions.PROJECT_MOVE, ["manage"]);
mappings.set(Actions.PROJECT_CREATE_REPORT, ["manage", "write", "read"]);
mappings.set(Actions.PROJECT_DELETE, ["manage"]);
mappings.set(Actions.PROJECT_DOWNLOAD, ["manage", "write", "read"]);
mappings.set(Actions.PROJECT_DELETE_ATTACHMENT, ["manage", "write"]);
mappings.set(Actions.PROJECT_EDIT_ATTACHMENT, ["manage", "write"]);
mappings.set(Actions.PROJECT_UPLOAD_ATTACHMENT, ["manage", "write"]);
mappings.set(Actions.PROJECT_VIEW_ATTACHMENT, ["manage", "write", "read"]);
mappings.set(Actions.PROJECT_SHARE_VIEW, ["manage", "write", "read"]);
mappings.set(Actions.PROJECT_SHARE_EDIT, ["manage", "write", "read"]);
mappings.set(Actions.PROJECT_EDIT_STYLES, ["manage", "write"]);
mappings.set(Actions.PROJECT_IMPORT, ["manage", "write"]);
mappings.set(Actions.PROJECT_EXPORT_VIEW, ["manage", "write", "read"]);
mappings.set(Actions.PROJECT_EXPORT_EDIT, ["manage", "write"]);
mappings.set(Actions.PROJECT_EXPORT_SPLICE_TABLES, ["manage", "write"]);
mappings.set(Actions.PERMISSION_ADD, ["manage", "write", "read"]);
mappings.set(Actions.PERMISSION_EDIT_USER, ["manage", "write", "read"]);
mappings.set(Actions.PERMISSION_EDIT_GROUP, ["manage", "write"]);
mappings.set(Actions.PERMISSION_REMOVE_USER, ["manage", "write", "read"]);
mappings.set(Actions.PERMISSION_REMOVE_GROUP, ["manage", "write"]);
mappings.set(Actions.PERMISSION_DENY, ["manage", "write"]);
mappings.set(Actions.REPORT_COPY, ["manage", "write"]);
mappings.set(Actions.REPORT_DELETE, ["manage"]);
mappings.set(Actions.REPORT_EDIT, ["manage", "write"]);
mappings.set(Actions.REPORT_GENERATE, ["manage", "write"]);
mappings.set(Actions.REPORT_MOVE, ["manage"]);
mappings.set(Actions.REPORT_RENAME, ["manage", "write"]);
mappings.set(Actions.REPORT_SHARE_EDIT, ["manage", "write", "read"]);
mappings.set(Actions.REPORT_SHARE_VIEW, ["manage", "write", "read"]);
mappings.set(Actions.VERSION_ADD, ["manage", "write"]);
mappings.set(Actions.VERSION_EDIT, ["manage", "write"]);
mappings.set(Actions.CITY_PLANNER_ADD, ["manage", "write"]);
mappings.set(Actions.CITY_PLANNER_RENAME, ["manage", "write"]);
mappings.set(Actions.CITY_PLANNER_DELETE, ["manage"]);
mappings.set(Actions.CITY_PLANNER_EDIT, ["manage", "write"]);

/**
 * Determines if the action is allowed
 * for the subscription passed in, if any
 *
 * @param currentSubscription The current subscription selected
 * @param action The action that is being checked
 * @returns `true` if action is allowed
 */
export const subscriptionCheck = (currentSubscription: Subscription | undefined | null, action: Actions): boolean => {
  const actionRequireSubscription = licenseSubscriptionActionsMapping[action].subscription;

  return actionRequireSubscription ? currentSubscription?.Status === SubscriptionStatus.Active && !!currentSubscription?.Planner : true;
};

/**
 * Determines if the action is allowed
 * for the license passed in, if any
 *
 * @param allocation - The user account allocation for current user/account combo
 * @param action - The action that is being checked
 * @returns `true` if action is allowed
 */
export const licenseCheck = (allocation: UserAccountAllocationResponse | undefined | null, action: Actions): boolean => {
  const hasLicense = !!allocation?.License;
  const actionRequireLicense = licenseSubscriptionActionsMapping[action].license;

  return actionRequireLicense ? hasLicense : true;
};

/**
 * Determins if the action is allowed
 * for the account modules passed in, if any
 *
 * @param accountModules - modules that the current account has access to
 * @param action - The action that is being checked
 * @returns `true` if action is allowed
 */
export const accountModuleCheck = (accountModules: GetAccountModulesResponse | undefined, action: Actions): boolean => {
  const modules = accountModules?.Modules.map((module) => module.Name) || [];
  const requiredModules = accountModulesActionsMappings.get(action);
  return requiredModules ? modules.includes(requiredModules) : true;
};

/**
 * Determines if the action being undertaken is an allowed action
 * for the permission passed
 * @param permission - The permission level to check
 * @param action - The action that is being checked
 */
export const permissionCheck = (permission: PermissionLevel | undefined, action: Actions): boolean => {
  if (!permission) {
    return false;
  }
  return mappings.get(action)?.includes(permission) || false;
};

/**
 * Determines if the action is allowed
 * given the permission level and license if any.
 * @param action - The action that is being checked
 * @param permission - The permission level to check
 * @param allocation - The user account allocation for current user/account combo
 * @returns `true` if action is allowed by permission level and license status
 */
export const isAllowed = (
  action: Actions,
  permission: PermissionLevel,
  allocation: UserAccountAllocationResponse | undefined | null,
  currentSubscription: Subscription | undefined | null
): boolean => {
  const hasPermission = permissionCheck(permission, action);

  const hasActiveSubscription = currentSubscription?.Status === SubscriptionStatus.Active;
  if (!hasActiveSubscription) {
    return false;
  }
  const canActWithLicense = licenseCheck(allocation, action);
  const canActWithSubscription = subscriptionCheck(currentSubscription, action);

  // if and only if allocation and currentSubscription is passed in, their account ID must always match
  if (allocation && currentSubscription && allocation.Account.ID !== currentSubscription.AccountID) {
    Sentry.captureException(
      new Error(`Allocation account ${JSON.stringify(allocation)} does not match currentSubscription ${JSON.stringify(currentSubscription)} `)
    );
    return false;
  }

  return hasPermission && canActWithLicense && canActWithSubscription;
};

/**
 * Determines if the permission row can be removed.  We prevent:
 * 1) Users cannot remove their own name;
 * 2) Permissions with inheritance can be removed, unless they have been overridden;
 * 3) Cannot demote managers;
 * @param permission - The permission to check
 * @param action - The username used to determine if they are allowed to remove.  Users cannot remove their own permissions
 * @param currentPermission - The PermissionLevel of user trying to remove.
 */
export const allowRemoval = (permission: ExtendedPermission, username: string, currentPermission: PermissionLevel | undefined): boolean => {
  if (!currentPermission) {
    return false;
  }
  return (
    (permission.IdentityType !== "user" || permission.Identity.Email !== username) &&
    (!permission.Inherited || (permission.Inherited !== undefined && permission.Level !== "inherited")) &&
    !isElevation(currentPermission, permission.Level as PermissionLevel) &&
    (permission.Level !== "deny" || permissionCheck(currentPermission, Actions.PERMISSION_DENY)) &&
    ((permission.IdentityType === "user" && permissionCheck(currentPermission, Actions.PERMISSION_REMOVE_USER)) ||
      (permission.IdentityType === "account" && permissionCheck(currentPermission, Actions.PERMISSION_REMOVE_GROUP)))
  );
};

/**
 * Determines if the permission can be edited.  We prevent:
 * 1) Users cannot change their own permission;
 * 2) Permissions with inheritance cannot be removed;
 * 3) Cannot change permissions higher than your own;
 * 4) Must have PERMISSION_DENY rights for editing the deny permission;
 * 5) View cannot change permissions
 * @param permission - The permission to check
 * @param action - The username used to determine if they are allowed to edit.  Users cannot edit their own permissions
 * @param currentPermission - The PermissionLevel of user trying to edit.
 */
export const allowEditPermission = (permission: ExtendedPermission, username: string, currentPermission: PermissionLevel | undefined): boolean => {
  const level = permission.Level === "inherited" ? permission.Inherited : permission.Level;
  if (!currentPermission) {
    return false;
  }
  return (
    ((permission.IdentityType === "user" && permission.Identity.Email !== username) || permission.IdentityType === "account") &&
    ((permission.IdentityType === "user" && permissionCheck(currentPermission, Actions.PERMISSION_EDIT_USER)) ||
      (permission.IdentityType === "account" && permissionCheck(currentPermission, Actions.PERMISSION_EDIT_GROUP))) &&
    !isElevation(currentPermission, level as PermissionLevel) &&
    (permission.Level !== "deny" || permissionCheck(currentPermission, Actions.PERMISSION_DENY))
  );
};

/**
 * Determines if a permission level during editing is allowed to be selected.
 * @param currentPermission - The PermissionLevel of user trying to edit.
 * @param newPermission - The PermissionLevel being checked.
 * @param inherited - a flag indicating if the permission is an inherited one.
 */
export const allowEditPermissionLevel = (
  currentPermission: PermissionLevel | undefined,
  newPermission: PermissionLevel,
  inherited = false
): boolean => {
  if (!currentPermission) {
    return false;
  }
  return (
    (newPermission !== "deny" || inherited || permissionCheck(currentPermission, Actions.PERMISSION_DENY)) &&
    !isElevation(currentPermission, newPermission)
  );
};

/**
 * Determines if the current permission is >= new permission.
 * Note we prevent users granting permissions greater than their own.
 *
 * @param currentPermission - The reference PermissionLevel.
 * @param newPermission - The PermissionLevel being checked.
 */
export const isElevation = (currentPermission: PermissionLevel, newPermission: PermissionLevel): boolean =>
  permissionLevel[currentPermission] < permissionLevel[newPermission];

/**
 * Determines if the current permission is <= new permission.
 * Note we prevent users from demoting themselves.
 * @param currentPermission - The reference PermissionLevel.
 * @param newPermission - The PermissionLevel being checked.
 */
export const isDemotion = (currentPermission: PermissionLevel, newPermission: PermissionLevel): boolean =>
  permissionLevel[currentPermission] > permissionLevel[newPermission];

/**
 * Determines if the permission is referencing an inherited value
 * @param permission - The permission to check for inheritance
 */
export const isInherited = (permission: ExtendedPermission): boolean => permission.Level === "inherited";

/**
 * Determines if the permission is a new permission yet to be saved.
 * @param permission - The permission to check for modification
 */
export const isNew = (permission: ExtendedPermission): boolean => {
  return !!permission.New && !permission.Submitted;
};

/**
 * Determines if the permission is an override of an inherited permission
 * @param permission - The permission to check for modification
 */
export const isNewInheritOverride = (permission: ExtendedPermission): boolean => {
  return permission.ReadOnly === true && permission.Level !== "inherited";
};

/**
 * Parses the /permissions/ endpoint data into the correct format for the
 * PermissionsField.tsx.
 *
 * @param permissions - A list permissions including all inheritance
 * @param resourceId - The unique ID of the resource being edited
 * @param isCreate - A flag indicating that these permissions relate to an resource being created & the new permission should be injected.
 * @param username - The username of the current user - only required if creating a new resource
 * @param suggestions - The list of suggested users this resource can be shared with, containing details such as permission limit
 * @param account - The account this resource was created within
 * @param accountUsers - The list of users in the resource's account.
 * @returns A list of permissions that can be passed to the PermissionsField within forms.
 */
export const parsePermissions = ({
  permissions = [],
  resourceId,
  isCreate = false,
  username,
  suggestions,
  account,
  accountUsers,
}: {
  permissions?: Permission[] | undefined;
  resourceId?: string | undefined;
  isCreate?: boolean;
  username: string;
  suggestions?: UserShareSuggestion[];
  account?: AccountBase | null;
  accountUsers?: UserAccountAllocationResponse[];
}): ExtendedPermission[] => {
  if (permissions) {
    const isGuest = (permission: ExtendedPermission) => {
      const ownerEmail = typeof permission.Resource.OwnedBy === "string" ? permission.Resource.OwnedBy : permission.Resource.OwnedBy?.Email;
      const isResourceOwner = permission.IdentityType === "user" && permission.Identity.Email === ownerEmail;
      const isResourceAccount = permission.IdentityType === "account" && permission.Identity.ID === account?.ID;

      const matchingUser = suggestions && suggestions.find((user) => user.User.ID === permission.Identity.ID);
      const isUserInAccount = matchingUser && (matchingUser.PermissionLimit === null || matchingUser.PermissionLimit?.Reason !== "NotInAccount");

      if (isResourceOwner || isResourceAccount || isUserInAccount) return false;
      return true;
    };

    const getLicense = (permission: ExtendedPermission) => {
      return accountUsers?.find((allocation) => allocation.User.ID === permission.Identity.ID)?.License;
    };

    // Populate the array with only the permissions locally set on the resource
    const newPermissions: ExtendedPermission[] = permissions
      .filter((perm) => perm.Resource.ID === resourceId)
      .map((perm) => ({ ...perm, Guest: isGuest(perm), License: getLicense(perm) }));

    // If resource is being created add them as a manager
    if (isCreate) {
      newPermissions.push({ ID: undefined, Resource: { ID: undefined }, Identity: { Email: username }, Level: "manage", IdentityType: "user" });
    }

    // For each permission we need to add users / groups if they are not already added
    permissions.forEach((permission) => {
      const existing = newPermissions.find(
        (perm) =>
          (perm.IdentityType === "user" && permission.IdentityType === "user" && perm.Identity.Email === permission.Identity.Email) ||
          (perm.IdentityType === "account" && permission.IdentityType === "account" && perm.Identity.ID === permission.Identity.ID)
      );

      // Inherited permissions
      if (permission.Resource.ID !== resourceId) {
        if (existing) {
          // Augment the locally set source to include its inherited permission
          // We only do this if it is not already inheriting a permission
          if (!existing.Inherited) {
            existing.Inherited = permission.Level;
            existing.Guest = isGuest(permission);
            existing.License = getLicense(permission);
          }
        } else {
          // For inherited permissions that have no overriding locally set
          // permission we create an inherited permission to show.
          newPermissions.push({
            ...permission,
            Resource: {
              ...permission,
              ...(resourceId ? { ID: resourceId } : {}),
            },
            Level: "inherited",
            Inherited: permission.Level,
            ReadOnly: true,
            Guest: isGuest(permission),
            License: getLicense(permission),
          });
        }
      }
    });

    return sortBy(newPermissions, [
      (perm) => {
        // Always elevate the users own permissions to the top
        if (perm.IdentityType === "user" && perm.Identity.Email === username) {
          return -99;
        }
        return perm.Level === "inherited" ? -permissionLevel[perm.Inherited as PermissionLevel] : -permissionLevel[perm.Level];
      },
      "Identity.Email",
    ]);
  }

  return [];
};
