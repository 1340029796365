import { useEffect, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import * as Sentry from "@sentry/react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { useSnackbar } from "notistack";

import {
  apiSlice,
  selectCurrentAccount,
  useGetAccountPaymentMethodQuery,
  useGetProductsQuery,
  usePurchaseProductsMutation,
  useRenewSubscriptionMutation,
} from "fond/api";
import { PRODUCT_CATEGORY_KEY, PRODUCT_SELECTION_KEY, PURCHASE_TYPE_KEY } from "fond/constants";
import { LineItem, PaymentFrequency, ProductCategory, PurchaseType } from "fond/types/stripe";
import { useAppDispatch, useQueryParams } from "fond/utils/hooks";
import { getLicenseLineItem, getSubscriptionLineItem } from "fond/utils/products";

import OrganisationDetails from "./PaymentMethod/OrganisationDetails";
import PaymentMethodDetails from "./PaymentMethod/PaymentMethodDetails";
import LicenseSelection from "./ProductSelection/LicenseSelection";
import SubscriptionSelection from "./ProductSelection/SubscriptionSelection";
import { SelectionHeader } from "./ProductSelection/SubscriptionSelection.style";
import InvoiceSummary from "./Summary/InvoiceSummary";
import SalesContact from "./SalesContact";

import { CheckoutContainer, Payment, Product, Summary } from "./Checkout.styles";

export type IFormData = {
  productId: string;
  billingPeriod: PaymentFrequency;
  numLicenses: number;
  promoCode: string;
  address?: StripeAddressElementChangeEvent["value"]["address"];
  country?: string; // used internally to track valid country selection
};

export const initialValues: IFormData = {
  productId: "",
  billingPeriod: "yearly",
  numLicenses: 0,
  promoCode: "",
};

const CheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const dispatch = useAppDispatch();
  const elements = useElements();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const selectedProductId = useQueryParams(PRODUCT_SELECTION_KEY) ?? "";
  const productCategory = useQueryParams<ProductCategory>(PRODUCT_CATEGORY_KEY);
  const purchaseType = useQueryParams<PurchaseType>(PURCHASE_TYPE_KEY);

  const accountId = useSelector(selectCurrentAccount)?.ID;
  const { data: products } = useGetProductsQuery();
  const { data: defaultPaymentMethod, isLoading: isLoadingPaymentMethod } = useGetAccountPaymentMethodQuery(accountId ?? skipToken);
  const [purchaseProducts] = usePurchaseProductsMutation();
  const [renewSubscription] = useRenewSubscriptionMutation();

  // if setupintent succeeds but FOND service fails unpredictably,
  // we don't need to re-confirm setup as that would now fail
  const paymentMethod = useRef<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [useDefaultPaymentMethod, setUseDefaultPaymentMethod] = useState(false);

  useEffect(() => {
    setUseDefaultPaymentMethod(Boolean(!isLoadingPaymentMethod && defaultPaymentMethod?.ID));
  }, [isLoadingPaymentMethod, defaultPaymentMethod]);

  const onSubmit = async (formData: IFormData) => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const { billingPeriod, productId, promoCode, numLicenses } = formData;
    const subscriptionLineItem = getSubscriptionLineItem(products, billingPeriod, productId);
    const licenseLineItem = getLicenseLineItem(products, billingPeriod, numLicenses);
    const lineItems: LineItem[] = [subscriptionLineItem, licenseLineItem].filter<LineItem>((item): item is LineItem => Boolean(item));

    if (lineItems.length === 0) {
      Sentry.captureMessage(`Failed to generate line items from product id ${productId}`, "warning");
      return;
    }

    // If users choose to use the existing payment method, simply grab the info from saved details.
    if (useDefaultPaymentMethod) {
      paymentMethod.current = defaultPaymentMethod?.ID;
    }

    if (!paymentMethod.current) {
      const { setupIntent, error } = await stripe.confirmSetup({
        elements: elements,
        redirect: "if_required",
      });
      if (error?.message) {
        enqueueSnackbar(error.message);
      }

      const resolvedPaymentMethod = typeof setupIntent?.payment_method === "string" ? setupIntent.payment_method : setupIntent?.payment_method?.id;
      paymentMethod.current = resolvedPaymentMethod;
    }

    if (paymentMethod.current) {
      try {
        if (purchaseType === PurchaseType.first_time_purchase) {
          const { Invoice, HostedInvoiceUrl, Status } = await purchaseProducts({
            LineItems: lineItems,
            PaymentMethod: paymentMethod.current,
            PromoCode: promoCode,
            Address: formData.address,
          }).unwrap();

          if (Status === "active") navigate("/payment-confirmed", { state: { Invoice } });
          if (Status === "incomplete") navigate("/payment-failed", { state: { Invoice, HostedInvoiceUrl } });
        } else if (purchaseType === PurchaseType.renew && accountId) {
          const { ID, StartTime } = await renewSubscription({
            accountId: accountId,
            LineItems: lineItems,
            PaymentMethod: paymentMethod.current,
            PromoCode: promoCode,
            Address: formData.address,
          }).unwrap();
          if (ID) {
            dispatch(apiSlice.util.invalidateTags([{ type: "RenewStatus", id: "ID" }]));
            navigate("/renewal-confirmed", { state: { subscriptionId: ID, startTime: StartTime, productCategory: productCategory } });
          }
        } else {
          Sentry.captureMessage(`Unsupported purchase type ${purchaseType}`, "error");
          return;
        }
      } catch (error) {
        Sentry.captureException(error);
        enqueueSnackbar("Fail to purchase, please try again later or contact FOND support.");
      }
    }

    setIsLoading(false);
  };

  const productSelection = (
    category: ProductCategory,
    values: IFormData,
    updateField: <F extends keyof IFormData>(fieldName: F, value: IFormData[F]) => void
  ): React.ReactNode => {
    if (category === ProductCategory.subscription) {
      return <SubscriptionSelection values={values} updateField={updateField} />;
    } else if (category === ProductCategory.license) {
      return <LicenseSelection />;
    }
    return null;
  };

  return (
    <Form<IFormData>
      onSubmit={onSubmit}
      initialValues={{ ...initialValues, productId: selectedProductId, numLicenses: productCategory === ProductCategory.license ? 1 : 0 }}
      render={({ handleSubmit, values, form }) => (
        <form onSubmit={handleSubmit}>
          <CheckoutContainer>
            <Product>{productCategory ? productSelection(productCategory, values, form.change) : null}</Product>
            <Payment>
              <SelectionHeader>Payment</SelectionHeader>
              {!isLoadingPaymentMethod && (
                <Box display="flex" marginBottom={2}>
                  <OrganisationDetails billingDetails={defaultPaymentMethod?.BillingDetails} />
                  <PaymentMethodDetails
                    paymentMethod={defaultPaymentMethod}
                    expanded={!useDefaultPaymentMethod}
                    onChange={() => setUseDefaultPaymentMethod((prev) => !prev)}
                  />
                </Box>
              )}
            </Payment>
            <Summary>
              <InvoiceSummary isLoading={isLoading} values={values} updateField={form.change} />
            </Summary>
            <SalesContact />
          </CheckoutContainer>
        </form>
      )}
    />
  );
};

export default CheckoutForm;
