import React from "react";
import { Breakpoint, Grid, Skeleton } from "@mui/material";

export type SkeletonItem = {
  /**
   * The key is optional and a unique value will be generated if omitted
   */
  key?: string;
  /**
   * The height of this grid item in pixels
   */
  height: number;
  /**
   * A breakpoint map of sizes for the grid items
   *
   * The values are the number of columns the item should span
   *
   * When omitted, the item will be full width on all breakpoints
   *
   * @example { xs: 12, sm: 6, lg: 3 }
   *
   * @default { xs: 12 }
   */
  sizes?: {
    [K in Breakpoint]?: number;
  };
};

const defaultGrid: SkeletonItem[] = [{ height: 30 }, { height: 100 }, { height: 300 }, { height: 500 }];

export type SkeletonLoadingGridProps = {
  items?: SkeletonItem[];
};

/**
 * Transform the items to ensure they all have keys and sizes
 * @param items
 * @returns
 */
const transform = (items: SkeletonItem[] = defaultGrid): SkeletonItem[] => {
  return items.map(({ key, height, sizes }, index) => ({
    key: key || `skeleton-${index}-${height}`,
    height: height,
    sizes: sizes || { xs: 12 },
  }));
};

/**
 * Generates a grid of skeleton loading items
 * @param items - The grid items to render
 * @example
 * <SkeletonLoadingGrid items={[
 *   { height: 50 },
 *   { height: 200, sizes: { xs: 12, lg: 3 } }
 *   { height: 500, sizes: { xs: 12, md: 6, lg: 3 } }
 * ]} />
 * @returns
 */
const SkeletonLoadingGrid: React.FC<SkeletonLoadingGridProps> = ({ items }) => {
  const skeletonItems = transform(items) || defaultGrid;

  return (
    <Grid container spacing={2.5}>
      {skeletonItems.map(({ key, height, sizes }) => (
        <Grid key={key} item {...sizes}>
          <Skeleton variant="rounded" animation="wave" sx={{ height }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SkeletonLoadingGrid;
