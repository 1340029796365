import { useSelector } from "react-redux";

import { beginMeasuring, endMeasuring } from "../project/redux";
import { Store, Version } from "../types";
import { useAppDispatch } from "../utils/hooks";

import BaseMapButtons from "./BaseMapButtons";
import { viewDesign, zoomIn, zoomOut } from "./redux";

interface IProps {
  version?: Version;
}

const MapButtons: React.FC<IProps> = ({ version }: IProps) => {
  const dispatch = useAppDispatch();
  const editMode = useSelector((state: Store) => state.project.editMode);

  const onClickMeasureHandler = () => {
    if (editMode === "measure") {
      dispatch(endMeasuring());
    } else {
      dispatch(beginMeasuring());
    }
  };

  const onClickZoomToDesignHandler = () => {
    dispatch(viewDesign(version?.BoundingBox, version?.SelectedArea));
  };

  const onClickZoomInHandler = () => {
    dispatch(zoomIn());
  };

  const onClickZoomOutHandler = () => {
    dispatch(zoomOut());
  };

  return (
    <BaseMapButtons
      editMode={editMode}
      onClickMeasure={onClickMeasureHandler}
      onClickZoomToDesign={onClickZoomToDesignHandler}
      onClickZoomIn={onClickZoomInHandler}
      onClickZoomOut={onClickZoomOutHandler}
    />
  );
};

export default MapButtons;
