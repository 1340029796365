import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

interface IProps {
  title: string;
  icon: React.ReactNode;
  value: string;
  unit?: string;
}

const CityPlannerInfoCard: React.FC<IProps> = ({ title, icon, value, unit }) => {
  return (
    <Grid item xs={6} sx={{ height: 160 }}>
      <Paper variant="elevation" elevation={1} square={false} sx={{ display: "flex", flexDirection: "column", height: "100%", p: 3 }}>
        <Typography variant="h7" fontWeight={500}>
          {title}
        </Typography>
        <Box flex={1} display="flex" alignItems="center">
          {icon}
          <Typography variant="h3" component="span" sx={{ ml: 2, mr: 1 }}>
            {value}
          </Typography>
          <Typography variant="h7" fontWeight={500}>
            {unit}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

export default CityPlannerInfoCard;
