import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";

import {
  AccountInvitation,
  EntityType,
  GetUsersResponse,
  Store,
  User,
  UserAccountAllocationResponse,
  UserMentionSuggestion,
  UserPreference,
  UserPreferenceKey,
  UserPreferenceSubKey,
  UserShareSuggestion,
} from "fond/types";

import { apiSlice } from "./apiSlice";

export type GetUserPreferencesResponse = {
  Items: UserPreference[];
};

export type GetUserAccountsResponse = {
  Allocations: UserAccountAllocationResponse[];
};

type GetUserShareSuggestionsResponse = {
  Suggestions: UserShareSuggestion[];
};

type GetUserMentionSuggestionsResponse = {
  Suggestions: UserMentionSuggestion[];
};

type Subscriber = {
  ID: string;
  Hash: string;
};

type NotificationSubscriber = {
  Subscriber: Subscriber;
};

type UserShareSuggestionArgs = {
  scenario: `${EntityType}.share`;
  entityId: string;
};

export const preferencesAdatpor = createEntityAdapter<UserPreference>({
  selectId: (entity: UserPreference): string => entity.ID,
});
const preferencesInitialState = preferencesAdatpor.getInitialState();

/**
 * User API Slice
 */
export const userSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserInvitations: build.query<AccountInvitation[], void>({
      query: () => "/v2/users/me/invitations",
      transformResponse: (response: { Invitations: AccountInvitation[] }) => response.Invitations,
    }),
    getUserMe: build.query<User | undefined, void>({
      query: () => `/v2/users/me`,
      transformResponse: (response: User) => response,
      providesTags: (result) => (result ? [{ type: "User", id: result.ID }] : []),
    }),
    getNotificationSubscriber: build.query<NotificationSubscriber, void>({
      query: () => "/v2/notification-subscriber",
    }),
    getUserMeAccounts: build.query<UserAccountAllocationResponse[], void>({
      query: () => `/v2/users/me/accounts`,
      transformResponse: (response: GetUserAccountsResponse) => response?.Allocations || [],
      providesTags: (result) => (result ? result.map((allocation) => ({ type: "UserAccountAllocation" as const, id: allocation.ID })) : []),
    }),
    getUserByEmail: build.query<User | undefined, string>({
      query: (email: string) => ({
        url: "/v2/users",
        params: { email: email },
      }),
      transformResponse: (response: GetUsersResponse) => {
        return response.Users?.[0] || undefined;
      },
      providesTags: (result) => (result ? [{ type: "User", id: result.ID }] : []),
    }),
    getUserPreferences: build.query({
      query: () => `/v2/user-preferences`,
      transformResponse: (response: GetUserPreferencesResponse) => preferencesAdatpor.setAll(preferencesInitialState, response.Items),
      providesTags: (result) =>
        result
          ? [
              ...Object.values(result.entities).map((entity) => ({ type: "UserPreference" as const, id: `${entity?.Key}_${entity?.Subkey}` })),
              { type: "UserPreference", id: "LIST" },
            ]
          : [{ type: "UserPreference", id: "LIST" }],
    }),
    updateUserPreferences: build.mutation<UserPreference, Pick<UserPreference, "Key" | "Subkey" | "Value">>({
      query: ({ Key, Subkey, Value }) => ({
        url: `/v2/user-preferences`,
        method: "POST",
        body: {
          Key,
          Subkey,
          Value,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "UserPreference" as const, id: `${result?.Key}_${result?.Subkey}` }],
    }),
    getUserShareSuggestions: build.query<UserShareSuggestion[], UserShareSuggestionArgs>({
      query: ({ scenario, entityId }) => {
        const resourceType = scenario.split(".")[0] as EntityType;
        return {
          url: "/v2/suggestions",
          params: {
            scenario: scenario,
            [`${resourceType}_id`]: entityId,
          },
        };
      },
      transformResponse: (response: GetUserShareSuggestionsResponse) => response.Suggestions || [],
      providesTags: (result, _error, arg) => (result ? [{ type: "ShareSuggestions", id: arg.entityId }] : []),
    }),
    getUserMentionSuggestions: build.query<UserMentionSuggestion[], { projectId: string }>({
      query: ({ projectId }) => ({
        url: "/v2/suggestions",
        params: {
          scenario: "project.comment-mention",
          project_id: projectId,
        },
      }),
      transformResponse: (response: GetUserMentionSuggestionsResponse) => response.Suggestions || [],
      providesTags: (result, _error, arg) => (result ? [{ type: "MentionSuggestions", id: arg.projectId }] : []),
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useGetUserInvitationsQuery,
  useGetUserMeQuery,
  useGetNotificationSubscriberQuery,
  useGetUserMeAccountsQuery,
  useGetUserByEmailQuery,
  useGetUserPreferencesQuery,
  useUpdateUserPreferencesMutation,
  useGetUserShareSuggestionsQuery,
  useGetUserMentionSuggestionsQuery,
} = userSlice;

/**
 * Selectors
 */
const selectPreferencesResult = userSlice.endpoints.getUserPreferences.select(undefined);
const selectPreferencesData = createSelector(selectPreferencesResult, (preferencesResult) => preferencesResult.data);

export const { selectAll: selectAllPreferences, selectById: selectPreferenceById } = preferencesAdatpor.getSelectors(
  (state: Store) => selectPreferencesData(state) ?? preferencesInitialState
);

export const getUserPreferenceValue = createSelector(
  [
    selectAllPreferences,
    (state: Store, key: UserPreferenceKey) => key,
    (state: Store, key: UserPreferenceKey, subKey: UserPreferenceSubKey) => subKey,
  ],
  (preferences: UserPreference[], key: UserPreferenceKey, subKey: UserPreferenceSubKey) => {
    return preferences.find((preference) => preference.Key === key && preference.Subkey === subKey)?.Value;
  }
);

export const selectUserMeAccountsResult = userSlice.endpoints.getUserMeAccounts.select();

/*
 * Select an account (at random) for the current user.
 * TODO FND-618: This is a temporary function until we have an account selection component.
 */
export const selectUserAccountIdAtRandom = createSelector(selectUserMeAccountsResult, (userAccountResult) => {
  const allocations = [...(userAccountResult?.data ?? [])];

  // We define "random" in this case to mean "lowest alphabetically".
  allocations.sort((a, b) => a.Account.Name.localeCompare(b.Account.Name));
  return allocations?.[0]?.Account.ID;
});

const selectUserMeResult = userSlice.endpoints.getUserMe.select();
export const selectUserId = createSelector(selectUserMeResult, (userResult) => userResult.data?.ID);
