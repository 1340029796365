import { BBox, FeatureCollection } from "@turf/helpers";
import { Feature, MultiPolygon } from "geojson";
import { Map, MapboxGeoJSONFeature } from "mapbox-gl";

import { getCurrentProject, getCurrentProjectData } from "fond/project";
import * as turf from "fond/turf";
import { isValidBoundingBox, until } from "fond/utils";
import { addTimestampToUrl } from "fond/utils/url";

import { AppDispatch, Store } from "../types";

let mapInstance: Map | null;
let promise: any;
let resolve: any;

function reset() {
  mapInstance = null;
  promise = new Promise((_resolve) => {
    resolve = _resolve;
  });
}

/**
 * If the map is ready, execute `func` immediately, else execute it when
 * the map becomes ready.
 */
function whenReady(func: () => void) {
  if (mapInstance != null) {
    func();
  } else {
    promise.then(func);
  }
}

reset();

export function getMap() {
  return mapInstance;
}

/**
 * The following functions are designed to be dispatched as Redux actions even
 * though they don't actually do any Redux stuff (except for `viewDesign` which
 * uses `getState`). This is just so callers don't need to worry about which
 * functions use the store and which don't; they are all called in the same way
 * regardless. Also means we can update these functions to interact more closely
 * with the store without modifying callers.
 */

export function loadMap(map: Map) {
  return (dispatch: AppDispatch) => {
    mapInstance = map;
    resolve();
  };
}

export function unloadMap() {
  return (dispatch: AppDispatch) => {
    reset();
  };
}

export function zoomIn() {
  // We have tests that inspect the names of the inner functions returned by
  // these actions.
  // eslint-disable-next-line @typescript-eslint/no-shadow
  return function zoomIn(dispatch: AppDispatch) {
    whenReady(() => {
      mapInstance?.zoomIn();
    });
  };
}

export function zoomOut() {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  return function zoomOut(dispatch: AppDispatch) {
    whenReady(() => {
      mapInstance?.zoomOut();
    });
  };
}

export function zoomTo(features: MapboxGeoJSONFeature[]) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  return function zoomTo(dispatch: AppDispatch) {
    whenReady(() => {
      // Only include features with geometry. Throw an error if there are no features with geometry.
      const featuresWithGeometry = features.filter((f) => f.geometry != null);
      if (featuresWithGeometry.length === 0) {
        throw Error(
          `Attempted to zoom to the extent of ${features.length === 0 ? "an empty features list" : "a list of features of NULL geometry"}.`
        );
      }

      // Determine the Polygon Bounding Box and then fit that within the view
      const bbox = turf.extent({ type: "FeatureCollection", features: featuresWithGeometry });
      mapInstance?.fitBounds(bbox, { padding: 10 });
    });
  };
}

export function viewDesign(versionBbox: [[number, number], [number, number]] | undefined, selectedArea: Feature<MultiPolygon> | null = null) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  return function viewDesign(dispatch: AppDispatch, getState: () => Store) {
    whenReady(() => {
      const { project } = getState();
      const currentProject = getCurrentProject(project);
      const data = getCurrentProjectData(project);
      const geoJson = getBboxPolygon(Object.values(data.layers)) || selectedArea;
      let bbox = versionBbox ?? currentProject.BoundingBox;

      if (geoJson) {
        // If we have features determine the bounding box of all geojson
        const [x1, y1, x2, y2] = turf.bbox(geoJson);
        bbox = [
          [x1, y1],
          [x2, y2],
        ];
      }

      // Be careful we don't crash Mapbox by passing it out-of-range coordinates.
      if (isValidBoundingBox(bbox)) {
        mapInstance?.fitBounds(bbox, { padding: 20 });
      }
    });
  };
}

/*
 * Return the union of an array of bounding boxes.
 */
function bboxUnion(bboxs: BBox[]): BBox {
  return [
    Math.min(...bboxs.map((bbox) => bbox[0])),
    Math.min(...bboxs.map((bbox) => bbox[1])),
    Math.max(...bboxs.map((bbox) => bbox[2])),
    Math.max(...bboxs.map((bbox) => bbox[3])),
  ];
}

/**
 * Return the bounding box of an array of feature collections as a feature.
 */
export function getBboxPolygon(layers: FeatureCollection[]) {
  const filteredLayers = layers.filter((layer) => layer.features != null && layer.features.length > 0);
  if (filteredLayers.length === 0) {
    return null;
  }
  return turf.bboxPolygon(bboxUnion(filteredLayers.map((layer) => turf.bbox(layer))));
}

/**
 * Reset the feature states of features touched during editing.
 */
export function clearFeatures(featuresToClear: any[]) {
  return async (dispatch: AppDispatch) => {
    // Mapbox has some issue where if you call `setFeatureState` but also
    // remove a related layer in quick succession, a subsequent render may
    // throw a "TypeError: Cannot read property 'queryRadius' of undefined".
    // Waiting until all the Mapbox Draw stuff has been cleared before calling
    // `setFeatureState` seems to avoid the problem.

    await until(() => {
      return mapInstance != null && mapInstance.getSource("mapbox-gl-draw-hot") == null;
    });

    for (let { layerId, featureId } of featuresToClear) {
      mapInstance?.setFeatureState({ source: layerId, id: featureId }, { isEditing: false });
    }
  };
}

/*
 * Refresh tiles by invalidating the cache, clearing the tiles and triggering a re-render.
 * Cache invalidation is performed by adding a timestamped url parameter to the vector tile source url.
 */
export const refreshTileSource = (sourceId: string) => {
  return (dispatch: AppDispatch, getState: () => Store): void => {
    const source = mapInstance?.getSource(sourceId);
    if (source?.type === "vector") {
      // Update the sourceURL with a cache invalidation query parameter.
      source.tiles = source.tiles?.map((tileUrl) => addTimestampToUrl(tileUrl).toString());
      // Refresh the current view
      // we cast to any because Mapbox doesn't include this function in their type stubs.
      (source as any).reload();
    }
  };
};
