import { versionsSlice } from "fond/api";
import mixpanel from "fond/mixpanel";
import {
  ADD_IMPORT,
  addImportDispatch,
  COMPLETE_IMPORT,
  completeImportAction,
  completeImportDispatch,
  loadImportsDispatch,
  REMOVE_IMPORT,
  removeImportAction,
  removeImportDispatch,
  START_IMPORT,
  startImportAction,
  startImportDispatch,
  UPDATE_IMPORT,
  updateImportAction,
  updateImportDispatch,
  VERSION_UPLOAD_CONCURRENCY_LIMIT,
} from "fond/redux/imports";
import { AppThunk, AppThunkDispatch, ImportStatus, Store } from "fond/types";

export const loadImports =
  ({ versionId }: loadImportsDispatch): AppThunk<Promise<void>> =>
  async (dispatch: AppThunkDispatch) => {
    const versionImportStatusResponse = await dispatch(versionsSlice.endpoints.getImportStatus.initiate({ versionId }, { forceRefetch: true }));

    for (const layerImport of versionImportStatusResponse?.data?.Layers || []) {
      if (![ImportStatus.COMPLETE, ImportStatus.ERROR].includes(layerImport.ImportStatus.Status)) {
        dispatch({
          type: ADD_IMPORT,
          versionId: versionId,
          layerKey: layerImport.LayerKey,
          layerId: layerImport.ID,
          displayName: layerImport.LayerKey, // TODO: Get the config label from the API response
          files: [],
          started: new Date(layerImport.ImportStatus.StartedAt),
          status: layerImport.ImportStatus.Status,
          progress: 0,
        });
      }
    }
  };

export const addImport =
  ({ versionId, layerKey, layerId, displayName, files, started, status, progress }: addImportDispatch): AppThunk<Promise<void>> =>
  async (dispatch: AppThunkDispatch, getState: () => Store) => {
    // Read the current import state.
    const { imports } = getState();

    // eslint-disable-next-line no-async-promise-executor
    await new Promise<void>(async (resolve, reject) => {
      // Handle forbidden import attempts.
      const existingImport = imports?.[versionId]?.[layerKey];
      if (existingImport != null && ![ImportStatus.COMPLETE, ImportStatus.ERROR].includes(existingImport.status)) {
        return reject(Error("Please dismiss the currently active import and try again."));
      } else if (imports[versionId] == null && Object.keys(imports).length >= VERSION_UPLOAD_CONCURRENCY_LIMIT) {
        return reject(
          Error(`Only ${VERSION_UPLOAD_CONCURRENCY_LIMIT} versions may be imported at the same time. Please dismiss an import and try again.`)
        );
      }

      if (existingImport != null) {
        await dispatch({
          type: REMOVE_IMPORT,
          versionId: versionId,
          layerKey: layerKey,
        });
      }

      // Dispatch the action and resolve.
      dispatch({
        type: ADD_IMPORT,
        versionId: versionId,
        layerKey: layerKey,
        layerId: layerId,
        displayName: displayName,
        files: files,
        started: started === undefined ? null : started,
        status: status === undefined ? ImportStatus.STARTING : status,
        progress: progress === undefined ? 0 : progress,
      });
      return resolve();
    });
  };

export const startImport = ({ versionId, layerKey, started }: startImportDispatch): startImportAction => ({
  type: START_IMPORT,
  versionId: versionId,
  layerKey: layerKey,
  started: started,
});

export const updateImport = ({ versionId, layerKey, layerId, status, progress, error, completed }: updateImportDispatch): updateImportAction => {
  if (error) {
    mixpanel.track("Failed layer import", { layerId, error, completed });
  }
  return {
    type: UPDATE_IMPORT,
    versionId: versionId,
    layerKey: layerKey,
    layerId: layerId,
    status: status,
    progress: progress,
    error: error,
    completed: completed,
  };
};

export const completeImport = ({ versionId, layerKey, completed }: completeImportDispatch): completeImportAction => ({
  type: COMPLETE_IMPORT,
  versionId: versionId,
  layerKey: layerKey,
  completed: completed,
});

export const removeImport = ({ versionId, layerKey }: removeImportDispatch): removeImportAction => ({
  type: REMOVE_IMPORT,
  versionId: versionId,
  layerKey: layerKey,
});
