import { Feature, MultiPolygon, Polygon } from "geojson";
import spatialTree from "geojson-rbush";

import * as turf from "fond/turf";
import { makeUuid } from "fond/utils";

type NodeFeature = Feature<Polygon | MultiPolygon, { id: string }>;

interface NodeCandidate {
  intersectionArea: number;
  newWeight: number;
  nearbyNodeEntry: Feature<Polygon | MultiPolygon, { id: string; addressCount: number }>;
}

/**
 * Combines collections of the given features into larger features, ensuring that no combination
 * of features has more than `maxAddresses` addresses.
 *
 * Each feature should have an `address_count` property that indicates the number of addresses
 * within that feature. If this property is not set, then the feature will be treated as having
 * zero addresses.
 *
 * @param features A collection of features to combine.
 * @param maxAddresses The maximum number of addresses that a combined feature can have.
 * @return A collection of combined features.
 */
export const autoCombine = (
  features: Feature<Polygon | MultiPolygon>[],
  maxAddresses: number
): Feature<Polygon | MultiPolygon, { addressCount: number }>[] => {
  if (!features) {
    return [];
  }

  // Performance improvement ideas:
  // - Calculate pairwise intersection areas upfront. Why? The intersection area of polygons P = P_1 U P_2 and Q
  //   is well approximated by the sum of the intersection areas of both P_1 and P_2 with Q for reasonable disjoint
  //   polygons.
  // - Use geometry simplification when computing intersection area.
  // - At that point we might have a "Capacitated Clustering Problem", and some smart person has probably
  //   written a neat algorithm for approximately solving that.

  const featuresWithIds = features
    .map((f) => {
      const id = makeUuid();
      const addressCount = f.properties?.address_count ?? 0;
      return turf.feature(f.geometry, { id, addressCount });
    })
    .sort((f1, f2) => f1.properties.addressCount - f2.properties.addressCount);

  const nodeEntries = new Map(
    featuresWithIds.map((f) => [f.properties.id, turf.feature(f.geometry, { id: f.properties.id, addressCount: f.properties.addressCount })])
  );
  const tree = spatialTree<Polygon | MultiPolygon, { id: string }>();
  tree.load(featuresWithIds.map((f) => turf.feature(f.geometry, { id: f.properties.id })));

  featuresWithIds.forEach(({ properties: { id: nodeId } }) => {
    const nodeEntry = nodeEntries.get(nodeId);
    if (nodeEntry === undefined) {
      return;
    }

    // Identify all nodes that are spatially close, ordered by absolute intersection area decreasing.
    const bufferedNodeEntry = turf.buffer(nodeEntry, 1, { units: "meters" }) as NodeFeature;

    const candidateNodes = tree
      .search(bufferedNodeEntry)
      .features.map((nearbyNode) => {
        const nearbyNodeId = nearbyNode.properties.id;
        // Can't combine a node with itself
        if (nearbyNodeId === nodeId) {
          return null;
        }

        const nearbyNodeEntry = nodeEntries.get(nearbyNodeId);
        // Can't combine with a node that doesn't exist
        if (nearbyNodeEntry === undefined) {
          return null;
        }

        // Can't combine if it would violate the maxAddresses constraint
        const { addressCount: nearbyNodeWeight } = nearbyNodeEntry.properties;
        const newWeight = nodeEntry.properties.addressCount + nearbyNodeWeight;
        if (newWeight > maxAddresses) {
          return null;
        }

        // Important: use bufferedNodeEntry here otherwise the intersection will be empty.
        const intersection = turf.intersect(turf.featureCollection([bufferedNodeEntry, nearbyNode]));
        const intersectionArea = intersection ? turf.area(intersection) : 0;
        return { intersectionArea: intersectionArea, newWeight: newWeight, nearbyNodeEntry: nearbyNodeEntry };
      })
      .filter((candidate): candidate is NodeCandidate => candidate !== null)
      .sort((c1, c2) => c2.intersectionArea - c1.intersectionArea);

    if (!candidateNodes.length) {
      return;
    }

    // Merge the current node into the best candidate node, update all data structures accordingly.
    const { nearbyNodeEntry, newWeight } = candidateNodes[0];
    const newId = nearbyNodeEntry.properties.id;
    const newEntry = turf.feature(turf.union(nodeEntry, nearbyNodeEntry)!.geometry, { id: newId, addressCount: newWeight });

    nodeEntries.delete(nodeId);
    nodeEntries.delete(newId);
    nodeEntries.set(newId, newEntry);
    tree.remove(nearbyNodeEntry, (a, b) => a.properties.id === b.properties.id);
    tree.remove(nodeEntry, (a, b) => a.properties.id === b.properties.id);
    tree.insert(newEntry);
  });

  return Array.from(nodeEntries.values()).map((f) => {
    return turf.feature(f.geometry, { addressCount: f.properties.addressCount });
  });
};
